import Axios from "@app/api/axios";

import { PERMISSIONS } from "@utils/constant";

const getPermissionsService = (portalType = "", roleName = "") =>
  Axios.get(
    `${PERMISSIONS}?per_page=1000&fields=id,name,portal,group&filter[portal]=${portalType}&filter[roles.name]=${roleName}`,
  ).then((response) => response.data);

export default getPermissionsService;
