import React from "react";
import PropTypes from "prop-types";

import { allExpanded, darkStyles, JsonView } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import Div from "@components/Div";

const JSONViewer = ({ data }) => {
  return (
    <Div width={[1, 1, "90%", "70%"]}>
      <JsonView data={data} shouldExpandNode={allExpanded} style={darkStyles} />
    </Div>
  );
};

JSONViewer.propTypes = {
  data: PropTypes.object,
};

export default JSONViewer;
