import React from "react";
import Section from "@components/Section";
import Div from "@components/Div";
import Container from "@components/Container";
import { H2 } from "@components/Heading";
import { TextLargeSemiBoldWeight } from "@components/Text";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@utils/constant";
const AccessDenied = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const permission_denied_message =
    history.location.state && history.location.state.message
      ? history.location.state.message
      : messages.permissions_access_denied_for_subscription;

  const redirectOnSubmit = (url) => {
    history.push(url);
  };
  return (
    <Section>
      <Container>
        <Div textAlign={"center"}>
          <H2 mb={4} color="var(--blue-dark) !important">
            {messages.sorry}
          </H2>
          <TextLargeSemiBoldWeight>
            {permission_denied_message}
          </TextLargeSemiBoldWeight>
          <Div
            mt={4}
            display="flex"
            justifyContent="center"
            textAlign={"center"}
          >
            <LinkArrow
              mx={2}
              justifyContent={["center", "left"]}
              label={messages.to_homepage_label}
              onClick={redirectOnSubmit.bind(this, ROUTES.SEARCH.URL)}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
            <LinkArrow
              mx={2}
              justifyContent={["center", "left"]}
              label={messages.my_profile_button}
              onClick={redirectOnSubmit.bind(this, ROUTES.COMPANY_INFO.URL)}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Div>
      </Container>
    </Section>
  );
};
export default AccessDenied;
