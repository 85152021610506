import styled from "styled-components";
import { Editor as PREditor } from "primereact/editor";
import { space, layout } from 'styled-system';


export const Editor = styled(PREditor).attrs({
  modules: {
    toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
    clipboard: true
  },
  theme: "snow",
})`
  ${space}
  ${layout}

  border-color: var(--turquoise) !important;

  .p-editor-toolbar {
    display: none;
  }

  .ql-editor {
    font-size: var(--fs-text-m) !important;
  }
  .ql-toolbar {
    border-radius: 10px 10px 0 0;
  }
  .ql-container {
    border-radius: 0 0 10px 10px;
    height: ${({height = "150px"}) => height};
  }
  .ql-snow {
    border-color: var(--turquoise) !important;
    background-color: var(--turquoise-light) !important;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  button {
    &.ql-active {
      color: #12120d !important;
      background-color: #ffffff !important;
      stroke: #12120d !important;
      margin-right: 4px;
    }
  }
  &:enabled {
    &:focus {
      box-shadow: 0 0 0 0.2rem var(--white);
      border-color: var(--turquoise);
    }
    &:hover {
      border-color: var(--turquoise);
      box-shadow: none;
    }
  }
`;
export default Editor;
