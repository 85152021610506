import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

/**
 * BillingEmail validation
 */
const BillingEmailSchema = Yup.object().shape({
  billing_email: Yup.string()
    .typeError(
      <FormattedMessage
        id="validation_empty_email_input"
        defaultMessage="Enter email"
      />,
    )
    .email(
      <FormattedMessage
        id="validation_valid_email_input"
        defaultMessage="Invalid email"
      />,
    )
    .required(
      <FormattedMessage
        id="validation_empty_email_input"
        defaultMessage="Enter email"
      />,
    ),
});

export default BillingEmailSchema;
