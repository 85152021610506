import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import { default as BaseAccordion } from "@components/Accordion";
import { H2 } from "@components/Heading";

import { useReportHooks } from "../hooks";

const StyledAccordion = styled(BaseAccordion)`
  .p-accordion-header-link {
    align-items: center;
    justify-content: flex-start;
    padding: 0px !important;
  }

  .p-accordion-toggle-icon {
    margin-left: 8px !important;
    width: 20px;
    height: 20px;
  }

  .p-accordion-content {
    background-color: transparent;
  }
`;

const SingleTabAccordion = ({ title, state, children }) => {
  const { isPDF } = useReportHooks();

  const activeIndex = state === "collapsed" ? -1 : 0;
  const content = [
    {
      title: <H2 color="var(--blue-dark) !important">{title}</H2>,
      content: children,
    },
  ];
  return (
    <StyledAccordion activeIndex={isPDF ? 0 : activeIndex} content={content} />
  );
};

SingleTabAccordion.propTypes = {
  title: PropTypes.string,
  state: PropTypes.string,
  children: PropTypes.node,
};

export default SingleTabAccordion;
