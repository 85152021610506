import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const SuccessDialog = ({ message, title, onConfirm, ...restProps }) => {
  const { messages } = useIntl();

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "450px", "450px"]}
      m={[3, 3, "auto", "auto"]}
      {...restProps}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={4}
      >
        <H3>{`${title || messages.label_done}!`}</H3>
        <Text textAlign="center">{message}</Text>
        <PrimaryButton
          width={[1, 1, "150px", "150px"]}
          label={messages.label_ok}
          onClick={onConfirm}
        />
      </Div>
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
};

export default SuccessDialog;
