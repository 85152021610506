import React from "react";
import Div from "@components/Div";
import Ellipse24Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { TextMediumWeight } from "@components/Text";
const ApplyDiscount = () => {
  const { messages } = useIntl();
  return (
    <Div
      ml={2}
      py={2}
      pr={"17px"}
      pl={"22px"}
      mt={"10px"}
      display="inline-block"
      backgroundColor={"rgba(var(--grey-lightest-rgba),0.4)"}
    >
      <Ellipse24Icon
        ml={"-30px"}
        mr={"10px"}
        name="tick-mark"
        p={"3px"}
        fontSize="var(--fs-text-s)"
        rounded
        display="inline-flex"
      ></Ellipse24Icon>
      <TextMediumWeight fontSize="var(--fs-text)">
        {messages.discount_for_Analys}
      </TextMediumWeight>
    </Div>
  );
};

export default ApplyDiscount;
