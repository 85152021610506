import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link as PRLink } from "react-router-dom";
import styled from "styled-components";
import { Accordion as PRAccordion, AccordionTab } from "primereact/accordion";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import CountTag from "@components/CountTag";
import { H5 } from "@components/Heading";
import Icon from "@components/Icon";

const Link = styled(PRLink)`
  text-decoration: none;
`;

const Accordion = styled(PRAccordion)`
  .p-accordion-content {
    padding: 0px !important;
  }
  .p-accordion-header {
    &:not(.p-disabled).p-highlight {
      &:hover {
        .p-accordion-header-link {
          background: var(--grey-lightest);
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: var(--grey-lightest);
          }
        }
      }
    }
  }
  a {
    padding: 0px !important;
    svg {
      width: 20px;
      height: 12px;
    }
  }
  
  .p-accordion-tab {
    margin-bottom: 0px !important;
    .p-accordion-toggle-icon {
      margin-left: auto;
      @media (max-width: 1023px) {
      margin-right: 22px !important;
    }
    }
  }
`;

const StyledDiv = styled(Div)`
  margin: ${({ dropDownItem }) => (dropDownItem ? "0" : "0px 0px")};
  padding: 12px;
  display: flex;
  color: var(--grey-dark);
  align-items: start;

  &:first-child {
    margin-top: 0px;
  }

  &:hover {
    background: var(--grey-lightest);
  }

  span {
    font-size: var(--fs-h4);
    margin-right: 0;
  }
`;

const SideMenuContent = (props) => {
  const { pathname = "" } = useLocation();
  let { Component = () => <></>, navItems: items = [], mobileView = false } = props;
  const ActiveMenuItem = (url) => pathname === url;

  const handleWpPageRedirection = (url) => () => {
    window.location = url;
  };

  const menuItem = (item, isDropDownItem = false) => {
    return (
      <StyledDiv
        dropDownItem={isDropDownItem}
        width={[1, 1, 1, 1]}
        justifyContent={"space-between"}
        key={item?.label}
        bg={ActiveMenuItem(item.redirectTo) && "var(--grey-lightest)"}
      >
        <Div display="flex" alignItems="center" pl={mobileView?"4px":"0px"} >
          
            <Icon
            color="var(--turquoise)"
            mr={"8px"}
            fontSize={"var(--fs-text-secondary) !important"}
            width={"16px !important"}
            name={item.tabIcon}
            />
          <TextMediumWeight
            wordBreak="break-all"
            mr={4}
            fontSize={"var(--fs-text-small) !important"}
            letterSpacing={"0.3px !important"}
            fontWeight={isDropDownItem && "var(--light-weight) !important"}
          >
            {item.label}
          </TextMediumWeight>
          {item.count && <CountTag count={item.count} />}
        </Div>
      </StyledDiv>
    );
  };

  const subMenuItems = (item) => {
    return item?.dropDownOptions?.map((obj) => {
      if (obj?.isWpPage) {
        return (
          <Link
            width={1}
            key={obj.label}
            onClick={handleWpPageRedirection(obj.redirectTo)}
          >
            {menuItem(obj, true)}
          </Link>
        );
      } else {
        return (
          <Link to={obj.redirectTo} width={1} key={obj.label}>
            {menuItem(obj, true)}
          </Link>
        );
      }
    });
  };

  return (
    <Div>
      <Component />
      <Div width={[1, 1, 1, 190]}>
        {items.map((item) => {
          if (item?.isWpPage) {
            return (
              <Link
                width={1}
                key={item.label}
                onClick={handleWpPageRedirection(item.redirectTo)}
              >
                {menuItem(item, false)}
              </Link>
            );
          } else if (item.isDropDown) {
            return (
              <Accordion key={item?.label}>
                <AccordionTab
                  header={<H5 width={1}>{menuItem(item, false)}</H5>}
                >
                  {subMenuItems(item)}
                </AccordionTab>
              </Accordion>
            );
          } else {
            return (
              <Link to={item.redirectTo} width={1} key={item.label}>
                {menuItem(item, false)}
              </Link>
            );
          }
        })}
      </Div>
    </Div>
  );
};

SideMenuContent.propTypes = {
  Component: PropTypes.node,
  navItems: PropTypes.array,
  mobileView: PropTypes.bool,
};

export default SideMenuContent;
