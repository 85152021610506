const AnalyticsEvent = {
  PageView: "pageview",
  Event: "event",
  Link: "link",
  Click: "click",
  NotFound: "search_result_not_found",
  Found: "search_result_found",
  Focus: "focus",
};

export const trackPageView = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: AnalyticsEvent.PageView,
      page: {
        url: document.location.pathname,
        title: document.title,
      },
    });
  }
};

export const trackEvent = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: AnalyticsEvent.Event,
      eventProps: {
        category: AnalyticsEvent.Link,
        action: AnalyticsEvent.Click,
      },
    });
  }
};

export const trackPageFound = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: AnalyticsEvent.PageView,
      page: {
        url: document.location.pathname,
        title: document.title,
      },
    });
  }
};

export const trackPageNotFound = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: AnalyticsEvent.PageView,
      page: {
        url: document.location.pathname,
        title: document.title,
      },
    });
  }
};

export const trackEventFocus = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: AnalyticsEvent.Event,
      eventProps: {
        action: AnalyticsEvent.Focus,
      },
    });
  }
};
