import React from "react";
import { FormattedMessage } from "react-intl";

const YesnoOptions = () => [
  {
    label: <FormattedMessage id="yes_label" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: 0,
  },
];

export default YesnoOptions;
