import React from "react";
import PropTypes from "prop-types";

import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";
import ProgressSpinner from "@components/ProgressSpinner";

import surveyTheme from "@assets/survey_theme.json";

import Config from "@src/config";

const SurveyForm = ({
  data,
  formConfig,
  mode = "edit",
  onComplete,
  onRefLoaded,
}) => {
  const _survey = new Model(formConfig);
  _survey.applyTheme(surveyTheme);
  _survey.onComplete.add(onComplete);
  _survey.locale = Config.language;
  _survey.showCompleteButton = false;
  _survey.data = { ..._survey.data, ...data };
  _survey.mode = mode;
  const survey = _survey;
  onRefLoaded?.(survey);

  _survey.onVisibleChanged.add((sender, options) => {
    // eslint-disable-next-line no-console
    console.log("Visible changed", sender, options);
    if (options.name === "apiConfiguration" && options.visible) {
      const apiConfigurations = sender.getValue("apiConfiguration");

      if (!apiConfigurations || apiConfigurations.length === 0) {
        return;
      }

      apiConfigurations.map((config, index) => {
        const apiUrl = config.api_url;
        const requestBody = config.request_body;

        if (!apiUrl || !requestBody) {
          // Handle missing data
          const errorMessage = `Configuration ${index + 1}: Missing API URL or Request Body`;
          sender.setValue(`apiConfiguration[${index}].apiResult`, errorMessage);
          return Promise.resolve();
        }

        // Parse the request body
        let parsedBody;
        try {
          parsedBody = JSON.parse(requestBody);
        } catch (error) {
          const errorMessage = `Configuration ${index + 1}: Invalid JSON in request body`;
          sender.setValue(`apiConfiguration[${index}].apiResult`, errorMessage);
          return Promise.resolve();
        }

        // Make the API call
        return fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(parsedBody),
        })
          .then(response => response.json())
          .then(data => {
            // Set API result in the corresponding `apiResult` field
            const resultMessage =
              data.message ||
              `Configuration ${index + 1}: API call succeeded, but no message returned.`;
            sender.setValue(
              `apiConfiguration[${index}].apiResult`,
              resultMessage
            );
          })
          .catch(error => {
            // Handle API errors
            const errorMessage = `Configuration ${index + 1}: API call failed - ${error.message}`;
            sender.setValue(
              `apiConfiguration[${index}].apiResult`,
              errorMessage
            );
          });
      });
    }
  });

  if (!survey && !survey?.data) {
    return <ProgressSpinner />;
  }

  return <Survey model={survey} />;
};

SurveyForm.propTypes = {
  data: PropTypes.object,
  formConfig: PropTypes.any,
  mode: PropTypes.string,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
};

export default SurveyForm;
