import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import Div from "./Div";
import { breakpoints } from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

const StyledDiv = styled(Div)`
  margin: ${({ isSearchPage }) => (isSearchPage ? "0 10%" : "0 auto")};
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    width: ${({ isSearchPage }) => (isSearchPage ? "90%" : "100%")};
    margin: ${({ isSearchPage }) => (isSearchPage ? "5%" : 0)};
  }
`;

const Container = (props) => <StyledDiv {...props} />;

Container.propTypes = {
  className: Proptypes.string,
};

export default Container;
