import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { compose, space, layout, flexbox } from "styled-system";

const StyledImg = styled("img")`
  ${compose(layout, space, flexbox)}
  cursor: pointer;
`;

const Logo = ({ logo, handleClick, ...props }) => {
  return <StyledImg src={logo} alt="logo" onClick={handleClick} {...props} />;
};

Logo.propTypes = {
  logo: Proptypes.string,
  handleClick: Proptypes.func,
};

export default Logo;
