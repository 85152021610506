import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { compose, space, layout, flexbox, position } from "styled-system";
import { H2 } from "@components/Heading";
import { default as PRDialog } from "@components/Dialog";
import ActionTemplate from "./ActionTemplate";
import { useIntl } from "react-intl";

const Dialog = styled(PRDialog)`
  ${compose(space, layout, flexbox, position)}
  text-align: center;
  .p-dialog-header {
    padding: 35px 24px 0;
    text-align: center;
    color: var(--grey-dark);
    .p-dialog-title {
      padding-bottom: 25px;
    }
  }
  .p-dialog-content {
    color: var(--grey-dark);
    padding: 0;
    > div {
      text-align: left;
    }
  }
`;

const FilterTemplate = (props) => {
  const {
    filterType,
    filters = [],
    onApplyFilter,
    onCancelFilter,
    onHide,
    options,
    setOptions,
    onFilterSelectedData,
  } = props;
  const [optionsCopy, setOptionsCopy] = useState(filters);
  const { messages } = useIntl();

  useEffect(() => {
    setOptionsCopy(filters);
  }, [filters]);

  const handleApplyFilters = (e) => {
    const currentData = options;
    const selectedData = onFilterSelectedData(options);
    setOptionsCopy(currentData);
    if (onApplyFilter) {
      onApplyFilter(selectedData, options, e);
    }
    onHide();
  };

  const handleCancelFilters = (e) => {
    const selectedData = onFilterSelectedData(options);
    setOptions(optionsCopy);
    if (onCancelFilter) {
      onCancelFilter(selectedData, options, e);
    }
    onHide();
  };

  const handleClearFilters = () => {
    const clearfilters = options.map((obj) => {
      const { options: objOptions } = obj;
      obj.options = objOptions.map((opt) => {
        const { id = "" } = opt;
        if (
          id === "start_date" ||
          id === "end_date" ||
          obj.type === "input" ||
          obj.type === "multiselect"
        ) {
          return { ...opt, value: "" };
        }
        return { ...opt, checked: false };
      });
      return obj;
    });
    setOptions(clearfilters);
  };

  const handleOnClick = (value, id) => (e) => {
    const filterSelectedData = options.map((optValues) => {
      if (optValues.options)
        optValues.options = optValues.options.map((obj) => {
          if (value === "calendar" && obj.id === id) {
            return { ...obj, value: e.value };
          } else if (obj.value === value && obj.id === id) {
            return { ...obj, checked: !obj.checked };
          } else if (
            (value === "input" || (value === "multiselect" && obj.id === id)) &&
            optValues.type === value
          ) {
            return { ...obj, value: e.target.value };
          }
          return obj;
        });
      return optValues;
    });
    setOptions(filterSelectedData);
  };

  const filterButtons = [
    {
      id: "ok",
      type: "button",
      label: messages.label_ok,
      width: [
        "-webkit-fill-available",
        "-webkit-fill-available",
        "auto",
        "auto",
      ],
      minWidth: 170,
      mb: 27,
      mt: 27,
      mx: 3,
      onClick: handleApplyFilters,
    },
    {
      type: "button-outlined",
      label: messages.label_clear_filters,
      icon: "rubber",
      width: [
        "-webkit-fill-available",
        "-webkit-fill-available",
        "auto",
        "auto",
      ],
      minWidth: 170,
      mb: 27,
      mt: [0, 0, 0, 27],
      onClick: handleClearFilters,
    },
    {
      type: "button-outlined",
      label: messages.label_cancel,
      width: [
        "-webkit-fill-available",
        "-webkit-fill-available",
        "auto",
        "auto",
      ],
      minWidth: 170,
      mb: 27,
      mt: [0, 0, 0, 27],
      onClick: handleCancelFilters,
    },
  ];

  const values = options.map((value) => {
    let obj = value;
    obj.options = obj.options.map((val) => ({
      ...val,
      onClick: handleOnClick,
    }));
    return obj;
  });

  const generateAllOptions = [...values, ...filterButtons];

  if (filterType === "dialog") {
    const dialogEl = (children) => (
      <Dialog
        header={<H2>{messages.title_filter}</H2>}
        visible={props.showDialog}
        width={[1, 8 / 10, 8 / 10, "50vw"]}
        onHide={props.onHide}
        draggable={false}
      >
        {children}
      </Dialog>
    );
    return [
      dialogEl(
        generateAllOptions.map((value) => ActionTemplate(value.type, value)),
      ),
    ];
  }
};

export default FilterTemplate;
