import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DataTable from "@components/DataTableV2/DataTable";
import UserContainer from "@layout/UserContainer";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";

import { ROUTES, ADMIN_NEW_WATCHLIST, ADMIN } from "@utils/constant";

const List = ({ customerId = "" }) => {
  const history = useHistory();
  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();

  const hasWriteAccessToWatchlists = hasAllPermissions([
    CustomerPermissions.CustomerWriteWatchLists,
  ]);

  const { id = "" } = useParams();

  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const searchableColumns = ["id", "name"];
  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";

  const handleCreateWatchList = () => {
    if (userInfo.type === ADMIN) {
      const customer_id = !customerId ? id : customerId;
      history.push(
        `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_NEW_WATCHLIST}`,
      );
    } else {
      history.push(ROUTES.NEW_WATCH_LIST.URL);
    }
  };

  const dataTableConfig = {
    enable_csv_download: true,
    csv_filename: "user_watchlist.csv",
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_watchlist_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/watch-lists`,
      method: "GET",
      search_fields: searchableColumns,
    },
    header: {
      actions: [
        {
          id: "new-watch-list",
          type: "button",
          label: messages.label_new_watch_list,
          onClick: handleCreateWatchList,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteAccessToWatchlists,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    columns: [
      {
        title: "label_list",
        width: "200px",
        db_field: "name",
        type: "text",
      },
      {
        title: "title_objects",
        db_field: "objects",
        type: "text",
      },

      {
        title: "label_created",
        db_field: "id",
        type: "dateTime",
      },
      {
        title: "label_last_results",
        db_field: "updated_at",
        type: "dateTime",
      },
      {
        type: "actions",
        width: "50px",

        actions: [
          {
            type: "link",
            href: ({ id }) => `${ROUTES.USER_WATCH_LIST.URL}/${id}`,
          },
        ],
      },
    ],
    filters: [
      {
        title: messages.label_status,
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
          },
          {
            label: messages.deleted,
            value: 0,
          },
        ],
      },
      {
        title: messages.registered_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.watchlist_screen_title,
  };

  return (
    <UserContainer config={headerConfig} wide>
      <DataTable config={dataTableConfig} />
    </UserContainer>
  );
};

List.propTypes = {
  customerId: PropTypes.number,
};

export default List;
