import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";

const WatchListDeleteButtons = ({ onCloseModal, handleSubmit }) => {
  const { messages } = useIntl();

  return (
    <Div
      mt={3}
      display="flex"
      flexDirection={["column-reverse", "row"]}
      justifyContent="center"
      width={300}
    >
      <PrimaryButtonOutlined
        rounded
        semibold
        label={messages.watchlist_label_cancel}
        onClick={onCloseModal}
        width={[1, 150]}
        mr={[0, 4]}
      />
      <PrimaryButtonIcon
        rounded
        semibold
        width={[1, 150]}
        mb={[3, 0]}
        onClick={handleSubmit}
        label={messages.watchlist_label_delete}
      />
    </Div>
  );
};

WatchListDeleteButtons.propTypes = {
  onCloseModal: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default WatchListDeleteButtons;
