import React from "react";
import { useIntl } from "react-intl";
import Proptypes from "prop-types";
import styled from "styled-components";
import Div from "@components/Div";
import { default as StyledInputText } from "@components/StyledInputText";
import { PrimaryButton } from "@components/Button";
import Icon from "@components/Icon";

const InputText = styled(StyledInputText)`
  border-radius: 40px;
`;

const SearchBar = ({ type }) => {
  const { messages } = useIntl();
  const { value, onChange, onSearch } = type;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Div
      display="flex"
      width={[1, 1 / 2, 1 / 2, 1 / 2]}
      position="relative"
      mr={3}
    >
      <InputText
        width={1}
        height="36px"
        placeholder={messages.search_text}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
      />
      <PrimaryButton
        position="absolute"
        right={0}
        onClick={onSearch}
        height="36px"
        py="0 !important"
      >
        <Icon px={2} name="search" />
      </PrimaryButton>
    </Div>
  );
};

SearchBar.propTypes = {
  type: Proptypes.object,
};

export default SearchBar;
