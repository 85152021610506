import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Proptypes from "prop-types";

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

ScrollToTop.propTypes = {
  history: Proptypes.object,
  children: Proptypes.node,
};

export default withRouter(ScrollToTop);
