import Proptypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  border,
  color,
  shadow,
  gridGap,
  gridColumnGap,
  grid,
} from "styled-system";

const Div = styled('div')`
  ${compose(
    layout,
    space,
    flexbox,
    position,
    typography,
    border,
    color,
    shadow,
    gridGap,
    gridColumnGap,
    grid,
  )};
  white-space: ${({ whiteSpace }) => (whiteSpace ? "nowrap" : "inherit")};
  cursor: ${({ cursor }) => cursor || "inherit"};
`;

Div.propTypes = {
  className: Proptypes.string,
  children: Proptypes.node,
};

export default Div;
