import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import customerService from "@app/services/customers/customerService";

import Div from "@components/Div";
import { InputAutocomplete } from "@components/Input";

import { useToast } from "@hooks/useToast";

const SelectCustomer = ({ formik }) => {
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [customers, setCustomers] = useState([]);

  const { values, handleChange } = formik;

  const getCustomers = async query => {
    try {
      let queryString = "include=subscription";

      if (query) {
        queryString += `&filter[search][columns]=customer_name,id,&filter[search][value]=${query}`;
      }

      const { data: { data: customersData = [] } = {} } =
        await customerService(queryString);
      const formattedData = customersData.map(customer => ({
        ...customer,
        label: `${customer.customer_name} - #${customer.id}`,
      }));

      setCustomers(formattedData);
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    }
  };

  const handleSearch = ({ query = "" }) => {
    getCustomers(query);
  };

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
      gridGap={3}
      width={1}
    >
      <InputAutocomplete
        flex="1 1 40%"
        delay={500}
        name="customer_id"
        optionLabel="label"
        label={messages.label_customer}
        placeholder={messages.placeholder_autocomplete}
        options={customers}
        onComplete={handleSearch}
        onChange={handleChange}
        value={values.customer_id}
        formikProps={formik}
      />

      <Div flex="1 1 40%" />
    </Div>
  );
};

SelectCustomer.propTypes = {
  formik: PropTypes.object,
};

export default SelectCustomer;