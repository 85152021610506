import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { RULES } from "@utils/constant";
import Span from "@components/Span";
import rulesService from "@app/services/rules/rulesService";
import {fetchAllProducts} from "@app/services/services/fetchProductById";

const List = () => {
  const { messages } = useIntl();
  const [types, setTypes] = useState([]);
  const [services, setServices] = useState([]);

  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";
  const searchableColumns = ["id", "name", "product_name"];

  useEffect(() => {
    const loadServicesData = async () => {
      const response = await fetchAllProducts({
        type: "service",
      });
      const { data: { data: products = [] } = {} } = response;
      const formattedData = products.map(o => ({
        name: `${o?.name} #${o?.id}`,
        code: o?.id,
      }));
      setServices(formattedData);
    };
    loadServicesData();
  }, []);

  const renderTooltip = value => {
    if (!value || value.length === 0) {
      return null;
    }
    const productIds = value.map(item => (
      <span key={`$item.id}`}>
        {item.name} - #{item.pivot?.product_id}
      </span>
    ));
    return (
      <Span display="inline-flex" flexDirection="column">
        {productIds}
      </Span>
    );
  };

  

  useEffect(() => {
    const formatRules = rules => {
      return Array.from(
        new Map(
          rules.map(o => [
            o?.type,
            { name: messages[`rule_type_${o.type}`], code: o?.type },
          ])
        ).values()
      );
    };
    const fetchData = async () => {
      try {
        const [rulesResponse] = await Promise.all([rulesService()]);
        const { data: { data: rules = [] } = {} } = rulesResponse;

        const formattedRules = formatRules(rules);

        setTypes(formattedRules);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [messages]);

  const dataTableConfig = {
    header: {
      title: "title_rules",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "rule_reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.date_created,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[type]",
        type: "multiselect",
        options: [
          {
            name: "filter[type]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[type]",
            options: types,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[products.id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItem.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItem.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${RULES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["fields", ["id", "name", "type", "step", "created_at", "updated_at"]],
        ["include", ["products"]],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "rule_id_lable",
        db_field: "id",
        type: "text",
        width: "80px",
        sortable: true,
      },
      {
        title: "date_created",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "rule_label",
        db_field: "name",
        type: "text",
        width: "25%",
        sortable: true,
      },
      {
        title: "type_label",
        db_field: "type",
        type: "text",
        width: "13%",
        sortable: true,
        formatter: value => messages[`rule_type_${value}`] || "-",      },
      {
        title: "produc_id_lable",
        db_field: "products",
        type: "text",
        sortable: true,
        sortField: "products.id",
        formatter: value => {
          const productIds = (value.length > 0 ? value : ["-"])
            .map(item => item.id)
            .join(", ");
          return productIds;
        },
        tooltip: renderTooltip,
      },
      {
        title: "step_lable",
        db_field: "step",
        type: "text",
        sortable: true,
        width: "80px",
        formatter: value => value || "-",
      },
      {
        title: "updated_at_label",
        db_field: "updated_at",
        type: "dateTime",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.view_edit_rule_lable,
            type: "link",
            icon: "icon-pen",
            href: () => {
              return "";
            },
          },
          {
            label: messages.delete_rule_lable,
            type: "link",
            icon: "icon-rubber",
            href: () => {
              return "";
            },
          },
        ],
      },
    ],
  };

  return types.length > 0 && services.length > 0 && <DataTable config={dataTableConfig} />;
};
export default List;
