import React from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { ROLES } from "@utils/constant";

const List = () => {
  const { messages } = useIntl();

  const searchableColumns = ["id", "portal", "name"];

  const renderPermissions = (value) => {
    if (!value || value.length === 0) {
      return "-";
    }
    return value.map((item) => item.id).join(", ");
  };
  const dataTableConfig = {
    header: {
      title: "menu_roles",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.report_portal,
        id: "filter[portal]",
        type: "multiselect",
        options: [
          {
            name: "filter[portal]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[portal]",
            options: [
              {
                name: messages.role_admin,
                code: "admin",
              },
              {
                name: messages.customer_label,
                code: "customer",
              },
            ],
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ROLES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["fields", ["id", "name", "portal"]],
        ["include", ["permissions"]],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "role_label",
        db_field: "name",
        type: "text",
        formatter: (value) =>messages[value.replace(/-/g, '_')],
        sortable: true,
      },
      {
        title: "report_portal",
        db_field: "portal",
        type: "text",
        formatter: (value) =>messages[value],
        sortable: true,
      },
      {
        title: "report_permissions",
        db_field: "permissions",
        type: "text",
        formatter: renderPermissions,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "text",
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            type: "text",
          },
        ],
      },
    ],
  };

  return <DataTable config={dataTableConfig} />;
};

export default List;
