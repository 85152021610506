import React, {
  createContext,
  useEffect,
  useState,
  useContext,
} from "react";
import PropTypes from "prop-types";

import { useUser } from "./AuthProvider";
import getUserDetails from "@app/services/auth/getUserDetails";

export const OrderContext = createContext({
  isLoading: true,
  orderUser: null,
  subscription: null,
  setOrderUser: () => {},
});

export const OrderProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [orderUser, setOrderUser] = useState(null);
  const { isCustomer } = useUser();
  useEffect(() => {
    const fetchUserDetails = async () => {
      const { data: userDetails } = await getUserDetails().then(
        response => response.data
      );
      setOrderUser(userDetails);
      setLoading(false);
    };

    if (isCustomer) {
      fetchUserDetails();
    } else {
      setLoading(false);
    }
  }, [isCustomer]);

  return (
    <OrderContext.Provider
      value={{
        isLoading,
        orderUser,
        setOrderUser,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  children: PropTypes.node,
};

export const useOrder = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error("useUser must be used within a OrderProvider");
  }
  return context;
};
