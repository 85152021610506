import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useUser } from "@contexts/AuthProvider";

const useAuthorization = () => {
  const { permissionsCopy: permissions = [] } = useSelector(
    (state) => state.authReducer.userInfo,
  );

  const {isAdmin, isCustomer, isAuthenticated} = useUser();

  const hasAllPermissions = useCallback(
    (_permissions) =>
      _permissions.every((permission) => permissions?.includes(permission)),
    [permissions],
  );

  const hasAnyPermissions = useCallback(
    (_permissions) =>
      _permissions.some((permission) => permissions?.includes(permission)),
    [permissions],
  );

  return {
    isAdmin,
    isCustomer,
    isAuthenticated,
    hasAllPermissions,
    hasAnyPermissions,
  };
};

export default useAuthorization;
