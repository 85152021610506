import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import FullSizeDialog from "@components/FullSizeDialog";
import JSONViewer from "@components/JSONViewer";

const DataEditorDialog = ({ data, onClose }) => {
  const { messages } = useIntl();

  return (
    <FullSizeDialog title={messages.title_data} onClose={onClose}>
      <JSONViewer data={data} />
    </FullSizeDialog>
  );
};

DataEditorDialog.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default DataEditorDialog;
