import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { PERMISSIONS } from "@utils/constant";
import permissionsService from "@app/services/permissions/permissionsService";

const List = () => {
  const { messages } = useIntl();
  const [groups, setGroups] = useState([]);
  const searchableColumns = ["id", "name"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [permissionsResponse] = await Promise.all([permissionsService()]);
        const { data: { data: rules = [] } = {} } = permissionsResponse;
        const formattedGroups = rules
          .map((o) => ({
            name: messages[`permissions_group_${o.group}`],
            code: o?.group,
          }))
          .filter(
            (rule, index, self) =>
              index === self.findIndex((r) => r.code === rule.code),
          );
        setGroups(formattedGroups);
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
  }, [messages]);

  const dataTableConfig = {
    header: {
      title: "report_permissions",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.report_portal,
        id: "filter[portal]",
        type: "multiselect",
        options: [
          {
            name: "filter[portal]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[portal]",
            options: [
              {
                name: messages.role_admin,
                code: "admin",
              },
              {
                name: messages.customer_label,
                code: "customer",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.permission_group,
        id: "filter[group]",
        type: "multiselect",
        options: [
          {
            name: "filter[group]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[group]",
            options: groups,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${PERMISSIONS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["fields", ["id", "name", "portal", "group"]]],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        sortable: true,
        width: "100px",
      },
      {
        title: "label_permission_name",
        db_field: "name",
        type: "text",
        formatter: (value) =>messages[value.replace(/-/g, '_')],
        sortable: true,
      },
      {
        title: "report_portal",
        db_field: "portal",
        type: "text",
        sortable: true,
        formatter: (value) =>
          value === "admin" ? value : messages.title_customer,
      },
      {
        title: "permission_group",
        db_field: "group",
        type: "text",
        formatter: (value) =>messages[`permissions_group_${value}`],
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "text",
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            type: "text",
          },
        ],
      },
    ],
  };

  return groups.length > 0 && <DataTable config={dataTableConfig} />;
};

export default List;
