import Axios from "@app/api/axios";

import { EMAIL_MESSAGES } from "@utils/constant";

const getEmailLogById = (id = "") => {
  return Axios.get(
    `${EMAIL_MESSAGES}?filter[id]=${id}&include=court,account,attachments`,
  ).then((response) => response.data);
};

export default getEmailLogById;
