import React, { useState } from "react";
import Proptypes from "prop-types";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { TextUpperCase } from "@components/Text";
import MultiSelect from "@components/MultiSelect";

const Multiselect = (prop) => {
  const { type, onChange, value, id, options, name, width, inputValue } = prop;
  const [expandAcc, setExpandAcc] = useState(true);

  const handleExpandAccordion = () => {
    setExpandAcc(!expandAcc);
  };

  return (
    <Div key={value?.id} borderTop={"1px solid var(--turquoise-light)"}>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleExpandAccordion}
        cursor="pointer"
        p={3}
      >
        <TextUpperCase>{value.title}</TextUpperCase>
        <Icon
          name={expandAcc ? "arrowbig-up" : "arrowbig-down"}
          fontSize="7px"
          onClick={handleExpandAccordion}
          cursor="pointer"
        />
      </Div>
      {expandAcc && (
        <Div width={1} pb={3} px={3}>
          <MultiSelect
            {...prop}
            type={type}
            onChange={onChange(type, id)}
            width={width}
            value={inputValue}
            name={name}
            id={id}
            key={id}
            options={options}
            optionLabel={"name"}
            filter
            inputId="multiselect"
          />
        </Div>
      )}
    </Div>
  );
};

Multiselect.propTypes = {
  value: Proptypes.object,
};

export default Multiselect;
