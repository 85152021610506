import React from "react";
import ForgotPassword from "@pages/auth/forgotPassword/ForgotPassword";

const UserForgotPassword = () => (
  <>
    <ForgotPassword />
  </>
);

export default UserForgotPassword;
