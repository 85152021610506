import React from "react";
import PropTypes from "prop-types";

import Calendar from "@components/Calendar";

import InputWrapper from "./InputWrapper";

const InputDate = props => {
  const { name, value, placeholder, onChange, disabled } = props;

  return (
    <InputWrapper {...props}>
      <Calendar
        curved
        showIcon
        mt={2}
        width={1}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        dateFormat="yy-mm-dd"
        disabled={disabled}
      />
    </InputWrapper>
  );
};

InputDate.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  formikProps: PropTypes.object,
};

export default InputDate;
