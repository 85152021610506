import Axios from "@app/api/axios";
import { CASES, SOURCE_DATA } from "@utils/constant";

const updateSourceDataById = (caseId = "", sourceDataId = "", payload = {}) => {
  return Axios.patch(
    `${CASES}/${caseId}/${SOURCE_DATA}/${sourceDataId}`,
    payload,
  ).then((response) => response.data);
};

export default updateSourceDataById;
