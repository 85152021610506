import React from "react";
import { SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { ReportStatus, ReportStatusToolTip } from "@src/enum/reportStatus";
export const StatusComponent = ({ value, db_fields }) => {
  const { messages } = useIntl();
  const statusKey = ReportStatus[value];
  const statusToolTip = ReportStatusToolTip[value];
  return statusKey ? (
    <>
      {messages[statusKey]}
      <SmallEllipseIcon
        ml={2}
        className={`class-tooltip-report-${db_fields["id"]}`}
        name="info"
      >
        <Tooltip
          boxShadow="none"
          target={`.class-tooltip-report-${db_fields["id"]}`}
          content={messages[statusToolTip]}
        />
      </SmallEllipseIcon>
    </>
  ) : (
    "-"
  );
};
StatusComponent.propTypes = {
  value: PropTypes.string,
  db_fields: PropTypes.object,
};
