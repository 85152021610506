import React from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";

import PrintReportDialog from "../../LegacyReport/PrintReportDialog";

const DownloadGDPRDialog = ({ onClose, onConfirm }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={handleClose}
      m={[3, 3, 3, "auto"]}
    >
      <PrintReportDialog onClose={handleClose} onConfirm={handleConfirm} />
    </Dialog>
  );
};

DownloadGDPRDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DownloadGDPRDialog;
