import React from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { EMAIL_TEMPLATES } from "@utils/constant";
const List = () => {
  const { messages } = useIntl();
  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";
  const searchableColumns = ["id", "subject", "body"];
  const dataTableConfig = {
    header: {
      title: "email_template_lable",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "email_template_reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${EMAIL_TEMPLATES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["fields", ["id", "subject", "body", "created_at"]]],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "watchlist_label_name",
        db_field: "subject",
        type: "text",
        sortable: true,
      },
      {
        title: "type_label",
        db_field: "body",
        type: "text",
        sortable: true,
      },
      {
        title: "created_at",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            title: messages.label_details,
            type: "link",
            href: () => `#`,
          },
        ],
      },
    ],
  };

  return <DataTable config={dataTableConfig} />;
};
export default List;
