import React from "react";
import Proptypes from "prop-types";
import { PrimaryButtonIconOutlined } from "@components/Button";
import Icon from "@components/Icon";

const ButtonOutlined = ({ type }) => {
  const { id, label, icon } = type;
  return (
    <PrimaryButtonIconOutlined
      {...type}
      key={id}
      id={id}
      mx={3}
      label={label}
      semibold
      px="20px"
      py="6px"
      minWidth={150}
      display="inline-flex"
      alignSelf="center"
      icon={<Icon name={icon} mr={1} fontSize="var(--lh-h5)" />}
    />
  );
};

ButtonOutlined.propTypes = {
  type: Proptypes.object,
};

export default ButtonOutlined;
