import ordersAdminService from "@app/services/orders/ordersAdminService";

export const CreateOrder = ({
  payload,
  props,
  paymentMethod,
  promoCode,
  billingAccountId,
  userDetails,
}) => {
  const { consent, email } = props;
  const finalPayload = {
    payment_method: paymentMethod,
    order_items: [...payload],
    promo_code: promoCode,
    billing_account_id: billingAccountId,
    consent: Number(consent),
  };

  if (userDetails) {
    const { user_id, customer_id } = userDetails;

    finalPayload.user_id = user_id;
    finalPayload.customer_id = customer_id;
  }

  if (email) {
    finalPayload.email = email;
  }

  return ordersAdminService(finalPayload);
};

export default CreateOrder;
