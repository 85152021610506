import React from "react";
import SubscriptionResult from "../results/SubscriptionResult";
import UpgradePackage from "@components/UpgradePackage";
const SubscriptionOrderConfirmation = () => (
  <>
    <SubscriptionResult />
    <UpgradePackage show_package_list={true} />
  </>
);
export default SubscriptionOrderConfirmation;
