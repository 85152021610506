import React, { useRef } from "react";
import PropTypes from "prop-types";

import AutoComplete from "@components/AutoComplete";

import InputWrapper from "./InputWrapper";

const InputAutocomplete = (props) => {
  const {
    name,
    optionLabel,
    value,
    placeholder,
    options,
    onChange,
    onComplete,
    disabled,
    delay,
  } = props;

  const autocompleteRef = useRef(null);

  return (
    <InputWrapper {...props}>
      <AutoComplete
        curved
        dropdown
        ref={autocompleteRef}
        mt={2}
        width={1}
        dropdownMode="current"
        name={name}
        field={optionLabel}
        value={value}
        placeholder={placeholder}
        suggestions={options}
        onChange={onChange}
        completeMethod={onComplete}
        disabled={disabled}
        onDropdownClick={() => autocompleteRef.current?.show()}
        delay={delay}
      />
    </InputWrapper>
  );
};

InputAutocomplete.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  options: PropTypes.array,
  formikProps: PropTypes.object,
  disabled: PropTypes.bool,
  itemTemplate: PropTypes.node,
  delay: PropTypes.number,
};

export default InputAutocomplete;
