import Axios from "@app/api/axios";

import { PROMO_CODES } from "@utils/constant";

const createPromoCode = (payload) => {
  return Axios.post(`${PROMO_CODES}`, payload).then(
    (response) => response.data,
  );
};

export default createPromoCode;
