import React from "react";
import PropTypes from "prop-types";
import { H2, H5 } from "@components/Heading";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import { Text, TextSemiBoldWeight } from "@components/Text";

const Header = ({
  credits = "",
  activePackage = false,
  currentSubscription = {},
}) => {
  const { messages } = useIntl();
  const { name = "", credits: currentCredits = "" } = currentSubscription;

  return (
    <Div
      display="flex"
      justifyContent="space-between"
      alignItems={["start", "start", "center", "center"]}
      flexDirection={["column", "column", "row", "row"]}
      width="100%"
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent={["space-between", "space-between"]}
        width="100%"
      >
        <H2
          pb={["5px", "5px", "10px", "10px"]}
          display="flex"
          alignItems="start"
        >
          {activePackage
            ? `${messages.upgrade} ${messages.subscriptions_title}`
            : messages.title_label_order_summary}
        </H2>
        {activePackage && (
          <H5>{`${messages.current} ${messages.package_label}: ${name} (${currentCredits})`}</H5>
        )}
      </Div>
      <Div
        display="flex"
        flexDirection={["row", "row", "column", "column"]}
        alignItems="end"
        justifyContent={["space-between", "space-between"]}
        width="100%"
        pt={[2, 2, 0, 0]}
      >
        <Text>{messages.label_included}</Text>
        <TextSemiBoldWeight pt={2}>
          {`${messages.analysis}: ${credits}`}
        </TextSemiBoldWeight>
      </Div>
    </Div>
  );
};

Header.propTypes = {
  credits: PropTypes.string,
  activePackage: PropTypes.bool,
  currentSubscription: PropTypes.object,
};

export default Header;
