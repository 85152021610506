import React from "react";
import PropTypes from "prop-types";
import LegacyGradeComponent from "./LegacyGradeComponent";
import StatusIndicator from "./StatusIndicator";
import { prefixNumberSign } from "./utils";

export const GradeComponent = ({ value, report }) => {
  if (report.is_case) {
    const grade = report["grade_data.grade"];
    return (
      <StatusIndicator
        color={report["grade_data.grade_color"]}
        bgColor={report["grade_data.grade_bg_color"]}
        value={prefixNumberSign(grade)}
      />
    );
  }
  return <LegacyGradeComponent value={value} report={report} />;
};

GradeComponent.propTypes = {
  value: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
};

export default GradeComponent;
