import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { Error } from "@components/Heading";
import ComponentCalendar from "@components/Calendar";
import { useFormikContext } from "formik";

const Calendar = ({ name }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const dateValue = values?.[name]?.value ? new Date(values[name].value) : null;
  const handleDateChange = (date) => {
    setFieldValue(name, date.value);
  };

  return (
    <Div flex="1 1 40%">
      <ComponentCalendar
        mt={2}
        width="100%"
        curved
        showIcon
        name={name}
        value={dateValue}
        onChange={handleDateChange}
        dateFormat="yy-mm-dd"
      />
      {errors[name] && touched[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors[name]}</Error>
        </Div>
      )}
    </Div>
  );
};

Calendar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Calendar;
