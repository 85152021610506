import React from "react";

import { useIntl } from "react-intl";
import ConsentFooter from "./ConsentFooter";

export const ConsentExpired = () => {
  const { messages } = useIntl();
  return (
    <ConsentFooter
      title={messages.consent_status_expired_title}
      description={messages.consent_status_expired_description}
    />
  );
};
export default ConsentExpired;
