import React from "react";
import Proptypes from "prop-types";
import { default as Div } from "@components/Div";
import Checkbox from "@components/Checkbox";
import Label from "@components/Label";
import { H4 } from "@components/Heading";

const CheckboxServices = (props) => {
  const { id, label, value, checked, onClick } = props;

  return (
    <Div alignItems="center" display="flex">
      <Checkbox
        key={id}
        inputId={id}
        checked={checked}
        onChange={onClick(value)}
        {...props}
        input
      />
      <Label
        htmlFor={id}
        className="p-checkbox-label"
        fontWeight="var(--medium-weight)!important"
        mx={15}
      >
        {label}
      </Label>
    </Div>
  );
};

CheckboxServices.propTypes = {
  id: Proptypes.string,
  label: Proptypes.string,
  checked: Proptypes.bool,
  onClick: Proptypes.func,
  value: Proptypes.string,
};

const CheckBoxes = ({ value = {}, options }) => {
  const { title: title = "" } = value;
  return (
    <Div
      display={["block", "flex"]}
      py={2}
      px={[3, 3, 3, 4]}
      borderTop={"1px solid var(--turquoise-light)"}
      borderBottom={"1px solid var(--turquoise-light)"}
    >
      <Div flexBasis={"25%"} my={2}>
        <H4>{title}:</H4>
      </Div>
      <Div display="flex" flexDirection="row" flexWrap="wrap" width={1}>
        {options.map((checkbox, key) => (
          <Div
            key={key}
            display="flex"
            alignItems={"center"}
            width={value?.width || [0.5, 0.5, 0.5, 0.43]}
            my={2}
          >
            <CheckboxServices
              id={checkbox.label}
              label={checkbox.label}
              value={checkbox.value}
              checked={checkbox.checked}
              onClick={checkbox.onClick}
            />
          </Div>
        ))}
      </Div>
    </Div>
  );
};

CheckBoxes.propTypes = {
  value: Proptypes.object,
  options: Proptypes.array,
  onClick: Proptypes.func,
};

export default CheckBoxes;
