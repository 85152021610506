import React from "react";
import { useHistory } from "react-router-dom";
import Form from "./Form";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";
import createNewProduct from "@app/services/services/createNewProduct";
import { ROUTES } from "@utils/constant";
import AdminContainer from "@layout/AdminContainer";

const CreateSubscription = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: messages.title_create_subscription,
    },
  ];

  const handleCreateSubscription = async (values) => {
    values.purchase_by_credits = true;
    values.price_credits = values.price;
    try {
      await createNewProduct(values);
      history.push(ROUTES.ADMIN_PRODUCTS_LIST.URL);
    } catch (e) {
      //catch error
    }
  };

  const headerConfig = {
    title: messages.title_create_subscription,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 12]} />
      <Div
        width={[1, 1, 1, 800]}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Form onSubmit={handleCreateSubscription} />
      </Div>
    </AdminContainer>
  );
};

export default CreateSubscription;
