import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { H3, H4 } from "@components/Heading";
import { INPUT } from "@utils/constant";
import InputText from "@components/InputText";
import PersonalNumber from "@pages/auth/schema/PersonalNumber";
import { PrimaryButtonOutlined, PrimaryButton } from "@components/Button";
import personInformationSearchService from "@app/services/search/personInformationSearchService";
import { Text, TextMediumWeight } from "@components/Text";
import { checkIfObject } from "@utils/common";
import Config from "@src/config";

const AddSingleObjModal = ({
  onClose,
  onSubmit,
  customerId,
  watchListObjects = null,
}) => {
  const { messages } = useIntl();
  const { PERSONAL_NUMBER } = INPUT.NAME;
  const [validSSNObject, setValidSSNObject] = useState(null);
  const [existingSSNObject, setExistingSSNObject] = useState(null);
  const [invalidSSN, setInvalidSSN] = useState(0);
  const PersonalNumberSchema = PersonalNumber.pick([PERSONAL_NUMBER]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async (values) => {
    const { personal_number } = values;
    try {
      const response = await personInformationSearchService(
        [personal_number],
        customerId,
        true,
      );
      const { data = [] } = response;
      const {
        existing_ssn_list = [],
        invalid_ssn_list = [],
        valid_ssn_list = [],
      } = data;
      const existingSsnList = checkIfObject(existing_ssn_list);
      const invalidSsnList = checkIfObject(invalid_ssn_list);
      const validSsnsList = checkIfObject(valid_ssn_list);
      setExistingSSNObject(
        existingSsnList.length > 0 ? existingSsnList[0] : null,
      );
      setInvalidSSN(Object.keys(invalidSsnList).length);
      setValidSSNObject(validSsnsList.length > 0 ? validSsnsList[0] : null);
    } catch (e) {
      //catch the error
    }
  };

  const handleAddObject = () => {
    if (watchListObjects) {
      duplicateCheck({ ...validSSNObject, id: validSSNObject?.ssn });
    } else {
      onSubmit({ ...validSSNObject, id: validSSNObject?.ssn });
    }
  };

  const duplicateCheck = (object = {}) => {
    const { id: objectId = "" } = object;
    if (!watchListObjects.find((o) => o?.id === objectId)) {
      onSubmit(object);
    } else {
      setExistingSSNObject(object);
    }
  };

  const handleContactUs = () => {
    window.open(`${Config.WP_URL}/kontakta-oss/`, "_blank");
  };

  return (
    <Div>
      {invalidSSN > 0 || existingSSNObject ? (
        <>
          <H3 width={1} textAlign="center" mb={3}>
            {messages.object_not_added}
          </H3>
          {existingSSNObject && (
            <Div my={3}>
              <Div display="flex" justifyContent="center" alignItems="center">
                <Icon
                  mr={2}
                  name="account-circle"
                  color="var(--turquoise)"
                  fontSize="var(--fs-text-m)"
                />
                <H4
                  width={1}
                  textAlign="center"
                  fontWeight="var(--semibold-weight) !important"
                >
                  {validSSNObject?.name}
                </H4>
              </Div>
              <H4
                width={1}
                textAlign="center"
                fontWeight="var(--semibold-weight) !important"
                mb={2}
                mt={2}
              >
                {validSSNObject?.ssn}
              </H4>
              <Text mx={4}>
                {messages.watchlist_message_ssn_already_in_use}
              </Text>
            </Div>
          )}
          {invalidSSN > 0 && (
            <Div mx={3}>
              <Text mt={3}>{messages.object_error_message}</Text>
              <Div>
                <ul>
                  <li>
                    <TextMediumWeight>
                      {messages.check_the_num}
                    </TextMediumWeight>
                  </li>
                  <li>
                    <TextMediumWeight>{messages.try_again}</TextMediumWeight>
                  </li>
                  <li>
                    <TextMediumWeight>
                      {messages.contact_us_case}
                    </TextMediumWeight>
                  </li>
                </ul>
              </Div>
            </Div>
          )}
          <Div display="flex" justifyContent="center" mt={4}>
            {invalidSSN > 0 && (
              <PrimaryButtonOutlined
                rounded
                semibold
                px={3}
                mr={3}
                width={150}
                label={messages.title_contact_us}
                onClick={handleContactUs}
              />
            )}

            <PrimaryButton
              rounded
              semibold
              py={1}
              px={4}
              width={150}
              onClick={handleCancel}
              label={messages.label_ok}
            />
          </Div>
        </>
      ) : (
        <Formik
          initialValues={{ personal_number: "" }}
          validationSchema={PersonalNumberSchema}
          onSubmit={handleSubmit}
        >
          {(prop) => {
            const { values, handleSubmit, handleBlur } = prop;
            return (
              <Form>
                <H3 width={1} textAlign="center">
                  {messages.add_object}
                </H3>
                {validSSNObject ? (
                  <Div my={4}>
                    <Div
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon
                        mr={2}
                        name="account-circle"
                        color="var(--turquoise)"
                        fontSize="var(--fs-text-m)"
                      />
                      <H4
                        width={1}
                        textAlign="center"
                        fontWeight="var(--semibold-weight) !important"
                      >
                        {validSSNObject?.name}
                      </H4>
                    </Div>
                    <H4
                      width={1}
                      textAlign="center"
                      fontWeight="var(--semibold-weight) !important"
                    >
                      {validSSNObject?.ssn}
                    </H4>
                  </Div>
                ) : (
                  <InputText
                    curved
                    placeholder={messages.watchlist_label_personal_number}
                    value={values.personal_number}
                    name={PERSONAL_NUMBER}
                    formikProps={prop}
                    maxLength={13}
                    label={messages.label_personal_number}
                    width="100%"
                    onBlur={handleBlur}
                  />
                )}
                {invalidSSN > 0 && <>dhbdhjvfsj</>}
                <Div display="flex" justifyContent="center" mt={3}>
                  <PrimaryButtonOutlined
                    rounded
                    semibold
                    px={3}
                    mr={3}
                    type="reset"
                    width={150}
                    label={messages.label_cancel}
                    onClick={handleCancel}
                  />

                  <PrimaryButton
                    rounded
                    semibold
                    py={1}
                    px={4}
                    width={150}
                    onClick={validSSNObject ? handleAddObject : handleSubmit}
                    label={
                      validSSNObject ? messages.text_add : messages.search_text
                    }
                  />
                </Div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Div>
  );
};

AddSingleObjModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  customerId: PropTypes.number,
  watchListObjects: PropTypes.array,
};

export default AddSingleObjModal;
