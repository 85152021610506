import React from "react";
import PropTypes from "prop-types";

import { default as Div } from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";

import Icon from "@components/Icon";
import Link from "@components/Link";

import SideMenu from "@layout/SideMenu";

import Config from "@src/config";
import { useIntl } from "react-intl";
import { TextMediumWeight } from "@components/Text";

const CandidateHeader = () => {
  const { messages } = useIntl();
  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  const start = (
    <>
      <Div display={["flex"]} alignItems={"center"}>
        <Logo
          logo={LogoFull}
          width="auto"
          maxHeight="60px"
          onClick={handleRedirect}
        />
        <TextMediumWeight
          display={["none", "none", "flex", "flex"]}
          pl={4}
          ml={3}
          pt={4}
          width={"400px"}
        >
          {messages.candidate_page_header_menu_text}
        </TextMediumWeight>
      </Div>

      <Div
        display={["flex", "flex", "flex", "none"]}
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Div>
          <Div display="flex" py={3} alignItems="center">
            <Div
              width={1 / 2}
              display="flex"
              justifyContent={"start"}
              pl={3}
              alignItems="center"
            >
              <Link
                label={messages.candidate_page_header_menu_link}
                href={Config.WP_URL}
                target="_blank"
              />
              <Icon pl={10} color="var(--turquoise)" name="headerarrowright" />
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );

  const end = (
    <>
      <Div
        display={["flex"]}
        alignItems="center"
        backgroundColor={"rgba(var(--grey-lightest-rgba), .3)"}
        px={[0, 0, 3, 24]}
        pt={74}
        pb={38}
        whiteSpace
      >
        <Link
          label={messages.candidate_page_header_menu_link}
          href={Config.WP_URL}
          target="_blank"
        />
        <Icon pl={10} color="var(--turquoise)" name="headerarrowright" />
      </Div>
    </>
  );

  let headerItems = [
    {
      label: null,
      preventRedirect: true,
    },
  ];

  return (
    <SideMenu model={headerItems} start={start} end={end} width={[1, 1, 1]} />
  );
};

CandidateHeader.propTypes = {
  config: PropTypes.object,
  navItems: PropTypes.array,
  sideMenuNavItems: PropTypes.array,
};

export default CandidateHeader;
