import React from "react";
import Div from "@components/Div";
import { useHistory } from "react-router-dom";
import { ROUTES, OBJECT_ORDER_DETAILS } from "@utils/constant";
import DataTable from "@components/Table/Table";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

const WatchListTables = ({
  isAdmin,
  isWatchList,
  isObject,
  reports,
  historyReports,
}) => {
  const { messages } = useIntl();
  const history = useHistory();

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent,
    );

  const handleRedirectToWatchListObjectOrderDetails = (event) => {
    if (!isAdmin) {
      history.push(
        `${ROUTES.WATCH_LIST_OBJECT_DETAIL.URL}/?id=${event.data.order_id}&ssn=${event.data.personal_number}`,
      );
    } else {
      history.push(
        `${ROUTES.ADMIN_WATCH_LIST_OBJECT_DETAIL.URL}?id=${event.data.order_id}&ssn=${event.data.personal_number}`,
      );
    }
  };

  const config = [
    {
      title: `${messages.watchlist_label_results}`,
      headerActions: [],
      columns: isMobile
        ? [
            {
              field: "name",
              header: messages.watchlist_label_name,
              primary: true,
              sortable: true,
            },
            {
              field: "grades",
              header: messages.watchlist_label_grade,
              sortable: true,
            },
            {
              field: "personal_number",
              header: messages.watchlist_label_personal_number,
              sortable: true,
            },
            { field: "action", header: messages.title_actions },
          ]
        : [
            {
              field: "grades",
              header: messages.watchlist_label_grade,
              sortable: true,
            },
            {
              field: "name",
              header: messages.watchlist_label_name,
              primary: true,
              sortable: true,
            },
            {
              field: "personal_number",
              header: messages.watchlist_label_personal_number,
              sortable: true,
            },
            { field: "action", header: messages.title_actions },
          ],
      rowActions: [
        {
          id: "results",
          label: messages.watchlist_tab_label_details,
          type: "link",
          icon: "headerarrowright",
          variant: "column",
          isPrimary: true,
          onClick: handleRedirectToWatchListObjectOrderDetails,
        },
      ],
      filterType: "dialog",
      filters: [],
      pagination: true,
      sortable: true,
    },
  ];

  const handleRedirectToWatchListObjectReport = (event) => {
    history.push(`${ROUTES.ORDER_RESULTS.URL}/?id=${event.data.id}`);
  };

  const historyConfig = [
    {
      title: messages.watchlist_title_order_history,
      headerActions: [],
      columns: [
        {
          field: "grades",
          header: messages.watchlist_label_grade,
          primary: true,
          sortable: false,
        },
        {
          field: "order_id",
          header: messages.watchlist_label_order,
          sortable: false,
        },
        {
          field: "completed_at",
          header: messages.watchlist_label_date,
          sortable: false,
        },
        { field: "action", header: messages.title_actions },
      ],
      rowActions: [
        {
          id: "results",
          label: messages.watchlist_label_results,
          type: "link",
          icon: "headerarrowright",
          variant: "column",
          isPrimary: true,
          onClick: handleRedirectToWatchListObjectReport,
        },
      ],
      filterType: "dialog",
      filters: [],
      pagination: true,
    },
  ];

  return (
    <>
      {isWatchList && !isObject && (
        <Div mt={2}>
          <DataTable
            config={config}
            showActionButton
            tableData={reports}
            handleRedirectToDetails={
              handleRedirectToWatchListObjectOrderDetails
            }
          />
        </Div>
      )}
      {isObject && (
        <Div mt={2}>
          <DataTable
            config={historyConfig}
            tableData={historyReports}
            handleRedirectToDetails={handleRedirectToWatchListObjectReport}
            screenName={OBJECT_ORDER_DETAILS}
          />
        </Div>
      )}
    </>
  );
};

WatchListTables.propTypes = {
  isAdmin: PropTypes.bool,
  isWatchList: PropTypes.bool,
  isObject: PropTypes.bool,
  reports: PropTypes.array,
  historyReports: PropTypes.array,
};

export default WatchListTables;
