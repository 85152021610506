import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Panel from "@components/Panel";
import RichText from "@components/RichText";
import { Text, TextMediumWeight } from "@components/Text";

import { EMAIL_CLASSIFICATION_TYPE, EMAIL_TYPE } from "@utils/enum";
import { formatDateAndTime } from "@utils/utils";

import Attachments from "./Attachments";

const EMAIL_LABELS = {
  [EMAIL_TYPE.INCOMING]: <FormattedMessage id="label_received" />,
  [EMAIL_TYPE.OUTGOING]: <FormattedMessage id="label_sent" />,
};

const IS_SECURE_EMAIL = {
  [1]: <FormattedMessage id="yes_label" />,
  [0]: <FormattedMessage id="no_label" />,
};

const EMAIL_CLASSIFICATION = {
  [EMAIL_CLASSIFICATION_TYPE.AUTOMATIC_REPLY]: (
    <FormattedMessage id={EMAIL_CLASSIFICATION_TYPE.AUTOMATIC_REPLY} />
  ),
  [EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES]: (
    <FormattedMessage id={EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES} />
  ),
  [EMAIL_CLASSIFICATION_TYPE.CRIMINAL_CASES_WITH_DOCUMENTS]: (
    <FormattedMessage
      id={EMAIL_CLASSIFICATION_TYPE.CRIMINAL_CASES_WITH_DOCUMENTS}
    />
  ),
  [EMAIL_CLASSIFICATION_TYPE.SECURE_EMAIL]: (
    <FormattedMessage id={EMAIL_CLASSIFICATION_TYPE.SECURE_EMAIL} />
  ),
};

const Email = ({
  from,
  to,
  subject,
  body_html,
  created_at,
  direction,
  is_secure_email,
  email_reply_classification,
  attachments,
  secure_body_html,
}) => {
  const { messages } = useIntl();

  const shouldShowAttachments = !!attachments?.length;
  const shouldShowSecureFields = direction === EMAIL_TYPE.INCOMING;
  const bodyToRender = is_secure_email ? secure_body_html : body_html;

  const renderHeader = () => (
    <Div display="flex" flexDirection="column" gridGap={3}>
      <Div display="flex" alignItems="center" gridGap={2}>
        <H4>{formatDateAndTime(created_at)}</H4>
        <Div
          display="flex"
          alignItems="center"
          backgroundColor="var(--grey-lightest)"
          borderRadius="8px"
          px={2}
          py={1}
        >
          <TextMediumWeight fontSize="var(--fs-text-small)">
            {EMAIL_LABELS[direction]}
          </TextMediumWeight>
        </Div>
      </Div>

      <Div display="flex" flexDirection="column" gridGap={2}>
        <Div display="flex" flexDirection="column" gridGap={3}>
          <Div display="flex" gridGap={1}>
            <TextMediumWeight>{`${messages.filter_date_from}:`}</TextMediumWeight>
            <Text>{from}</Text>
          </Div>

          <Div display="flex" gridGap={1}>
            <TextMediumWeight>{`${messages.filter_date_to}:`}</TextMediumWeight>
            <Text>{to}</Text>
          </Div>

          <Div display="flex" gridGap={1}>
            <TextMediumWeight>{`${messages.label_subject}:`}</TextMediumWeight>
            <Text>{subject}</Text>
          </Div>

          {shouldShowSecureFields && (
            <>
              <Div display="flex" gridGap={1}>
                <TextMediumWeight>{`${messages.label_secure_email}:`}</TextMediumWeight>
                <Text>{IS_SECURE_EMAIL[is_secure_email]}</Text>
              </Div>

              <Div display="flex" gridGap={1}>
                <TextMediumWeight>{`${messages.label_email_classification}:`}</TextMediumWeight>
                <Text>{EMAIL_CLASSIFICATION[email_reply_classification]}</Text>
              </Div>
            </>
          )}
        </Div>
      </Div>
    </Div>
  );

  const renderAttachments = () => (
    <Div>
      <Div my={3}>
        <TextMediumWeight>{`${messages.label_attachments}: `}</TextMediumWeight>
      </Div>
      <Attachments showLabel attachments={attachments} />
    </Div>
  );

  return (
    <Panel header={renderHeader}>
      {shouldShowAttachments && renderAttachments()}

      <RichText>{bodyToRender}</RichText>
    </Panel>
  );
};

Email.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  subject: PropTypes.string,
  body_html: PropTypes.string,
  created_at: PropTypes.string,
  direction: PropTypes.string,
  is_secure_email: PropTypes.bool,
  email_reply_classification: PropTypes.string,
  attachments: PropTypes.array,
  secure_body_html: PropTypes.string,
};

export default Email;
