import React, { useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import AutoComplete from "@components/AutoComplete";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Calendar from "@components/Calendar";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Dropdown from "@components/Dropdown";
import Editor from "@components/Editor";
import { Error, H3, H5 } from "@components/Heading";
import Icon from "@components/Icon";
import { InputMultiselect } from "@components/Input";
import InputText from "@components/InputText";
import { Text, TextMediumWeight } from "@components/Text";
import { FORM_INPUT_TYPE } from "@utils/enum";
import { isAdminUser } from "@utils/utils";
import { INPUT } from "@utils/constant";
import UploadBordered from "@components/UploadBordered";
import InputTextArea from "@components/InputTextArea";
import Checkbox from "@components/Checkbox";

InputText.defaultProps = {
  curved: true,
  isLowercaseLabel: true,
  width: 1,
  labelAlignment: "left",
};

const renderFiller = ({ key, index }) => (
  <Div
    key={`${key}_${index}`}
    flex="1 1 40%"
    display={["none", "none", "block", "block"]}
  />
);

const renderInputTextArea = ({
  key,
  index,
  label,
  formik,
  required,
  fullWidth,
  disabled,
}) => {
  const {
    errors = {},
    touched = {},
    handleChange,
    handleBlur,
    values = {},
  } = formik || {};
  return (
    <Div key={`${key}_${index}`} flex={`1 1 ${fullWidth ? "100%" : "40%"}`}>
      <Div mt={3} pt={1} mb={2}>
        <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      </Div>
      <InputTextArea
        width={1}
        rows={4}
        cols={24}
        name={key}
        placeholder={label}
        value={values?.[key]}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors[key] && touched[key] && (
        <Div pb={1} pt={3}>
          <Error>{errors[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const renderCheckBox = ({
  key,
  index,
  label,
  header,
  formik,
  required,
  fullWidth,
  messages,
}) => {
  const { values } = formik;

  return (
    <Div mt={20} key={`${key}_${index}`} flex={`1 1 ${fullWidth ? "100%" : "40%"}`}>
      {header && (
        <TextMediumWeight>{`${messages[header]}${required ? "*" : ""}`}</TextMediumWeight>
      )}
      <Div my={2} display="flex" alignItems="center">
        <Checkbox
          checked={values?.[key]}
          onChange={formik.handleChange}
          name={key}
        />
        <H5 mx={2}>{label}</H5>
      </Div>
    </Div>
  );
};

const renderTextInput = ({
  key,
  index,
  label,
  formik,
  required,
  fullWidth,
  maxLength,
  textType = INPUT.TYPE.TEXT,
  disabled = false,
}) => {
  const { values } = formik;

  return (
    <Div key={`${key}_${index}`} flex={`1 1 ${fullWidth ? "100%" : "40%"}`}>
      <InputText
        width={1}
        name={key}
        label={`${label}${required ? "*" : ""}`}
        placeholder={label}
        formikProps={formik}
        value={values?.[key]}
        type={textType}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Div>
  );
};

const renderDropdown = ({
  key,
  index,
  label,
  placeholder,
  formik,
  options,
  optionFields,
  messages,
  selectorField,
  required,
  fullWidth,
}) => {
  const { values, handleChange, errors, touched } = formik;

  return (
    <>
      <Div
        key={`${key}_${index}`}
        flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
        mt={3}
        pt={1}
      >
        <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
        <Dropdown
          width={1}
          mt={2}
          name={key}
          value={values?.[key]}
          onChange={handleChange}
          options={options}
          optionField={selectorField}
          placeholder={placeholder}
          dropdownIcon={<Icon name="chevrondown" />}
          filter={options?.length > 5}
        />
        {errors?.[key] && touched?.[key] && (
          <Div pb={1} pt={3} m="auto">
            <Error>{errors?.[key]}</Error>
          </Div>
        )}
      </Div>

      {optionFields?.[values?.[key]]?.map(
        ({ key, translationKey, type }, index) => {
          const renderItem = components[type];

          return (
            <Div key={`${key}_${index}`} flex="1 1 40%">
              {renderItem?.({ key, label: messages[translationKey], formik })}
            </Div>
          );
        }
      )}
    </>
  );
};

const renderAutocomplete = ({
  key,
  index,
  label,
  placeholder,
  formik,
  options,
  selectorField,
  required,
  completeMethod,
  autocompleteItemOptions,
  messages,
  fullWidth,
}) => {
  const { values, handleChange, errors, touched } = formik;

  const renderItem = item => {
    const formattedDescription = autocompleteItemOptions
      .map(({ key, translationKey }) => ({
        label: messages[translationKey],
        value: item[key],
      }))
      .filter(({ value }) => value != null)
      .map(({ label, value }) => `${label}: ${value}`)
      .join(" | ");

    return (
      <Div display="flex" flexDirection="column" gridGap={1}>
        <TextMediumWeight>{item[selectorField]}</TextMediumWeight>
        <Text>{formattedDescription}</Text>
      </Div>
    );
  };

  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      mt={3}
      pt={1}
    >
      <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      <AutoComplete
        mt={2}
        width="100%"
        curved
        dropdown
        hideLoader
        name={key}
        field={selectorField}
        value={values?.[key]}
        placeholder={placeholder}
        delay={200}
        suggestions={options}
        itemTemplate={renderItem}
        onChange={handleChange}
        completeMethod={completeMethod}
        dropdownMode="current"
      />

      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const renderCalendar = ({ key, index, label, formik, required, fullWidth }) => {
  const { values, errors, touched, handleChange } = formik;
  const dateValue = values?.[key] ? new Date(values?.[key]) : "";

  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      mt={3}
      pt={1}
    >
      <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      <Calendar
        mt={2}
        width="100%"
        curved
        showIcon
        name={key}
        value={dateValue}
        onChange={handleChange}
        dateFormat="yy-mm-dd"
      />

      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const renderEditor = ({ key, index, formik, fullWidth }) => {
  const { errors, touched, values, setFieldValue } = formik;

  const handleOnChange = ({ htmlValue }) => {
    setFieldValue(key, htmlValue);
  };

  const handleLoad = quill => {
    const htmlString = values?.[key];

    quill.clipboard.dangerouslyPasteHTML(htmlString);
  };

  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      mt={3}
      pt={1}
    >
      <Editor
        name={key}
        mb={4}
        value={values?.[key]}
        onLoad={handleLoad}
        onTextChange={handleOnChange}
      />

      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const renderMultiselect = ({
  key,
  index,
  label,
  formik,
  fullWidth,
  options,
  selectorField,
  placeholder,
  selectAllLabel,
}) => {
  const { values, handleChange } = formik;
  const selectedValue = values?.[key];
  return (
    <InputMultiselect
      key={`${key}_${index}_multiselect`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      name={key}
      label={label}
      options={options}
      optionLabel={selectorField}
      placeholder={placeholder}
      onChange={handleChange}
      selectAllLabel={selectAllLabel}
      value={selectedValue}
      formikProps={formik}
    />
  );
};

const renderFileUpload = ({
  key,
  formik,
  uploadRef,
  index,
  label,
  fullWidth,
  required,
}) => {
  const { setFieldValue, errors, touched } = formik;

  const handleFileAttached = event => {
    if (event.files.length > 0) {
      setFieldValue(key, event.files[0]);
    }
  };

  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      mt={3}
      pt={1}
    >
      <TextMediumWeight
        mb={2}
      >{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      <Div mt={2} pt={1}>
        <UploadBordered
          uploadRef={uploadRef}
          errorMessage={errors?.[key]}
          category={key}
          onFileAttached={handleFileAttached}
        />
      </Div>
      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const components = {
  [FORM_INPUT_TYPE.FILLER]: renderFiller,
  [FORM_INPUT_TYPE.TEXT]: renderTextInput,
  [FORM_INPUT_TYPE.INPUT_NUMBER]: renderTextInput,
  [FORM_INPUT_TYPE.DROPDOWN]: renderDropdown,
  [FORM_INPUT_TYPE.AUTOCOMPLETE]: renderAutocomplete,
  [FORM_INPUT_TYPE.CALENDAR]: renderCalendar,
  [FORM_INPUT_TYPE.EDITOR]: renderEditor,
  [FORM_INPUT_TYPE.MULTISELECT]: renderMultiselect,
  [FORM_INPUT_TYPE.FILE_UPLOAD]: renderFileUpload,
  [FORM_INPUT_TYPE.TEXTAREA]: renderInputTextArea,
  [FORM_INPUT_TYPE.CHECK_BOX]: renderCheckBox,
};

const EditFormDialog = ({
  fullWidthInputs,
  title,
  config,
  validationSchema,
  onHide,
  onSubmit,
  description,
}) => {
  const { messages } = useIntl();
  const myUploadRef = useRef(null);

  const initialValues = config
    .filter(item => item.key !== undefined )
    .reduce((result, { key, value, optionFields }) => {
      if (optionFields) {
        const selectedOptionFields = optionFields[value];
        selectedOptionFields
          ?.filter(optionField => optionField.key !== undefined)
          .forEach(optionField => {
            result[optionField.key] = optionField.value;
          });
      }
      result[key] = value;
      return result;
    }, {});

  const handleSubmit = values => {
    onSubmit(values);
    onHide();
  };

  const handleOnChange = (onChange, formik, e) => {
    onChange(e, formik);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <H3 textAlign="center">{title}</H3>
      {description && (
        <Div display="flex" justifyContent="center" mt={1}>
          <Text textAlign="center">{description}</Text>
        </Div>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => {
          const { dirty } = formik;

          return (
            <Form>
              <Div
                display="flex"
                flexDirection={
                  fullWidthInputs
                    ? "column"
                    : ["column", "column", "row", "row"]
                }
                flexWrap={
                  fullWidthInputs ? "nowrap" : ["nowrap", "wrap", "wrap"]
                }
                gridColumnGap={4}
              >
                {config
                .filter(({ show = true }) => 
                  typeof show === "function" ? show(formik.values || {}) : show
                )
              .map(
                  (
                    {
                      key,
                      translationKey,
                      type,
                      placeholderKey,
                      options,
                      optionFields,
                      isAdminField,
                      selectorField,
                      required = false,
                      completeMethod,
                      autocompleteItemOptions,
                      fullWidth,
                      selectAllLabel,
                      textType,
                      isHidden,
                      editable = true,
                      maxLength,
                      uploadRef = { myUploadRef },
                      isDisabled = () => {},
                      handleChange = formik?.handleChange,
                      header = "",
                    },
                    index
                  ) => {
                    if (isAdminField && !isAdminUser()) {
                      return null;
                    }
                    const isHiddenField =
                      (typeof isHidden === "boolean" && isHidden) ||
                      (typeof isHidden === "function" &&
                        !isHidden(formik.values));
                    const isEditableField =
                      (typeof editable === "boolean" && editable) ||
                      (typeof editable === "function" &&
                        !editable(formik.values));
                    if (isHiddenField && !isEditableField) {
                      return null;
                    }

                    return (
                      editable &&
                      components[type]?.({
                        key,
                        index,
                        label: messages[translationKey],
                        placeholder: messages[placeholderKey],
                        formik: {
                          ...formik,
                          handleChange: handleOnChange.bind(
                            this,
                            handleChange,
                            formik
                          ),
                        },
                        options,
                        optionFields,
                        messages,
                        selectorField,
                        required,
                        completeMethod,
                        autocompleteItemOptions,
                        fullWidth,
                        selectAllLabel,
                        textType,
                        isHidden,
                        maxLength,
                        uploadRef,
                        disabled: isDisabled(formik?.values),
                        header,
                      })
                    );
                  }
                )}
              </Div>

              <Div
                display="flex"
                flexDirection={["column", "column", "row", "row"]}
                alignItems="center"
                justifyContent="center"
                gridGap={4}
                mt={4}
              >
                <PrimaryButtonOutlined
                  width={[1, 1, "150px", "150px"]}
                  label={messages.label_cancel}
                  onClick={onHide}
                />
                <PrimaryButton
                  width={[1, 1, "150px", "150px"]}
                  label={messages.label_save}
                  disabled={!dirty}
                  type="submit"
                />
              </Div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

EditFormDialog.propTypes = {
  fullWidthInputs: PropTypes.bool,
  title: PropTypes.string,
  config: PropTypes.array,
  validationSchema: PropTypes.object,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  description: PropTypes.string,
};

export default EditFormDialog;
