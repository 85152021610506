import React, { useState } from "react";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Link from "@components/Link";
import Icon from "@components/Icon";
import {
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  PENDING_CONSENT,
} from "@utils/constant";
import PropTypes from "prop-types";
import Dialog from "@components/Dialog";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import updateReportStatus from "@app/services/candidate/updateReportStatus";

const ConsentResultCard = (props) => {
  const { messages } = useIntl();
  const [openDialog, setOpenDialog] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleModal = () => {
    setOpenDialog(!openDialog);
  };

  const handleClickOk = () => {
    props.fetchReportDetails();
    setSuccessView(false);
    setOpenDialog(false);
  };

  const { orderDetails, report } = props;

  const { consent_id: consentId } = orderDetails || {};
  const updateStatus = () => {
    if (report.id && consentId && report?.report_status) {
      setIsLoading(true);
      const payloadStatus =
        report?.report_status === PENDING_CONSENT
          ? STATUS_IN_PROGRESS
          : STATUS_COMPLETED;
      updateReportStatus(report.id, consentId, payloadStatus).then(() => {
        setIsLoading(false);
        if (report?.report_status === PENDING_CONSENT) {
          setSuccessView(true);
        } else {
          setOpenDialog(false);
          props.fetchReportDetails();
        }
      });
    }
  };

  return (
    <>
      {openDialog && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 1, 1, 445]}
          onHide={handleModal}
          m={[3, 3, 3, "auto"]}
        >
          {!successView ? (
            <Div mt={2} textAlign={["center"]}>
              {isLoading ? (
                <Div height={162} textAlign={["center"]}>
                  <ProgressSpinner />
                </Div>
              ) : (
                <>
                  <H3 mb={3}>
                    {report?.report_status === PENDING_CONSENT
                      ? messages.consent_label_confirm_for_consent
                      : messages.consent_label_confirm_to_share_for_consent}
                  </H3>
                  <Div
                    mt={30}
                    display="flex"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <PrimaryButtonOutlined
                      mx={15}
                      minWidth="150px"
                      minHeight="40px"
                      justifyContent="center"
                      onClick={handleModal}
                    >
                      {messages.label_cancel}
                    </PrimaryButtonOutlined>
                    <PrimaryButton
                      mx={15}
                      minWidth="150px"
                      minHeight="40px"
                      justifyContent="center"
                      onClick={updateStatus}
                    >
                      {messages.consent_button_confirm}
                    </PrimaryButton>
                  </Div>
                </>
              )}
            </Div>
          ) : (
            <Div mt={2} textAlign={["center"]}>
              <H3 mb={3}>{messages.consent_label_success}</H3>
              <Div
                mt={30}
                display="flex"
                alignItems="start"
                justifyContent="center"
              >
                <PrimaryButton
                  mx={15}
                  minWidth="150px"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                  minHeight="40px"
                  onClick={handleClickOk}
                >
                  {messages.label_ok}
                </PrimaryButton>
              </Div>
            </Div>
          )}
        </Dialog>
      )}

      <Div
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", "column", "column", "row"]}
      >
        {props.showConsentModal && (
          <Div>
            <Link
              label={
                report?.report_status === PENDING_CONSENT
                  ? messages.consent_label_for_creating_report
                  : messages.consent_label_for_creating_share_report
              }
              direction="right"
              iconPos="left"
              cursor="pointer"
              onClick={handleModal}
            >
              <Icon name="pen" mr={1} />
            </Link>
          </Div>
        )}
      </Div>
    </>
  );
};

ConsentResultCard.propTypes = {
  orderDetails: PropTypes.object,
  report: PropTypes.object,
  showOrderLink: PropTypes.boolean,
  isAdmin: PropTypes.boolean,
  showObject: PropTypes.boolean,
  id: PropTypes.string,
  consent: PropTypes.string,
  fetchReportDetails: PropTypes.func,
  showConsentModal: PropTypes.bool,
};

export default ConsentResultCard;
