import Axios from "@app/api/axios";
import { CASES } from "@utils/constant";

const updateCaseDetailsById = (caseId = "", payload = {}) => {
  return Axios.patch(`${CASES}/${caseId}`, payload).then(
    (response) => response.data,
  );
};

export default updateCaseDetailsById;
