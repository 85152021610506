import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import createPromoCode from "@app/services/promoCodes/createPromoCode";

import BreadCrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES } from "@utils/constant";
import { APPLICATION_TYPE } from "@utils/enum";

import CancelDialog from "./components/CancelDialog";
import CreateSchema from "./CreateSchema";
import CreateCouponForm from "./Form";
import { INITIAL_COUPON_DATA } from "./types";

const BREADCRUMB_ITEMS = [
  {
    label: <FormattedMessage id="title_coupons" />,
    url: ROUTES.ADMIN_COUPONS_LIST.URL,
  },
  {
    label: <FormattedMessage id="title_create_coupon" />,
  },
];

const CreateCoupon = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const handleOpenCancelDialog = () => {
    setIsCancelDialogVisible(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogVisible(false);
  };

  const createCoupon = async payload => {
    try {
      setIsLoading(true);

      await createPromoCode(payload);

      showSuccessToast(messages.message_coupon_created);

      handleCancelCouponCreation();
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCoupon = ({ products, ...values }) => {
    const allProducts =
      values.application_type === APPLICATION_TYPE.ENTIRE_ORDER;

    const payload = {
      ...values,
      all_products: allProducts,
      active: 1,
    };

    payload.products = allProducts ? [] : products?.map(({ code }) => code);

    createCoupon(payload);
  };

  const handleCancelCouponCreation = () => {
    history.goBack();
  };

  const headerConfig = {
    title: messages.title_create_coupon,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Div width={[1, 1, "32%", "32%"]}>
        <BreadCrumb p={0} mb={"12px"} items={BREADCRUMB_ITEMS} />

        {isLoading && <ProgressSpinner />}

        <CreateCouponForm
          initialData={INITIAL_COUPON_DATA}
          validationSchema={CreateSchema}
          onSubmit={handleCreateCoupon}
          onCancel={handleOpenCancelDialog}
        />

        {isCancelDialogVisible && (
          <CancelDialog
            title={messages.title_cancel_creating_coupon}
            message={messages.message_are_you_sure}
            onConfirm={handleCancelCouponCreation}
            onHide={handleCloseCancelDialog}
          />
        )}
      </Div>
    </AdminContainer>
  );
};

export default CreateCoupon;
