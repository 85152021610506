import Axios from "@app/api/axios";
import { REPORT_AUTH, REPORTS } from "@utils/constant";

const getReportAnalys = payload => {
  const token = localStorage.getItem("token");
  const reportURL = token ? REPORT_AUTH : REPORTS;
  return Axios.get(`${reportURL}/${payload}`).then(response => response);
}


export default getReportAnalys;
