import Axios from "@app/api/axios";
import {
  WATCHLIST_OBJECT_HISTORIES_API,
  WATCHLIST_OBJECTS_API,
} from "@utils/constant";

const getWatchListObjectOrderDetails = (id, customerId, orderId) =>
  Axios.get(
    `${WATCHLIST_OBJECTS_API}/${id}/${WATCHLIST_OBJECT_HISTORIES_API}/${orderId}?customer_id=${customerId}`,
  );

export default getWatchListObjectOrderDetails;
