import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FileUpload as PRFileUpload } from "primereact/fileupload";
import { REPORT_SIZE_LIMIT } from "@utils/constant";
import Div from "@components/Div";
import Span from "@components/Span";
import fileUploadService from "@app/services/files/fileUploadService";
import { Text, TextSemiBoldWeight } from "@components/Text";
import Link from "@components/Link";
import Icon from "@components/Icon";

export const ACCEPT_FILE_TYPES = [".pdf", "application/pdf"];

const UploadBordered = ({
  onChange,
  assignmentId = "",
  errorMessage = "",
  category = "",
  uploadRef,
  onFileAttached,
}) => {
  const { messages } = useIntl();

  const [error, setError] = useState("");

  const onError = () => {
    setError(errorMessage);
  };

  const handleOnSubmit = () => {
    const input = uploadRef.current.getInput();

    input.click();
  };

  const handleClear = () => {
    uploadRef.current.clear();

    onFileAttached({ files: [] });
  };

  const handleAttachFile = (event) => {
    const { files = [] } = event;

    if (!files.length) {
      uploadRef.current.clear();
      return;
    }

    const acceptedFileType = "application/pdf";
    const [attachedFile = {}] = files;
    const { type: attachedFileType = "" } = attachedFile;

    if (attachedFileType !== acceptedFileType) {
      uploadRef.current.clear();
      return;
    }

    onFileAttached(event);
  };

  const uploadHandler = async (event) => {
    const { files } = event;
    const { type = "" } = files[0];

    let form = new FormData();
    form.append("assignment_id", assignmentId);
    form.append("media", files[0]);
    form.append("type", type);
    form.append("category", category);

    try {
      const response = await fileUploadService(form);
      const { data = {} } = response;
      setError("");
      uploadRef.current.clear();
      onChange(data);
    } catch (e) {
      throw new Error(e);
    }
  };

  const renderEmptyTemplate = () => (
    <Div display="flex" flexDirection="row" gridGap={20} px={24} py={16}>
      <Link handleClick={handleOnSubmit}>
        <Icon name="pin" mr={1} color="var(--blue-dark) !important" />
        {messages.label_attach_file}
      </Link>
      <Text>{messages.text_drag_and_drop_here}</Text>
    </Div>
  );

  const renderProgressBar = () => <Div display="none" />;

  const renderItem = (file) => (
    <Div
      display="flex"
      flexDirection="row"
      bg="var(--blue-lightest)"
      borderRadius={20}
      justifyContent="space-between"
      alignItems="center"
      px={12}
      py={1}
      mx={24}
      my={16}
    >
      <Icon
        name="download-assignment"
        color="var(--turquoise)"
        fontSize="var(--fs-text-secondary)"
      />
      <TextSemiBoldWeight>{file.name}</TextSemiBoldWeight>
      <Icon
        name="close"
        color="var(--grey)"
        fontSize="var(--fs-grade-text)"
        onClick={handleClear}
        style={{
          cursor: "pointer",
        }}
      />
    </Div>
  );

  return (
    <>
      <PRFileUpload
        customUpload
        uploadHandler={uploadHandler}
        name="file"
        accept={ACCEPT_FILE_TYPES}
        maxFileSize={REPORT_SIZE_LIMIT}
        invalidFileSizeMessageDetail={messages.label_maximum_upload_size}
        invalidFileSizeMessageSummary={messages.label_invalid_file_size}
        ref={uploadRef}
        onError={onError}
        emptyTemplate={renderEmptyTemplate}
        headerStyle={{
          display: "none",
        }}
        progressBarTemplate={renderProgressBar}
        itemTemplate={renderItem}
        onSelect={handleAttachFile}
      />
      {error && (
        <Div>
          <Span error py={2}>
            {error}
          </Span>
        </Div>
      )}
    </>
  );
};

UploadBordered.propTypes = {
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  assignmentId: PropTypes.string,
  category: PropTypes.string,
  uploadRef: PropTypes.object,
  onFileAttached: PropTypes.func,
};

export default UploadBordered;
