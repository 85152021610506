import React from "react";
import PropTypes from "prop-types";

import {
  Accordion as PRAccordion,
  AccordionTab as PRAccordionTab,
} from "primereact/accordion";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  gridGap,
  gridColumnGap,
  grid,
} from "styled-system";

import Div from "@components/Div";
import Icon from "@components/Icon";
import RichText from "@components/RichText";

const StyledRichText = styled(RichText)`
  font-weight: var(--regular-weight);
`;

const StyledAccordion = styled(PRAccordion).attrs({
  className: "deviations-accordion",
})`
  ${compose(space, flexbox, layout, position, gridGap, gridColumnGap, grid)};
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-bottom: 16px;
`;

const GradeIconsData = {
  1: {
    color: "var(--white)",
    name: "tick-mark",
    bg: "var(--green-dark) !important",
    border: "solid var(--green-dark) !important",
  },
  0: {
    color: "var(--black)",
    name: "warning",
    bg: "var(--yellow-medium) !important",
    border: "solid var(--yellow-medium) !important",
  },
  "-1": {
    color: "var(--white)",
    name: "error",
    bg: "var(--red-medium) !important",
    border: "solid var(--red-medium) !important",
  },
};

const CaseDeviationsAccordion = ({
  content = [],
  activeIndex,
  onTabChange,
  gridTemplateColumns,
}) => {
  const renderHeaderTemplate = ({ title = "", grade = 0, iconColor }) => {
    const iconProps = iconColor && {
      bg: `var(--${iconColor}) !important`,
      border: `solid var(--${iconColor}) !important`,
    };

    return (
      <Div className="p-accordion-header-template">
        <Div className="p-accordion-header-text">{title}</Div>
        <Div className="p-accordion-value-icon">
          <StyledIcon key={grade} {...GradeIconsData[grade]} {...iconProps} />
        </Div>
      </Div>
    );
  };

  const renderContent = ({ htmlContent = "" }) => (
    <StyledRichText fontWeight="var(--regular-weight)">{htmlContent}</StyledRichText>
  );

  return (
    <StyledAccordion
      multiple
      activeIndex={activeIndex}
      onTabChange={onTabChange}
      gridTemplateColumns={gridTemplateColumns}
    >
      {content?.map((item, index) => (
        <PRAccordionTab
          key={`${index}_${item?.title}`}
          headerTemplate={renderHeaderTemplate(item)}
        >
          {renderContent(item)}
        </PRAccordionTab>
      ))}
    </StyledAccordion>
  );
};

CaseDeviationsAccordion.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onTabChange: PropTypes.func,
  gridTemplateColumns: PropTypes.string,
};

export default CaseDeviationsAccordion;
