import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";
import ProgressSpinner from "@components/ProgressSpinner";
import Tabs from "@components/Tabs";

import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import SelectedSubscriptionDetails from "@pages/shared/subscription/SelectedSubscriptionDetails";

import { loadActiveSubscriptionData } from "@utils/common";
import { ROUTES } from "@utils/constant";

import SubscriptionOrders from "./SubscriptionOrders";

export const SubscriptionDetails = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { subscription_id } = useParams();
  const { showErrorToast } = useToast();

  const [customerId, setCustomerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const tabContent = useMemo(
    () => [
      {
        title: messages.subscription,
        content: (
          <SelectedSubscriptionDetails
            customerId={customerId}
            subscriptionId={subscription_id}
          />
        ),
        url: ROUTES.SUBSCRIPTION_DETAILS.URL.replace(
          ":subscription_id",
          subscription_id
        ),
      },
      {
        title: messages.title_orders,
        content: <SubscriptionOrders customerId={customerId} />,
        url: ROUTES.SUBSCRIPTION_ORDERS.URL.replace(
          ":subscription_id",
          subscription_id
        ),
      },
    ],
    [customerId, messages.subscription, messages.title_orders, subscription_id]
  );

  const _activeTabIndex = Math.max(
    tabContent.findIndex(item => item.url === history?.location?.pathname),
    0
  );

  const [activeIndex, setActiveIndex] = useState(_activeTabIndex);

  const headerConfig = {
    title: `${messages.subscription} #${subscription_id}`,
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setIsLoading(true);

        const subscriptionResponse = await loadActiveSubscriptionData(
          {},
          `&filter[id]=${subscription_id}&include=auditLogs,customer`
        );
        const { customer_id } = subscriptionResponse;

        setCustomerId(customer_id);
      } catch {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!customerId) {
      fetchSubscription();
    }
  }, [
    customerId,
    messages.exception_error_message,
    showErrorToast,
    subscription_id,
  ]);

  const breadCrumbItems = [
    {
      label: messages.subscriptions_title,
      url: ROUTES.SUBSCRIPTION_LIST.URL,
    },
    {
      label: `#${subscription_id}`,
    },
  ];

  const handleTabChange = event => {
    const { index } = event;
    const targetUrl = tabContent[index]?.url;
    const targetIndex = Math.max(
      tabContent.findIndex(item => item.url === targetUrl),
      0
    );

    setActiveIndex(targetIndex);

    window.history.pushState(null, "", targetUrl);
  };

  return (
    <AdminContainer config={headerConfig}>
      {(isLoading || !customerId) && <ProgressSpinner />}

      <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />

      <Tabs
        content={tabContent}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default SubscriptionDetails;
