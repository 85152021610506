import Axios from "@app/api/axios";
import { USERS, SETPASSWORD } from "@utils/constant";

const setPasswordService = (payload, id, token) => {
  Axios.put(`${USERS}/${id}/${SETPASSWORD}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export default setPasswordService;
