import React from "react";
import { useSelector } from "react-redux";
// import Divider from "@components/Divider";
import ProgressSpinner from "@components/ProgressSpinner";
import Login from "@pages/auth/login/Login";
// import BankLogin from "@pages/auth/login/bank/BankLogin";

const UserLogin = () => {
  const { isFetching } = useSelector((state) => state.authReducer);

  return (
    <>
      {isFetching ? (
        <ProgressSpinner />
      ) : (
        <>
          <Login />
          {/* <Divider
            width={[8 / 10, 8 / 10, 8 / 10, 1 / 2]}
            m={"0 auto !important"}
          /> */}
          {/* <BankLogin /> */}
        </>
      )}
    </>
  );
};

export default UserLogin;
