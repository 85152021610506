import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import CrimesForm from "./CrimesForm";

const INITIAL_VALUES = {
  crime: null,
  crime_case_number: "",
  crime_date: "",
  court: null,
};

const AddCrimesDialog = ({ onHide, onAdd }) => {
  const { messages } = useIntl();

  return (
    <CrimesForm
      title={messages.label_add_crime}
      data={INITIAL_VALUES}
      onHide={onHide}
      onSubmit={onAdd}
    />
  );
};

AddCrimesDialog.propTypes = {
  onHide: PropTypes.func,
  onAdd: PropTypes.func,
};

export default AddCrimesDialog;
