import React from "react";
import Div from "@components/Div";
import { TextColoured } from "@components/Text";
import PropTypes from "prop-types";

const StatusIndicator = ({ bgColor, color = "white", value }) => (
  <Div
    width={40}
    height={24}
    bg={`var(--${bgColor})`}
    borderRadius="50px"
    display={"flex"}
    alignItems="center"
    justifyContent="center"
  >
    <TextColoured color={`var(--${color})`} fontWeight={`var(--medium-weight)`}>
      {value}
    </TextColoured>
  </Div>
);

StatusIndicator.propTypes = {
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default StatusIndicator;
