import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import customerDetailsService from "@app/services/customers/customerDetailsService";

import Breadcrumb from "@components/Breadcrumb";
import ProgressSpinner from "@components/ProgressSpinner";
import Tabs from "@components/Tabs";

import AdminContainer from "@layout/AdminContainer";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ADMIN_BILLING_ACCOUNTS, ROUTES } from "@utils/constant";

import BillingDetails from "../../user/billingAccountInfo/tabs/BillingDetails";
import Orders from "../../user/billingAccountInfo/tabs/Orders";

const AdminBillingAccountInfo = () => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { customer_id = "", billing_account_id = "" } = useParams();
  const { showErrorToast } = useToast();

  const [billingAccountInfo, setBillingAccountInfo] = useState(null);

  const hasAdminReadOrdersPermission = useMemo(
    () => hasAllPermissions([AdminPermissions.AdminReadOrders]),
    [hasAllPermissions]
  );

  const tabViewContent = useMemo(
    () => [
      {
        title: messages.label_details,
        content: (
          <BillingDetails
            billingAccountId={billing_account_id}
            customerId={customer_id}
            onBillingInfoLoaded={setBillingAccountInfo}
          />
        ),
        url: ROUTES.ADMIN_BILLING_ACCOUNT_INFO.URL.replace(
          ":customer_id",
          customer_id
        ).replace(":billing_account_id", billing_account_id),
      },
      {
        title: messages.title_orders,
        isHidden: !hasAdminReadOrdersPermission,
        content: (
          <Orders
            customerId={customer_id}
            billingAccountId={billing_account_id}
          />
        ),
        url: ROUTES.ADMIN_BILLING_ACCOUNT_ORDERS.URL.replace(
          ":customer_id",
          customer_id
        ).replace(":billing_account_id", billing_account_id),
      },
    ],
    [
      billing_account_id,
      customer_id,
      hasAdminReadOrdersPermission,
      messages.label_details,
      messages.title_orders,
    ]
  );

  const _activeTabIndex = Math.max(
    tabViewContent.findIndex(({ url }) => url === history.location.pathname),
    0
  );

  const [activeTabIndex, setActiveTabIndex] = useState(_activeTabIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        setIsLoading(true);

        const { data: { data: { customer_name: name = "" } = {} } = {} } =
          await customerDetailsService(customer_id);

        setCustomerName(name);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomerName();
  }, [customer_id, messages.exception_error_message, showErrorToast]);

  const breadCrumbItems = [
    {
      label: messages.title_customers,
      url: ROUTES.ADMIN_CUSTOMERS.URL,
    },
    {
      label: customerName,
    },
    {
      label: messages.title_billing,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_BILLING_ACCOUNTS}`,
    },
    {
      label: billingAccountInfo?.name,
    },
  ];

  const containerConfig = {
    title: billingAccountInfo?.name,
  };

  const handleTabChange = ({ index }) => {
    const targetUrl = tabViewContent[index]?.url;
    const activeIndex = Math.max(
      tabViewContent.findIndex(({ url }) => url === targetUrl),
      0
    );

    setActiveTabIndex(activeIndex);

    window.history.pushState(null, "", targetUrl);
  };

  return (
    <AdminContainer config={containerConfig}>
      <Breadcrumb items={breadCrumbItems} px={0} pt={0} />

      {isLoading && <ProgressSpinner />}

      <Tabs
        isPlainPage
        content={tabViewContent}
        activeIndex={activeTabIndex}
        onTabChange={handleTabChange}
      />
    </AdminContainer>
  );
};

export default AdminBillingAccountInfo;
