import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { SVLANG } from "@utils/constant";

/**
 * Email validation
 */
const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      <FormattedMessage
        id="validation_valid_email_input"
        defaultMessage="Invalid email"
      />,
    )
    .required(
      <FormattedMessage
        id="validation_empty_email_input"
        defaultMessage="Enter email"
      />,
    ),
  confirm_email: Yup.string()
    .required(SVLANG.validation_empty_confirm_email)
    .when("email", {
      is: (email) => !!(email && email.length > 0),
      then: () =>
        Yup.string().oneOf(
          [Yup.ref("email")],
          SVLANG.validation_valid_confirm_email,
        ),
    }),
});

export default EmailSchema;
