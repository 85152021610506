import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { compose, flexbox, layout } from "styled-system";
import {
  TabView as PRTabView,
  TabPanel as PRTabPanel,
} from "primereact/tabview";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

const StyledDiv = styled.div`
  padding-top: 16px;
  .p-tabview {
    ${compose(layout, flexbox)};
  }
  .p-tabview-nav {
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
    border: 1px solid var(--grey-lightest);
    align-items: flex-start;
    border-radius: 10px;
    justify-content: start;
    width: fit-content;

    li {
      border-radius: 10px;

      .p-tabview-nav-link {
        padding: 8px 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 10px !important;

        box-shadow: none !important;
        border: none;

        .p-tabview-title {
          font-weight: var(--medium-weight);
          font-size: var(--fs-h5);
          line-height: var(--lh-text-m);
          color: var(--light-gray);
        }
      }

      &:not(.p-highlight) {
        .p-tabview-nav-link {
          background-color: transparent;
          :hover {
            background: transparent !important;
          }
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: var(--grey-lightest);

          .p-tabview-title {
            color: var(--grey-dark);
          }
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 0;
    padding-top: ${({ isPlainPage }) => (isPlainPage ? "32px" : "16px")};

    .datatable-demo.has-header-actions {
      margin-top: -32px;
    }
  }

  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    .p-tabview-nav {
      justify-content: center;
    }
  }
`;

const Tabs = ({ content, onTabChange, activeIndex, isPlainPage, ...props }) => {
  const contentFiltered = content?.filter(value => !value.isHidden);

  return (
    <StyledDiv
      isPlainPage={isPlainPage}
      isVisible={contentFiltered?.length > 1}
    >
      <PRTabView {...props} activeIndex={activeIndex} onTabChange={onTabChange}>
        {contentFiltered.map(value => (
          <PRTabPanel key={`${value.title}`} header={value.title}>
            {value.content}
          </PRTabPanel>
        ))}
      </PRTabView>
    </StyledDiv>
  );
};

Tabs.propTypes = {
  content: Proptypes.array.isRequired,
  onTabChange: Proptypes.func,
  activeIndex: Proptypes.bool,
  isPlainPage: Proptypes.bool,
};

export default Tabs;
