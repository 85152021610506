import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import Proptypes from "prop-types";
import Icon, { SmallEllipseIcon } from "@components/Icon";
import Div from "../../../components/Div";
import { Text, TextMediumWeight } from "@components/Text";
import styled from "styled-components";
import Card from "@components/Card";
import { compose, position } from "styled-system";
import { PrimaryButton } from "@components/Button";

const StyledCard = styled(Card)`
  ${compose(position)}
  white-space: break-spaces;
  margin-left: 0px;
  position: absolute;
  width: max-content;
  max-width: ${({ isSearchPage }) => (isSearchPage ? "600px" : "180px")};
  &:before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 18px solid var(--white);
    rotate: 90deg;
    right: 97.3%;
    top: 6%;
  }
`;

const ExpiredLink = ({ type, isSearchPage = false }) => {
  const { label, icon, iconSize = "", content = "" } = type;
  const [isShownPopup, setIsShownPopup] = useState(false);
  const { messages } = useIntl();

  const handlePopupOpen = useCallback(() => {
    setIsShownPopup(true);
  }, [setIsShownPopup]);

  const handlePopupClose = useCallback(() => {
    setIsShownPopup(false);
  }, [setIsShownPopup]);

  return (
    <Div
      display="flex"
      onMouseEnter={handlePopupOpen}
      onMouseLeave={handlePopupClose}
    >
      <TextMediumWeight color="var(--grey-dark)" pr={[1, 1, 2, 2]}>
        {label}
      </TextMediumWeight>
      <Div display="flex" position={"relative"}>
        {isSearchPage ? (
          <SmallEllipseIcon name={icon} fontSize={iconSize} />
        ) : (
          <Icon name={icon} mx={1} fontSize={iconSize} />
        )}
      </Div>
      <Div ml={isSearchPage ? 1 : 3}>
        {isShownPopup && (
          <StyledCard py={9} px={15} isSearchPage={isSearchPage}>
            <Text>
              {content || messages.report_status_expired_tooltip_text}
            </Text>
            {!!isSearchPage && (
              <Div display="flex" justifyContent="center" mb={3}>
                <PrimaryButton
                  onClick={handlePopupClose}
                  label={messages.label_ok}
                  width={200}
                />
              </Div>
            )}
          </StyledCard>
        )}
      </Div>
    </Div>
  );
};

ExpiredLink.propTypes = {
  type: Proptypes.object,
  isSearchPage: Proptypes.bool,
};

export default ExpiredLink;
