import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";

import FormListItem from "./components/FormListItem";

const FormsTab = ({ formsData, onUpdateService }) => {
  const { hasAllPermissions } = useAuthorization();

  const hasFormConfigPermission = hasAllPermissions([
    AdminPermissions.AdminWriteRules,
  ]);

  const formsConfig = Object.entries(formsData).map(([key, value]) => {
    return {
      type: key,
      data: value,
      isEditable: hasFormConfigPermission,
      onUpdate: (formData) => {
        onUpdateService({ [key]: formData });
      },
    };
  });

  return (
    <Div
      with={[1, 1, 1, "80%"]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      {formsConfig.map((configItem) => (
        <FormListItem key={configItem.type} {...configItem} />
      ))}
    </Div>
  );
};

FormsTab.propTypes = {
  formsData: PropTypes.object,
  onUpdateService: PropTypes.func,
};

export default FormsTab;
