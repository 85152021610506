import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import queryString from "query-string";
import * as Yup from "yup";

import getCrimes from "@app/services/cases/getCrimes";
import getCourts from "@app/services/cases/getCourts";

import ErrorDialog from "@components/ErrorDialog";
import JsonFormDialog from "@components/JsonForm/JsonFormDialog";
import ProgressSpinner from "@components/ProgressSpinner";

const CrimesForm = ({ title, data, onHide, onSubmit }) => {
  const { messages } = useIntl();

  const [crimesData, setCrimesData] = useState([]);
  const [courtsData, setCourtsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCrimesData = async (query = "") => {
    try {
      setIsLoading(true);

      const completeQuery = query
        ? queryString.stringify(
            {
              "filter[search][columns]": "id,name",
              "filter[search][value]": query,
            },
            { skipNull: true }
          )
        : "";

      const { data: crimes } = await getCrimes(completeQuery);

      setCrimesData(crimes);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const getCourtsData = async (query = "") => {
    try {
      setIsLoading(true);

      const completeQuery = query
        ? queryString.stringify(
            {
              "filter[search][columns]": "id,court_name",
              "filter[search][value]": query,
            },
            { skipNull: true }
          )
        : "";

      const { data: courts } = await getCourts(completeQuery);

      setCourtsData(courts);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchCrime = event => {
    const { query = "" } = event;

    getCrimesData(query);
  };

  const handleSearchCourt = event => {
    const { query = "" } = event;

    getCourtsData(query);
  };

  const CONFIG = [
    {
      label: messages.title_crime,
      fieldType: "autocomplete",
      name: "crime",
      options: crimesData,
      optionField: "name",
      placeholder: "search_text",
      completeMethod: handleSearchCrime,
      validation: Yup.object().required(
        <FormattedMessage id="validation_enter_crime" />
      ),
    },
    {
      label: messages.title_case_number,
      fieldType: "text",
      name: "crime_case_number",
      placeholder: "placeholder_number",
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_crime_case_number" />
      ),
    },
    {
      label: messages.title_date_of_crime,
      fieldType: "calendar",
      name: "crime_date",
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_crime_date" />
      ),
    },
    {
      label: messages.label_court_name,
      fieldType: "autocomplete",
      name: "court",
      options: courtsData,
      optionField: "court_name",
      placeholder: "search_text",
      completeMethod: handleSearchCourt,
      validation: Yup.object().required(
        <FormattedMessage id="validation_enter_court" />
      ),
    },
    {
      label: messages.label_crime_sentence,
      fieldType: "text",
      name: "crime_sentence",
      placeholder: "placeholder_crime_sentence",
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_crime_sentence" />
      ),
    },
  ];

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);

        const [{ data: crimes }, { data: courts }] = await Promise.all([
          getCrimes(),
          getCourts(),
        ]);

        setCrimesData(crimes);
        setCourtsData(courts);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [messages.exception_error_message]);

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <JsonFormDialog
        title={title}
        config={CONFIG}
        initialValues={data}
        onHide={onHide}
        onSubmit={onSubmit}
      />
    </>
  );
};

CrimesForm.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CrimesForm;
