import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { CRIMES } from "@utils/constant";
import crimesService from "@app/services/crimes/crimesService";
const List = () => {
  const { messages } = useIntl();
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [crimes, setCrimes] = useState([]);

  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";

  const searchableColumns = ["id", "name"];

  const createUniqueArray = (array, key) =>
    Array.from(
      new Map(
        array
          .filter(o => o?.[key])
          .map(o => [o[key], { name: o[key], code: o[key] }])
      ).values()
    );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const crimesResponse = await Promise.resolve(crimesService());
        const { data: { data: crimes = [] } = {} } = crimesResponse;

        const formattedCrimes = createUniqueArray(crimes, "name");
        const formattedType = createUniqueArray(crimes, "type");
        const formattedCategories = createUniqueArray(crimes, "category");

        setCrimes(formattedCrimes);
        setTypes(formattedType);
        setCategories(formattedCategories);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  const dataTableConfig = {
    header: {
      title: "title_crimes",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "crimes_reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.title_crimes,
        id: "filter[name]",
        type: "multiselect",
        options: [
          {
            name: "filter[name]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[name]",
            options: crimes,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[type]",
        type: "multiselect",
        options: [
          {
            name: "filter[type]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[type]",
            options: types,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.category_label,
        id: "filter[category]",
        type: "multiselect",
        options: [
          {
            name: "filter[category]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[category]",
            options: categories,
            type: "multiselect",
          },
        ],
      },
      
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${CRIMES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "name",
            "type",
            "category",
            "created_at",
            "points",
            "time_relevance",
            "points_role_analysis",
            "time_relevance_role_analysis",
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "80px",
        sortable: true,
      },
      {
        title: "title_crime_items",
        db_field: "name",
        type: "text",
        sortable: true,
        width: "21%",
      },
      {
        title: "type_label",
        db_field: "type",
        type: "text",
        sortable: true,
        width: "100px",
      },
      {
        title: "category_label",
        db_field: "category",
        type: "text",
        sortable: true,
      },
      {
        title: "label_points",
        db_field: "points",
        type: "text",
        sortable: true,
        width: "80px"
      },
      {
        title: "label_time_relevance",
        db_field: "time_relevance",
        type: "text",
        sortable: true,
        width: "105px"
      },
      {
        title: "label_roles_points",
        db_field: "points_role_analysis",
        type: "text",
        sortable: true,
      },
      {
        title: "label_roles_time_relevance",
        db_field: "time_relevance_role_analysis",
        type: "text",
        sortable: true,
      },
    ],
  };

  return (
    types.length > 0 &&
    categories.length > 0 &&
    crimes.length > 0 && <DataTable config={dataTableConfig} />
  );
};

export default List;
