import Axios from "@app/api/axios";

import { SOURCE_DATA_API } from "@utils/constant";

const getSourceDataById = (sourceDataId = "") => {
  return Axios.get(`${SOURCE_DATA_API}?filter[search][columns]=id,&filter[search][value]=${sourceDataId}`).then(
    (response) => response.data,
  );
};

export default getSourceDataById;
