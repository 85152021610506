import React from "react";
import styled from "styled-components";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const StyledDiv = styled(Div)`
  &:hover {
    background-color: var(--bright-gray);
    cursor: pointer;
  }
`;

const StyledLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const UploadButton = ({ changeHandler, allowedTypes }) => {
  const { messages } = useIntl();
  return (
    <StyledDiv p={3}>
      <input
        type="file"
        id="file-browser-input"
        name="file-browser-input"
        className="FileInput"
        onChange={changeHandler}
        accept={allowedTypes.join(", ")}
        style={{ display: "none" }}
      />
      <StyledLabel htmlFor="file-browser-input">
        <Icon mr={2} name="upload-file" color="var(--turquoise)" />
        {messages.upload_file_with_objects}
      </StyledLabel>
    </StyledDiv>
  );
};

UploadButton.propTypes = {
  changeHandler: PropTypes.func,
  allowedTypes: PropTypes.string,
};

export default UploadButton;
