import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Tabs from "@components/Tabs";
import AdminContainer from "@layout/AdminContainer";
import { ROUTES } from "@utils/constant";
import CouponsList from "./tabs/CouponsList";
import ServicesList from "./tabs/ServicesList";
const TAB_CONTENT = [
  {
    title: <FormattedMessage id="label_services" />,
    content: <ServicesList />,
    url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
  },
  {
    title: <FormattedMessage id="title_coupons" />,
    content: <CouponsList />,
    url: ROUTES.ADMIN_COUPONS_LIST.URL,
  },
];
const List = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(
      (item) => item.url === pathname,
    );
    setActiveIndex(activeTabIndex);
  }, [history]);
  const handleTabChange = (event) => {
    const { index } = event;
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };
  const headerConfig = {
    title: activeIndex ? messages.title_coupons : messages.label_services,
  };
  return (
    <AdminContainer config={headerConfig} wide>
      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default List;
