import React, { useState, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import Card from "@components/Card";
import { LinkArrow } from "@components/Link";
import Span from "@components/Span";
import Dropdown from "@components/Dropdown";
import { getColorAndValueOfGrade } from "@utils/utils";
import { H2, H4, H5 } from "@components/Heading";
import Container from "@components/Container";
import styled from "styled-components";
import { TextLarge, ColouredSemiBoldText } from "@components/Text";
import Icon, { SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";
import {
  STATUS_EXPIRED,
  STATUS_NOT_PAID,
  STATUS_PAID,
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  STATUS_CANCELLED,
  STATUS_NEW,
  STATUS_ERROR,
} from "@utils/constant";

const StyledContainer = styled(Container)`
  position: relative;
  background: var(--blue-lightest);
  box-shadow: none;
`;
const StyledH4 = styled(H4)`
  font-weight: 600;
`;
const AnalysisParts = ({
  analysData = {},
  handleSaveAndSendPopup = () => {},
  setGrade,
  grade = null,
}) => {
  const { messages } = useIntl();

  const {
    name: person_name = "",
    personal_number,
    report_status: reportStatus = "",
    product = {},
  } = analysData;

  const {
    name: service = "",
    description: serviceDetail = "",
    id: productID = "",
  } = product;

  const handleStatus = useCallback(
    (reportStatus) => {
      switch (reportStatus) {
        case STATUS_NOT_PAID:
          return messages.status_not_started;
        case STATUS_PAID:
          return messages.paid;
        case STATUS_COMPLETED:
          return messages.completed;
        case STATUS_IN_PROGRESS:
          return messages.status_in_progress;
        case STATUS_CANCELLED:
          return messages.cancelled;
        case STATUS_NEW:
          return messages.new;
        case STATUS_ERROR:
          return (
            <ColouredSemiBoldText color="var(--red)">
              {messages.waiting}
            </ColouredSemiBoldText>
          );
        case STATUS_EXPIRED:
          return messages.expired_status_label;
        default:
          return reportStatus;
      }
    },
    [messages],
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const getGrade = (key) => {
    const { label, color } = getColorAndValueOfGrade(key);

    return (
      <Div display="flex" alignSelf="center">
        <Div width={20} height={20} bg={color} borderRadius="50%" />
        <H4 ml={2}>{label}</H4>
      </Div>
    );
  };

  const options = [
    {
      name: getGrade(0),
      value: 1,
      grade: "1",
    },
    {
      name: getGrade(1),
      value: -1,
      grade: "-1",
    },
    { name: getGrade(2), value: 0, grade: null },
  ];

  useEffect(() => {
    setGrade(options.find((o) => o.grade === analysData?.grades)?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysData, setGrade]);

  const handleClick = () => {
    setIsDisabled(true);
    handleSaveAndSendPopup();
  };

  const handleChange = (e) => {
    setGrade(e?.value);
    setIsDisabled(false);
  };

  return (
    <>
      <Card mb={5} p={0} maxWidth="1110px">
        <Div>
          <StyledContainer
            color="var(--blue-lightest)"
            width={1}
            py={4}
            px={[3, 4]}
            maxWidth="1110px"
          >
            <Div
              display="flex"
              alignItems={["left", "center"]}
              justifyContent="space-between"
              flexDirection={["column", "row"]}
            >
              <H2>{person_name}</H2>
              <Div
                pt={[1, 0]}
                mt={[3, 0]}
                display="flex"
                flexDirection={["row", "column"]}
                justifyContent="space-between"
                alignItems={"flex-end"}
              >
                <TextLarge>{messages.label_personal_number}</TextLarge>
                <StyledH4>{personal_number}</StyledH4>
              </Div>
            </Div>
          </StyledContainer>
          <Container
            mr={[0, 0, 0, 4]}
            width={1}
            pt={3}
            pb={2}
            px={[3, 4]}
            minWidth={["0px", "610px"]}
            maxWidth="1110px"
          >
            <Div
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Div minWidth={["0px", "425px"]}>
                <H5>{messages.object_services_title_services}</H5>
              </Div>
            </Div>
          </Container>
          <Container
            mr={[0, 0, 0, 4]}
            width={1}
            pt={3}
            pb={2}
            px={[3, 4]}
            minWidth={["0px", "610px"]}
            maxWidth="1110px"
          >
            <Div
              display={"flex"}
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Div
                display={"flex"}
                alignItems="center"
                minWidth={["0px", "425px"]}
              >
                <Div display="flex" flexDirection={["column", "row"]}>
                  <Div display={"flex"} alignItems="center">
                    <Div>
                      <StyledH4>{service}</StyledH4>
                    </Div>
                    <SmallEllipseIcon
                      ml={2}
                      name="info"
                      className={`description_${productID}`}
                    >
                      <Tooltip
                        target={`.description_${productID}`}
                        content={serviceDetail}
                      ></Tooltip>
                    </SmallEllipseIcon>
                  </Div>
                  <Div display="flex" alignItems="center">
                    <H5 ml={[0, 4]} mt={[2, 0]} display="block">
                      {handleStatus(reportStatus)}
                    </H5>
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div mt={3} mb={3}>
              <Dropdown
                onChange={handleChange}
                value={grade}
                name="grade"
                optionLabel={"name"}
                options={options}
                width={[1, 1, 340, 340]}
              />
            </Div>
            <LinkArrow
              label={messages.title_save_send}
              direction="right"
              variant="secondary"
              mr={2}
              handleClick={handleClick}
              iconPos="right"
              disabled={isDisabled}
            >
              <Span px={1}>
                <Icon name="headerarrowright" />
              </Span>
            </LinkArrow>
          </Container>
        </Div>
      </Card>
    </>
  );
};

AnalysisParts.propTypes = {
  analysData: PropTypes.array,
  setGrade: PropTypes.node,
  grade: PropTypes.string,
  handleSaveAndSendPopup: PropTypes.func,
};
export default AnalysisParts;
