import React from "react";
import { default as Div } from "@components/Div";
import ActionTemplate from "@components/Table/ActionTemplate.jsx";

export const FooterTemplate = ({ footerActions = [] }) => {
  const footerComponent = (children) =>
    footerActions.length > 1 && (
      <Div
        bg="var(--ligth-grey) !important"
        flexDirection={["column", "column", "row", "row"]}
      >
        <Div
          display="flex"
          alignItems={"center"}
          width={[1, 1, "auto", "auto"]}
          mb={[3, 3, 0, 0]}
        >
          {children}
        </Div>
      </Div>
    );
  const footerEl = footerActions.map((value) => {
    return ActionTemplate(value.type, value);
  });
  return footerComponent(footerEl);
};
