import React from "react";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

import NumberSchema from "@pages/auth/schema/Numbers";

import { INPUT } from "@utils/constant";
import { INVOICE_TYPE } from "@utils/enum";

const Schema = Yup.object({
  name: Yup.string().required(
    <FormattedMessage
      id="validation_empty_company_name"
      defaultMessage="Enter company name"
    />,
  ),
  address_1: Yup.string().required(
    <FormattedMessage id="validation_enter_address_1" />,
  ),
  zip_code: Yup.number().required(
    <FormattedMessage id="validation_enter_zip_code" />,
  ),
  address_2: Yup.string(),
  city: Yup.string().required(<FormattedMessage id="validation_enter_city" />),
  country_code: Yup.string().required(
    <FormattedMessage id="validation_enter_country_name" />,
  ),
  your_reference: Yup.string().required(
    <FormattedMessage
      id="validation_enter_your_reference"
      defaultMessage="Enter reference person"
    />,
  ),
  invoice_remark: Yup.string(),
  invoice_type: Yup.string().required(
    <FormattedMessage
      id="validation_enter_invoice_type"
      defaultMessage="Enter invoice type"
    />,
  ),
  edi_identification: Yup.number().when(
    "invoice_type",
    ([invoice_type], schema) =>
      invoice_type === INVOICE_TYPE.E_INVOICE
        ? schema.required(
            <FormattedMessage
              id="validation_enter_edi_identification"
              defaultMessage="Enter edi identification"
            />,
          )
        : schema,
  ),
  peppol_address: Yup.string().when("invoice_type", ([invoice_type], schema) =>
    invoice_type === INVOICE_TYPE.E_INVOICE
      ? schema
          .email(
            <FormattedMessage
              id="validation_valid_email_input"
              defaultMessage="Invalid email"
            />,
          )
          .required(
            <FormattedMessage
              id="validation_empty_email_input"
              defaultMessage="Enter email"
            />,
          )
      : schema,
  ),
  gln: Yup.number().when("invoice_type", ([invoice_type], schema) =>
    invoice_type === INVOICE_TYPE.E_INVOICE
      ? schema.required(
          <FormattedMessage
            id="validation_enter_glm"
            defaultMessage="Enter gln number"
          />,
        )
      : schema,
  ),
  van_operator: Yup.number().when("invoice_type", ([invoice_type], schema) =>
    invoice_type === INVOICE_TYPE.E_INVOICE
      ? schema.required(
          <FormattedMessage
            id="validation_enter_van_operator"
            defaultMessage="Enter van operator"
          />,
        )
      : schema,
  ),
  email_invoice: Yup.string().when("invoice_type", ([invoice_type], schema) =>
    invoice_type === INVOICE_TYPE.EMAIL_INVOICE
      ? schema
          .email(
            <FormattedMessage
              id="validation_valid_email_input"
              defaultMessage="Invalid email"
            />,
          )
          .required(
            <FormattedMessage
              id="validation_empty_email_input"
              defaultMessage="Enter email"
            />,
          )
      : schema,
  ),
}).concat(NumberSchema.pick([INPUT.NAME.COMPANY_NUMBER]));

export default Schema;
