import Axios from "@app/api/axios";
import { PRODUCTS } from "@utils/constant";

const getProductById = (id) => {
  return Axios.get(
    `${PRODUCTS}?filter[state]=active&filter[search][columns]=id,&filter[search][value]=${id}`,
  );
};

export default getProductById;
