import React from "react";
import styled from "styled-components";
import { Calendar as PRCalendar } from "primereact/calendar";
import { locale, addLocale } from "primereact/api";
import {
  compose,
  width,
  space,
  position,
  layout,
  flexbox,
  typography,
} from "styled-system";

const StyledCalendar = styled(PRCalendar)`
  ${compose(compose, width, space, position, layout, flexbox, typography)};
  border-radius: ${({ curved }) => (curved ? "10px" : "auto")} !important;
  .p-datepicker-calendar.p-datepicker {
    background-color: #f00 !important;
  }
`;

const Calendar = (props) => {
  addLocale("sv", {
    firstDayOfWeek: 1,
    dayNames: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],
    dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    dayNamesMin: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    monthNames: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "hav",
      "apr",
      "maj",
      "juni",
      "jul",
      "jag ska",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    today: "Idag",
    clear: "Rensa",
  });

  locale("sv");
  return <StyledCalendar {...props} />;
};

export default Calendar;
