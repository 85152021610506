import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Tabs from "@components/Tabs";

import useAuthorization from "@hooks/useAuthorization";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import CasesListTab from "./tabs/CasesListTab";
import EmailLogListTab from "./tabs/EmailLogListTab";

const AdminCasesList = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { hasAllPermissions } = useAuthorization();

  const hasEmailLogPermission = hasAllPermissions([
    AdminPermissions.AdminReadEmailLog,
  ]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabContent = useMemo(
    () => [
      {
        title: messages.title_cases,
        content: <CasesListTab />,
        url: ROUTES.ADMIN_CASES_LIST.URL,
      },
      {
        title: messages.title_email_log,
        content: <EmailLogListTab />,
        url: ROUTES.ADMIN_EMAIL_LOG.URL,
        isHidden: !hasEmailLogPermission,
      },
    ],
    [messages, hasEmailLogPermission],
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = tabContent.findIndex(
      (item) => item.url === pathname,
    );
    setActiveTabIndex(activeTabIndex);
  }, [history, tabContent]);

  const handleTabChange = (event) => {
    const { index } = event;
    const targetUrl = tabContent[index]?.url;
    history.push({
      pathname: targetUrl,
    });
  };

  const selectedTitle = useMemo(
    () => tabContent[activeTabIndex]?.title,
    [activeTabIndex, tabContent],
  );

  const headerConfig = {
    title: selectedTitle,
  };

  return (
    <AdminContainer config={headerConfig} wide>
      <Tabs
        content={tabContent}
        activeIndex={activeTabIndex}
        onTabChange={handleTabChange}
      />
    </AdminContainer>
  );
};
export default AdminCasesList;
