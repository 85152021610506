import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import getEmailsBySourceDataId from "@app/services/cases/getEmailsBySourceDataId";

import ErrorDialog from "@components/ErrorDialog";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";

import { EmailThread } from "./components";

const EmailsDialog = ({ onClose }) => {
  const { messages } = useIntl();
  const { data_source_id } = useParams();

  const [emailsData, setEmailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchEmailsForDataSource = async () => {
      try {
        setIsLoading(true);

        const { data = [] } = await getEmailsBySourceDataId(data_source_id);

        setEmailsData(data);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmailsForDataSource();
  }, [data_source_id, messages.exception_error_message]);

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  return (
    <FullSizeDialog title={messages.label_view_emails} onClose={onClose}>
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <EmailThread data={emailsData} />
    </FullSizeDialog>
  );
};

EmailsDialog.propTypes = {
  onClose: PropTypes.func,
};

export default EmailsDialog;
