import React, { useEffect, useState } from "react";
import getReportByConsentIdService from "@app/services/candidate/getReportByConsentIdService";
import ProgressSpinner from "@components/ProgressSpinner";
import { useParams } from "react-router-dom";
import PendingConsentToShare from "@components/Consent/PendingConsentToShare";
import PendingConsent from "@components/Consent/PendingConsent";
import ConsentGiven from "@components/Consent/ConsentGiven";
import ReportNotReady from "@components/Consent/ReportNotReady";
import ConsentExpired from "@components/Consent/ConsentExpired";
import updateReportStatus from "@app/services/candidate/updateReportStatus";
import Div from "@components/Div";

const consentComponents = {
  "Pending Consent": PendingConsent,
  "Pending consent to share": PendingConsentToShare,
  failed: ReportNotReady,
  "in-progress": ReportNotReady,
  completed: ConsentGiven,
  "expired-no-consent": ConsentExpired,
};

export const CandidateConsent = () => {
  const { consent_id } = useParams();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const query_status = urlParams.get("query_status");
  const [currentReport, setCurrentReport] = useState({});
  const [companyName, setCompanyName] = useState("");

  const getReport = async (consent_id) => {
    setLoading(true);
    const response = await getReportByConsentIdService(consent_id);
    const [orderItem] = response.data;
    const { report = {} } = orderItem;
    setCompanyName(orderItem.customer_name);
    setCurrentReport(report);
    setLoading(false);
    return report;
  };

  useEffect(() => {
    if (consent_id && query_status === null) {
      setTimeout(() => {
        getReport(consent_id);
      }, 2000);
    }
  }, [consent_id, query_status]);

  const handleUpdate = async (status) => {
    let intervalId;
    const timeout = 30000;
    setLoading(true);
    await updateReportStatus(currentReport.id, consent_id, status);
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        stopAuthorization();
        reject(new Error("Report generation in progress timed out"));
      }, timeout);
      const stopAuthorization = async () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
      intervalId = setInterval(async () => {
        const response = await getReport(consent_id);
        if (response?.data?.status !== "in-progress") {
          stopAuthorization();
          resolve(response);
        }
      }, 5000);
    });
    setLoading(false);
  };

  if (
    !Object.keys(consentComponents).includes(currentReport.report_status) ||
    loading
  ) {
    return <ProgressSpinner />;
  }
  const Component = consentComponents[currentReport.report_status];

  return (
    <Div width={["100%", "100%", "100%", "1174px"]}
      paddingLeft={["0", "16px", "16px", "32px"]}
      paddingRight={["0", "16px", "16px", "32px"]}
    >
      <Component
        companyName={companyName}
        report={currentReport}
        consent_id={consent_id}
        onHandleUpdate={handleUpdate}
      />
    </Div>
  );
};

export default CandidateConsent;
