import React from "react";

import { Editor as PREditor } from "primereact/editor";
import styled from "styled-components";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import {
  PropertyGridEditorCollection,
  localization,
} from "survey-creator-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";

const RICH_CONTENT_EDITOR = "rich_content_editor";

const StyledEditor = styled(PREditor)`
  height: 200px;
  padding-bottom: 32px;

  font-weight: 300;
  font-family: "Noto Sans";
  color: var(--grey-dark);

  .p-editor-toolbar {
    display: none;
  }

  strong {
    font-weight: var(--semibold-weight);
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      margin-left: 1.5rem;
    }
  }

  p,
  span {
    font-size: var(--fs-text);
    line-height: var(--lh-text);
  }

  h6 {
    color: var(--blue-dark);
    font-size: var(--fs-text-sm);
    font-weight: var(--semibold-weight);
  }
`;

class QuestionRichTextModel extends Question {
  getType() {
    return RICH_CONTENT_EDITOR;
  }

  get height() {
    return this.getPropertyValue("height");
  }

  set height(val) {
    this.setPropertyValue("height", val);
  }
}

class SurveyQuestionRichText extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get type() {
    return RICH_CONTENT_EDITOR;
  }

  handleValueChange(val) {
    const { htmlValue, textValue } = val;
    const valueToAssign = this.question.isHTML ? htmlValue : textValue;

    this.question.value = valueToAssign;
  }

  get style() {
    return { height: this.question.height };
  }

  renderRichText() {
    const isReadOnly = this.question.isReadOnly || this.question.isDesignMode;

    const handleLoad = quill => {
      const htmlString = this.question.value;

      quill.clipboard.dangerouslyPasteHTML(htmlString);
    };

    return (
      <StyledEditor
        readOnly={isReadOnly}
        value={this.state.value}
        onTextChange={this.handleValueChange.bind(this)}
        onLoad={handleLoad.bind(this)}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["background", "color"],
            [{ list: "ordered" }],
            [{ align: [] }],
            ["blockquote", "code-block", "link", "image"],
            ["clean"],
          ],
        }}
      />
    );
  }

  renderElement() {
    return <div style={this.style}>{this.renderRichText()}</div>;
  }
}

function applyTranslations() {
  const locale = localization.getLocale("");

  locale.qt[RICH_CONTENT_EDITOR] = "Rich Content Editor";
}

function applyIcon() {
  settings.customIcons[`icon-${RICH_CONTENT_EDITOR}`] =
    "icon-html";
}

export function registerRichEditor() {
  ElementFactory.Instance.registerElement(
    RICH_CONTENT_EDITOR,
    name => {
      return new QuestionRichTextModel(name);
    }
  );

  Serializer.addClass(
    RICH_CONTENT_EDITOR,
    [{ name: "height", default: "250px", category: "layout" }],
    function () {
      return new QuestionRichTextModel("");
    },
    "question"
  );



  ReactQuestionFactory.Instance.registerQuestion(
    RICH_CONTENT_EDITOR,
    props => {
      return React.createElement(SurveyQuestionRichText, props);
    }
  );

  PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type === "html";
    },
    getJSON: function () {
      return { type: RICH_CONTENT_EDITOR };
    },
  });

  applyTranslations();
  applyIcon();
}
