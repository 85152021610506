import React, { useState } from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import Form from "@components/Form";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { InvertedIcon } from "@components/Icon";
import InputText from "@components/InputText";
import {
  PrimaryButtonIcon,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import { INPUT, GENERAL_ANALYSIS } from "@utils/constant";
import EmailSchema from "@pages/auth/schema/Email";
import PasswordSchema from "@src/schema/Password";
import NameSchema from "@pages/auth/schema/Name";
import NumbersSchema from "@pages/auth/schema/Numbers";
import BillingEmailSchema from "@pages/auth/schema/BillingEmail";
import ErrorMessage from "@pages/shared/ErrorMessage";

const RegistrationSchema = EmailSchema.concat(PasswordSchema)
  .concat(BillingEmailSchema)
  .concat(NameSchema)
  .concat(NumbersSchema);

const CreateCustometSchema = EmailSchema.concat(BillingEmailSchema)
  .concat(NameSchema)
  .concat(NumbersSchema);

const RegisterUserForm = (props) => {
  const { messages } = useIntl();

  const {
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    NAME,
    LAST_NAME,
    PHONE,
    COMPANY_NAME,
    COMPANY_NUMBER,
    BILLING_EMAIL,
  } = INPUT.NAME;
  const { TEXT, EMAIL: EMAIL_TYPE } = INPUT.TYPE;
  const {
    isCompany = false,
    onRegistrationSubmit,
    isCreateCustomer,
    onCancelCreate,
    errorMessage,
  } = props;
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const userDetails = [
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    NAME,
    LAST_NAME,
    PHONE,
  ];
  const companyDetails = [COMPANY_NAME, COMPANY_NUMBER, BILLING_EMAIL];

  const handleTogglePassword = (label) => () => () => {
    if (label === CONFIRM_PASSWORD) {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };
  const regFormDetails = userDetails.concat(companyDetails);

  return (
    <Div>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          password: "",
          confirm_password: "",
          company_name: "",
          registration_nr: "",
          billing_email: "",
          analysis_type: GENERAL_ANALYSIS,
        }}
        validationSchema={
          isCreateCustomer
            ? isCompany
              ? CreateCustometSchema.pick(regFormDetails)
              : CreateCustometSchema.pick(userDetails)
            : isCompany
            ? RegistrationSchema.pick(regFormDetails)
            : RegistrationSchema.pick(userDetails)
        }
        onSubmit={onRegistrationSubmit}
      >
        {(prop) => {
          const { values, handleSubmit, isValid, touched } = prop;
          return (
            <Form>
              <Div display="flex" flexDirection="column" pt={16} pb={1}>
                <H2>{messages.label_account_info}</H2>
                <Div
                  display={["block", "flex"]}
                  width={1}
                  justifyContent="center"
                >
                  <Div mr={[0, 3]} width={[1, "350px"]}>
                    <InputText
                      curved
                      placeholder={messages.label_firstname}
                      value={values.firstname}
                      name={NAME}
                      type={TEXT}
                      formikProps={prop}
                      label={`${messages.label_firstname} *`}
                      width={[1, "350px"]}
                      medium
                      labelAlignment={["center", "left"]}
                    />
                  </Div>
                  <Div ml={[0, 3]} width={[1, "350px"]}>
                    <InputText
                      curved
                      placeholder={messages.label_last_name}
                      value={values.lastname}
                      name={LAST_NAME}
                      type={TEXT}
                      formikProps={prop}
                      label={`${messages.label_last_name} *`}
                      width={[1, "350px"]}
                      medium
                      labelAlignment={["center", "left"]}
                    />
                  </Div>
                </Div>
                <Div
                  display={["block", "flex"]}
                  width={1}
                  justifyContent="center"
                >
                  <Div mr={[0, 3]} width={[1, "350px"]}>
                    <InputText
                      curved
                      placeholder={messages.label_email}
                      value={values.email}
                      name={EMAIL}
                      formikProps={prop}
                      label={`${messages.label_email} *`}
                      width={[1, "350px"]}
                      medium
                      labelAlignment={["center", "left"]}
                    />
                  </Div>
                  <Div ml={[0, 3]} width={[1, "350px"]}>
                    <InputText
                      curved
                      placeholder={`${messages.label_phone} *`}
                      value={values.phone}
                      name={PHONE}
                      formikProps={prop}
                      label={`${messages.label_phone} *`}
                      width={[1, "350px"]}
                      medium
                      labelAlignment={["center", "left"]}
                    />
                  </Div>
                </Div>
                {!isCreateCustomer && (
                  <Div
                    display={["block", "flex"]}
                    width={1}
                    justifyContent="center"
                  >
                    <Div mr={[0, 3]} width={[1, "350px"]}>
                      <InputText
                        isPassword
                        curved
                        placeholder={messages.label_password}
                        value={values.password}
                        name={PASSWORD}
                        formikProps={prop}
                        label={`${messages.label_set_password} *`}
                        width={[1, "350px"]}
                        medium
                        onTogglePassword={handleTogglePassword(PASSWORD)}
                        showPassword={showPassword}
                        labelAlignment={["center", "left"]}
                      />
                    </Div>
                    <Div ml={[0, 3]} width={[1, "350px"]}>
                      <InputText
                        isPassword
                        curved
                        placeholder={messages.label_password}
                        value={values.confirm_password}
                        name={CONFIRM_PASSWORD}
                        formikProps={prop}
                        label={`${messages.label_repeat_password} *`}
                        width={[1, "350px"]}
                        medium
                        onTogglePassword={handleTogglePassword(
                          CONFIRM_PASSWORD,
                        )}
                        showPassword={showConfirmPassword}
                        labelAlignment={["center", "left"]}
                      />
                    </Div>
                  </Div>
                )}
              </Div>
              {isCompany && (
                <>
                  <Div display="flex" flexDirection="column" mt={4}>
                    <H2>{messages.label_company_info}</H2>
                    <Div
                      display={["block", "flex"]}
                      width={1}
                      justifyContent="center"
                    >
                      <Div mr={[0, 3]} width={[1, "350px"]}>
                        <InputText
                          curved
                          placeholder={messages.label_company_name}
                          value={values.company_name}
                          name={COMPANY_NAME}
                          formikProps={prop}
                          type={TEXT}
                          label={`${messages.label_company_name} *`}
                          width={[1, "350px"]}
                          medium
                          labelAlignment="left"
                        />
                      </Div>
                      <Div ml={[0, 3]} width={[1, "350px"]}>
                        <InputText
                          curved
                          placeholder={messages.label_company_number}
                          value={values.registration_nr}
                          name={COMPANY_NUMBER}
                          formikProps={prop}
                          label={`${messages.label_company_number} *`}
                          width={[1, "350px"]}
                          medium
                          maxLength={11}
                          labelAlignment="left"
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div mr={[0, 3]} width={[1, "350px"]}>
                    <InputText
                      type={EMAIL_TYPE}
                      curved
                      placeholder={messages.billing_email}
                      value={values.billing_email}
                      name={BILLING_EMAIL}
                      formikProps={prop}
                      label={messages.billing_email}
                      width={[1, "350px"]}
                      medium
                      labelAlignment={["center", "left"]}
                    />
                  </Div>
                </>
              )}
              {errorMessage && (
                <Div display="flex" justifyContent="center">
                  <ErrorMessage errorMessage={errorMessage} />
                </Div>
              )}
              <Div
                display={["block", "block", "block", "flex"]}
                justifyContent="center"
                textAlign="center"
                my={4}
                py={2}
              >
                <Div mb={[4, 4, 4, 0]} display="flex" justifyContent="center">
                  <PrimaryButtonIcon
                    rounded
                    semibold
                    py={1}
                    px={"23px"}
                    icon={
                      <InvertedIcon
                        p={1}
                        mr={1}
                        rounded={true}
                        name="tick-mark"
                      />
                    }
                    onClick={handleSubmit}
                    disabled={!(isValid && Object.keys(touched).length > 0)}
                    label={
                      isCreateCustomer
                        ? messages.title_create_customer
                        : messages.label_register
                    }
                  />
                </Div>
                {!isCreateCustomer ? (
                  <></>
                ) : (
                  <Div display="flex" justifyContent="center" ml={[0, 0, 0, 4]}>
                    <PrimaryButtonIconOutlined
                      rounded
                      semibold
                      label={messages.label_cancel}
                      onClick={onCancelCreate}
                      px={40}
                      py={1}
                      fontSize={"var(--fs-h5)"}
                    />
                  </Div>
                )}
              </Div>
            </Form>
          );
        }}
      </Formik>
    </Div>
  );
};

RegisterUserForm.propTypes = {
  isCompany: Proptypes.bool,
  onRegistrationSubmit: Proptypes.func,
  errorMessage: Proptypes.bool,
  isCreateCustomer: Proptypes.func,
  onCancelCreate: Proptypes.func,
};

export default RegisterUserForm;
