import Axios from "@app/api/axios";
import { USERS } from "@utils/constant";

const getUserDetails = () =>
  Axios.get(`${USERS}/me`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e?.response;
    });
export default getUserDetails;
