import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { useFormikContext } from "formik";
import { Password } from "primereact/password";
import { Error } from "@components/Heading";

const TextPassword = ({ name }) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();
  const errorMessage = errors[name];
  const touchedMessage = touched[name];

  return (
    <Div flex="1 1 40%">
      <Password
        mt={2}
        width={1}
        name={name}
        value={values?.[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        toggleMask
      />
      {errorMessage && touchedMessage && (
        <Div pb={1} pt={3}>
          <Error>{errorMessage}</Error>
        </Div>
      )}
    </Div>
  );
};

TextPassword.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TextPassword;
