import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonOutlined } from "@components/Button";
import Container from "@components/Container";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Icon from "@components/Icon";
import PDFPreview from "@components/PDFPreview";

import PrintReportDialog from "./PrintReportDialog";

import { PDFLoader } from "../components";

const AssignmentReport = ({ assignment }) => {
  const { messages } = useIntl();

  const [isPrintReportDialogVisible, setIsPrintReportDialogVisible] =
    useState(false);
  const [isPDFGenerating, setIsPDFGenerating] = useState(false);

  const previewFile = {
    url: assignment?.url,
  };

  const handleOpenPrintDialog = () => {
    setIsPrintReportDialogVisible(true);
  };

  const handleClosePrintDialog = () => {
    setIsPrintReportDialogVisible(false);

    handlePDFGenerateFinish();
  };

  const handlePDFGenerateStart = () => {
    setIsPDFGenerating(true);
  };

  const handlePDFGenerateFinish = () => {
    setTimeout(setIsPDFGenerating, 2000, false);
  };

  return (
    <Container alignItems="center" display="flex" flexDirection="column">
      {isPDFGenerating && <PDFLoader />}

      {isPrintReportDialogVisible && (
        <PrintReportDialog
          downloadUrl={assignment?.url}
          onConfirm={handlePDFGenerateStart}
          onClose={handleClosePrintDialog}
        />
      )}

      <Div
        width={1}
        display="flex"
        justifyContent="space-between"
        alignItems={["flex-start", "flex-start", "center", "center"]}
        flexDirection={["column", "column", "row", "row"]}
        mt={4}
        className={isPDFGenerating ? "report-pdf" : ""}
      >
        <H1>{assignment.productName}</H1>

        <PrimaryButtonOutlined
          minWidth="217px"
          onClick={handleOpenPrintDialog}
          px={3}
          py={1}
          height="40px"
          mt={[1, 1, 0, 0]}
        >
          <Icon alt="icon" name="download-assignment" mr={2} />
          {messages.label_download_as_pdf}
        </PrimaryButtonOutlined>
      </Div>

      <PDFPreview previewFile={previewFile} />
    </Container>
  );
};

AssignmentReport.propTypes = {
  assignment: PropTypes.object,
};

export default AssignmentReport;
