import React, { useState } from "react";
import Proptypes from "prop-types";
import { default as Div } from "@components/Div";
import Checkbox from "@components/Checkbox";
import Label from "@components/Label";
import { TextUpperCase } from "@components/Text";
import Icon from "@components/Icon";

const CheckboxServices = (props) => {
  const { id, label, value, checked, onClick } = props;

  return (
    <Div alignItems="center" display="flex">
      <Checkbox
        key={id}
        inputId={id}
        checked={checked}
        onChange={onClick(value)}
        {...props}
        input
      />
      <Label
        htmlFor={id}
        className="p-checkbox-label"
        fontSize="var(--fs-text-small) !important"
        fontWeight="var(--medium-weight) !important"
        mx={2}
      >
        {label}
      </Label>
    </Div>
  );
};

CheckboxServices.propTypes = {
  id: Proptypes.string,
  label: Proptypes.string,
  checked: Proptypes.bool,
  onClick: Proptypes.func,
  value: Proptypes.string,
};

const CheckBoxes = ({ value = {}, options }) => {
  const { title = "" } = value;
  const [expand, setExpand] = useState(true);

  const handleAccordion = () => {
    setExpand(!expand);
  };
  return (
    <Div display={"block"} borderTop={"1px solid var(--turquoise-light)"}>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleAccordion}
        cursor="pointer"
        p={3}
      >
        <TextUpperCase>{title}</TextUpperCase>
        <Icon
          name={expand ? "arrowbig-up" : "arrowbig-down"}
          fontSize="7px"
          onClick={handleAccordion}
          cursor="pointer"
        />
      </Div>
      {expand && (
        <Div width={1} pb={3} px={3}>
          {options.map((item) => (
            <Div
              key={`check-${item.label}`}
              display="flex"
              alignItems={"center"}
              width={value?.width || [0.5, 0.5, 0.5, 0.43]}
              mb={2}
            >
              <CheckboxServices
                id={item.label}
                label={item.label}
                value={item.value}
                checked={item.checked}
                onClick={item.onClick}
              />
            </Div>
          ))}
        </Div>
      )}
    </Div>
  );
};

CheckBoxes.propTypes = {
  value: Proptypes.object,
  options: Proptypes.array,
};

export default CheckBoxes;
