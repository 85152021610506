import React from "react";
import Proptypes from "prop-types";
import {
  Accordion as PRAccordion,
  AccordionTab as PRAccordionTab,
} from "primereact/accordion";
import styled from "styled-components";
import { compose, space, layout, flexbox, position } from "styled-system";

const StyledAccordion = styled(PRAccordion)`
  ${compose(space, flexbox, layout, position)};
  .p-accordion-tab {
    .p-accordion-toggle-icon {
      margin-left: ${({ alignArrowLeft }) =>
        alignArrowLeft ? "15px" : "auto"};
    }
  }
`;

const Accordion = ({ content, activeIndex, alignArrowLeft, ...rest }) => {
  return (
    <StyledAccordion
      activeIndex={activeIndex}
      alignArrowLeft={alignArrowLeft}
      {...rest}
    >
      {content.map((value, index) => {
        const { title, content, ...rest } = value;
        return (
          <PRAccordionTab key={index} header={title} {...rest}>
            {content}
          </PRAccordionTab>
        );
      })}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  content: Proptypes.array.isRequired,
  activeIndex: Proptypes.number,
  alignArrowLeft: Proptypes.bool,
};

export default Accordion;
