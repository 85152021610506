import configureMarkerIO from "./configureMarkerIO";
import configureCookieConsent from "./vanilla-cookieconsent/configureCookieConsent";
import { configureSentry } from "./configureSentry";
import { ROUTES } from "@utils/constant";

const disable = window.location.pathname === ROUTES.REPORT_PDF.URL;

const configure = () => {
  if (!disable) {
    configureMarkerIO();
    configureCookieConsent();
  }
  configureSentry();
};

export default configure;
