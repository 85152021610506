import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import patchPromoCode from "@app/services/promoCodes/patchPromoCode";

import DataTable from "@components/DataTableV2/DataTable";
import ErrorDialog from "@components/ErrorDialog";
import SuccessDialog from "@components/SuccessDialog";

import useAuthorization from "@hooks/useAuthorization";

import { formatDiscountValue } from "@pages/admin/coupons/utils";

import { AdminPermissions } from "@src/enum/Permissions";

import { PROMO_CODES, ROUTES } from "@utils/constant";
import { APPLICATION_TYPE, DISCOUNT_TYPE } from "@utils/enum";
import { enumValueToTranslationKey } from "@utils/utils";

const SEARCHABLE_COLUMNS = [
  "id",
  "code",
  "discount_type",
  "discount_value",
  "application_type",
  "valid_to",
  "active",
];

const CouponsList = () => {
  const history = useHistory();

  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();
  const hasWriteProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirectToCreateCoupon = () => {
    history.push(ROUTES.ADMIN_CREATE_COUPON.URL);
  };

  const handleRedirectToEditCoupon = event => {
    const { id: couponId } = event;

    history.push(ROUTES.ADMIN_EDIT_COUPON.URL.replace(":coupon_id", couponId));
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogVisible(false);
  };

  const updateCoupon = async (id, payload) => {
    try {
      setIsLoading(true);

      await patchPromoCode(id, payload);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleCouponActivation = event => {
    const { active, id } = event;

    const payload = {
      active: active ? 0 : 1,
    };

    updateCoupon(id, payload);
  };

  const config = {
    header: {
      actions: [
        {
          id: "new-service",
          type: "button",
          label: messages.button_add_new,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteProductsPermission,
          onClick: handleRedirectToCreateCoupon,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          content: messages.filter_description,
        },
      ],
    },
    row_selection_checkbox: false,
    enable_filter: true,
    enable_csv_download: true,
    filters: [
      {
        title: messages.label_discount_type,
        id: "filter[discount_type]",
        type: "checkbox",
        options: [
          {
            label: messages.label_percent,
            value: DISCOUNT_TYPE.PERCENTAGE,
            checked: false,
          },
          {
            label: messages.label_fixed_amount,
            value: DISCOUNT_TYPE.FIXED_AMOUNT,
            checked: false,
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[application_type]",
        type: "checkbox",
        options: [
          {
            label: messages.label_order,
            value: APPLICATION_TYPE.ENTIRE_ORDER,
            checked: false,
          },
          {
            label: messages.label_item,
            value: APPLICATION_TYPE.SINGLE_ORDER_ITEM,
            checked: false,
          },
          {
            label: messages.label_all_applicable_order_items,
            value: APPLICATION_TYPE.ALL_ITEMS,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
            checked: false,
          },
          {
            label: messages.label_inactive,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_expire_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_expire_date,
        options: [
          {
            label: messages.filter_date_to,
            value: "",
            id: "filter[valid_to][lt]",
          },
        ],
      },
    ],
    enable_search: true,
    backend_querying: true,
    no_records_message: "datatable_admin_coupons_no_objects_found",
    enable_query_builder: true,
    api: {
      resource: `${PROMO_CODES}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [["fields", SEARCHABLE_COLUMNS]],
    },
    default_sort: {
      field: "id",
      order: "desc",
    },
    columns: [
      {
        title: "label_coupon_code",
        db_field: "code",
        sortable: true,
        type: "text",
        width: "14%",
      },
      {
        title: "label_discount_type",
        db_field: "discount_type",
        type: "text",
        width: "16%",
        formatter: value =>
          messages[`label_${enumValueToTranslationKey(value)}`],
      },
      {
        title: "label_discount",
        db_field: "discount_value",
        type: "text",
        width: "10%",
        formatter: (value, { discount_type }) =>
          formatDiscountValue(value, discount_type),
      },
      {
        title: "type_label",
        db_field: "application_type",
        type: "text",
        width: "32%",
        formatter: value =>
          messages[`label_${enumValueToTranslationKey(value)}`],
      },
      {
        title: "label_expire_date",
        db_field: "valid_to",
        sortable: true,
        type: "dateTime",
        width: "17%",
      },
      {
        title: "label_status",
        db_field: "active",
        type: "enum",
        width: "8%",
        formatter: value =>
          value === 1 ? messages.active : messages.label_inactive,
      },
      {
        type: "actions",
        width: "6%",
        actions: [
          {
            label: messages.label_edit_coupon,
            icon: "icon-menu-eye",
            onClick: handleRedirectToEditCoupon,
          },
          {
            formatter: ({ active }) =>
              active ? messages.label_inactivate : messages.label_activate,
            iconSelector: ({ active }) =>
              active ? "icon-rubber" : "icon-restart",
            onClick: handleToggleCouponActivation,
          },
        ],
      },
    ],
  };

  return (
    <>
      <DataTable config={config} resetData={!isLoading} />

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onConfirm={handleCloseErrorDialog}
          onHide={handleCloseErrorDialog}
        />
      )}

      {isSuccessDialogVisible && (
        <SuccessDialog
          message={messages.message_coupon_deleted}
          onConfirm={handleCloseSuccessDialog}
        />
      )}
    </>
  );
};

export default CouponsList;
