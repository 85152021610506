import React, { useState } from "react";
import Proptypes from "prop-types";
import { locale, addLocale } from "primereact/api";
import Div from "@components/Div";
import Calendar from "@components/Calendar";
import Icon from "@components/Icon";
import { TextUpperCase } from "@components/Text";

const UtilCalendar = ({ value }) => {
  const [expand, setExpand] = useState(true);
  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  let maxDate = new Date();
  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  addLocale("sv", {
    firstDayOfWeek: 1,
    dayNames: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],
    dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    dayNamesMin: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    monthNames: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "hav",
      "apr",
      "maj",
      "juni",
      "jul",
      "jag ska",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    today: "Idag",
    clear: "Rensa",
  });

  locale("sv");

  const handleExpandAccordion = () => {
    setExpand(!expand);
  };

  return (
    <Div key={value.id} borderTop={"1px solid var(--turquoise-light)"}>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleExpandAccordion}
        cursor="pointer"
        p={3}
      >
        <TextUpperCase>{value.title}</TextUpperCase>
        <Icon
          name={expand ? "arrowbig-up" : "arrowbig-down"}
          fontSize="7px"
          onClick={handleExpandAccordion}
          cursor="pointer"
        />
      </Div>
      {expand && (
        <Div width={1} pb={3} px={3}>
          {value.options.map((obj, index) => (
            <Calendar
              key={`calendar-${obj.id}-${obj.label}`}
              id={`calendar${index}`}
              placeholder={obj.label}
              showIcon
              icon={<Icon name="calendar" />}
              value={obj.value}
              onChange={obj.onClick(value.type, obj.id)}
              readOnlyInput
              mr={[0, 3]}
              mb={[20, 0]}
              selectionMode={obj.selectionMode}
              width={1}
              showButtonBar
            />
          ))}
        </Div>
      )}
    </Div>
  );
};

UtilCalendar.propTypes = {
  value: Proptypes.object,
};

export default UtilCalendar;
