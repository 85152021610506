import React from "react";
import { useIntl } from "react-intl";
import ConsentFooter from "./ConsentFooter";
import PropTypes from "prop-types";

export const ConsentGiven = ({ companyName = "" }) => {
  const { messages } = useIntl();
  return (
    <ConsentFooter
      title={messages.consent_status_given_title}
      description={`${messages.consent_status_given_description} ${companyName}`}
    />
  );
};

ConsentGiven.propTypes = {
  companyName: PropTypes.string,
};

export default ConsentGiven;
