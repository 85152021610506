import React from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { COURTS } from "@utils/constant";

const List = () => {
  const { messages } = useIntl();

  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";

  const searchableColumns = [
    "id",
    "court_name",
    "court_email",
    "verifiera_court_name",
    "created_at",
  ];

  const dataTableConfig = {
    header: {
      title: "header_court",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "courts_reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${COURTS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "court_name",
            "court_email",
            "verifiera_court_name",
            "created_at",
            "type",
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "50px",
        sortable: true,
      },
      {
        title: "label_court_name",
        db_field: "court_name",
        type: "text",
        sortable: true,
      },
      {
        title: "verifiera_court_name",
        db_field: "verifiera_court_name",
        type: "text",
        sortable: true,
      },
      {
        title: "type_label",
        db_field: "type",
        type: "text",
        formatter: (value) => {
          return value || "-";
        },
      },
      {
        title: "courts_email",
        db_field: "court_email",
        type: "text",
        sortable: true,
      },
      {
        title: "created_at",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            title: messages.label_details,
            type: "link",
            href: () => `#`,
          },
        ],
      },
    ],
  };

  return <DataTable config={dataTableConfig} />;
};

export default List;
