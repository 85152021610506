import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { Text } from "@components/Text";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";

const ConfirmationPopup = (props) => {
  const { messages } = useIntl();
  const {
    title,
    description,
    acceptButtonLabel = messages.yes_label,
    cancelButtonLabel = messages.label_cancel,
    onCancelClick,
    onAcceptClick,
  } = props;

  return (
    <Dialog
      header={<H3 textAlign="center">{title}</H3>}
      visible={true}
      draggable={false}
      textAlign="center"
      onHide={onCancelClick}
      m={[3, 3, "auto", "auto"]}
      width={[1, 1, 500, 500]}
    >
      <Div
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        mt={4}
      >
        <Div textAlign="center" display="grid">
          <Text>{description}</Text>
        </Div>
        <Div
          mt={4}
          px={[0, 4]}
          width={1}
          display="flex"
          flexDirection={["column-reverse", "row"]}
          justifyContent="space-between"
        >
          <PrimaryButtonOutlined
            rounded
            semibold
            label={cancelButtonLabel}
            onClick={onCancelClick}
            mt={[3, 0]}
            width={[1, "47%"]}
          />
          {onAcceptClick && (
            <PrimaryButtonIcon
              rounded
              semibold
              width={[1, "47%"]}
              onClick={onAcceptClick}
              label={acceptButtonLabel}
            />
          )}
        </Div>
      </Div>
    </Dialog>
  );
};
ConfirmationPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  acceptButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  onAcceptClick: PropTypes.func,
};

export default ConfirmationPopup;
