import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const StyledRichText = styled.div`
  font-weight: 300;
  color: var(--grey-dark);

  display: flex;
  flex-direction: column;
  gap: 1rem;

  strong {
    font-weight: var(--semibold-weight);
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      margin-left: 1.5rem;
    }
  }

  p,
  span {
    font-size: var(--fs-text);
    line-height: var(--lh-text);
  }

  h6 {
    color: var(--blue-dark);
    font-size: var(--fs-text-sm);
    font-weight: var(--semibold-weight);
  }
`;

const RichText = ({ className, children }) => (
  <StyledRichText className={className} dangerouslySetInnerHTML={{ __html: children }} />
);

RichText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default RichText;
