import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import styled from "styled-components";

import { personInformationActions } from "@app/reducers/personInformationReducer";
import loginService from "@app/services/auth/loginService";
import getSubscription from "@app/services/subscription/getSubscription";

import { useUser } from "@contexts/AuthProvider";

import { PrimaryButtonIcon } from "@components/Button";
import Container from "@components/Container";
import Div from "@components/Div";
import Email from "@pages/auth/schema/Email";
import Form from "@components/Form";
import { H1, H4 } from "@components/Heading";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import Link, { LinkArrow } from "@components/Link";
import Password from "@src/schema/Password";
import Section from "@components/Section";
import { TextMediumWeight } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";

import ErrorMessage from "@pages/shared/ErrorMessage";

import Config from "@src/config";

import {
  ROUTES,
  PERSONAL_DATA_POLICY,
  ADMIN_NAV,
  ADMIN_NAV_MY_PROFILE,
  COMPANY,
  CUSTOMER,
  INPUT,
} from "@utils/constant";
import useHistory from "@utils/useHistory";
import { isAdminUser, isAuthenticated } from "@utils/utils";

const CredentialsSchema = Email.concat(Password);

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const PRIVACY_POLICY_URL = `${Config.WP_URL}/${PERSONAL_DATA_POLICY}`;

const { EMAIL, LOGIN_PASSWORD, PASSWORD } = INPUT.NAME;
const { TEXT } = INPUT.TYPE;
const LoginSchema = CredentialsSchema.pick([EMAIL, LOGIN_PASSWORD]);

export const Login = () => {
  const { hasAnyPermissions } = useAuthorization();
  const dispatch = useDispatch();
  const location = useHistory();
  const { messages } = useIntl();
  const { userInfo = {} } = useSelector(state => state.authReducer);
  const { initializeUser } = useUser();

  const [showPassword, setShowPassword] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [initValues] = useState({ email: "", password: "" });

  const { type: userType } = userInfo;

  const findPath = () => {
    const [myAcc] = ADMIN_NAV_MY_PROFILE;
    let pathValue = myAcc?.redirectTo;
    ADMIN_NAV.some(obj => {
      const { permissions = [] } = obj;
      if (hasAnyPermissions(permissions)) {
        pathValue = obj?.redirectTo;
        return true;
      }
    });
    return pathValue;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTogglePassword = () => () => {
    setShowPassword(!showPassword);
  };

  const isTokenValid = token => {
    return token !== null;
  };

  const redirectToPage = async user => {
    const { customer: { id = "" } = {}, type = "" } = user;
    if (type == "customer" && id) {
      const subscriptionResponse = await getSubscription(id);
      const { data: { data: subscriptionData = [] } = {} } =
        subscriptionResponse;
      const [activeSubscription = false] = subscriptionData;
      dispatch(
        personInformationActions.setHasActiveSubscription(activeSubscription)
      );
    }
  };

  const handleLoginSubmit = async values => {
    try {
      const response = await loginService({
        email: values.email,
        password: values.login_password,
      });
      const { token, user } = response.data || {};
      if (isTokenValid(token)) {
        localStorage.setItem("token", token);
        initializeUser();
        return redirectToPage(user);
      }
    } catch (e) {
      setErrorMessage(messages.invalid_data);
    }
  };

  const redirectToForgotPassword = () => location.push("FORGOT_PASSWORD");

  if (isAuthenticated()) {
    if (isAdminUser()) {
      return (
        <Redirect
          to={{
            pathname: findPath(),
          }}
        />
      );
    } else {
      if (userType === CUSTOMER && userInfo.customer.type === COMPANY) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.SEARCH.URL,
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: ROUTES.USER_PROFILE.URL,
            }}
          />
        );
      }
    }
  }

  return (
    <Section>
      <Container>
        <Div
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <H1 display={"block"}>{messages.login}</H1>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={LoginSchema}
            onSubmit={handleLoginSubmit}
          >
            {prop => {
              const { values, handleSubmit, isValid } = prop;
              return (
                <>
                  <Form width={1}>
                    <Div
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <InputText
                        curved
                        placeholder={messages.placeholder_email_address}
                        value={values.email}
                        name={EMAIL}
                        formikProps={prop}
                        label={
                          <TextMediumWeight>
                            {messages.label_email}
                          </TextMediumWeight>
                        }
                        width={[1, "350px"]}
                      />
                      <InputText
                        isPassword
                        curved
                        placeholder={messages.label_password}
                        value={values.login_password}
                        name={LOGIN_PASSWORD}
                        formikProps={prop}
                        label={messages.label_password}
                        type={showPassword ? PASSWORD : TEXT}
                        onTogglePassword={handleTogglePassword}
                        showPassword={showPassword}
                        width={[1, "350px"]}
                      />
                      {errorMessage && (
                        <Div display="flex" justifyContent="center">
                          <ErrorMessage errorMessage={errorMessage} />
                        </Div>
                      )}
                      <LinkArrow
                        label={messages.title_forgot_password}
                        onClick={redirectToForgotPassword}
                        direction="left"
                        my={3}
                      >
                        <Icon ml={1} name="headerarrowright" />
                      </LinkArrow>
                      <PrimaryButtonIcon
                        rounded
                        semibold
                        onClick={handleSubmit}
                        disabled={
                          !(isValid && Object.values(values).length > 0)
                        }
                        label={messages.login}
                        width={["auto"]}
                        py={2}
                        px={5}
                      />
                    </Div>
                  </Form>
                  <Div
                    display="flex"
                    justifyContent="center"
                    textAlign="left"
                    mt={4}
                    py={[3, 3, 26]}
                    px={[3, 3, 31]}
                    mx={2}
                    borderColor="var(--turquoise)"
                    borderWidth="1px"
                    borderStyle="dashed"
                    maxWidth="790px"
                  >
                    <Div mt="2px">
                      <StyledIcon
                        name="info"
                        rounded={true}
                        ml={[0, 0, 1, 1]}
                        mr={2}
                        borderWidth={2}
                        width={36}
                        height={36}
                      />
                    </Div>
                    <Div ml={10}>
                      <H4
                        fontSize="var(--fs-link-m) !important"
                        color="var(--grey-dark)"
                      >
                        {messages.login_gdpr_text}
                        <Link
                          href={PRIVACY_POLICY_URL}
                          label={messages.text_privacy_policy}
                          color="var(--grey-dark) !important"
                          fontSize="var(--fs-link-m)"
                          fontWeight="400"
                          textDecoration="underline"
                          target="_blank"
                          px="4px"
                          divDisplay="contents"
                        />
                      </H4>
                    </Div>
                  </Div>
                </>
              );
            }}
          </Formik>
        </Div>
      </Container>
    </Section>
  );
};

export default Login;
