import Axios from "@app/api/axios";
import { REPORTS, RESEND_REPORT_EMAIL } from "@utils/constant";

const resendReportService = (reportId) =>
  Axios.get(`${REPORTS}/${reportId}/${RESEND_REPORT_EMAIL}`)
    .then((response) => response)
    .catch((e) => {
      return e.response;
    });

export default resendReportService;
