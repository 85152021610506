import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import createCasesReport from "@app/services/cases/createCasesReport";
import deleteCasesReportById from "@app/services/cases/deleteCasesReportById";
import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import {
  PrimaryButton,
  PrimaryButtonIconOutlined,
  PrimaryButtonOutlined,
} from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { LinkV2 } from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import PromptDialog from "@components/PromptDialog";
import { SurveyForm } from "@components/Survey";
import { Text, TextMediumWeight } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";
import { CASES_DATA_STATUS_TYPE, SURVEY_MODE_TYPE } from "@utils/enum";

const QUESTION_DIALOG_TYPE = {
  CREATE: "create",
  RE_CREATE: "re_create",
  NONE: "none",
};

const QUESTION_DIALOG_CONTENT_TYPE = {
  [QUESTION_DIALOG_TYPE.CREATE]: {
    title: (
      <FormattedMessage
        id="label_create_report"
        defaultMessage="Create report"
      />
    ),
    message: (
      <FormattedMessage
        id="message_create_report"
        defaultMessage="Are you sure you want to create a report for {productName}?"
        values={{
          productName: "",
        }}
      />
    ),
    onConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.RE_CREATE]: {
    title: (
      <FormattedMessage
        id="label_re_create_report"
        defaultMessage="Re-create report"
      />
    ),
    message: (
      <FormattedMessage
        id="message_re_create_report"
        defaultMessage="Are you sure you want to delete current report and create new?"
      />
    ),
    onConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.NONE]: null,
};

const ReportTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [questionDialogContentType, setQuestionDialogContentType] = useState(
    QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
  );
  const [caseDetails, setCaseDetails] = useState({});

  const surveyRef = useRef(null);

  const hasWriteReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminWriteReportAnalysis,
  ]);

  const {
    report_data: reportData,
    report_config: reportConfig,
    process_type: processType,
    data_sources_status: sourceDataStatus,
    report_id: reportId,
  } = caseDetails;

  const isReportCreated = reportId !== null;
  const isSurveyEditable =
    sourceDataStatus === CASES_DATA_STATUS_TYPE.COMPLETED &&
    !isReportCreated &&
    hasWriteReportAnalysisPermission;
  const shouldShowMessage =
    !isLoading &&
    (isReportCreated || sourceDataStatus !== CASES_DATA_STATUS_TYPE.COMPLETED);
  const surveyMode = isSurveyEditable
    ? SURVEY_MODE_TYPE.EDIT
    : SURVEY_MODE_TYPE.DISPLAY;

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        setIsLoading(true);

        const { data: caseData = {} } = await getCaseDetailsById(case_id);

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseDetails();
  }, [case_id, messages, showErrorToast]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          report_data: data,
        };

        const { data: caseData = {} } = await updateCaseDetailsById(
          case_id,
          payload
        );

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [case_id, messages, showErrorToast]
  );

  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  const handleCreateReport = () => {
    const createType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.CREATE];
    createType.message = (
      <FormattedMessage
        id="message_create_report"
        defaultMessage="Are you sure you want to create a report for {productName}?"
        values={{
          productName: caseDetails?.product?.name,
        }}
      />
    );
    createType.onConfirm = handleConfirmCreateReport;

    setQuestionDialogContentType(createType);

    handleSave();
  };

  const handleConfirmCreateReport = () => {
    createReport();
  };

  const handleReCreateReport = () => {
    const reCreateType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.RE_CREATE];
    reCreateType.onConfirm = handleConfirmReCreateReport;

    setQuestionDialogContentType(reCreateType);
  };

  const createReport = async () => {
    try {
      setIsLoading(true);

      const { id: generatedReportId = "" } = await createCasesReport({
        case_id: case_id,
      });

      setCaseDetails({ ...caseDetails, report_id: generatedReportId });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const recreateReport = async () => {
    try {
      setIsLoading(true);

      await deleteCasesReportById(reportId);

      setCaseDetails({ ...caseDetails, report_id: null });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const handleConfirmReCreateReport = () => {
    recreateReport();
  };

  const handleCloseQuestionDialog = () => {
    setQuestionDialogContentType(
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
    );
  };

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const renderText = () => {
    const notReadyText =
      messages[`text_case_report_${processType}_${sourceDataStatus}`];
    const valueToPresent = String(
      isReportCreated ? messages.text_case_report_created : notReadyText
    );

    return valueToPresent;
  };

  return (
    <Div className="report-config">
      {isLoading && <ProgressSpinner />}

      {questionDialogContentType && (
        <PromptDialog
          title={questionDialogContentType?.title}
          message={questionDialogContentType?.message}
          onConfirm={questionDialogContentType?.onConfirm}
          onCancel={handleCloseQuestionDialog}
          confirmLabel={messages.label_create}
        />
      )}

      {shouldShowMessage && (
        <Div
          borderColor={"var(--turquoise)"}
          borderWidth="1px"
          borderStyle="dashed"
          mt={3}
          p={3}
          mb={4}
          display="flex"
          alignItems="center"
          gridGap={2}
        >
          <Icon
            name="sign"
            fontSize="var(--fs-icon-m)"
            color={"var(--white)"}
            backgroundColor={
              isReportCreated ? "var(--blue-dark)" : "var(--red)"
            }
            p={1}
            borderRadius={"50%"}
          />
          <Text fontSize="var(--fs-text-m) !important">{renderText()}</Text>

          {isReportCreated && (
            <LinkV2 to={ROUTES.ANALYS_DETAILS.URL.replace(":id", reportId)}>
              <TextMediumWeight>{messages.label_report}</TextMediumWeight>
              <Icon name="headerarrowright" ml={1} color="var(--turquoise)" />
            </LinkV2>
          )}
        </Div>
      )}

      <Div bgColor="var(--white)">
        <SurveyForm
          mode={surveyMode}
          data={reportData}
          formConfig={reportConfig}
          onComplete={handleCompleteSurvey}
          onRefLoaded={handleLoadSurveyRef}
        />
      </Div>

      {!isLoading && (
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={3}
        >
          {!isReportCreated && isSurveyEditable && (
            <PrimaryButton
              label={messages.label_create_report}
              onClick={handleCreateReport}
            />
          )}

          {!isReportCreated && isSurveyEditable && (
            <PrimaryButtonOutlined
              backgroundColor="transparent !important"
              minWidth="150px"
              label={messages.label_save}
              onClick={handleSave}
            />
          )}

          {isReportCreated && hasWriteReportAnalysisPermission && (
            <PrimaryButtonIconOutlined
              backgroundColor="transparent !important"
              label={messages.label_re_create_report}
              onClick={handleReCreateReport}
              icon={
                <Icon
                  name="restart"
                  mr={1}
                  color="var(--blue-dark)"
                  fontSize="var(--fs-icon-m)"
                />
              }
            />
          )}
        </Div>
      )}
    </Div>
  );
};

export default ReportTab;
