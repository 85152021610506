import React from "react";
import { Text, TextSemiBoldWeight } from "@components/Text";
import Div from "@components/Div";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { addHyphenToNumber } from "@utils/utils";

function Header({ ssn, name, isConsent, email }) {
  const { messages } = useIntl();

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      justifyContent="space-between"
      alignItems={["", "", "center", "center"]}
    >
      <Div
        display="flex"
        flexDirection={["row", "row", "column", "column"]}
        justifyContent="space-between"
        alignItems={["center", "center", "start", "start"]}
      >
        <Text>{messages.label_candidate_name}</Text>
        <TextSemiBoldWeight
          fontSize={[
            "var(--fs-text-m) !important",
            "var(--fs-text-m) !important",
            "var(--fs-tex)",
            "var(--fs-tex)",
          ]}
          mt={[0, 0, 2, 2]}
        >
          {name}
        </TextSemiBoldWeight>
      </Div>
      <Div
        display="flex"
        flexDirection={["row", "row", "column", "column"]}
        justifyContent="space-between"
        alignItems={["center", "center", "end", "end"]}
        pt={[20, 20, 0, 0]}
      >
        <Text>{messages.label_personal_number}</Text>
        <TextSemiBoldWeight
          fontSize={[
            "var(--fs-text-m) !important",
            "var(--fs-text-m) !important",
            "var(--fs-tex)",
            "var(--fs-tex)",
          ]}
          mt={[0, 0, 2, 2]}
        >
          {addHyphenToNumber(ssn, 8)}
        </TextSemiBoldWeight>
      </Div>
      {isConsent && (
        <Div
          display="flex"
          flexDirection={["row", "row", "column", "column"]}
          justifyContent="space-between"
          alignItems={["center", "center", "end", "end"]}
          mt={[2, 2, 0, 0]}
        >
          <Text>{messages.lable_candidate_email}</Text>
          <TextSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-tex)",
              "var(--fs-tex)",
            ]}
            mt={[0, 0, 3, 3]}
            ml={[3, 3, 0, 0]}
            style={{
              wordBreak: "break-all",
            }}
          >
            {email}
          </TextSemiBoldWeight>
        </Div>
      )}
    </Div>
  );
}

Header.propTypes = {
  ssn: PropTypes.number,
  name: PropTypes.string,
  isConsent: PropTypes.bool,
  email: PropTypes.string,
};

export default Header;
