import React from "react";

import Div from "@components/Div";
import Checkbox from "@components/Checkbox";

import { Error } from "@components/Heading";

const formikCheckbox = (prop) => {
  const { formikProps, onChange, name, labelAlignment = "center" } = prop;
  const {
    handleChange,
    handleBlur,
    errors = {},
    touched = {},
  } = formikProps || {};
  const errorMessage = errors[name] || "";
  const touchedMessage = touched[name] || "";
  const handleCheckbox = (e) => {
    handleBlur(e);
    handleChange(e);
    onChange(e);
  };
  return (
    <>
      <Checkbox
        {...prop}
        onChange={handleCheckbox}
        onBlur={handleBlur}
        error={errorMessage && touchedMessage}
      />
      {errorMessage && touchedMessage && (
        <Div pb={1} pt={3} m={labelAlignment !== "left" && "auto"}>
          <Error>{errorMessage}</Error>
        </Div>
      )}
    </>
  );
};

export default formikCheckbox;
