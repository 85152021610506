import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const QuestionDialog = ({ onConfirm, onCancel }) => {
  const { messages } = useIntl();

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "450px", "450px"]}
      m={[3, 3, "auto", "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={4}
      >
        <H3>{`${messages.heading_save_survey_question}!`}</H3>
        <Text textAlign="center">{messages.message_want_to_save_form}</Text>
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={3}
          width={[1, 1, "250px", "250px"]}
        >
          <PrimaryButton
            label={messages.label_save}
            onClick={onConfirm}
            width={1}
          />
          <PrimaryButtonOutlined
            label={messages.label_close}
            onClick={onCancel}
            width={1}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

QuestionDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default QuestionDialog;
