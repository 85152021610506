import Axios from "@app/api/axios";

import { BILLING_ACCOUNTS } from "@utils/constant";

const patchAccountStatus = (payload) => {
  const { billing_account_id = "" } = payload;

  return Axios.patch(`${BILLING_ACCOUNTS}/${billing_account_id}`, payload);
};
export default patchAccountStatus;
