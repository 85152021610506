import React from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { EMAIL_ACCOUNTS } from "@utils/constant";
const List = () => {
  const { messages } = useIntl();

  const searchableColumns = ["id", "email_address"];
  const dataTableConfig = {
    header: {
      title: "email_accounts_lable",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "email_accounts.csv",
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${EMAIL_ACCOUNTS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "email_address",
            "from_name",
            "enabled",
            "first_name",
            "last_name",
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: "true",
      },
      {
        title: "watchlist_label_name",
        db_field: "from_name",
        type: "text",
        sortable: "true",
      },
      {
        title: "label_email",
        db_field: "email_address",
        type: "text",
        sortable: "true",
      },
      {
        title: "label_status",
        db_field: "enabled",
        type: "text",
        sortable: "true",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            title: messages.label_details,
            type: "link",
            href: () => `#`,
          },
        ],
      },
    ],
  };

  return <DataTable config={dataTableConfig} />;
};
export default List;
