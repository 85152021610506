import React, { createContext, useContext, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const StepperContext = createContext({
  activeStepIndex: 0,
  setActiveStepIndex: () => {},
  onNextStep: () => {},
  onPrevStep: () => {},
});

export const StepperProvider = ({ children, numberOfSteps }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const onNextStep = useCallback(() => {
    const nextIndex = activeStepIndex + 1;

    if (nextIndex >= numberOfSteps) {
      return;
    }

    setActiveStepIndex(nextIndex);
  }, [activeStepIndex, setActiveStepIndex, numberOfSteps]);

  const onPrevStep = useCallback(() => {
    const prevIndex = activeStepIndex - 1;

    if (prevIndex < 0) {
      return;
    }

    setActiveStepIndex(prevIndex);
  }, [activeStepIndex, setActiveStepIndex]);

  const value = useMemo(
    () => ({
      activeStepIndex,
      setActiveStepIndex,
      onNextStep,
      onPrevStep,
    }),
    [
      activeStepIndex,
      setActiveStepIndex,
      onNextStep,
      onPrevStep,
    ],
  );

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.node,
  numberOfSteps: PropTypes.number,
};

export const useStepper = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  return context;
};
