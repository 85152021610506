import React from "react";
import Proptypes from "prop-types";
import { PrimaryButton } from "@components/Button";
import Icon from "@components/Icon";

const Button = ({ type }) => {
  const { id, label, icon, minWidth, mx } = type;
  return (
    <PrimaryButton
      {...type}
      semibold
      key={id}
      id={id}
      mx={mx || [0, 0, 3]}
      label={label}
      icon={icon ? <Icon name={icon} mr={label ? 2 : 0} /> : ""}
      px={3}
      py="8px"
      minWidth={minWidth ? minWidth : "40px"}
      minHeight="40px"
      display="inline-flex"
      textAlign="center"
      whiteSpace
    />
  );
};

Button.propTypes = {
  type: Proptypes.object,
  headerActionsFunctions: Proptypes.array,
};

export default Button;
