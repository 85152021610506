import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { FAILED_STATUS, PENDING_STATUS } from "@utils/constant";
import { isMobile } from "@utils/utils";

import Div from "@components/Div";
import BankIDModal from "@pages/shared/CreateOrderWizard/orderSummary/BankIDModal";
import { Error } from "@components/Heading";
import { PrimaryButton } from "@components/Button";
import { useBankIDLogin } from "@hooks/useBankIDLogin";
import ProgressSpinner from "@components/ProgressSpinner";

const isValidSSN = (ssn, bankIdSSN) => {
  return ssn.toString().replace("-", "") === bankIdSSN.replace("-", "");
};

const BankIDLogin = ({ ssn, onSuccess, onError }) => {
  const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    qrCodeData,
    bankIdFeedback,
    onUseQrCode,
    onUseBankIdApp,
    onCancelBankId,
  } = useBankIDLogin(isQRCodeModalVisible, onError);

  const { messages } = useIntl();
  useEffect(() => {
    const checkBankIdStatus = () => {
      if (
        !bankIdFeedback.status ||
        [PENDING_STATUS, FAILED_STATUS].includes(bankIdFeedback.status)
      ) {
        return;
      }

      if (!isValidSSN(ssn, bankIdFeedback.personalNumber)) {
        setIsQRCodeModalVisible(false);
        setError(messages.bankid_ssn_mismatch);

        return;
      }

      onSuccess();
    };

    checkBankIdStatus();

    const handleVisibilityChange = () => {
      checkBankIdStatus();
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [bankIdFeedback.status, bankIdFeedback.personalNumber, onSuccess, ssn, messages.bankid_ssn_mismatch]);

  const handleUseBankIdApp = () => {
    setLoading(true);
    onUseBankIdApp();
  };

  const handleUseQRCode = () => {
    onUseQrCode();
    setIsQRCodeModalVisible(true);
  };

  const handleCloseModal = () => {
    onCancelBankId();
  };

  const handleTryAgain = () => {
    onCancelBankId();
    onUseQrCode();
  };

  if (loading) {
    return <ProgressSpinner />;
  }

  return (
    <Div>
      {error && <Error>{error}</Error>}
      {isMobile && (
        <PrimaryButton
          mt={15}
          mb={15}
          width={[1, 1, "fit-content"]}
          justifyContent="center"
          minHeight="40px"
          px={30}
          onClick={handleUseBankIdApp}
        >
          {messages.label_use_bankID_app}
        </PrimaryButton>
      )}
      <PrimaryButton
        width={[1, 1, "fit-content"]}
        minHeight="40px"
        onClick={handleUseQRCode}
        type="button"
      >
        {messages.open_bank_id_label}
      </PrimaryButton>

      {isQRCodeModalVisible && qrCodeData && (
        <BankIDModal
          hideModal={handleCloseModal}
          qrCodeData={qrCodeData}
          bankIDFeedback={bankIdFeedback}
          onTryAgain={handleTryAgain}
        />
      )}
    </Div>
  );
};

BankIDLogin.propTypes = {
  ssn: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default BankIDLogin;
