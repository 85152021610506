import queryString from "query-string";

import Axios from "@app/api/axios";

import { CRIMES } from "@utils/constant";

const getCrimes = (query = "") => {
  const url = queryString.stringifyUrl({
    url: CRIMES,
    query: queryString.parse(query),
  });

  return Axios.get(url).then((response) => response.data);
};

export default getCrimes;
