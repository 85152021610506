import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { deleteUserProfile } from "@app/services/users/deleteUserProfileService";
import getRolesByTypeService from "@app/services/users/getRolesByTypeService";
import resendInvitationService from "@app/services/users/resendInvitationService";

import Breadcrumb from "@components/Breadcrumb";
import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import PromptDialog from "@components/PromptDialog";
import { Text } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";

import UserContainer from "@layout/UserContainer";

import DeleteUser from "@pages/admin/users/Delete";

import { USERS, CUSTOMER_USER } from "@utils/constant";
import { USER_TYPE } from "@utils/enum";
import { CustomerPermissions } from "@src/enum/Permissions";
import { getUserRoleTranslationKey, fetchUserStatus } from "@utils/utils";

import UserDialog from "../admin/users/components/UserDialog";

const searchableColumns = ["id", "firstname", "lastname", "email"];

const SubUsers = () => {
  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();
  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [roleFilters, setRoleFilters] = useState([]);
  const [showResendInvitationDialog, setShowResendInvitationDialog] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { customer: { id: customerId = "" } = {} } = userInfo || {};

  const hasWriteUsersPermission = hasAllPermissions([
    CustomerPermissions.CustomerWriteUsers,
  ]);

  const shouldHideResendButton = ({ email_verified_at }) => !!email_verified_at;

  const handleAddUser = () => {
    if (showInviteDialog) {
      setReloadList(!reloadList);
    }

    setShowInviteDialog(!showInviteDialog);
  };

  const handleDeleteModal = (data = {}) => {
    setSelectedData(data);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteUser = async () => {
    const { id: selectedCustomerId = "" } = selectedData;

    try {
      await deleteUserProfile({ id: selectedCustomerId });
      if (showDeleteModal) {
        setReloadList(!reloadList);
      }
      handleDeleteModal();
    } catch (e) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleResendInvitation = async () => {
    const { id = "" } = selectedData;

    try {
      await resendInvitationService(id);

      handleResendInvitationModal();
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleEditUserModal = (data = {}) => {
    setSelectedData(data);

    if (showEditUserModal) {
      setReloadList(!reloadList);
    }

    setShowEditUserModal(!showEditUserModal);
  };

  const handleResendInvitationModal = (data = {}) => {
    setSelectedData(data);

    if (showResendInvitationDialog) {
      setReloadList(!reloadList);
    }

    setShowResendInvitationDialog(!showResendInvitationDialog);
  };

  useEffect(() => {
    const loadRulesData = async () => {
      const response = await getRolesByTypeService(CUSTOMER_USER);
      const { data: rules = [] } = response;
      const formattedData = rules.map((o) => ({
        name: messages[getUserRoleTranslationKey(o.name)],
        code: o?.id,
      }));
      setRoleFilters(formattedData);
    };

    loadRulesData();
  }, [messages]);

  const config = {
    header: {
      actions: [
        {
          id: "new-user",
          type: "button",
          label: messages.label_new_user,
          width: "auto",
          icon: "plus",
          variant: "header",
          onClick: handleAddUser,
          isHidden: !hasWriteUsersPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.menu_roles,
        id: "filter[roles.id]",
        type: "multiselect",
        options: [
          {
            name: "filter[roles.id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[roles.id]",
            options: roleFilters,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_customer_users_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `users.csv`,
    api: {
      resource: `/${USERS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["roles", "roles.permissions", "permissions"]],
        ["filter[customer.id]", customerId],
      ],
    },
    columns: [
      {
        title: "label_user_name",
        db_field: "name",
        type: "text",
        sortable: true,
        sortField: "firstname",
      },
      {
        title: "label_email",
        db_field: "email",
        type: "text",
        sortable: true,
      },
      {
        title: "role_label",
        db_field: "roles",
        type: "text",
        sortable: true,
        sortField: "roles.name",
        formatter: (data) => {
          const [values] = data;
          return values?.name
            ? messages[getUserRoleTranslationKey(values?.name)]
            : "-";
        },
      },
      {
        title: "label_status",
        db_field: "email_verified_at",
        type: "text",
        formatter: fetchUserStatus,
        sortable: true,
      },
      {
        title: "last_visit_label",
        db_field: "last_login_at",
        type: "dateTime",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        isHidden: !hasWriteUsersPermission,
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "button",
            onClick: handleEditUserModal,
          },
          {
            label: messages.label_resend_verification,
            icon: "icon-pen",
            type: "button",
            onClick: handleResendInvitationModal,
            shouldHideAction: shouldHideResendButton,
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            onClick: handleDeleteModal,
            type: "button",
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.user,
  };

  const handleCloseResendInvitationModal = () => {
    setShowResendInvitationDialog(false);
    setSelectedData({});
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.title_users },
  ];

  return (
    <UserContainer config={headerConfig}  wide>
      <Breadcrumb p={0} items={breadCrumbItems} />
      {roleFilters.length > 0 && (
        <DataTable config={config} resetData={reloadList} />
      )}
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      {showInviteDialog && (
        <UserDialog
          title={messages.label_invite_sub_user}
          userType={USER_TYPE.CUSTOMER}
          customerId={customerId}
          handleClose={handleAddUser}
        />
      )}

      {showEditUserModal && (
        <UserDialog
          title={messages.label_edit_user}
          userType={USER_TYPE.CUSTOMER}
          userDetails={selectedData}
          handleClose={handleEditUserModal}
        />
      )}

      {showResendInvitationDialog && (
        <PromptDialog
          title={messages.title_user_not_confirmed_invitation}
          message={`${messages.message_invitation_sent_to} ${selectedData?.email}`}
          onConfirm={handleResendInvitation}
          onCancel={handleCloseResendInvitationModal}
        />
      )}

      {showDeleteModal && (
        <DeleteUser
          onDelete={handleDeleteUser}
          onCancel={handleDeleteModal}
          subUser
        >
          <Div justifyContent="center" textAlign="center" display="flex">
            <Text>
              {`${messages.label_delete_user_confirmation_first} ${selectedData.name} ${messages.label_delete_user_confirmation_second}`}
            </Text>
          </Div>
        </DeleteUser>
      )}
    </UserContainer>
  );
};


export default SubUsers;
