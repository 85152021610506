import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import customerDetailsService from "@app/services/customers/customerDetailsService";
import terminateSubscription from "@app/services/subscription/terminateSubscription";
import updateSubscription from "@app/services/subscription/updateSubscription";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import SubscriptionUpdateValidation from "@pages/auth/schema/SpecialSubscriptionValidation";

import { AdminPermissions } from "@src/enum/Permissions";

import { loadActiveSubscriptionData, loadServicesData } from "@utils/common";
import { INPUT, SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";

import { SubscriptionActivityLog } from "./components";
import { buildPayload, getCustomerConfig, getPackageConfig } from "./utils";

const { AVAILABLE_ANALYSIS, TOTAL_ANALYSIS, PRICE } = INPUT.NAME;

const SelectedSubscriptionDetails = ({
  customerDetails = {},
  customerId: customer_id = "",
  subscriptionId,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();
  const { email = "" } = useSelector(state => state.authReducer.userInfo) ?? {};

  const [subscriptionData, setSubscriptionData] = useState({});
  const [activityLogsData, setActivityLogsData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState(customerDetails);

  const { id: customerId = "" } = customerData;
  const hasWriteSubscriptionPermission = hasAllPermissions([
    AdminPermissions.AdminWriteSubscription,
  ]);

  if (subscriptionData?.name === SPECIAL_PACKAGE) {
    SubscriptionUpdateValidation.concat(
      SubscriptionUpdateValidation.pick([AVAILABLE_ANALYSIS])
    )
      .concat(SubscriptionUpdateValidation.pick([TOTAL_ANALYSIS]))
      .concat(SubscriptionUpdateValidation.pick([PRICE]));
  }

  const fetchDetails = useCallback(async () => {
    try {
      setIsLoading(true);

      const [subscriptionResponse = {}, packagesListResponse = []] =
        await Promise.all([
          loadActiveSubscriptionData(
            {
              customer: {
                id: customerId,
              },
            },
            `&filter[id]=${subscriptionId}&include=auditLogs,customer`
          ),
          loadServicesData(customerId),
        ]);

      const { audit_logs: activityLogs = [] } = subscriptionResponse ?? {};
      const { name: activeSubscriptionName } =
        packagesListResponse?.find(
          ({ id }) => id === subscriptionResponse?.id
        ) ?? {};

      setSubscriptionData({
        ...subscriptionResponse,
        name: activeSubscriptionName,
      });
      setActivityLogsData(activityLogs);
      setPackagesData(packagesListResponse);
    } catch {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [
    customerId,
    messages.exception_error_message,
    showErrorToast,
    subscriptionId,
  ]);

  useEffect(() => {
    if (customerId) {
      fetchDetails();
    }
  }, [fetchDetails, customerId]);

  useEffect(() => {
    const fetchCustomerData = async () => {
      if (!customerId && customer_id) {
        const customerResponse = await customerDetailsService(customer_id);
        const { data: { data: customerData = {} } = {} } = customerResponse;
        setCustomerData(customerData);
      }
    };

    fetchCustomerData();
  }, [customerId, customer_id]);

  const { details } = getCustomerConfig(customerData);
  const { packages, settings } = getPackageConfig(
    subscriptionData,
    packagesData
  );

  const updateSubscriptionPackage = async payload => {
    try {
      setIsLoading(true);

      await updateSubscription(payload, subscriptionData.id);

      fetchDetails();

      showSuccessToast(messages.subscription_updated_success_message);
    } catch (err) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSubscriptionPackage = async values => {
    const updatedSubscriptionData = { ...subscriptionData, ...values };
    const subscriptionPayload = buildPayload(
      updatedSubscriptionData,
      customerId,
      email
    );

    await updateSubscriptionPackage(subscriptionPayload);
  };

  const handleUpdateSettings = async values => {
    try {
      setIsLoading(true);

      const shouldTerminateSubscription =
        values?.status === SUBSCRIPTION_STATUS.TERMINATED &&
        subscriptionData?.status !== SUBSCRIPTION_STATUS.TERMINATED;

      if (shouldTerminateSubscription) {
        await terminateSubscription(
          subscriptionData.id,
          subscriptionData.status
        );

        showSuccessToast(messages.subscription_updated_success_message);

        fetchDetails();
      } else {
        const updatedSubscriptionData = { ...subscriptionData, ...values };
        const subscriptionPayload = buildPayload(
          updatedSubscriptionData,
          customerId,
          email
        );

        await updateSubscriptionPackage(subscriptionPayload);
      }
    } catch (e) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <ProgressSpinner />
  ) : (
    <Div
      maxWidth={["100%", "100%", "100%", "1110px"]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      <EditableFieldsCard
        title={messages.title_customer}
        config={details}
        data={customerData}
        isEditPermission={false}
      />

      <EditableFieldsCard
        title={messages.package_label}
        config={packages}
        data={subscriptionData}
        onSubmit={handleUpdateSubscriptionPackage}
        isEditPermission={hasWriteSubscriptionPermission}
      />

      <EditableFieldsCard
        title={messages.Settings}
        config={settings}
        data={subscriptionData}
        onSubmit={handleUpdateSettings}
        isEditPermission={hasWriteSubscriptionPermission}
      />

      <SubscriptionActivityLog activityLogData={activityLogsData} />
    </Div>
  );
};

SelectedSubscriptionDetails.propTypes = {
  customerDetails: PropTypes.object,
  customerId: PropTypes.string,
  subscriptionId: PropTypes.string,
};

export default SelectedSubscriptionDetails;
