import React from "react";
import { FormattedMessage } from "react-intl";

const DeliveryFrequencyOptions = [
  {
    label: <FormattedMessage id="admin_customer_details_delivery_frequency_weekly" />,
    value: "weekly",
  },
  {
    label: <FormattedMessage id="admin_customer_details_delivery_frequency_every_other_week" />,
    value: "every_other_week",
  },
  {
    label: <FormattedMessage id="admin_customer_details_delivery_frequency_when_everything_is_done" />,
    value: "when_everything_is_done",
  },
  {
    label: <FormattedMessage id="admin_customer_details_delivery_frequency_other" />,
    value: "other",
  },
];

export default DeliveryFrequencyOptions;