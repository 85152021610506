import Axios from "@app/api/axios";
import { ORDERS_API } from "@utils/constant";

const ordersBillingAccountService = (order_id, payload) => {
  return Axios.patch(`${ORDERS_API}/${order_id}`, payload).then(
    (response) => response.data,
  );
};

export default ordersBillingAccountService;
