import { createSlice } from "@reduxjs/toolkit";

const servicesSlice = createSlice({
  name: "personInformationReducer",
  initialState: {
    personInfo: [],
    paymentMethod: "",
    creditsData: {},
    hasActiveSubscription: false,
  },
  reducers: {
    personInfo: (state, action) => {
      state.personInfo = action.payload;
    },
    isMultiple: (state, action) => {
      state.isMultiple = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setCredits: (state, action) => {
      state.creditsData = action.payload;
    },
    setHasActiveSubscription: (state, action) => {
      state.hasActiveSubscription = action.payload;
    },
  },
});

export const {
  actions: personInformationActions,
  reducer: personInformationReducer,
} = servicesSlice;
