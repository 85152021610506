import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonLinkIcon, PrimaryButton } from "@components/Button";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon, { SmallEllipseIcon } from "@components/Icon";
import { Text, TextMediumWeight, TextSemiBoldWeight } from "@components/Text";

import { downloadDataInTxt } from "@utils/utils";

const CredentialsDialog = ({ credentialsData, onClose }) => {
  const { messages } = useIntl();

  const {
    api_access_client_id: client_id = "",
    api_access_client_secret: client_secret = "",
  } = credentialsData;

  const [isCredentialsSaved, setIsCredentialsSaved] = useState(false);

  const handleCheckboxChange = () => {
    setIsCredentialsSaved(!isCredentialsSaved);
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const handleDownloadAsTXT = () => {
    downloadDataInTxt(messages.label_credentials, {
      client_id,
      client_secret,
    });
  };

  return (
    <Dialog
      hideCloseIcon
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, "636px"]}
      m={[3, 3, 3, "auto"]}
    >
      <Div display="flex" flexDirection="column" alignItems="center">
        <H3>{messages.header_api_keys_created}</H3>

        <Text mt={3}>{messages.message_please_save_api_keys}</Text>

        <Div
          mt={3}
          width={1}
          px={12}
          py={16}
          display="flex"
          justifyContent="space-between"
          backgroundColor="var(--turquoise-light)"
        >
          <TextSemiBoldWeight>{messages.header_client_id}</TextSemiBoldWeight>
          <TextSemiBoldWeight>{client_id}</TextSemiBoldWeight>
        </Div>

        <Div
          mt={1}
          width={1}
          px={12}
          py={16}
          display="flex"
          justifyContent="space-between"
          backgroundColor="var(--turquoise-light)"
        >
          <TextSemiBoldWeight>
            {messages.header_client_secret}
          </TextSemiBoldWeight>
          <TextSemiBoldWeight>{client_secret}</TextSemiBoldWeight>
        </Div>

        <ButtonLinkIcon
          my={3}
          semibold
          label={
            <TextMediumWeight>
              {messages.label_download_as_txt}
            </TextMediumWeight>
          }
          iconPos="left"
          icon={
            <Icon
              name="download-assignment"
              fontSize="var(--fs-icon-m)"
              color="var(--turquoise)"
              mr={1}
            />
          }
          onClick={handleDownloadAsTXT}
        />

        <Div
          display="flex"
          border="1px dashed var(--turquoise)"
          px={3}
          py={2}
          gridGap={2}
        >
          <SmallEllipseIcon>
            <Icon
              name="sign"
              fontSize="var(--fs-text-small)"
              color="var(--blue-dark)"
            />
          </SmallEllipseIcon>
          <TextMediumWeight>
            {messages.message_password_will_disappear}
          </TextMediumWeight>
        </Div>

        <Div mt={3} display="flex">
          <Checkbox
            mr={2}
            onChange={handleCheckboxChange}
            checked={isCredentialsSaved}
          />
          <TextMediumWeight>{messages.label_i_saved_keys}</TextMediumWeight>
        </Div>

        <PrimaryButton
          disabled={!isCredentialsSaved}
          mt={3}
          width="150px"
          label={messages.label_close}
          onClick={handleCloseDialog}
        />
      </Div>
    </Dialog>
  );
};

CredentialsDialog.propTypes = {
  credentialsData: PropTypes.object,
  onClose: PropTypes.func,
};

export default CredentialsDialog;
