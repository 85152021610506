import React from "react";
import { useIntl } from "react-intl";
import Header from "../subscription/Header";
import Proptypes from "prop-types";
import CardV2 from "@components/CardV2";
import Div from "@components/Div";
import { TextUpperCaseSemiBold, TextMediumWeight } from "@components/Text";
import { H2 } from "@components/Heading";
import { SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";
import { calculateVat } from "@utils/utils";

const SelectedPackageSection = ({
  services = {},
  activePackages = "",
  subscriptionStatus = {},
}) => {
  const { messages } = useIntl();
  const { status = "" } = activePackages;

  const totalPriceWithoutVat =
    status && status != subscriptionStatus.TERMINATED
      ? services?.price - activePackages?.price
      : services?.price;

  const totalPriceWithVat =
    totalPriceWithoutVat + calculateVat(totalPriceWithoutVat);

  return (
    <>
      <CardV2
        header={
          <Header
            credits={services.credits}
            activePackage={status === subscriptionStatus.ACTIVE}
            currentSubscription={activePackages}
          />
        }
      >
        <Div display="flex" justifyContent="space-between" alignItems="center">
          <TextMediumWeight>{messages.package_label}</TextMediumWeight>
          <TextMediumWeight>
            {messages.search_label_price_excluded}
          </TextMediumWeight>
        </Div>
        <Div
          py={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Div>
            <TextUpperCaseSemiBold>{services.name}</TextUpperCaseSemiBold>
            <SmallEllipseIcon
              display="flex"
              justifyContent="center"
              ml={2}
              name="info"
              className="serviceName"
            >
              <Tooltip content={services.description} target=".serviceName" />
            </SmallEllipseIcon>
          </Div>
          <TextUpperCaseSemiBold>
            {`${Number(services.price)?.toLocaleString("sv-SE")} kr`}
          </TextUpperCaseSemiBold>
        </Div>
      </CardV2>
      <CardV2 mt={3}>
        <Div display="flex" justifyContent="space-between">
          <TextMediumWeight>{messages.label_moms}</TextMediumWeight>
          <TextMediumWeight>{`${Number(
            calculateVat(totalPriceWithoutVat),
          )?.toLocaleString("sv-SE")} kr`}</TextMediumWeight>
        </Div>
        <Div display="flex" justifyContent="space-between" py={2}>
          <TextMediumWeight>
            {messages.search_label_price_excluded}
          </TextMediumWeight>
          <TextMediumWeight>{`${Number(totalPriceWithoutVat)?.toLocaleString(
            "sv-SE",
          )} kr`}</TextMediumWeight>
        </Div>
        <Div display="flex" justifyContent="space-between" alignItems="end">
          <H2>{messages.text_total_price}:</H2>
          <H2>{`${Number(totalPriceWithVat)?.toLocaleString("sv-SE")} kr`}</H2>
        </Div>
      </CardV2>
    </>
  );
};
SelectedPackageSection.propTypes = {
  activePackages: Proptypes.object,
  services: Proptypes.object,
  subscriptionStatus: Proptypes.object,
};
export default SelectedPackageSection;
