import React from "react";
import PropTypes from "prop-types";

import Chip from "@components/Chip";
import Div from "@components/Div";

const Attachments = ({ attachments, }) => {

  const handleOpenAttachment = (url) => {
    if (!url) {
      return;
    }

    return () => {
      window.open(url);
    };
  };
  
  return (
    <Div
      display={"flex"}
      flexDirection={["column", "column", "row", "row"]}
      gridGap={3}
    >
      {attachments?.map(({ id, file_name: fileName, url = "" }) => (
        <Chip
          width="fit-content"
          key={id}
          label={fileName}
          icon={"icon-pin"}
          removeIcon={"icon-download-assignment"}
          onClick={handleOpenAttachment(url)}
        />
      ))}
    </Div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
};

export default Attachments;
