import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { methodProps } from "./config";
import { TextLarge } from "@components/Text";

const Method = ({ isPrintable = false }) => {
  const { messages } = useIntl();
  const { description } = methodProps;

  const constructDescription = arr =>
    arr.map((value, index) => (
      <TextLarge
        mb={3}
        key={index}
        display="block"
        lineHeight={isPrintable && "150% !important"}
        fontSize={isPrintable && "var(--fs-text-s) !important"}
      >
        {messages[value]}
      </TextLarge>
    ));

  return <Div>{constructDescription(description)}</Div>;
};

Method.propTypes = {
  isPrintable: PropTypes.bool,
};

export default Method;
