import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const CancelDialog = ({ title, message, onConfirm, onHide, restProps }) => {
  const { messages } = useIntl();

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "450px", "450px"]}
      m={[3, 3, "auto", "auto"]}
      {...restProps}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={4}
      >
        <H3>{title}</H3>
        <Text textAlign="center">{message}</Text>

        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={[3, 3, 4, 4]}
          width={1}
          justifyContent="center"
        >
          <PrimaryButton
            width={[1, 1, "150px", "150px"]}
            label={messages.label_leave_page}
            onClick={onConfirm}
          />
          <PrimaryButtonOutlined
            width={[1, 1, "150px", "150px"]}
            label={messages.label_stay}
            onClick={onHide}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

CancelDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  restProps: PropTypes.object,
};

export default CancelDialog;
