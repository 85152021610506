import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import { useIntl } from "react-intl";

import { ButtonLink, PrimaryButton } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextSemiBoldWeight } from "@components/Text";

import {
  BANKID_MESSAGE_TERM,
  COMPLETE_STATUS,
  FAILED_STATUS,
  PENDING_STATUS,
} from "@utils/constant";

const BankIDModal = (props) => {
  const { messages } = useIntl();

  const { status, messageTerm } = props.bankIDFeedback;

  const BankIDSteps = useMemo(
    () => [
      messages.message_bankID_step_1,
      messages.message_bankID_step_2,
      messages.message_bankID_step_3,
    ],
    [messages],
  );

  const parseFeedback = useCallback(() => {
    if ((!status && !messageTerm) || status === COMPLETE_STATUS) {
      return "";
    }

    if (status === PENDING_STATUS) {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.OUTSTANDING_TRANSACTION:
        case BANKID_MESSAGE_TERM.NO_CLIENT:
          return messages.message_bankID_start_your_bankID;

        case BANKID_MESSAGE_TERM.USER_SIGN:
          return messages.message_bankID_enter_security_code;

        case BANKID_MESSAGE_TERM.STARTED:
          return messages.message_bankID_searching;

        case BANKID_MESSAGE_TERM.USER_MRTD:
          return messages.message_bankID_process_travel_document;

        default:
          return messages.message_bankID_identification_in_progress;
      }
    } else if (status === FAILED_STATUS) {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.USER_CANCEL:
          return messages.message_bankID_action_cancelled;

        case BANKID_MESSAGE_TERM.EXPIRED_TRANSACTION:
          return messages.message_bankID_not_responding;

        case BANKID_MESSAGE_TERM.CERTIFICATE_ERR:
          return messages.message_bankID_blocked;

        case BANKID_MESSAGE_TERM.START_FAILED:
          return messages.message_bankID_not_found;

        default:
          return messages.message_bankID_unknown_error;
      }
    } else {
      switch (messageTerm) {
        case BANKID_MESSAGE_TERM.CANCELLED:
          return messages.message_bankID_cancelled;

        case BANKID_MESSAGE_TERM.ALREADY_IN_PROGRESS:
          return messages.message_bankID_already_started;

        default:
          return messages.message_bankID_internal_error;
      }
    }
  }, [messages, status, messageTerm]);

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={props.hideModal}
      m={[3, 3, 3, 3]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={30}
      >
        {status === FAILED_STATUS &&
        messageTerm === BANKID_MESSAGE_TERM.START_FAILED ? (
          <>
            <TextSemiBoldWeight color="var(--dark-gray)">
              {messages.label_bankID_login_failed}
            </TextSemiBoldWeight>
            {status && messageTerm && <Text>{parseFeedback()}</Text>}
          </>
        ) : (
          <>
            <Icon
              name="bankID"
              color="var(--blue-bank-id-logo)"
              fontSize="var(--fs-icon-xl)"
            />
            <TextSemiBoldWeight>
              {messages.label_log_in_with_bankID}
            </TextSemiBoldWeight>
            {props.qrCodeData && <QRCode size={148} value={props.qrCodeData} />}

            {status && messageTerm && <Text>{parseFeedback()}</Text>}

            <Div
              width="100%"
              display="flex"
              flexDirection="column"
              bg="var(--blue-lightest)"
              px={30}
              py={20}
            >
              <TextSemiBoldWeight
                mb={10}
              >{`${messages.label_how_to_use}:`}</TextSemiBoldWeight>
              <ul>
                {BankIDSteps.map((text, index) => (
                  <li key={text}>
                    <TextSemiBoldWeight>{`${(
                      index + 1
                    ).toString()}. `}</TextSemiBoldWeight>
                    <Text>{text}</Text>
                  </li>
                ))}
              </ul>
            </Div>
          </>
        )}
      </Div>
      <Div display="flex" alignItems="center" justifyContent="center" mt={20}>
        {status === FAILED_STATUS &&
          messageTerm === BANKID_MESSAGE_TERM.START_FAILED && (
            <PrimaryButton justifyContent="center" onClick={props.onTryAgain}>
              {messages.label_try_again}
            </PrimaryButton>
          )}
        <ButtonLink onClick={props.hideModal}>
          {messages.label_cancel}
        </ButtonLink>
      </Div>
    </Dialog>
  );
};

BankIDModal.propTypes = {
  hideModal: PropTypes.func,
  qrCodeData: PropTypes.string,
  bankIDFeedback: PropTypes.object,
  onTryAgain: PropTypes.func,
};

export default BankIDModal;
