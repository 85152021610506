import { useDispatch } from "react-redux";

import { personInformationActions } from "@app/reducers/personInformationReducer";

import { useOrder } from "@contexts/OrderContext";

import {
  CREDITS_CHECKOUT,
  KLARNA_CHECKOUT,
  PENDING_STATUS,
  INVOICE_CHECKOUT,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";

const PAYMENT_METHODS = {
  INVOICE: INVOICE_CHECKOUT,
  KLARNA: KLARNA_CHECKOUT,
  CREDITS: CREDITS_CHECKOUT,
  PENDING: PENDING_STATUS,
};

const useUserPaymentInfo = () => {
  const { orderUser = {} } = useOrder();
  const dispatch = useDispatch();
  const { customer = {} } = orderUser;

  const isPrepaidUser = () => {
    const { status: subscriptionStatus } = customer;

    return subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE;
  };

  const isUserHasCredits = (itemsPurchasedByCreditsCount = 0) => {
    const { available_credits = 0, total_credits: totalCredits = 0 } = customer;
    const availableCredits = Math.max(available_credits, 0);

    dispatch(
      personInformationActions.setCredits({
        availableCredits: availableCredits,
        totalCredits: totalCredits,
      }),
    );

    return availableCredits > itemsPurchasedByCreditsCount;
  };

  const isInvoiceActive = () => {
    const { invoice = 0 } = customer;
    return invoice > 0;
  };

  const getPaymentMethod = (itemsPurchasedByCreditsCount = 0) => {
    const hasCredits = isUserHasCredits(itemsPurchasedByCreditsCount);

    if (isPrepaidUser()) {
      return hasCredits ? PAYMENT_METHODS.CREDITS : PAYMENT_METHODS.PENDING;
    }

    const shouldUseInvoice = isInvoiceActive();

    return shouldUseInvoice ? INVOICE_CHECKOUT : KLARNA_CHECKOUT;
  };

  return {
    orderUser,
    getPaymentMethod,
    isUserHasCredits,
  };
};

useUserPaymentInfo.propTypes = {};

export default useUserPaymentInfo;
