// ExternalRedirectComponent.jsx

import React, { useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes

import Config from "@src/config";
import { useIntl } from "react-intl";

const ExternalRedirectComponent = ({ redirectTo = "/kontakta-oss/" }) => {
  const { messages } = useIntl();
  useEffect(() => {
    window.location.href = `${Config.WP_URL}${redirectTo}`;
  }, [redirectTo]);
  return <div>{messages.redirecting_to_wordpress_website}</div>;
};
ExternalRedirectComponent.propTypes = {
  redirectTo: PropTypes.string,
};

export default ExternalRedirectComponent;
