import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

import fetchOrderDetails from "@app/services/orders/fetchOrderDetails";

import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES } from "@utils/constant";

import OrderDetails from "../../shared/orderDetails/OrderDetails";

const AdminOrderDetails = () => {
  const { messages } = useIntl();
  const { search = "" } = useLocation();
  const { showErrorToast } = useToast();

  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { id: orderId = "" } = queryString.parse(search);

    const getOrderDetails = async () => {
      try {
        setIsLoading(true);

        const orderResponse = await fetchOrderDetails(orderId);

        setOrderData(orderResponse);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    getOrderDetails();
  }, [messages.exception_error_message, search, showErrorToast]);

  const headerConfig = {
    title: `${messages.label_order} #${orderData.order_id || " "} `,
  };

  return (
    <AdminContainer config={headerConfig} isLoading={isLoading}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <OrderDetails
          orderSummaryProps={orderData}
          redirectTo={ROUTES.ADMIN_ORDERS_LIST.URL}
          isAdmin
          onUpdateOrderSummaryProps={setOrderData}
        />
      )}
    </AdminContainer>
  );
};

export default AdminOrderDetails;
