import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { Ellipse24Icon } from "@components/Icon";

import Email from "./Email";

const EmailThread = ({ data }) => {
  return (
    <Div my={4} display="flex" width={1} gridGap={2}>
      <Div borderLeft="2px solid var(--grey-lightest)" my={3} />
      <Div
        display="flex"
        flexDirection="column"
        gridGap={4}
        width={1}
        ml="-27px"
      >
        {data?.map((emailData) => (
          <Div
            key={emailData.id}
            display="flex"
            alignItems="flex-start"
            gridGap={2}
          >
            <Ellipse24Icon
              name={`message-${emailData.direction}`}
              color="var(--white)"
              backgroundColor="var(--blue-dark) !important"
              mt={3}
            />
            <Email {...emailData} />
          </Div>
        ))}
      </Div>
    </Div>
  );
};

EmailThread.propTypes = {
  data: PropTypes.array,
};

export default EmailThread;
