import React from "react";
import { Field } from "formik";
import Div from "@components/Div";
import { Error } from "@components/Heading";
import StyledInputText from "@components/StyledInputText";
import PropTypes from "prop-types";

const InputTextV2 = ({
  name,
  type,
  maxLength,
  labelAlignment = "center",
  disabled = false,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { errors, touched } = form;
        const errorMessage = errors[name];
        const touchedMessage = touched[name];

        return (
          <>
            <StyledInputText
              {...field}
              {...rest}
              type={type}
              maxLength={maxLength}
              disabled={disabled}
              error={errorMessage && touchedMessage}
              style={{ borderRadius: "10px" }}
            />

            {errorMessage && touchedMessage && (
              <Div pb={1} pt={3} m={labelAlignment !== "left" && "auto"}>
                <Error>{errorMessage}</Error>
              </Div>
            )}
          </>
        );
      }}
    </Field>
  );
};

InputTextV2.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  labelAlignment: PropTypes.oneOf(["left", "center"]),
  disabled: PropTypes.bool,
};

export default InputTextV2;
