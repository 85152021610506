import React from "react";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

import Email from "@pages/auth/schema/Email";

const Schema = Yup.object({
  role: Yup.string(
    <FormattedMessage
      id="validation_valid_role"
      defaultMessage="Invalid role"
    />,
  ).required(
    <FormattedMessage id="validation_enter_role" defaultMessage="Enter role" />,
  ),
  permissions: Yup.object().required(
    <FormattedMessage
      id="validation_enter_permissions"
      defaultMessage="Choose permissions"
    />,
  ),
}).concat(Email);

export default Schema;
