import React from "react";
import PropTypes from "prop-types";

import { Panel as PRPanel } from "primereact/panel";
import styled from "styled-components";
import {
  compose,
  width,
  position,
  space,
  layout,
  flexbox,
} from "styled-system";

const StyledWrapper = styled.div`
  ${compose(width, position, space, layout, flexbox)};

  .p-panel {
    background-color: var(--white);
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid var(--grey-lightest);
    border-radius: 10px;

    .p-panel-header {
      padding: 1rem 0rem 0.5rem 0rem;
      background: none;
      border: none;

      .p-panel-title {
        font-weight: var(--semibold-weight);
        font-size: var(--fs-h4);
        line-height: var(--lh-h3);
      }

      .p-panel-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }

    .p-toggleable-content .p-panel-content {
      border: none;
      border-top: 1px solid var(--grey-lightest);
      padding: 1rem 0rem 1rem 0rem;
    }

    .p-panel-header-icon:enabled:hover {
      color: none;
      border-color: transparent;
      background: none;
    }
  }
`;

const Panel = ({ children, ...rest }) => {
  return (
    <StyledWrapper width={1}>
      <PRPanel {...rest}>{children}</PRPanel>
    </StyledWrapper>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
};

export default Panel;
