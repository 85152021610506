import React from "react";

import GDPRInformationBox from "@components/privacyPolicies/GDPRInformationBox";
import Div from "@components/Div";
import {Text } from "@components/Text";
import {
  default as PRIcon,
} from "@components/Icon";
import styled from "styled-components";
import { useIntl } from "react-intl";


const Icon = styled(PRIcon)`
  
  color: var(--blue-dark);
  background: var(--grey-lightest);
  padding: 6px;
  border-radius: 50%;
  
`;
const GDPRInformationMessage = () => {
    const { messages } = useIntl();
  
    return (
     
        <GDPRInformationBox
              >
                <Div
                display="flex"
              >
                <Div mt="2" mr={[3,2]}>
                <Icon name="sign"></Icon>
                </Div>
                <Text pl={[0, "10px"]} >
                {messages.privacy_policies_text} <a href="https://stage.bakgrundsanalys.se/personuppgiftspolicy/">{messages.privacy_policies_link_text}</a>
                </Text>
              </Div>
              </GDPRInformationBox>
             
        
    );
  };
  
  export default GDPRInformationMessage;