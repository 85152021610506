import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import { BreadCrumb as PRBreadCrumb } from "primereact/breadcrumb";
import { compose, space, layout, flexbox, position } from "styled-system";

const StyledBreadcrumb = styled(PRBreadCrumb)`
  ${compose(space, flexbox, layout, position)};
  border: none;
  .p-menuitem-separator {
    &:first-child {
      .p-breadcrumb-chevron {
        display: none;
      }
    }
    .p-breadcrumb-chevron {
      margin: 0 5px;
      height: 12px;
      width: 12px;

      path {
        color: var(--grey) !important;
      }
    }
  }
  .p-menuitem-link {
    &:focus {
      box-shadow: none !important;
    }
  }
  .p-menuitem {
    &:nth-child(2) {
      span {
        color: var(--grey) !important;
      }
    }
    span {
      color: var(--grey-dark) !important;
    }
  }
  .p-menuitem-text {
    font-weight: var(--medium-weight);
    font-size: var(--fs-text-small);
    line-height: var(--lh-text-m);
  }
  .p-menuitem-icon {
    margin-right: 6px;
    transform: rotate(180deg);
    color: var(--turquoise) !important;
  }
  .p-menuitem-link {
    &:focus {
      box-shadow: none !important;
    }
    box-shadow: none !important;
  }
`;

const Breadcrumb = (props) => {
  const { items, home } = props;

  return <StyledBreadcrumb model={items} home={home} {...props} />;
};

Breadcrumb.propTypes = {
  items: Proptypes.array,
  home: Proptypes.object,
};

export default Breadcrumb;
