import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { BigRoundedRectIcon } from "@components/Icon";

import "@sass/full-size-dialog.scss";

const customBreakpoints = ["480px", "768px", "1174px", "1200px"];

const FullSizeDialog = ({ title, fullWidth, onClose, children, header }) => {
  const history = useHistory();
  const maxWidth = useMemo(() => (fullWidth ? "100%" : "1174px"), [fullWidth]);

  const handleClose = () => {
    if (!onClose) {
      history.goBack();
      return;
    }

    onClose();
  };

  const renderDefaultHeaderContent = (
    <Div
      m={0}
      width="100%"
      maxWidth={maxWidth}
      mr="auto"
      ml="auto"
      bg="var(--white)"
    >
      <Div flex={1} display="flex" alignItems="center">
        <Div flex={2} display="flex" alignItems="center">
          <H2 color="var(--blue-dark) !important" mr={5}>
            {title}
          </H2>
        </Div>

        <Div ml={5} display="flex" justifyContent="flex-end">
          <BigRoundedRectIcon
            name="close"
            cursor="pointer"
            onClick={handleClose}
          />
        </Div>
      </Div>
    </Div>
  );

  const renderHeader = (
    <Div
      theme={{
        breakpoints: customBreakpoints,
      }}
      m={0}
      py={3}
      px={[3, 3, 4, 4]}
      width="100%"
      maxWidth={maxWidth}
      mr="auto"
      ml="auto"
      bg="var(--white)"
    >
      {header || renderDefaultHeaderContent}
    </Div>
  );

  return (
    <Dialog
      hideCloseButton
      closeOnEscape={false}
      draggable={false}
      visible="displayBasic"
      width="100%"
      height="100%"
      maxHeight="100%"
      header={renderHeader}
      className="full-size-dialog"
      pt={{
        mask: {
          className: "full-size-dialog-mask",
        },
      }}
    >
      <Div display="flex" flexDirection="column" width="100%">
        <Div
          flex={1}
          width="100%"
          maxWidth={maxWidth}
          p={
            fullWidth
              ? "0 !important"
              : ["1rem !important;", "1rem !important;", "2rem !important;"]
          }
          mr="auto"
          ml="auto"
        >
          {children}
        </Div>
      </Div>
    </Dialog>
  );
};

FullSizeDialog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default FullSizeDialog;
