import Axios from "@app/api/axios";

import { PROMO_CODES } from "@utils/constant";

const patchPromoCode = (promoCodeId, payload) => {
  return Axios.patch(`${PROMO_CODES}/${promoCodeId}`, payload).then(
    (response) => response.data,
  );
};

export default patchPromoCode;
