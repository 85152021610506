import React, { useState,useRef  } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";
import UserBillingAccountDialog from "./UserBillingAccountDialog";

const BillingAccounts = ({ customer_id }) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { hasAllPermissions } = useAuthorization();
  const hasWriteAccessToOrg = hasAllPermissions([
    CustomerPermissions.CustomerWriteCompanyInformation,
  ]);

  const dataTableRef = useRef(null);
  const [showAddUserBillingAccountDialog, setShowAddUserBillingAccountDialog] =
    useState(false);
  const reload = () => {
    if (dataTableRef.current) {
      dataTableRef.current.reload();
    }
  };

  const handleAddUserNewBillingAccount = () => {
    setShowAddUserBillingAccountDialog(!showAddUserBillingAccountDialog);
    reload();
  };


  const handleRedirectToUpdateBilling = (values) => {
    const { id } = values;
    history.push(`/mittkonto/foretagsinformation/faktura/${id}`);
  };

  const handleDeactivateAccount = async (values) => {
    const { id } = values;
    try {
      setLoading(true);
      await patchAccountStatus({ billing_account_id: id, active: 0 });
    } catch (_error) {
      // Show error message when failed to deactivate account
    } finally {
      setLoading(false);
    }
  };

  const searchableColumns = [
    "name",
    "email_invoice",
    "your_reference",
    "invoice_remark",
  ];

  const dataTableConfig = {
    header: {
      title: "header_billing_accounts",
      actions: [
        {
          id: "create-billing",
          type: "button",
          label: messages.button_add_new,
          onClick:handleAddUserNewBillingAccount,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteAccessToOrg,
        },
      ],
    },
    row_selection_checkbox: false,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "billing_no_records_found",
    row_selection_actions: [],
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `billing-accounts.csv`,
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["filter[active]", 1],
        ["filter[customer_id]", customer_id],
        [
          "fields",
          [
            "id",
            "name",
            "email_invoice",
            "your_reference",
            "invoice_remark",
            "active",
          ],
        ],
      ],
    },
    columns: [
      {
        title: "header_company_name",
        db_field: "name",
        type: "text",
      },
      {
        title: "header_billing_email",
        db_field: "email_invoice",
        type: "text",
      },
      {
        title: "header_reference_person",
        db_field: "your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        isHidden: !hasWriteAccessToOrg,
        actions: [
          {
            label: messages.view_details,
            icon: "icon-showeye",
            onClick: handleRedirectToUpdateBilling,
          },
          {
            label: messages.deactivate_acc,
            icon: "icon-rubber",
            onClick: handleDeactivateAccount,
          },
        ],
      },
    ],
  };



  return (
    <>
      <DataTable config={dataTableConfig} resetData={!loading} />
      {showAddUserBillingAccountDialog && (
        <UserBillingAccountDialog handleClose={handleAddUserNewBillingAccount} />
      )}
    </>
  );

};

BillingAccounts.propTypes = {
  customer_id: PropTypes.id,
  is_admin: PropTypes.bool,
};

export default BillingAccounts;
