import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  space,
  color,
  typography,
  border,
  layout,
  flexbox,
  position,
} from "styled-system";

const StyledIcon = styled.i.attrs((props) => ({
  className: props.rounded
    ? `rounded icon-${props.name}`
    : `icon-${props.name}`,
}))`
  ${compose(space, color, typography, border, layout, flexbox, position)};
  cursor: ${({ cursor }) => cursor || "inherit"};
`;

export const InvertedIcon = styled(StyledIcon).attrs({
  className: "invert-rounded",
})``;

export const BundleIcon = styled(StyledIcon)`
  ${compose(space, color, typography)};
  background-color: var(--yellow);
  color: #fff;
  padding: 2px 2px 2px 3px;
  border-radius: 25%;
  min-height: 20px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 17px;
  color: var(--white) !important;
  display: inline-block;
`;
export const Ellipse24Icon = styled(StyledIcon)`
  ${compose(space, typography, color, border)};
  background-color: var(--turquoise);
  padding: 10px;
  border-radius: 50%;
  float: left;
`;
export const Ellipse24IconDark = styled(Ellipse24Icon)`
  ${compose(space, typography, color, border)};
  background-color: var(--blue-dark);
`;

export const SmallEllipseIcon = styled(StyledIcon)`
  ${compose(space, color, typography)};
  background-color: #e9f1f3;
  padding: 2px;
  border-radius: 50%;
  min-height: 20px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 17px;
  color: var(--grey) !important;
  display: inline-block;
`;

export const RoundedRectIcon = styled.div`
  ${compose(space, color, typography)};
  background-color: var(--turquoise-light);
  border-radius: 5px;
  padding: 4px;
  padding-bottom: 0px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;

export const BigRoundedRectIcon = styled(StyledIcon)`
  ${compose(space, color, typography)};
  background-color: var(--blue-lightest);
  border-radius: 5px;
  padding: 4px;

  font-size: var(--fs-icon-m);
  text-align: center;
  line-height: var(--lh-h5);
  color: var(--grey);
`;

export const SmallEllipseIconRed = styled(StyledIcon)`
  ${compose(space, color, typography)};
  background-color: #e06a6f;
  padding: 2px;
  border-color: #e06a6f;
  border-radius: 50%;
  border: 0;
  height: 20px;
  width: 20px;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  color: #666;
`;

const Icon = (props) => <StyledIcon {...props} />;

Icon.propTypes = {
  rounded: Proptypes.bool,
  name: Proptypes.string,
};

Icon.defaultProps = {
  rounded: false,
};

export default Icon;
