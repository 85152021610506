import styled from "styled-components";

import Div from "@components/Div";

const CoResidentDiv = styled(Div)`
  a {
    color: var(--blue-dark) !important;
  }
`;

export default CoResidentDiv;
