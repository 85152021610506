import Axios from "@app/api/axios";

import { EMAIL_MESSAGES } from "@utils/constant";

const getEmailsBySourceDataId = (source_data_id = "") => {
  return Axios.get(
    `${EMAIL_MESSAGES}?filter[case_source_data_id]=${source_data_id}&include=court,account,attachments`,
  ).then((response) => response.data);
};

export default getEmailsBySourceDataId;
