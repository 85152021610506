import React from "react";
import StyledInputText from "@components/StyledInputText";
import Div from "@components/Div";
import { Text } from "@components/Text";
import { useIntl } from "react-intl";

const InputText = (prop) => {
  const { messages } = useIntl();
  const { type, onChange, value, id } = prop;

  return (
    <>
      <Div
        display={["block", "block", "block", "flex"]}
        py={1}
        borderTop={"1px solid var(--turquoise-light)"}
      >
        <Div flexBasis={"25%"} my={[11, 11, 11, 14]} mt={[30, 30, 30, 14]}>
          <Text>{messages.title_customers}</Text>
        </Div>
        <Div
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems={"center"}
          width={1}
          mb={14}
          mt={1}
        >
          <Div display="flex" width={1}>
            {
              <StyledInputText
                {...prop}
                type={type}
                onChange={onChange(type, id)}
                width={"97%"}
                borderRadius={10}
                value={value}
                id={id}
                pl={"7px !important"}
              />
            }
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default InputText;
