import React from "react";
import PropTypes from "prop-types";
import InputTextV2 from "@components/InputTextV2";
import Div from "@components/Div";
import { useFormikContext } from "formik";

const TextInput = ({ name, placeholder,maxLength }) => {
  const { values } = useFormikContext();
  return (
    <Div flex="1 1 40%">
      <InputTextV2
        width={1}
        mt={2}
        name={name}
        placeholder={placeholder}
        value={values?.[name]}
        maxLength={maxLength}
      />
    </Div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

export default TextInput;
