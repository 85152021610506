import React from "react";
import PropTypes from "prop-types";

import { default as BaseCard } from "@components/Card";
import Div from "@components/Div";
import { TextSemiBoldWeight } from "@components/Text";

const Card = ({ title, children }) => (
  <BaseCard p={3} borderRadius="20px" width={1}>
    <TextSemiBoldWeight>{title}</TextSemiBoldWeight>
    <Div my={2} width={1} height="1px" backgroundColor="var(--grey-lightest)" />
    {children}
  </BaseCard>
);

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
