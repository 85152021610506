import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import DataTable from "@components/DataTableV2/DataTable";
import Container from "@components/Container";
import { ROUTES, WATCHLIST_OBJECT_HISTORIES_API } from "@utils/constant";

const WatchlistHistoryTab = ({ watchListId }) => {
  const { messages } = useIntl();

  const searchableColumns = ["id", "name"];

  const dataTableConfig = {
    enable_csv_download: true,
    csv_filename: "watchlist_history.csv",
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_history_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/watch-lists/${watchListId}/orders`,
      method: "GET",
      search_fields: searchableColumns,
    },
    header: {
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        width: "150px",
        db_field: "id",
        type: "text",
      },
      {
        title: "watchlist_title_object",
        db_field: "object",
        type: "text",
      },

      {
        title: "watchlist_label_price",
        db_field: "price",
        type: "currency",
      },
      {
        title: "watchlist_title_sub_user",
        db_field: "sub_user",
        type: "text",
      },
      {
        type: "actions",
        width: "20px",

        actions: [
          {
            type: "link",
            href: ({ id }) =>
              `${ROUTES.WATCHLIST_OBJECTS_API.URL}/${id}/${WATCHLIST_OBJECT_HISTORIES_API}/${id}`,
          },
        ],
      },
    ],
    filters: [
      {
        title: messages.label_status,
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
          },
          {
            label: messages.deleted,
            value: 0,
          },
        ],
      },
    ],
  };

  return (
    <Container m={"0px !important"}>
      <DataTable config={dataTableConfig} />
    </Container>
  );
};

WatchlistHistoryTab.propTypes = {
  watchListId: PropTypes.number,
};

export default WatchlistHistoryTab;
