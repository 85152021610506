import React from "react";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import { H1 } from "@components/Heading";
import { TextUpperCase, TextLarge } from "@components/Text";
import UpgradePlan from "@assets/upgrade_plan.jpg";
import Logo from "@components/Logo";
import UpgradePackage from "@components/UpgradePackage";
import { useIntl } from "react-intl";
const UpgradeSubscription = () => {
  const { messages } = useIntl();
  return (
    <>
      <Div width={1}>
        <Section
          pt={["40px", "40px", "60px", "60px"]}
          pb={["30px", "30px", "80px", "80px"]}
        >
          <Container>
            <Div flexWrap="wrap" display="flex">
              <Div pr={[0, 0, 5]} mb={[4, 4, 0]} width={[1, 1, 0.5]}>
                <H1 mb={4}>{messages.upgrade_you_subscription}</H1>
                <TextLarge>{messages.upgrade_package_sub_heading}</TextLarge>
                <Div>
                  <TextUpperCase my={3} display="block">
                    {messages.how_it_works}
                  </TextUpperCase>
                  <TextLarge display="block">
                    {messages.how_it_work_info}
                  </TextLarge>
                  <TextLarge mt={3} display="block">
                    {messages.subscription_renew_info}
                  </TextLarge>
                </Div>
              </Div>
              <Div width={[1, 1, 0.5]}>
                <Logo width={1} logo={UpgradePlan} />
              </Div>
            </Div>
          </Container>
        </Section>
        <UpgradePackage />
      </Div>
    </>
  );
};
export default UpgradeSubscription;
