import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const RecurranceConfirmationModal = ({ onCloseModal, handleConfirmPopup }) => {
  const { messages } = useIntl();

  return (
    <Div
      py={0}
      display="flex"
      alignItems="center"
      flexDirection="column"
      maxWidth="450px"
    >
      <H3>{messages.watchlist_title_are_you_sure}</H3>
      <Text mt={3}>{messages.watchlist_message_recurring_deactivation}</Text>
      <Div
        mt={3}
        display="flex"
        flexDirection={["column-reverse", "row"]}
        justifyContent="center"
      >
        <PrimaryButtonOutlined
          rounded
          semibold
          label={messages.watchlist_label_leave}
          onClick={onCloseModal}
          width={[310, 150]}
          mr={[0, 4]}
        />
        <PrimaryButtonIcon
          rounded
          semibold
          width={[310, 150]}
          mb={[3, 0]}
          onClick={handleConfirmPopup}
          label={messages.watchlist_label_yes}
        />
      </Div>
    </Div>
  );
};

RecurranceConfirmationModal.propTypes = {
  onCloseModal: PropTypes.func,
  handleConfirmPopup: PropTypes.func,
};

export default RecurranceConfirmationModal;
