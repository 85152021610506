import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space, position, layout } from "styled-system";
import { Tag as PrimeTag } from "primereact/tag";
import { Button } from "primereact/button";

const StyledTag = styled(PrimeTag)`
  ${compose(space, position, layout)};
  background-color: var(--grey-light);
  color: var(--grey);
  height: 34px;
`;

const StyledButton = styled(Button)`
  background-color: var(--grey-light);
  color: var(--grey);
  font-size: 10px;
  border: 0;

  &:hover {
    background-color: var(--grey-light) !important;
    color: var(--grey) !important;
  }
`;

const Tag = ({ tag, handleRemoveTag = () => {}, showButton = true }) => (
  <>
    <StyledTag key={tag} pl={2} py={1} value={tag} mr={3} mt={2}>
      {showButton && (
        <StyledButton icon="pi pi-times" onClick={() => handleRemoveTag(tag)} />
      )}
    </StyledTag>
  </>
);

Tag.propTypes = {
  tag: PropTypes.string,
  handleRemoveTag: PropTypes.func,
  showButton: PropTypes.bool,
};

export default Tag;
