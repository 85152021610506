import { Steps as PRSteps } from "primereact/steps";
import styled from "styled-components";

const Steps = styled(PRSteps)`
  .p-steps-item:before {
    top: 0.5rem;
    margin-top: 0rem;
    border-top: 1px solid var(--grey-lightest);
  }

  .p-steps-item {
    opacity: 1;

    .p-menuitem-link:focus {
      box-shadow: none !important;
    }

    .p-steps-number {
      min-width: 1rem !important;
      height: 1rem !important;

      font-size: 8px !important;
      font-weight: var(--medium-weight);
      color: var(--grey) !important;

      border: 1px solid var(--grey-lightest);
      background-color: var(--blue-lightest) !important;
    }

    .p-steps-title {
      font-size: var(--fs-grade-text);
      font-weight: var(--medium-weight);
      color: var(--grey);
    }

    &.p-steps-current {
      .p-steps-number {
        color: var(--white) !important;

        border: none;
        background-color: var(--blue-dark) !important;
      }

      .p-steps-title {
        color: var(--blue-dark);
      }
    }
  }
`;

export default Steps;
