import * as Yup from "yup";

/**
 * Name validation
 */
const NameSchema = Yup.object().shape({
  terms_and_condition: Yup.boolean().oneOf([true], ""),
});

export default NameSchema;
