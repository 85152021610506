import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { TextLarge } from "@components/Text";
import { recommendationProps } from "./config";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import Config from "@src/config";
import { ROUTES } from "@utils/constant";

const Recommendation = ({ isPrintable = false }) => {
  const { messages } = useIntl();

  const { descriptions, contactProps } = recommendationProps;

  const constructContent = arr =>
    arr.map((value, index) => (
      <Div mb={3} key={index}>
        <TextLarge
          display="block"
          fontSize={isPrintable && "var(--fs-text-s) !important"}
          lineHeight={isPrintable && "150% !important"}
        >
          {messages[value]}
        </TextLarge>
      </Div>
    ));

  const constructContactProps = arr =>
    arr.map((value, index) => (
      <Div key={index}>
        <Div>
          {!isPrintable && (
            <LinkArrow
              direction="left"
              target="_blank"
              href={`${Config.WP_URL}${ROUTES.COUNSELLING.URL}`}
              label={messages.label_order_interview}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          )}
          <TextLarge
            display="block"
            fontSize={isPrintable && "var(--fs-text-s) !important"}
            lineHeight={isPrintable && "150% !important"}
          >
            {messages[value.description]}
          </TextLarge>
        </Div>
      </Div>
    ));

  return (
    <Div>
      <Div mb={3}>{constructContent(descriptions)}</Div>
      <Div>{constructContactProps(contactProps)}</Div>
    </Div>
  );
};

Recommendation.propTypes = {
  isPrintable: PropTypes.bool,
};

export default Recommendation;
