import Axios from "@app/api/axios";
import { CASES } from "@utils/constant";

const getCaseDetailsById = (caseId = "") => {
  return Axios.get(`${CASES}/${caseId}?include=orderItem,user,product`).then(
    (response) => response.data,
  );
};

export default getCaseDetailsById;
