import * as Yup from "yup";
import { SVLANG } from "@utils/constant";

const Schema = Yup.object().shape({
  service_type: Yup.string().required(SVLANG.error_service_type),
  analysis_type: Yup.string().when("service_type", {
    is: (serviceType) => {
      return serviceType === "analysis";
    },
    then: () => Yup.string().required(SVLANG.error_analysis_type),
    otherwise: () => Yup.string(),
  }),
  data_source: Yup.array(),
  child: Yup.array().when("service_type", {
    is: (serviceType) => {
      return serviceType === "bundle";
    },
    then: () =>
      Yup.array()
        .required(SVLANG.error_service_in_bundle)
        .min(1, SVLANG.error_service_in_bundle),
    otherwise: () => Yup.array(),
  }),
  name: Yup.string().required(SVLANG.error_product_name),
  price: Yup.number().required(SVLANG.error_price_validation),
  listing_order: Yup.number()
    .typeError(SVLANG.error_listing_order)
    .integer(SVLANG.error_listing_order_type)
    .min(1, SVLANG.error_listing_order_min_number)
    .max(999, SVLANG.error_listing_order_max_number),
  description: Yup.string().required(SVLANG.error_product_description),
  default_selected: Yup.boolean(),
  default_available: Yup.boolean(),
  purchase_by_credits: Yup.boolean(),
  price_credits: Yup.number().when("purchase_by_credits", {
    is: true,
    then: () => Yup.number().typeError(SVLANG.error_price_credits),
    otherwise: () => Yup.number(),
  }),
});

export default Schema;
