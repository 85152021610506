import Axios from "@app/api/axios";
import { ROLES } from "@utils/constant";

const getRolesByTypeService = (userType = "") =>
  Axios.get(`${ROLES}?filter[portal]=${userType}&include=permissions`).then(
    (response) => {
      return response.data;
    },
  );

export default getRolesByTypeService;
