import React from "react";
import { useIntl } from "react-intl";
import Icon from "@components/Icon";
import Div from "@components/Div";
import { H3, H4 } from "@components/Heading";
import { TextLarge } from "@components/Text";
import PropTypes from "prop-types";
import { PrimaryButtonOutlined, PrimaryButton } from "@components/Button";

const LoadObjectsModal = ({
  collectedSSNs,
  loadedSSNs,
  onClose,
  onSubmit,
  existingSSNs,
  invalidSSNs,
}) => {
  const { messages } = useIntl();
  return (
    <Div>
      <H3 width={1} textAlign="center" mb={3}>
        {messages.loading_objects}
      </H3>
      <Div my={2} display="flex" alignItems="center" justifyContent="center">
        <TextLarge>{messages.you_are_loading}:</TextLarge>
        <Icon
          mx={1}
          name="account-circle"
          color="var(--turquoise)"
          fontSize="var(--fs-text)"
        />
        <H4>{` ${collectedSSNs} ${messages.text_objects}`}</H4>
      </Div>
      <Div display="flex" alignItems="center" justifyContent="center" my={2}>
        <TextLarge>{messages.loaded_successfully}:</TextLarge>
        <Icon
          mx={1}
          name="account-circle"
          color="var(--turquoise)"
          fontSize="var(--fs-text)"
        />
        <H4>{` ${loadedSSNs} ${messages.text_objects}`}</H4>
      </Div>
      {existingSSNs > 0 && (
        <Div display="flex" alignItems="center" justifyContent="center" my={2}>
          <TextLarge>{messages.not_loaded}:</TextLarge>
          <Icon
            mx={1}
            name="account-circle"
            color="var(--red)"
            fontSize="var(--fs-text)"
          />
          <H4>{` ${existingSSNs} ${messages.text_objects}`}</H4>
        </Div>
      )}
      {invalidSSNs > 0 && (
        <Div display="flex" alignItems="center" justifyContent="center" my={2}>
          <TextLarge>{messages.wrong_number}:</TextLarge>
          <Icon
            mx={1}
            name="account-circle"
            color="var(--red)"
            fontSize="var(--fs-text)"
          />
          <H4>{` ${invalidSSNs} ${messages.text_objects}`}</H4>
        </Div>
      )}
      <Div display="flex" justifyContent="center" mt={3}>
        <PrimaryButtonOutlined
          rounded
          semibold
          px={3}
          mr={3}
          type="reset"
          width={150}
          label={messages.label_cancel}
          onClick={onClose}
        />

        <PrimaryButton
          rounded
          semibold
          py={1}
          px={4}
          width={150}
          onClick={onSubmit}
          label={messages.label_ok}
        />
      </Div>
    </Div>
  );
};

LoadObjectsModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  collectedSSNs: PropTypes.number,
  loadedSSNs: PropTypes.number,
  existingSSNs: PropTypes.number,
  invalidSSNs: PropTypes.number,
};

export default LoadObjectsModal;
