import React from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import AdminSideMenu from "./AdminSideMenu";

const AdminLayout = ({ children }) => {
  return (
    <Div display="flex" maxWidth="100vw" overflow="hidden">
      <Div
        width={[1, 1, 1, "192px"]}
        minHeight="100vh"
        minWidth={192}
        display={["none", "none", "none", "block"]}
        borderRight={"2px solid var(--grey-lightest)"}
        zIndex={1000}
        backgroundColor={"var(--white)"}
      >
        <Div
          position="fixed"
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          height="100%"
          overflowY="auto"
        >
          <AdminSideMenu />
        </Div>
      </Div>
      <Div
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        maxWidth={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        backgroundColor={"var(--site-background-color)"}
      >
        {children}
      </Div>
    </Div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default AdminLayout;
