import React, { useEffect, useState } from "react";
import { useIntl,FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import getProductById from "@app/services/services/getProductById";
import updateProductById from "@app/services/services/updateProductById";

import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import AdminContainer from "@layout/AdminContainer";
import JsonFormCard from "@components/JsonForm/JsonFormCard";
import { ROUTES, SUBSCRIPTION_STATUS,
  STATUS_IN_ACTIVE, SVLANG} from "@utils/constant";
import * as Yup from "yup";
import { useToast } from "@hooks/useToast";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";


const UpdateSubscription = () => {
  const { messages } = useIntl();
  const { id: productId = "" } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const { hasAllPermissions } = useAuthorization();
  const hasWriteProductsPermission = hasAllPermissions([AdminPermissions.AdminWriteProducts]);


  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: data?.name || "",
    },
  ];

  const formatAndSetData = (responseData) => {
    const {
      name,
      price,
      description,
      credits,
      type,
      id,
      state,
      discount = 0,
    } = responseData;
    setData({
      id,
      name,
      price,
      description,
      credits,
      type,
      state,
      discount,
    });
  };
  const statusValues = [
    {
      label: messages.active,
      value: SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: messages.state_in_active,
      value: STATUS_IN_ACTIVE,
    },
  ];


  const subscriptionDetailsConfig = [
    {
      label: <FormattedMessage id="label_id" />,
      fieldType: "text",
      name: "id",
      value: data?.id,
      placeholder: <FormattedMessage id="label_id" />,
      show:false,
    },
    {
      label: <FormattedMessage id="label_product_type" />,
      fieldType: "text",
      name: "label_product_type",
      value: messages.subscription,
      placeholder: <FormattedMessage id="label_product_type" />,
      show:false,
    },
    {
      label: <FormattedMessage id="label_status" />,
      fieldType: "dropdown",
      name: "state",
      value: data?.state,
      options:statusValues,
      placeholder: <FormattedMessage id="label_status" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_name" />,
      ),
    },
    {
      label: <FormattedMessage id="label_subscription_name" />,
      fieldType: "text",
      name: "name",
      value: data?.name,
      placeholder: <FormattedMessage id="label_subscription_name" />,
      validation: Yup.string().required(
        <FormattedMessage id="error_product_name" />,
      ),
    },
    {
      label:  <>
      <FormattedMessage id="watchlist_label_price" /> <FormattedMessage id="price_currency_label" />
    </>,
      fieldType: "text",
      name: "price",
      value: data?.price,
      placeholder: <FormattedMessage id="watchlist_label_price" />,
      validation: Yup.number()
      .typeError(<FormattedMessage id="error_price_integer" />)
      .integer(<FormattedMessage id="error_price_integer" />)
      .min(0,<FormattedMessage id="error_price_integer" />)
      .required(<FormattedMessage id="error_price_validation" />),
    },
    {
      label:<FormattedMessage id="label_credits_in_package" />,
      fieldType: "text",
      name: "credits",
      value: data?.credits,
      placeholder: <FormattedMessage id="label_credits_in_package" />,
      validation:Yup.number()
      .typeError(<FormattedMessage id="error_credits_integer" />)
      .integer(<FormattedMessage id="error_credits_integer" />)
      .min(0, <FormattedMessage id="error_credits_integer" />)
      .required(<FormattedMessage id="error_price_credits" />),
    },
    {
      label:<FormattedMessage id="label_discount_percentage" />,
      fieldType: "text",
      name: "discount",
      value: data?.discount,
      placeholder: <FormattedMessage id="label_discount_percentage" />,
      validation: Yup.number()
      .typeError( <FormattedMessage id="text_invalid_discount" />)
      .integer( <FormattedMessage id="error_type_discount" />)
      .min(0, <FormattedMessage id="error_type_discount" />)
      .required(<FormattedMessage id="error_discount" />),
    },
  ];

  const subscriptionDescriptionConfig = [
    {
      label: <FormattedMessage id="label_description" />,
      fieldType: "editor",
      name: "description",
      value: data?.description,
      placeholder: <FormattedMessage id="label_description" />,
      validation: Yup.string().required(SVLANG.error_product_description),
    },
  ];
  const handleUpdateSubscription = async (payload) => {
    setIsLoading(true);
    try {
      const { data: response = {} } = await updateProductById({
        ...payload,
        id: data?.id
      });
      formatAndSetData(response);
      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.exception_error_message);
    }
    finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);
      try {
        const response = await getProductById(productId);
        const {
          data: { data: respData = [] },
        } = response;
        const [values = {}] = respData;
        formatAndSetData(values);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  const containerConfig = {
    title: data?.name,
  };

  return (
    <AdminContainer config={containerConfig}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Div pb={3}>
          <Breadcrumb items={breadCrumbItems} p={0} pb={[2, 2, 3, 3]} />
          <Div display="flex" flexDirection="column" width={1}>
          <JsonFormCard
            title={messages.label_details}
            config={subscriptionDetailsConfig}
            onSubmit={handleUpdateSubscription}
            isEditPermission={hasWriteProductsPermission}
          />
          <JsonFormCard
            title={messages.label_description}
            config={subscriptionDescriptionConfig}
            onSubmit={handleUpdateSubscription}
            isEditPermission={hasWriteProductsPermission}
          />
        </Div>
        </Div>
      )}
    </AdminContainer>
  );
};

export default UpdateSubscription;
