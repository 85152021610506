import React from "react";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { Text } from "@components/Text";

import Config from "@src/config";

import { ROUTES } from "@utils/constant";

const ServiceUnavailableCard = () => {
  const { messages } = useIntl();

  return (
    <Div width={["50%", "50%", "100%", "100%"]}>
      <Text display="block" mb={3}>
        {messages.service_unavailalbe_description}
      </Text>
      <Text display="block" mb={4}>
        {messages.service_unavailalbe_content}
      </Text>
      <LinkArrow
        direction="left"
        href={`${Config.WP_URL}${ROUTES.INTERNATIONELLBAKGRUNDSANALYS.URL}`}
        label={messages.order_manual_background_check}
        mb={1}
        fontSize={["var(--fs-h4)"]}
      >
        <Icon ml={1} name="headerarrowright" />
      </LinkArrow>
    </Div>
  );
};

ServiceUnavailableCard.propTypes = {};

export default ServiceUnavailableCard;
