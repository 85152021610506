import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import moment from "moment";

import Div from "@components/Div";
import { H4 } from "@components/Heading";
import { SmallEllipseIcon } from "@components/Icon";
import { TextColoured } from "@components/Text";
import Tooltip from "@components/ToolTip";
import Luhn from "luhn";
import RoutesList from "@shared/RoutesList";

import {
  APPLICATION_NAME,
  TITLE_DIVIDER,
  CUSTOMER_USER,
  GUEST_USER,
  ADMIN,
  KLARNA_CHECKOUT,
  SORT_KEY_STATUS,
  SORT_KEY_NAME,
  SORT_KEY_COMPANY,
  SORT_KEY_CUSTOMER,
  SORT_KEY_SERVICE,
  SORT_KEY_ORDER,
  SORT_KEY_USER,
  SORT_KEY_IS_ACTIVE,
  SORT_KEY_FIRSTNAME,
  SORT_KEY_COMPANY_NAME,
  SORT_KEY_NUMBER,
  SORT_KEY_CREATED_AT,
  SORT_KEY_ASSIGNMENT,
  SORT_KEY_ASSIGNEE,
  SORT_KEY_ORDERS_COUNT,
  SORT_KEY_USERNAME,
  CUSTOMERS_API,
  SUBSCRIPTION,
  SORT_KEY_PRODUCT,
  ORDER_LIST,
  ADMIN_CUSTOMERS,
  ADMIN_ASSIGNMENTS,
  USERS_LIST,
  PAGE_NOT_FOUND,
  CUSTOMERS,
  USERS,
  ORDERS_API,
  ASSIGNMENTS_API,
  DASHBOARD,
  DASHBOARD_COMPANY,
  CUSTOMER,
  INDIVIDUAL,
  CREDITS_CHECKOUT,
  START_YEAR,
  END_YEAR,
  OLD_CENTURY,
  NEW_CENTURY,
  DISCOUNT_CHECKOUT,
  INVOICE_CHECKOUT,
  SORT_KEY_UPDATED_AT,
  SORT_REPORT_NAME,
  GRADES,
  ORDERS_ID,
  SORT_REPORT_NAMES,
  SORT_CLIENT_NAME,
  SORT_CLIENT_NAMES,
  SVLANG,
} from "@utils/constant";
import { CASES_DATA_STATUS_TYPE, ROLE_TYPE } from "@utils/enum";
import countries from "@utils/countries.json";

export const addHyphenToNumber = (number, position) => {
  let value = number || "";
  const regEx = new RegExp(`(\\d{${position}})`, "g");
  value = value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  value = value && value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  if (value && (value.length > position + 1 || value.length < position + 1)) {
    value = value.replace(/-/g, "");
  }
  if (value && value.length > position && value.indexOf("-") !== position) {
    value = value.replace(regEx, "$1-");
  }
  return value;
};

export const setTitle = () => {
  const routePath = window.location.pathname.split("/");
  const routePathName = `/${routePath[routePath.length - 1]}`;
  const route = RoutesList.find(obj => obj.url.includes(routePathName));
  if (route) {
    document.title = APPLICATION_NAME + TITLE_DIVIDER + route.title;
  }
};

const urlencode = new Map();
urlencode.set("%", ",");

export const omit = (keys, obj) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

export const encodeHTML = (arr, value) =>
  arr.length > 1 ? arr.join(urlencode.get(value)) : arr;

export const splitString = (str, value = "\n") =>
  str
    .split(value)
    .map(string => string.trim()) // remove hyphens here
    .filter(trimmedStr => trimmedStr !== ""); // check if len is 12 additionally

export const parseJwt = token => {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
  return token;
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  try {
    const jwtPayload = parseJwt(token);
    if (jwtPayload) {
      return jwtPayload.exp > +new Date() / 1000;
    }
  } catch (e) {
    throw new Error(e);
  }
  return false;
};

const isAdmin = () => location.pathname.includes(ADMIN);

export const getUserPaymentDetails = (
  isPrepaidAccount = false,
  discount = "",
  isPaymentMethodInPending = false,
  isCustomerHasInvoice = false,
  hasActiveSubscription = false
) => {
  let userDetails = {};
  switch (true) {
    case !isAuthenticated():
      userDetails = {
        type: GUEST_USER,
        payment_method: KLARNA_CHECKOUT,
      };
      break;

    case isPaymentMethodInPending:
      userDetails = {
        type: CUSTOMER_USER,
        noCredits: true,
        payment_method: INVOICE_CHECKOUT,
      };
      break;
    case hasActiveSubscription:
      userDetails = {
        type: CUSTOMER_USER,
        payment_method: CREDITS_CHECKOUT,
      };
      break;

    case isCustomerHasInvoice:
      userDetails = {
        type: CUSTOMER_USER,
        payment_method: INVOICE_CHECKOUT,
      };
      break;

    case isAuthenticated() && !isAdmin():
      userDetails = {
        type: CUSTOMER_USER,
        payment_method: isPrepaidAccount
          ? INVOICE_CHECKOUT
          : discount
            ? DISCOUNT_CHECKOUT
            : KLARNA_CHECKOUT,
      };
      break;

    case isAdmin():
      userDetails = {
        type: ADMIN,
        payment_method: isPrepaidAccount ? CREDITS_CHECKOUT : INVOICE_CHECKOUT,
      };
      break;
  }

  return userDetails;
};

export const renderSnippet = htmlSnippet => {
  const checkoutContainer = document.getElementById("my-checkout-container");

  if (checkoutContainer) {
    // eslint-disable-next-line no-unsanitized/property
    checkoutContainer.innerHTML = htmlSnippet;

    const scriptsTags = checkoutContainer.getElementsByTagName("script");

    for (let i = 0; i < scriptsTags.length; i += 1) {
      const { parentNode } = scriptsTags[i];
      const newScriptTag = document.createElement("script");
      newScriptTag.type = "text/javascript";
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }
};

export const parseFilterParams = (element = []) => {
  let keys;
  let params = "";
  if (element && element[1]) {
    if (element[1] === true) {
      params = "filter=true";
    } else {
      keys = Object.keys(element[1]);
      if (keys.length) {
        params = `filter=${keys.map(obj => obj).join(",")}&${keys
          .map(
            obj =>
              `${obj}=${element[1][obj]
                .map(ele => encodeURIComponent(ele))
                .filter(o => o !== "")
                .join(",")}`
          )
          .filter(o => o !== "")
          .join("&")}`;
      }
    }
  }
  return params;
};

export const formatDateAndTime = (value = "") =>
  moment(value).format("YYYY-MM-DD [kl.] HH.mm");

export const formatDateAndTimeForCSV = (value = "") =>
  moment(value).format("YYYY-MM-DD, HH:mm:ss");

export const formatDate = (value = "") => {
  if (!value || value === '0000-00-00' || !moment(value, "YYYY-MM-DD", true).isValid()) {
    return null;
  }
  return moment(value).format("YYYY-MM-DD");
};


export const formatYear = (value = "") => moment(value).format("YYYY");

export const getSortKey = (sortField, screenName) => {
  switch (sortField) {
    case SORT_KEY_STATUS:
      if (screenName === CUSTOMERS_API) return SORT_KEY_IS_ACTIVE;
      return SORT_KEY_STATUS;
    case SORT_KEY_NAME:
    case SORT_KEY_USER:
      if (screenName === SUBSCRIPTION) return SORT_KEY_PRODUCT;
      return SORT_KEY_FIRSTNAME;
    case SORT_KEY_ORDERS_COUNT:
      return SORT_KEY_ORDERS_COUNT;
    case SORT_KEY_COMPANY:
      return SORT_KEY_COMPANY_NAME;
    case SORT_KEY_CUSTOMER:
      return SORT_KEY_CUSTOMER;
    case SORT_KEY_ASSIGNMENT:
      return SORT_KEY_ASSIGNEE;
    case SORT_KEY_SERVICE:
      return SORT_KEY_SERVICE;
    case SORT_KEY_ORDER:
      return SORT_KEY_NUMBER;
    case SORT_KEY_USERNAME:
      return SORT_KEY_USER;
    case SORT_KEY_UPDATED_AT:
      return SORT_KEY_UPDATED_AT;
    case GRADES:
      return GRADES;
    case ORDERS_ID:
      return ORDERS_ID;
    case SORT_REPORT_NAMES:
      return SORT_REPORT_NAME;
    case SORT_CLIENT_NAME:
      return SORT_CLIENT_NAMES;
    default:
      return SORT_KEY_CREATED_AT;
  }
};

export const getRedirectPage = userInfo => {
  const { permissions = [], type, customer } = userInfo;
  const rights = Object.keys(permissions).map(obj => {
    return obj;
  });
  switch (true) {
    case rights.includes("company-information") && customer.type !== INDIVIDUAL:
      return DASHBOARD_COMPANY;
    case type === CUSTOMER:
      return DASHBOARD;
    case rights.includes(CUSTOMERS):
      return ADMIN_CUSTOMERS;
    case rights.includes(ORDERS_API):
      return ORDER_LIST;
    case rights.includes(ASSIGNMENTS_API):
      return ADMIN_ASSIGNMENTS;
    case rights.includes(USERS):
      return USERS_LIST;
    default:
      return PAGE_NOT_FOUND;
  }
};

export const diffDays = endDate => moment(endDate).diff(moment(), "days");

export const convertSEK = price => {
  return price ? price.toString().replaceAll(".", ",").replace(/,00$/, "") : 0;
};

export const formatCurrency = value => {
  return Number(value).toFixed(2);
};

/* Utility to make 15000 display as 15 000 */
export const getFormattedNumber = (value = "") => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

/* Utility to make 11423 display as 114 23 */
export const getFormattedPinCode = value => {
  return value.match(/.{1,3}/g).join(" ");
};

/* Utility to add either 19 or 20 infront of the entered SSN,
 1911-1999  (11-99)  and 2000-2010 (00-10) */

// this should be called after trim -> check the string length and perform necessary

export const getPinWithFullYear = pin => {
  const year = pin.slice(0, 2).toString();
  const appendedValue =
    year >= START_YEAR && year <= END_YEAR
      ? NEW_CENTURY + pin
      : OLD_CENTURY + pin;
  return appendedValue;
};

export const isAdminUser = () => {
  const token = localStorage.getItem("token");
  try {
    const json = parseJwt(token);
    if (json) {
      return json.scopes.filter(obj => obj === "admin").length > 0;
    }
  } catch (_e) {
    //catch the error here
  }
  return false;
};

export const isCustomerUser = () => {
  const token = localStorage.getItem("token");
  try {
    const json = parseJwt(token);
    if (json) {
      return json.scopes.filter(obj => obj === "customer").length > 0;
    }
  } catch (_e) {
    //catch the error here
  }
  return false;
};

export const onFilterSelectedData = (data = []) => {
  const filterOptions = data;
  let selectedData = [];
  filterOptions.forEach(item => {
    const { options: itemOptions, id, type, filter_by_startdate } = item;
    let filterId = id;
    const checkedData = [];
    const calendardata = [];
    itemOptions.map(obj => {
      const { value, checked } = obj;
      if (type === "calendar" && value) {
        const [startDate = "", endDate = ""] = value;
        const [startdateId = "", endDateId = ""] = obj.id;
        if (startDate) {
          calendardata.push({
            id: endDate ? startdateId : `${filter_by_startdate}`,
            values: [formatDate(startDate)],
            type,
          });
        }
        if (endDate) {
          calendardata.push({
            id: endDateId,
            values: [formatDate(endDate)],
            type,
          });
        }
      } else if (type === "input" && value) {
        checkedData.push(value);
      } else if (type === "multiselect" && value) {
        checkedData.push(value);
      } else if (checked) {
        checkedData.push(value);
      }
      return obj;
    });
    if (checkedData.length) {
      selectedData.push({ id: filterId, values: checkedData, type });
    }
    if (type === "calendar") {
      selectedData = [...selectedData, ...calendardata];
    }
    return item;
  });
  return selectedData;
};

export const getInitials = (name = "") => {
  const initials = name?.split(" ") || [];
  return initials.map(obj => obj.substring(0, 1).toUpperCase()).join("");
};

export const getColorAndValueOfGrade = key => {
  const values = [
    "Relevanta brott ej identifierade",
    "Relevanta brott identifierade",
    "Resultat ej klart",
  ];
  const colors = [
    "var(--green-dark)",
    "var(--red-dark)",
    "var(--grey)",
    "var(--yellow-medium)",
  ];
  return { label: values?.[key], color: colors[key] };
};

export const ValidateSwedishSSN = ssn => {
  const SSN = ssn.includes("-") ? ssn.replaceAll("-", "") : ssn;
  if (SSN && SSN.length !== 10 && SSN.length !== 12) {
    return false;
  }
  const pattern =
    /^(19|20)?\d{2}(0[1-9]|[1-9][0-2])(0[1-9]|[1-2]\d|3[0-1])-?(\d{4})$/;
  if (pattern.test(ssn)) {
    const datePart = ssn.split(/[-+]/)[0];
    try {
      new Date(
        datePart.replace(
          /(\d{2})(\d{2})(\d{2})/,
          (_, year, month, day) => `20${year}-${month}-${day}`
        )
      );
      return true;
    } catch {
      return false;
    }
  }
  return false;
};


export const ValidateOrganisationNumber = (value) => {
  return (
    /^[0-9-]+$/.test(value) &&
    value.length === 11 &&
    Luhn.validate(value.replace("-", ""))
  );
};


export const calculateVat = (price = 0) => price * 0.25;

export const calculatePercentage = (fraction = 0, totalValue = 0) =>
  Math.min((fraction * 100) / totalValue, 100);

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    navigator.userAgent
  );

export const flattenObject = (
  obj,
  parentKey = "",
  delimiter = ".",
  result = {}
) => {
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const newKey = parentKey ? `${parentKey}${delimiter}${key}` : key;

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !(obj[key] instanceof Date) &&
        !Array.isArray(obj[key])
      ) {
        result = { ...result, ...flattenObject(obj[key], newKey) };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};

export const unFlattenObject = (obj, delimiter = ".") =>
  Object.keys(obj).reduce((res, k) => {
    k.split(delimiter).reduce((acc, e, i, keys) => {
      const objectToReturn = keys.length - 1 === i ? obj[k] : {};

      return (
        acc[e] || (acc[e] = isNaN(Number(keys[i + 1])) ? objectToReturn : [])
      );
    }, res);
    return res;
  }, {});

export function flattenOrderObject(order, maxOrderItems) {
  const baseRecord = {};

  // Process order fields
  Object.keys(order).forEach(key => {
    if (typeof order[key] !== "object" || order[key] === null) {
      baseRecord[`order_${key}`] = order[key];
    }
  });

  // Process customer fields
  if (order.customer) {
    Object.keys(order.customer).forEach(key => {
      baseRecord[`customer_${key}`] = order.customer[key];
    });
  }

  // Process user fields
  if (order.user) {
    Object.keys(order.user).forEach(key => {
      baseRecord[`user_${key}`] = order.user[key];
    });
  }

  baseRecord[`payment_payable_type`] = null;

  const [payment = {}] = order.payments;
  Object.keys(payment).forEach(key => {
    baseRecord[`payment_${key}`] = payment[key];
  });

  // Initialize placeholders for all potential order items
  for (let i = 0; i < maxOrderItems; i++) {
    baseRecord[`order_items_${i}_name`] = null;
    baseRecord[`order_items_${i}_service_type`] = null;
    baseRecord[`order_items_${i}_price`] = null;
    baseRecord[`order_items_${i}_vat_amount`] = null;
    baseRecord[`order_items_${i}_person_name`] = null;
    // Add other order item properties here as needed
  }

  // Process order_items fields with index
  order.order_items.forEach((item, index) => {
    Object.keys(item).forEach(key => {
      baseRecord[`order_items_${index}_${key}`] = item[key];
    });
  });

  return baseRecord; // return as an array of one element
}

export function enumValueToTranslationKey(value = "") {
  return value?.replace ? value?.replace(/[-\s]/g, "_")?.toLowerCase() : value;
}

export const downloadDataInTxt = (fileName, dataObj = {}) => {
  const jsonString = JSON.stringify(dataObj, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });

  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${fileName}.txt`;

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export function getCountryDetails(searchValue, searchSelector, targetValueKey) {
  const searchableCountry =
    countries.find(country => country[searchSelector] == searchValue) || {};

  return searchableCountry[targetValueKey] || null;
}

export function removeZeroValuesFromObject(object = {}) {
  for (let key in object) {
    if (!object[key] && object[key] !== false && object[key] !== 0) {
      delete object[key];
    }
  }

  return object;
}

export function getUserRoleTranslationKey(roleType) {
  const translationKeysByRoleType = {
    [ROLE_TYPE.ADMIN_SUPERADMIN]: "admin_superadmin",
    [ROLE_TYPE.ADMIN]: "role_admin",
    [ROLE_TYPE.SECURITY_ANALYST]: "role_security_analyst",
    [ROLE_TYPE.CV_VERIFICATION]: "role_cv_verification",
    [ROLE_TYPE.ADMIN_CUSTOM]: "custom",
    [ROLE_TYPE.OWNER]: "owner",
    [ROLE_TYPE.SUPERADMIN]: "superadmin",
    [ROLE_TYPE.USER]: "user",
    [ROLE_TYPE.CUSTOM]: "custom",
    [ROLE_TYPE.SECURITY_OFFICER]: "security_officer",
    [ROLE_TYPE.ADMIN_ADMIN]: "role_admin",
    [ROLE_TYPE.ADMIN_INVESTIGATOR]: "admin_investigator",
    [ROLE_TYPE.ADMIN_SALES]: "admin_sales",
  };

  return translationKeysByRoleType[roleType];
}

export const getGrade = (
  key,
  isRoleSpecificAnalysis = true,
  labelForGrade = ""
) => {
  const { label, color } = getColorAndValueOfGrade(key);

  return (
    <Div display="flex" alignSelf="center">
      <Div width={20} height={20} bg={color} borderRadius="50%" />
      <H4 ml={2}>{isRoleSpecificAnalysis ? label : labelForGrade}</H4>
    </Div>
  );
};

export const reportStatusComponent = (value, report) => {
  const parsedConfig = JSON.parse(report["product.report_config"]);
  const repor_array = parsedConfig ? parsedConfig?.grade : [];
  const foundGrade = repor_array?.find(grade => grade.grades === value);
  const { color = "white", bgColor = "grey" } = foundGrade || {};
  return value !== "-" ? (
    <Div display="flex" alignSelf="center">
      <Div
        width={40}
        height={24}
        bg={`var(--${bgColor})`}
        borderRadius="50px"
        display={"flex"}
        alignItems="center"
        justifyContent="center"
      >
        <TextColoured
          color={`var(--${color})`}
          fontWeight={`var(--medium-weight)`}
        >
          {value}
        </TextColoured>
      </Div>
    </Div>
  ) : (
    <Div width={40} height={24} textAlign={["center"]}>
      {"-"}
    </Div>
  );
};

export const StatusComponent = ({ statusKey, statusToolTip }) => {
  const { messages } = useIntl();

  return statusKey ? (
    <>
      {messages[statusKey]}
      <SmallEllipseIcon ml={2} className={`class-${statusToolTip}`} name="info">
        <Tooltip
          boxShadow="none"
          target={`.class-${statusToolTip}`}
          content={messages[statusToolTip]}
        />
      </SmallEllipseIcon>
    </>
  ) : (
    "-"
  );
};

StatusComponent.propTypes = {
  statusKey: PropTypes.string,
  statusToolTip: PropTypes.string,
};

export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export const formatId = (id = "") => {
  return id ? `#${id?.toString()?.padStart(6, "0")}` : "-";
};

export const formatDataStatus = (dataSources = []) => {
  if (!dataSources?.length || !Array.isArray(dataSources)) {
    return "-";
  }

  if (
    dataSources.every(
      ({ status }) => status === CASES_DATA_STATUS_TYPE.COMPLETED
    )
  ) {
    return CASES_DATA_STATUS_TYPE.COMPLETED;
  }

  if (
    dataSources.some(({ status }) => status === CASES_DATA_STATUS_TYPE.MANUAL)
  ) {
    return CASES_DATA_STATUS_TYPE.MANUAL;
  }

  if (
    dataSources.some(({ status }) => status === CASES_DATA_STATUS_TYPE.PENDING)
  ) {
    return CASES_DATA_STATUS_TYPE.PENDING;
  }
};

export const isArraysEqual = (array1, array2) => {
  if (array1.length !== array2.length) return false;
  return (
    new Set(array1).size === new Set(array2).size &&
    array1.every(item => array2.includes(item))
  );
};

export const convertStringToHTML = text => {
  const lines = text?.split("\n");
  const htmlLines = [];

  lines.forEach(line => {
    const trimmedLine = line.trim();

    if (trimmedLine.startsWith("-")) {
      htmlLines.push(`<li>${trimmedLine.slice(1).trim()}</li>`);
    } else if (trimmedLine) {
      htmlLines.push(`<p>${trimmedLine}</p>`);
    }
  });

  const updatedLines = [];

  htmlLines.forEach((line, currentIndex, array) => {
    if (
      line.startsWith("<li>") &&
      !array[currentIndex - 1]?.startsWith("<li>")
    ) {
      updatedLines.splice(currentIndex, 0, "<ul>");
      updatedLines.push(line);
    } else if (
      line.startsWith("<li>") &&
      !array[currentIndex + 1]?.startsWith("<li>")
    ) {
      updatedLines.push(line);
      updatedLines.push("</ul>");
    } else {
      updatedLines.push(line);
    }
  });

  const htmlString = updatedLines.join("\n");

  return htmlString;
};

export const parseBAClassNames = (baClassNames = "") => {
  if (!baClassNames) {
    return "";
  }

  const valueByClassKey = {
    bg: ".bg-:value { background-color: var(--:value); }",
    column:
      ".column-:value { column-count: :value; display: flex; flex-direction: row; gap: 1rem;\n}",
    p: ".p-:value { padding: :value; }",
    radius: ".radius-:value { border-radius: :value; }",
    fs: ".fs-:value { font-size: var(--fs-:value); }",
    fw: ".fw-:value { font-weight: var(--:value); }",
    color: ".color-:value {color: var(--:value) !important; }",
  };
  const classes = baClassNames
    .split(", ")
    .map(value => {
      const [classKey, restValue] = value.split(/-(.*)/s);

      return valueByClassKey[classKey]?.replaceAll(":value", restValue);
    })
    .join("\n\n");

  return classes;
};

export const fetchUserStatus = (email_verified = "", data = {}) => {
  const { is_active = "" } = data || {};
  if (email_verified) {
    if (is_active) {
      return SVLANG.active;
    }
    return SVLANG.state_in_active;
  } else {
    return SVLANG.invited;
  }
};

export const isHtmlString = (stringValue = "") => {
  if (!stringValue.length) {
    return false;
  }

  const doc = new DOMParser().parseFromString(stringValue, "text/html");
  const childArray = Array.from(doc.body.childNodes) ?? [];

  return childArray.some(({ nodeType }) => nodeType === 1);
};

export const translateObjectKeys = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    // Translate the key and keep the same value
    const translatedKey = SVLANG[key] || key;
    acc[translatedKey] = obj[key];
    return acc;
  }, {});
};
