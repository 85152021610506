import { compose, space, display, layout, typography } from "styled-system";
import styled from "styled-components";

const Label = styled("label")`
  ${compose(space, display, layout, typography)};
  font-size: var(--fs-h4);
  font-weight: var(--medium-weight);
  line-height: var(--lh-h4);
  color: var(--grey-dark);
  white-space: nowrap;
`;
export const LabelSemiBold = styled("Label")`
  ${compose(space, display, layout)};
  font-weight: var(--semibold-weight);
`;

export default Label;
