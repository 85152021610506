import Axios from "@app/api/axios";
const updateReportStatus = (report_id, consent_id, status) => {
  const payload = {
    status: status,
    consent_id: consent_id,
  };
  // /reports/id/consent
  return Axios.put(`reports/${report_id}/consent`, payload);
};

export default updateReportStatus;
