import React from "react";
import PropTypes from "prop-types";

import FullSizeDialog from "@components/FullSizeDialog";

import Form from "./InviteEditUserForm";

const UserDialog = ({
  title,
  userType,
  userDetails,
  customerId,
  handleClose,
}) => {
  return (
    <FullSizeDialog title={title} onClose={handleClose}>
      <Form
        userType={userType}
        userDetails={userDetails}
        customerId={customerId}
        onClose={handleClose}
      />
    </FullSizeDialog>
  );
};

UserDialog.propTypes = {
  title: PropTypes.string,
  userType: PropTypes.string,
  userDetails: PropTypes.object,
  customerId: PropTypes.string,
  handleClose: PropTypes.func,
};

export default UserDialog;
