import React, { useEffect, useState } from "react";
import { PrimaryButtonIcon } from "@components/Button";
import Card from "@components/Card";
import { H2, H4, H5 } from "@components/Heading";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import Section from "@components/Section";
import { loadServicesData, redirectOnSubmit } from "@utils/common";
import { useHistory } from "react-router-dom";
import { SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";
const SubscriptionPackages = prop => {
  const { messages } = useIntl();
  const {
    activePackage: { product_id = "", status = "" },
    activePackage = "",
    onCreateSubscription,
  } = prop;
  const history = useHistory();
  const [subsPackages, setSubsPackages] = useState([]);
  useEffect(() => {
    const { customer = {} } = prop.userInfo;
    const { id: customer_id = "" } = customer;
    let package_list = loadServicesData(customer_id);
    package_list.then(result => {
      setSubsPackages(result);
      prop.subscriptionListLoaded();
    });
  }, [prop]);
  return (
    <Section py={3} px={0}>
      <Div display="flex" flexWrap="wrap">
        {subsPackages.length > 0
          ? subsPackages.map((subscriptionPackage) => {
              const { id, name, credits, price = "" } = subscriptionPackage;
              const isSpecialPackage = name === SPECIAL_PACKAGE;
              return (
                <Div
                  pr={[0, 0, 0, 24]}
                  width={[1, 1, 1, 215]}
                  pb={24}
                  key={id}
                >
                  <Card>
                    <Div p={3} borderBottom={"1px solid var(--grey-lightest)"}>
                      <H4 mb={1}>
                        {isSpecialPackage
                          ? messages.label_special_package
                          : name}
                      </H4>
                      {isSpecialPackage ? (
                        <H2>{messages.label_negoitable}</H2>
                      ) : (
                        <Div display={"flex"} alignItems={"baseline"}>
                          <H2 whiteSpace={"nowrap"}>
                            {`${price?.toLocaleString("sv-SE")} ${
                              messages.price_currency
                            }`}
                          </H2>
                          <H5>{` /${messages.label_year}`}</H5>
                        </Div>
                      )}
                    </Div>
                    <Div p={3}>
                      <H5 mb={2}>{`${messages.label_include}:`}</H5>
                      {isSpecialPackage ? (
                        <Div mb={"18px"} />
                      ) : (
                        <H4
                          mb={1}
                        >{`${credits} ${messages.watchlist_label_credits}`}</H4>
                      )}
                      <H5>
                        {isSpecialPackage
                          ? messages.description_special_package
                          : messages.description_credits_and_analysis}
                      </H5>
                    </Div>
                    <Div p={3}>
                      {activePackage &&
                      product_id &&
                      product_id === id &&
                      status !== SUBSCRIPTION_STATUS.TERMINATED ? (
                        <PrimaryButtonIcon minHeight="40px" width={[1, 1, 0.5]}>
                          {messages.current}
                        </PrimaryButtonIcon>
                      ) : (
                        <PrimaryButtonIcon
                          width={1}
                          onClick={
                            onCreateSubscription
                              ? onCreateSubscription.bind(this, id)
                              : redirectOnSubmit.bind(this, id, history)
                          }
                        >
                          {messages.placeholder_choose}
                        </PrimaryButtonIcon>
                      )}
                    </Div>
                  </Card>
                </Div>
              );
            })
          : prop.pageSectionShow && (
              <Div pr={[0, 0, 0, 4]} width={[1, 1, 0.5]}>
                {messages.no_payment_received_yet}
              </Div>
            )}
      </Div>
    </Section>
  );
};
export default SubscriptionPackages;
