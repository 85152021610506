import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { PrimaryButtonOutlined, PrimaryButton } from "@components/Button";
import { Text } from "@components/Text";

import UploadBordered from "@components/UploadBordered";

const CompleteAssignmentsDialog = ({
  assignmentId,
  category,
  onHide,
  onUploadReport,
}) => {
  const { messages } = useIntl();

  const [isFileAttached, setIsFileAttached] = useState(false);

  const uploadRef = useRef(null);

  const handleSubmit = () => {
    uploadRef.current?.upload();
  };

  const handleChange = (event) => {
    onUploadReport(event);
  };

  const onFileAttached = (event) => {
    setIsFileAttached(!!event.files.length);
  };

  const dialogHeader = () => (
    <Div
      width={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <H3>{messages.title_complete_assignment}</H3>
      <Text mt={3}>{messages.text_attach_assignments_report}</Text>
    </Div>
  );

  const dialogFooter = () => (
    <Div
      display="flex"
      width={1}
      flexDirection={["column", "row"]}
      justifyContent="center"
      gridGap={[3, 4]}
    >
      <PrimaryButton
        rounded
        label={messages.label_ok}
        onClick={handleSubmit}
        width={[1, "50%"]}
        px={5}
        disabled={!isFileAttached}
      />
      <PrimaryButtonOutlined
        rounded
        label={messages.label_cancel}
        onClick={onHide}
        width={[1, "50%"]}
        px={48}
      />
    </Div>
  );

  return (
    <Dialog
      header={dialogHeader}
      visible="displayBasic"
      draggable={false}
      footer={dialogFooter}
      onHide={onHide}
      width={[1, 450]}
      m={[3, 3, 3, "auto"]}
    >
      <Div pt={4}>
        <UploadBordered
          uploadRef={uploadRef}
          errorMessage={messages.error_try_later}
          assignmentId={assignmentId}
          category={category}
          onChange={handleChange}
          onFileAttached={onFileAttached}
        />
      </Div>
    </Dialog>
  );
};

CompleteAssignmentsDialog.propTypes = {
  assignmentId: PropTypes.string,
  category: PropTypes.string,
  onHide: PropTypes.func,
  onUploadReport: PropTypes.func,
};

export default CompleteAssignmentsDialog;
