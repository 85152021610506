import styled from "styled-components";
import { RadioButton as PRRadio } from "primereact/radiobutton";
import { compose, color, layout, space } from "styled-system";
const RadioButton = styled(PRRadio).attrs({
  type: "radio",
})`
  box-shadow: none !important;
  ${compose(layout, space, color)};
  .p-radiobutton {
    box-shadow: none !important;
  }
  .p-radiobutton-box {
    border-radius: 50px;
    border: ${({ input }) =>
      input ? "1.5px solid var(--grey)" : "2px solid var(--turquoise)"};
    &: hover {
        border-color: ${({ input }) =>
          input ? "var(--grey) !important" : "var(--turquoise) !important"};
    }
  } {
    .p-radiobutton-box {
      &.p-highlight {
        border-color: ${({ input }) =>
          input ? "var(--grey)" : "var(--turquoise)"};
        background: ${({ input }) =>
          input ? "transparent !important" : "var(--turquoise) !important"};
        &: hover {
        border-color: ${({ input }) =>
          input ? "var(--grey)" : "var(--turquoise)"};
        background: ${({ input }) =>
          input ? "transparent" : "var(--turquoise)"};
      }
      &.p-focus {
        border-color:${({ input }) =>
          input ? "var(--grey)" : "var(--turquoise)"};
      }
    }
  }
  .p-radiobutton-icon {
    width: 8px !important;
    height: 8px !important;
    transition-duration: 0.2s;
    border: 3px solid #fff;
    background-clip: padding-box;
    padding: 5px; 
    color: ${({ input }) =>
      input ? "var(--blue-dark) !important" : "var(--white) !important"};
    background-color:${({ input }) =>
      input ? "transparent" : "var(--turquoise) !important"};
    &: hover {
        border-color: ${({ input }) =>
          input ? "var(--blue-dark)" : "var(--turquoise)"};    
      }
`;

export default RadioButton;
