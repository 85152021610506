import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { TextLarge, TextLargeSemiBoldWeight, Text } from "@components/Text";
import Div from "@components/Div";

function formatGrade(input) {
  // Convert the input string to a number
  const number = parseInt(input, 10);

  // Check if the number is positive and not zero, prepend '+' sign
  if (number > 0) {
    return `+${number}`;
  }

  // For zero or negative numbers, just return the number as is
  return number.toString();
}

const StatementScale = ({ isPrintable = false, config = {} }) => {
  const { messages } = useIntl();
  const { description, grade, footer } = config;

  const renderPrintableGrade = (value, index) => (
    <Div key={`${value?.grades}${index}`} display="flex" mb={2}>
      <Div
        display="block"
        width={10}
        maxHeight={[
          "-webkit-fill-available",
          "-webkit-fill-available",
          "-webkit-fill-available",
          116,
        ]}
        px="5px"
        bg={`var(--${value.bgColor})`}
      />

      <Div display="flex" width={1}>
        <Div
          display="flex"
          mr={15}
          p={0}
          width={130}
          maxHeight={
            isPrintable
              ? "inherit"
              : [
                  "-webkit-fill-available",
                  "-webkit-fill-available",
                  "-webkit-fill-available",
                  116,
                ]
          }
          alignItems="center"
          backgroundColor={
            isPrintable
              ? "var(--turquoise-light)"
              : ["none", "none", "none", "var(--turquoise-light)"]
          }
        >
          <Div
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={70}
            px={3}
          >
            <TextLargeSemiBoldWeight
              width={1}
              px={0}
              py={[25, 25, 25, 0]}
              textAlign={["center", "center", "start", "start"]}
              lineHeight={"var(--fs-h5) !important"}
              fontSize={"var(--fs-text-s) !important"}
            >
              {messages[value.title]} {formatGrade(value.grades)}
            </TextLargeSemiBoldWeight>
          </Div>
        </Div>

        <Div
          display="flex"
          flexDirection="column"
          backgroundColor={"var(--turquoise-light)"}
          justifyContent="center"
          alignItems="flex-start"
          mr={15}
          p={10}
          width={1 / 2}
          maxHeight="inherit"
        >
          <Text
            textAlign={["center", "center", "start", "start"]}
            lineHeight="var(--fs-h5) !important"
            fontSize="var(--fs-text-s) !important"
          >
            {messages[value.description.title]}
            {messages[value.description.subTitle]}
            {messages[value.description.description]}
          </Text>
        </Div>

        <Div
          display="flex"
          flexDirection="column"
          justifyContent={["flex-start", "flex-start", "center", "center"]}
          alignItems={["center", "center", "flex-start", "flex-start"]}
          width={170}
          height={"inherit"}
          backgroundColor={"var(--turquoise-light)"}
        >
          <Text
            textAlign={["center", "center", "start", "start"]}
            p={10}
            lineHeight="var(--fs-h5) !important"
            fontSize="var(--fs-text-s) !important"
          >
            {messages[value.footer]}
          </Text>
        </Div>
      </Div>
    </Div>
  );

  const renderGrade = (value, index) => (
    <Div key={`${value?.grades}${index}`} display="flex" mb={24}>
      <Div
        display="block"
        width={[20, 20, 20, 30]}
        maxHeight={[
          "-webkit-fill-available",
          "-webkit-fill-available",
          "-webkit-fill-available",
          116,
        ]}
        px={[10, 10, 10, 15]}
        bg={`var(--${value.bgColor})`}
      />

      <Div
        display={["block", "block", "block", "flex"]}
        ml={[10, 10, 10, 20]}
        width={[1]}
      >
        <Div
          display="flex"
          mb={[10, 10, 10, 0]}
          mr={[0, 0, 0, 24]}
          width={[1, 1, 1, 130]}
          maxHeight={[
            "-webkit-fill-available",
            "-webkit-fill-available",
            "-webkit-fill-available",
            116,
          ]}
          alignItems="center"
          backgroundColor={["none", "none", "none", "var(--turquoise-light)"]}
        >
          <Div
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={[60, 60, 60, 116]}
            backgroundColor={[
              "var(--turquoise-light)",
              "var(--turquoise-light)",
              "var(--turquoise-light)",
              "none",
            ]}
            px={3}
          >
            <TextLargeSemiBoldWeight
              width={1}
              px={0}
              py={[25, 25, 25, 0]}
              textAlign={["center", "center", "start", "start"]}
              lineHeight="var(--lh-text-secondary)"
            >
              {messages[value.title]} {formatGrade(value.grades)}
            </TextLargeSemiBoldWeight>
          </Div>
        </Div>

        <Div
          display="flex"
          flexDirection="column"
          backgroundColor={"var(--turquoise-light)"}
          justifyContent="center"
          alignItems="flex-start"
          mb={[10, 10, 10, 0]}
          mr={[0, 0, 0, 24]}
          p={3}
          width={["auto", "auto", "auto", 1 / 2]}
          maxHeight={[
            "-webkit-fill-available",
            "-webkit-fill-available",
            "-webkit-fill-available",
            116,
          ]}
        >
          <Text
            textAlign={["center", "center", "start", "start"]}
            lineHeight="var(--lh-text-secondary)"
          >
            {messages[value.description.title]}
            {messages[value.description.subTitle]}
            {messages[value.description.description]}
          </Text>
        </Div>

        <Div
          display="flex"
          flexDirection="column"
          justifyContent={["flex-start", "flex-start", "center", "center"]}
          alignItems={["center", "center", "flex-start", "flex-start"]}
          width={["auto", "auto", "auto", 370]}
          height={["auto", "auto", "auto", 116]}
          backgroundColor="var(--turquoise-light)"
        >
          <Text
            textAlign={["center", "center", "start", "start"]}
            p={3}
            lineHeight="var(--lh-text-secondary)"
          >
            {messages[value.footer]}
          </Text>
        </Div>
      </Div>
    </Div>
  );

  const constructGrades = (arr = []) => {
    const gradeToRender = isPrintable ? renderPrintableGrade : renderGrade;

    return arr.map(gradeToRender);
  };

  return (
    <Div>
      <TextLarge
        lineHeight={isPrintable && "150% !important"}
        fontSize={isPrintable && "var(--fs-text-s) !important"}
        fontWeight="var(--light-weight)"
      >
        {messages[description]}
      </TextLarge>
      <Div mt={3}>{constructGrades(grade)}</Div>
      <TextLarge
        display="block"
        fontSize={isPrintable && "var(--fs-text-s) !important"}
        lineHeight={
          isPrintable ? "150% !important" : "var(--lh-text-secondary)"
        }
        fontWeight="var(--light-weight)"
      >
        {messages[footer]}
      </TextLarge>
    </Div>
  );
};

StatementScale.propTypes = {
  isPrintable: PropTypes.bool,
  config: PropTypes.object.isRequired,
};

export default StatementScale;
