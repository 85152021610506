import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const PromptDialog = ({
  title,
  message,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}) => {
  const { messages } = useIntl();

  const renderHeader = () => (
    <Div display="flex" justifyContent="center">
      <H3>{title}</H3>
    </Div>
  );

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      m={[3, 3, "auto", "auto"]}
      header={renderHeader}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={3}
        pt={3}
      >
        <Text textAlign="center">{message}</Text>

        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          justifyContent="center"
          gridGap={4}
          width={1}
          px={3}
          mt={2}
        >
          <PrimaryButtonOutlined
            label={cancelLabel || messages.label_cancel}
            onClick={onCancel}
            width={[1, 1, "150px", "150px"]}
          />
          <PrimaryButton
            label={confirmLabel || messages.label_ok}
            onClick={onConfirm}
            width={[1, 1, "150px", "150px"]}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

PromptDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PromptDialog;
