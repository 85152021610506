import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import JsonForm from "@components/JsonForm/JsonForm";

const JsonFormDialog = ({
  title,
  config,
  formLayout,
  onHide,
  onSubmit,
  initialValues,
}) => {
  const { messages } = useIntl();

  const handleSubmit = (values) => {
    onSubmit(values);
    onHide();
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <H3 textAlign="center">{title}</H3>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty,isValid }) => (
          <Form>
            <JsonForm config={config} formLayout={formLayout} />
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              alignItems="center"
              justifyContent="center"
              gridGap={4}
              mt={4}
            >
              <PrimaryButtonOutlined
                width={[1, 1, "150px", "150px"]}
                label={messages.label_cancel}
                onClick={onHide}
              />
              <PrimaryButton
                width={[1, 1, "150px", "150px"]}
                label={messages.label_save}
                type="submit"
                disabled={!dirty || isSubmitting || !isValid}
              />
            </Div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

JsonFormDialog.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  formLayout: PropTypes.object,
};

export default JsonFormDialog;
