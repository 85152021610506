import React from "react";
import PropTypes from "prop-types";

import { useFormikContext } from "formik";

import AutoComplete from "@components/AutoComplete";
import Div from "@components/Div";
import { Error } from "@components/Heading";

const Autocomplete = ({
  name,
  options,
  placeholder,
  optionField,
  completeMethod,
  renderItem,
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <Div flex="1 1 40%">
      <AutoComplete
        curved
        dropdown
        hideLoader
        mt={2}
        width="100%"
        name={name}
        field={optionField}
        value={values?.[name]}
        placeholder={placeholder}
        delay={200}
        suggestions={options}
        itemTemplate={renderItem}
        onChange={handleChange}
        completeMethod={completeMethod}
        dropdownMode="current"
      />

      {errors[name] && touched[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors[name]}</Error>
        </Div>
      )}
    </Div>
  );
};

Autocomplete.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  optionField: PropTypes.string,
  placeholder: PropTypes.string,
  completeMethod: PropTypes.func,
  renderItem: PropTypes.func,
};

export default Autocomplete;
