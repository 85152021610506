import React, { Suspense, memo } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { RoutesList } from "@shared/RoutesList";
import PrivateRoute from "@shared/PrivateRoute";
import ProgressSpinner from "@components/ProgressSpinner";
import Layout from "./layout/Layout";
import { useUser } from "@contexts/AuthProvider";

const renderSelf = components =>
  components.map((Component, key) => <Component key={key} />);

const AppRoute = () => {
  const { isLoading } = useUser();

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <Router>
      <Layout>
        <Switch>
          {RoutesList.map(
            (
              {
                url: route,
                component: components,
                title: title,
                isAdmin = false,
              },
              index
            ) => (
              <PrivateRoute
                location={location}
                exact
                path={route}
                component={() => (
                  <>
                    <Suspense fallback={<ProgressSpinner />}>
                      {renderSelf(components)}
                    </Suspense>
                  </>
                )}
                key={index}
                title={title}
                isAdmin={isAdmin}
              />
            )
          )}
        </Switch>
      </Layout>
    </Router>
  );
};
export default memo(AppRoute);
