import React from "react";
import Proptypes from "prop-types";
import { locale, addLocale } from "primereact/api";
import Div from "@components/Div";
import Calendar from "@components/Calendar";
import Icon from "@components/Icon";
import { H4 } from "@components/Heading";

const UtilCalendar = ({ value }) => {
  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  let maxDate = new Date();
  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  addLocale("sv", {
    firstDayOfWeek: 1,
    dayNames: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],
    dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    dayNamesMin: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    monthNames: [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "hav",
      "apr",
      "maj",
      "juni",
      "jul",
      "jag ska",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    today: "Idag",
    clear: "Rensa",
  });

  locale("sv");

  return (
    <Div
      display={["block", "flex"]}
      key={value.id}
      py={[30, 17]}
      px={[3, 3, 3, 4]}
      mt={[16, 0]}
      borderTop={"1px solid var(--turquoise-light)"}
    >
      <Div flexBasis={"25%"} mb={[25, 25, "auto", "auto"]} mt={10}>
        <H4>{value.title}</H4>
      </Div>
      <Div display="flex" flexWrap={["wrap", "nowrap"]} width={1}>
        {value.options.map((obj, index) => (
          <Calendar
            key={index}
            id={`calendar${index}`}
            placeholder={obj.label}
            showIcon
            icon={<Icon name="calendar" />}
            value={obj.value}
            onChange={obj.onClick(value.type, obj.id)}
            readOnlyInput
            width={[1, 0.5]}
            mr={[0, 3]}
            mb={[20, 0]}
          />
        ))}
      </Div>
    </Div>
  );
};

UtilCalendar.propTypes = {
  value: Proptypes.object,
};

export default UtilCalendar;
