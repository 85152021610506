import React, { useEffect, useState, useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import Proptypes from "prop-types";
import { Formik, Form as FormikForm } from "formik";
import InputText from "@components/InputText";
import { H1, H2, H3, H4 } from "@components/Heading";
import Container from "@components/Container";
import Section from "@components/Section";
import SelectedPackageSection from "../subscription/SelectedPackageSection";
import Div from "@components/Div";
import Checkbox from "@components/ValidateCheckbox";
import Label from "@components/Label";
import Link, { LinkArrow } from "@components/Link";
import { Text, TextMediumWeight } from "@components/Text";
import { PrimaryButton, PrimaryButtonIconOutlined } from "@components/Button";
import Icon from "@components/Icon";
import EmailSchema from "@pages/auth/schema/ConfirmEmail";
import BillingEmailSchema from "@pages/auth/schema/BillingEmail";
import PasswordSchema from "@src/schema/Password";
import NameSchema from "@pages/auth/schema/NameValidation";
import NumbersSchema from "@pages/auth/schema/Numbers";
import PersonalNumbersSchema from "@pages/auth/schema/ValidatePeronalNumber";
import CheckboxSchema from "@pages/auth/schema/Checkbox";
import Dialog from "@components/Dialog";
import { authActions } from "@app/reducers/authReducer";
import { checkSubscriptionPermissions } from "@utils/common";
import Breadcrumb from "@components/Breadcrumb";
import Config from "@src/config";

import {
  COMPANY,
  INPUT,
  GENERAL_ANALYSIS,
  ROUTES,
  SUBSCRIPTION_STATUS,
  PENDING_STATUS,
  FAILED_STATUS,
  ERROR_KEY,
  PRODUCT_TYPE_SUBSCRIPTION,
  PERSONAL_DATA_POLICY,
} from "@utils/constant";
import { fetchAllProducts } from "@app/services/services/fetchProductById";
import createSubscription from "@app/services/subscription/createSubscription";
import updateSubscription from "@app/services/subscription/updateSubscription";
import registrationService from "@app/services/auth/registrationService";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import getSubscription from "@app/services/subscription/getSubscription";
import ErrorMessage from "@pages/shared/ErrorMessage";

import styled from "styled-components";
import ProgressSpinner from "@components/ProgressSpinner";
import BankIDModal from "@pages/shared/CreateOrderWizard/orderSummary/BankIDModal";
import { useBankIDLogin } from "@hooks/useBankIDLogin";
import { isMobile } from "@utils/utils";

const StyledBreadcrumb = styled(Breadcrumb)`
  .p-breadcrumb-chevron {
    display: none;
  }
`;

const SubscriptionOrderSummary = () => {
  const { messages } = useIntl();

  const [errorMessage, setErrorMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [signinByQRCode, setSigninByQRCode] = useState(false);
  const [services, setServices] = useState([]);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [serverError, setServerError] = useState({});
  const [activePackages, setActivePackages] = useState();
  const [checkoutTitle, setCheckoutTitle] = useState(
    messages.subscribing_for_credits_package,
  );
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const product_id = query.get("id");
  const history = useHistory();

  const formData = useRef(null);

  const privacyPolicyURL = `${Config.WP_URL}/${PERSONAL_DATA_POLICY}`;

  var loggedInUser = "";
  var InfoSchemaCompany = "";
  let initialData = {
    firstname: "",
    lastname: "",
    email: "",
    confirm_email: "",
    password: "",
    confirm_password: "",
    phone: "",
    company_name: "",
    registration_nr: "",
    billing_email: "",
    terms_and_condition: false,
    payment_method: "",
    personal_number: "",
    isPersonalNumber: false,
  };
  const dispatch = useDispatch();

  const getErrorMessage = useCallback(
    (errors_data) => {
      if (!errors_data) {
        return;
      }

      setPopupValue();

      const result = Object.values(INPUT.NAME);
      let error_message = "";

      Object.entries(errors_data)
        .filter(([error_key]) => error_key == ERROR_KEY)
        .forEach(([, individualError]) => {
          if (error_message != "") {
            error_message += ", ";
          }

          const errors = Object.entries(individualError).reduce(
            (acc, [key, value]) => {
              if (result.includes(key)) {
                acc.push(value);
              } else {
                error_message += `${key}-${value}`;
              }
              return acc;
            },
            [],
          );

          setServerError(errors);
        });

      setErrorMessage(messages.error + " : " + error_message);
    },
    [messages.error],
  );

  const {
    qrCodeData,
    bankIdFeedback,
    onUseBankIdApp,
    onUseQrCode,
    onCancelBankId,
  } = useBankIDLogin(showQR, getErrorMessage);

  const userInfo = useSelector((state) => state.authReducer.userInfo);

  useEffect(() => {
    const loadActiveSubscriptionData = async () => {
      if (userInfo.customer) {
        const customer_id = userInfo.customer.id;
        const servicesResponse = await getSubscription(customer_id);
        const { data: { data = [] } = {} } = servicesResponse;
        let [currentPackage = false] = data;
        setActivePackages(currentPackage);
      }
    };
    loadActiveSubscriptionData();
  }, [userInfo]);

  useEffect(() => {
    if (activePackages && activePackages.status == SUBSCRIPTION_STATUS.ACTIVE) {
      setCheckoutTitle(messages.checkout_upgrade_title);
    }
  }, [activePackages, messages]);

  if (Object.keys(userInfo).length > 0) {
    let message = "";
    let subscription = activePackages; // Endpoint to get subscription  data
    message = checkSubscriptionPermissions(
      subscription,
      userInfo,
      message,
      messages,
      history,
    );
    if (message != "") {
      history.push(`${ROUTES.ACCESSDENIED.URL}`, {
        message: message,
      });
    } else {
      loggedInUser = true;
      initialData = {
        terms_and_condition: false,
        personal_number: "",
        isPersonalNumber: false,
      };
    }
  }

  const handleQRPopup = () => {
    setShowQR(!showQR);
  };

  const handleQRCodeSignin = useCallback(() => {
    setSigninByQRCode(!signinByQRCode);
  }, [signinByQRCode]);

  const {
    EMAIL,
    CONFIRM_EMAIL,
    PHONE,
    PASSWORD,
    NAME,
    LAST_NAME,
    COMPANY_NAME,
    COMPANY_NUMBER,
    PERSONAL_NUMBER,
    CONFIRM_PASSWORD,
    TERM_CONDITION,
    BILLING_EMAIL,
  } = INPUT.NAME;

  if (!loggedInUser) {
    InfoSchemaCompany = EmailSchema.concat(
      EmailSchema.pick([CONFIRM_EMAIL])
        .concat(NameSchema.pick([NAME, LAST_NAME]))
        .concat(PasswordSchema.pick([PASSWORD, CONFIRM_PASSWORD]))
        .concat(NumbersSchema.pick([PHONE])),
    )
      .concat(BillingEmailSchema.pick([BILLING_EMAIL]))
      .concat(
        NameSchema.pick([COMPANY_NAME]).concat(
          NumbersSchema.pick([COMPANY_NUMBER]),
        ),
      )
      .concat(NameSchema.concat(CheckboxSchema.pick([TERM_CONDITION])))
      .concat(NameSchema.concat(PersonalNumbersSchema.pick([PERSONAL_NUMBER])));
  }

  const { TEXT } = INPUT.TYPE;

  const disableCopyPaste = (e) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    const loadServicesData = async () => {
      const type = PRODUCT_TYPE_SUBSCRIPTION;

      let customer_id = "";
      if (loggedInUser) {
        customer_id = userInfo?.customer?.id;
      }
      const servicesResponse = await fetchAllProducts({ type, customer_id });
      const {
        data: { data },
      } = servicesResponse;
      const filteredList = data.find((service) => service.id == product_id);
      setServices(filteredList);
    };
    loadServicesData();
  }, [product_id, userInfo, loggedInUser]);

  const createUpdateSubscription = useCallback(
    async (user_info, payload = {}) => {
      let subscription_data = {};
      let user = user_info.user;

      if (!loggedInUser) {
        const type = COMPANY;
        const {
          billing_email,
          company_name,
          confirm_password,
          email,
          firstname,
          lastname,
          password,
          phone,
          registration_nr,
        } = formData.current;

        try {
          const userResponse = await registrationService({
            type: type,
            analysis_type: GENERAL_ANALYSIS,
            billing_email,
            company_name,
            confirm_password,
            email,
            firstname,
            lastname,
            password,
            phone,
            registration_nr,
          });

          user = userResponse.data.user;
        } catch (error) {
          setErrorMessage(messages.message_unknown_account_check_email);
        }
      }

      subscription_data = {
        product_id: services.id,
        price: services.price,
        user_id: user.id,
        customer_id: user.customer.id,
        signed_by: payload.personal_number,
      };

      if (
        activePackages &&
        activePackages.status != SUBSCRIPTION_STATUS.TERMINATED
      ) {
        subscription_data.status = activePackages.status;
        await updateSubscription(subscription_data, activePackages.id);
      } else {
        await createSubscription(subscription_data);
        if (user_info.token) {
          localStorage.setItem("token", user_info.token);
          dispatch(authActions.storeUserInfo(user));
        }
      }

      history.push(ROUTES.SUBSCRIPTION_ORDER_CONFIRMATION.URL);
    },
    [
      activePackages,
      dispatch,
      services.id,
      services.price,
      history,
      loggedInUser,
      messages.message_unknown_account_check_email,
    ],
  );

  useEffect(() => {
    if (
      !bankIdFeedback.status ||
      [PENDING_STATUS, FAILED_STATUS].includes(bankIdFeedback.status)
    ) {
      return;
    }

    const userDetails = {
      user: userInfo,
    };
    const payload = {
      personal_number: bankIdFeedback.personalNumber,
      orderRef: bankIdFeedback.orderRef,
    };

    setShowQR(false);

    createUpdateSubscription(userDetails, payload);
  }, [bankIdFeedback, userInfo, createUpdateSubscription]);

  const setPopupValue = () => {
    setShowQR((showQr) => !showQr);
  };

  const handleOnSubscriptionOrderSubmitted = (data) => {
    setIsLoading(true);
    setErrorMessage("");

    formData.current = data;

    if (isMobile) {
      onUseBankIdApp();
    } else {
      onUseQrCode();
      handleQRPopup();
    }

    setIsLoading(false);
  };

  const handleTogglePassword = (label) => () => () => {
    if (label === CONFIRM_PASSWORD) {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const constructBreadcrumbProps = {
    items: [
      {
        label: messages.label_back,
        url: `${Config.WP_URL}${ROUTES.WP_SUBSCRIPTION.URL}`,
      },
    ],
    home: {
      icon: "icon-headerarrowright",
    },
  };

  const handleTryAgain = () => {
    onCancelBankId();
    handleQRPopup();

    setTimeout(() => {
      onUseQrCode();
      setShowQR(true);
    }, 1000);
  };

  const handleCancel = () => {
    onCancelBankId();
    handleQRPopup();
  };

  const handleTryOnAnotherDevice = () => {
    setIsLoading(true);
    setErrorMessage("");

    onUseQrCode();
    handleQRPopup();

    setIsLoading(false);
  };

  return (
    <Div width={[1]}>
      <Section
        pt={["30px", "50px", "55px", "55px"]}
        pb={[["30px", "50px", "55px", "55px"]]}
      >
        <Container>
          <StyledBreadcrumb p={0} py={3} {...constructBreadcrumbProps} />
          <H1 mb={4}>{messages.title_checkout_register}</H1>
          <Div mb={"38px"}>
            <TextMediumWeight fontSize="var(--fs-text)">
              {checkoutTitle}
            </TextMediumWeight>
          </Div>
          <SelectedPackageSection
            activePackages={activePackages}
            services={services}
            subscriptionStatus={SUBSCRIPTION_STATUS}
          />
          <Div px={3} mt={["30px", "50px", "50px", "50px"]}>
            {!loggedInUser && (
              <Div
                mb={"30px"}
                textAlign={["center", "center", "center", "left"]}
              >
                <H2 color="var(--blue-dark) !important" mb={"30px"}>
                  {messages.Registration_heading}
                </H2>
                <H4>{messages.checkout_registration_label}</H4>
              </Div>
            )}
            <Formik
              initialValues={initialData}
              validationSchema={InfoSchemaCompany}
              onSubmit={handleOnSubscriptionOrderSubmitted}
            >
              {(formik) => {
                const { values } = formik;

                return (
                  <FormikForm
                    textAlign={["center", "center", "center", "left"]}
                  >
                    {!loggedInUser && (
                      <Div>
                        <H3>{messages.checkout_registration__fields_label}</H3>

                        <Div width={[1, 1 / 1, 2 / 3]}>
                          <Div
                            display="flex"
                            name={NAME}
                            flexWrap="wrap"
                            alignItems="start"
                          >
                            <Div pr={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_firstname}
                                label={`${messages.label_firstname} *`}
                                value={values.firstname}
                                name={NAME}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pl={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_last_name}
                                label={`${messages.label_last_name} *`}
                                value={values.lastname}
                                name={LAST_NAME}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pr={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_email}
                                label={`${messages.label_email} *`}
                                onPaste={disableCopyPaste}
                                onCopy={disableCopyPaste}
                                value={values.email}
                                name={EMAIL}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pl={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_repeat_email}
                                label={`${messages.label_repeat_email} *`}
                                onPaste={disableCopyPaste}
                                onCopy={disableCopyPaste}
                                value={values.confirm_email}
                                name={CONFIRM_EMAIL}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pr={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                isPassword
                                curved
                                placeholder={messages.label_set_password}
                                label={`${messages.label_set_password} *`}
                                onPaste={disableCopyPaste}
                                onCopy={disableCopyPaste}
                                value={values.password}
                                name={PASSWORD}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                                medium
                                onTogglePassword={handleTogglePassword(
                                  PASSWORD,
                                )}
                                showPassword={showPassword}
                              />
                            </Div>
                            <Div pl={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                isPassword
                                curved
                                placeholder={messages.label_repeat_password}
                                label={`${messages.label_repeat_password} *`}
                                onPaste={disableCopyPaste}
                                onCopy={disableCopyPaste}
                                value={values.confirm_password}
                                name={CONFIRM_PASSWORD}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                                medium
                                onTogglePassword={handleTogglePassword(
                                  CONFIRM_PASSWORD,
                                )}
                                showPassword={showConfirmPassword}
                              />
                            </Div>
                            <Div pr={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder="+46 ..."
                                label={messages.label_phone}
                                value={values.phone}
                                name={PHONE}
                                formikProps={formik}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                          </Div>
                        </Div>
                        <H3 mt={"35px"}>{messages.label_company_info}</H3>
                        <Div width={[1, 1 / 1, 2 / 3]}>
                          <Div
                            display="flex"
                            flexWrap="wrap"
                            alignItems="start"
                          >
                            <Div pr={[0, 0, 0, 3]} width={[1, 1 / 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_company_name}
                                label={`${messages.label_company_name} *`}
                                value={values.company_name}
                                name={COMPANY_NAME}
                                formikProps={formik}
                                type={TEXT}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pl={[0, 0, 0, 3]} width={[1, 1, 1 / 2]}>
                              <InputText
                                curved
                                placeholder={messages.label_company_number}
                                label={`${messages.label_company_number} *`}
                                value={values.registration_nr}
                                name={COMPANY_NUMBER}
                                type={TEXT}
                                formikProps={formik}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                            <Div pr={[0, 0, 0, 3]} width={[1, 1, 1 / 2]}>
                              <InputText
                                curved
                                type={TEXT}
                                placeholder={messages.label_billing_email}
                                label={messages.label_billing_email}
                                value={values.billing_email}
                                name={BILLING_EMAIL}
                                formikProps={formik}
                                labelAlignment="left"
                                width={1}
                              />
                            </Div>
                          </Div>
                        </Div>
                        <Div display="flex" mt={4} mb={3}>
                          <Checkbox
                            inputId="register_check"
                            checked={checked}
                            name={TERM_CONDITION}
                            onChange={(e) => {
                              setChecked(e.checked);
                            }}
                            formikProps={formik}
                          />
                          <Label htmlFor="register_check" ml={10}>
                            {messages.text_agree_terms_and_conditions}
                            <Link
                              href={`${Config.WP_URL}/allmannavillkor/`}
                              label={messages.text_terms_and_conditions}
                              color="var(--grey-dark) !important"
                              fontSize="var(--fs-h4) !important"
                              fontWeight="var(--medium-weight) !important"
                              textDecoration="underline"
                              target="_blank"
                              px="4px"
                              divDisplay="contents"
                            />
                            {messages.text_and}
                            <Link
                              href={privacyPolicyURL}
                              label={messages.text_privacy_policy}
                              color="var(--grey-dark) !important"
                              fontSize="var(--fs-h4) !important"
                              fontWeight="var(--medium-weight) !important"
                              textDecoration="underline"
                              target="_blank"
                              pl="4px"
                              divDisplay="contents"
                            />
                          </Label>
                        </Div>
                      </Div>
                    )}
                    {errorMessage && (
                      <Div display="flex" justifyContent="left">
                        <ErrorMessage errorMessage={errorMessage} />
                      </Div>
                    )}
                    {serverError.length &&
                      serverError.map((error, key) => {
                        return (
                          <Div display="flex" justifyContent="left" key={key}>
                            <ErrorMessage errorMessage={error} />
                          </Div>
                        );
                      })}
                    {isLoading && <ProgressSpinner />}
                    <Div
                      bg="var(--blue-lightest)"
                      mx={[0, 0, 0, 15]}
                      mt={40}
                      px={[0, 0, 0, 30]}
                      py={30}
                    >
                      <Div
                        display="flex"
                        flexDirection="column"
                        gridGap={15}
                        px={20}
                      >
                        <H3>{messages.label_BankID.toUpperCase()}</H3>
                        <Text>{messages.message_bankID}</Text>
                        <PrimaryButton
                          mt={15}
                          width={[1, 1, "fit-content"]}
                          justifyContent="center"
                          minHeight="40px"
                          px={30}
                        >
                          {messages.label_use_bankID_app}
                        </PrimaryButton>
                        {isMobile && (
                          <PrimaryButtonIconOutlined
                            minHeight="40px"
                            onClick={handleTryOnAnotherDevice}
                            type="button"
                          >
                            {messages.label_use_bankID_on_another_device}
                          </PrimaryButtonIconOutlined>
                        )}
                      </Div>
                      {showQR && (
                        <BankIDModal
                          hideModal={handleCancel}
                          qrCodeData={qrCodeData}
                          bankIDFeedback={bankIdFeedback}
                          onTryAgain={handleTryAgain}
                        />
                      )}
                    </Div>
                  </FormikForm>
                );
              }}
            </Formik>
          </Div>
        </Container>
      </Section>
      <Section
        backgroundColor={"rgba(var(--grey-lightest-rgba), 0.4)"}
        px={"15px !important"}
        py={50}
      >
        <Container>
          <Div
            display="flex"
            justifyContent="left"
            flexDirection="column"
            alignItems="left"
            textAlign={["center", "left"]}
          >
            <Div width={[1]}>
              <H2 display={"block"}>{messages.label_existing_user}</H2>
            </Div>
            <Div mt={3} pt={1}>
              <LinkArrow
                justifyContent={["center", "left"]}
                label={messages.label_to_login_page}
              >
                <Icon ml={1} name="headerarrowright" />
              </LinkArrow>
            </Div>
          </Div>
        </Container>
        {signinByQRCode && (
          <>
            {userInfo.role === "admin" ? (
              <Dialog
                visible="displayBasic"
                draggable={false}
                width={[1, 1, 1, 500]}
                onHide={handleQRCodeSignin}
                m={[3, 3, 3, "auto"]}
              >
                <Div mt={2} textAlign={["center"]}>
                  <Text>{messages.label_cannot_proceed}</Text>
                </Div>
                <Div mt={3} textAlign={["center"]}>
                  <Text>{messages.admin_login_popup_info}</Text>
                </Div>
              </Dialog>
            ) : (
              <Dialog
                visible="displayBasic"
                draggable={false}
                width={[1, 1, 1, 500]}
                onHide={handleQRCodeSignin}
                m={[3, 3, 3, "auto"]}
              >
                <Div mt={2} textAlign={["center"]}>
                  <Text>{messages.verify_text_message}</Text>
                </Div>
              </Dialog>
            )}
          </>
        )}
      </Section>
    </Div>
  );
};
SubscriptionOrderSummary.propTypes = {
  orderSummaryProps: Proptypes.array,
};
export default SubscriptionOrderSummary;
