import React from "react";
import Div from "@components/Div";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";

export const DoneModal = ({
  handleConfirmPopup = {},
  content = "",
  okLabel = null,
  cancelLabel = null,
  onCancel = null,
  title = null,
}) => {
  const { messages } = useIntl();
  return (
    <Div
      display="flex"
      alignItems="center"
      flexDirection="column"
      mt={3}
      pt={1}
    >
      <TextLargeSemiBoldWeight mb={2}>
        {`${title || messages.done_popup_header}`}
      </TextLargeSemiBoldWeight>
      <Text textAlign="center">{content || messages.done_popup_paragraph}</Text>
      <Div my={3}>
        {onCancel && (
          <PrimaryButtonOutlined
            py={2}
            px={4}
            mt={2}
            mr={[0, 4]}
            label={cancelLabel || messages.watchlist_label_no}
            onClick={onCancel}
            width={[1, 160]}
          />
        )}
        <PrimaryButton
          py={2}
          px={4}
          mt={2}
          width={[1, "150px"]}
          label={okLabel || messages.label_ok}
          onClick={handleConfirmPopup}
        />
      </Div>
    </Div>
  );
};

DoneModal.propTypes = {
  handleConfirmPopup: Proptypes.func,
  content: Proptypes.string,
  okLabel: Proptypes.string,
  cancelLabel: Proptypes.string,
  onCancel: Proptypes.func,
  title: Proptypes.string,
};

export default DoneModal;
