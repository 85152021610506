import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";
import Tabs from "@components/Tabs";

import useAuthorization from "@hooks/useAuthorization";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import DetailsTab from "./tabs/DetailsTab";
import SourceDataTab from "./tabs/SourceDataTab";
import ReportTab from "./tabs/ReportTab";
import CommentsTab from "./tabs/CommentsTab";
import ActivityLogsTab from "./tabs/ActivityLogsTab";

const Details = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const history = useHistory();

  const hasReadDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminReadDataSources,
  ]);
  const hasReadReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  const tabContent = useMemo(
    () => [
      {
        title: messages.label_details,
        content: <DetailsTab />,
        url: ROUTES.ADMIN_CASE_DETAILS.URL.replace(":case_id", case_id),
      },
      {
        title: messages.label_source_data,
        content: <SourceDataTab />,
        url: ROUTES.ADMIN_CASE_SOURCE_DATA.URL.replace(":case_id", case_id),
        isHidden: !hasReadDataSourcesPermission,
      },
      {
        title: messages.label_report,
        content: <ReportTab />,
        url: ROUTES.ADMIN_CASE_REPORT.URL.replace(":case_id", case_id),
        isHidden: !hasReadReportAnalysisPermission,
      },
      {
        title: messages.label_comments,
        content: <CommentsTab />,
        url: ROUTES.ADMIN_CASE_COMMENTS.URL.replace(":case_id", case_id),
      },
      {
        title: messages.activity_log,
        content: <ActivityLogsTab />,
        url: ROUTES.ADMIN_CASE_ACTIVITY_LOG.URL.replace(":case_id", case_id),
      },
    ],
    [
      messages,
      case_id,
      hasReadDataSourcesPermission,
      hasReadReportAnalysisPermission,
    ],
  );

  const _activeTabIndex = Math.max(
    tabContent.findIndex((item) => item.url === history?.location?.pathname),
    0,
  );
  const [activeTabIndex, setActiveTabIndex] = useState(_activeTabIndex);

  const handleTabChange = (event) => {
    const { index } = event;
    const targetUrl = tabContent[index]?.url;

    const activeTabIndex = Math.max(
      tabContent.findIndex((item) => item.url === targetUrl),
      0,
    );
    setActiveTabIndex(activeTabIndex);
    window.history.pushState(null, '', targetUrl);
  };

  const formattedId = `#${case_id}`;

  const headerConfig = {
    title: `${messages.label_case} ${formattedId}`,
  };

  const breadcrumbItems = [
    {
      label: messages.title_cases,
      url: ROUTES.ADMIN_CASES_LIST.URL,
    },
    {
      label: `${messages.label_case_details}: ${formattedId}`,
    },
  ];

  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={breadcrumbItems} pt={0} pl={0} />
      <Tabs
        content={tabContent}
        activeIndex={activeTabIndex}
        onTabChange={handleTabChange}
      />
    </AdminContainer>
  );
};

export default Details;
