import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import CardWrapper from "@pages/admin/users/components/CardWrapper";
import { INVOICE_TYPE } from "@utils/enum";
import { Formik, Form } from "formik";
import { createBillingAccountService } from "@app/services/billingAccounts/createBillingAccountService";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import JsonForm from "@components/JsonForm/JsonForm";
import * as Yup from "yup";
import countries from "@utils/countries.json";

import { ValidateOrganisationNumber ,isAdminUser,removeZeroValuesFromObject} from "@utils/utils";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";

const StyledCreateBilling = styled.div`
  max-width: 1110px;
`;
const mappedCountries = countries.map((country) => ({
  label: country.name,
  value: country.code,
}));

const UserBillingAccountDialog = ({ handleClose }) => {
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer: { id: userCustomerId = "" } = {} } = userInfo || {};
  const { id: customerIdFromParam = "" } = useParams();


  const basicInformation = [
    {
      label: <FormattedMessage id="label_company_name" />,
      fieldType: "text",
      name: "name",
      placeholder: <FormattedMessage id="label_company_name" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_company_name" />,
      ),
    },
    {
      label: <FormattedMessage id="label_organisation_number" />,
      fieldType: "text",
      name: "organisation_number",
      placeholder: <FormattedMessage id="label_organisation_number" />,
      validation: Yup.string()
        .required(<FormattedMessage id="validation_valid_company_number" />)
        .test({
          name: "valid-company-number",
          message: <FormattedMessage id="validation_valid_company_number" />,
          test: ValidateOrganisationNumber,
        }),
    },
    {
      label: <FormattedMessage id="label_invoice_address_line1" />,
      fieldType: "text",
      name: "address_1",
      placeholder: <FormattedMessage id="label_invoice_address_line1" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_address_1" />,
      ),
    },
    {
      label: <FormattedMessage id="label_invoice_address_line2" />,
      fieldType: "text",
      name: "address_2",
      placeholder: <FormattedMessage id="label_invoice_address_line2" />,
    },
    {
      label: <FormattedMessage id="label_postal_code" />,
      fieldType: "text",
      name: "zip_code",
      placeholder: <FormattedMessage id="label_postal_code" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_zip_code" />,
      ),
    },
    {
      label: <FormattedMessage id="label_city" />,
      fieldType: "text",
      name: "city",
      placeholder: <FormattedMessage id="label_city" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_city" />,
      ),
    },
    {
      label: <FormattedMessage id="label_country" />,
      fieldType: "dropdown",
      name: "country_code",
      placeholder: <FormattedMessage id="label_country" />,
      options: mappedCountries,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_country_name" />,
      ),
    },
  ];

  const invoiceInformation = [
    {
      label: <FormattedMessage id="label_your_reference_person" />,
      fieldType: "text",
      name: "your_reference",
      placeholder: <FormattedMessage id="label_your_reference_person" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_your_reference" />,
      ),
    },
    {
      label: <FormattedMessage id="heading_invoice_reference" />,
      fieldType: "text",
      name: "invoice_remark",
      placeholder: <FormattedMessage id="heading_invoice_reference" />,
    },
  ];
  const INVOICE_TYPE_OPTIONS = [
    {
      value: INVOICE_TYPE.E_INVOICE,
      label: messages.label_e_invoice,
    },
    {
      value: INVOICE_TYPE.EMAIL_INVOICE,
      label: messages.label_email_invoice,
    },
  ];

  const billingInformation = [
    {
      label: <FormattedMessage id="label_invoice_type" />,
      fieldType: "dropdown",
      name: "invoice_type",
      placeholder: <FormattedMessage id="label_invoice_type" />,
      options: INVOICE_TYPE_OPTIONS,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_invoice_type" />,
      ),
    },

    {
      label: <FormattedMessage id="label_edi_identification" />,
      fieldType: "text",
      name: "edi_identification",
      placeholder: <FormattedMessage id="label_edi_identification" />,
      show: (values) => values.invoice_type === INVOICE_TYPE.E_INVOICE,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_edi_identification" />,
      ),
    },
    {
      label: <FormattedMessage id="label_electronic_address_in_peppol" />,
      fieldType: "text",
      name: "peppol_address",
      placeholder: <FormattedMessage id="label_electronic_address_in_peppol" />,
      show: (values) => values.invoice_type === INVOICE_TYPE.E_INVOICE,
      validation: Yup.string().required(
        <FormattedMessage id="validation_Electronic_Address_Peppol" />,
      ),
    },
    {
      label: <FormattedMessage id="label_gln_number" />,
      fieldType: "text",
      name: "gln",
      placeholder: <FormattedMessage id="label_gln_number" />,
      show: (values) => values.invoice_type === INVOICE_TYPE.E_INVOICE,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_glm" />,
      ),
    },
    {
      label: <FormattedMessage id="label_van_operator" />,
      fieldType: "text",
      name: "van_operator",
      placeholder: <FormattedMessage id="label_van_operator" />,
      show: (values) => values.invoice_type === INVOICE_TYPE.E_INVOICE,
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_van_operator" />,
      ),
    },
    {
      label: <FormattedMessage id="header_billing_email" />,
      fieldType: "text",
      name: "email_invoice",
      placeholder: <FormattedMessage id="header_billing_email" />,
      show: (values) => values.invoice_type === INVOICE_TYPE.EMAIL_INVOICE,
      validation: Yup.string()
        .required(<FormattedMessage id="validation_empty_email_input" />)
        .email(<FormattedMessage id="validation_valid_email_input" />),
    },
  ];

  const initialValues = {
    name: "",
    organisation_number: "",
    address_1: "",
    address_2: "",
    zip_code: "",
    city: "",
    country_code: "",
    your_reference: "",
    invoice_remark: "",
    invoice_type: "",
    edi_identification: "",
    peppol_address: "",
    gln: "",
    van_operator: "",
    email_invoice: "",
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };


  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      let payload = {
        ...values,
        organisation_number: values.organisation_number,
      };

      let customer_id = "";

      if (isAdminUser()) {
        customer_id = customerIdFromParam;
      } else {
        customer_id = userCustomerId;
      }

      payload.customer_id = customer_id;
      const updatedPayload = removeZeroValuesFromObject(payload);
      await createBillingAccountService(updatedPayload);
      showSuccessToast(messages.label_billing_account_creation_success);
      if (handleClose) handleClose();
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    }
    setIsLoading(false);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}
      <FullSizeDialog
        title={messages.title_create_billing_account}
        onClose={handleClose}
      >
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ isSubmitting, dirty, isValid }) => (
            <StyledCreateBilling>
              <Form>
                <CardWrapper title={messages.heading_basic_information}>
                  <JsonForm config={basicInformation} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.heading_invoice_reference}>
                  <JsonForm
                    config={invoiceInformation}
                    formLayout={formLayout}
                  />
                </CardWrapper>

                <CardWrapper title={messages.title_billing}>
                  <JsonForm
                    config={billingInformation}
                    formLayout={formLayout}
                  />
                </CardWrapper>

                <Div
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  alignItems="center"
                  justifyContent="center"
                  gridGap={4}
                  mt={4}
                >
                  <PrimaryButtonOutlined
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_cancel}
                    onClick={handleClose}
                  />
                  <PrimaryButton
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_save}
                    type="submit"
                    disabled={!dirty || isSubmitting || !isValid}
                  />
                </Div>
              </Form>
            </StyledCreateBilling>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};

UserBillingAccountDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default UserBillingAccountDialog;
