import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { Error } from "@components/Heading";
import { TextMediumWeight } from "@components/Text";

const InputWrapper = ({
  name,
  label,
  required,
  children,
  formikProps,
  ...rest
}) => {
  const { errors, touched } = formikProps || {};

  return (
    <Div mt={3} pt={1} {...rest}>
      <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>

      <Div>{children}</Div>

      {errors?.[name] && touched?.[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[name]}</Error>
        </Div>
      )}
    </Div>
  );
};

InputWrapper.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  formikProps: PropTypes.object,
  children: PropTypes.node,
};

export default InputWrapper;
