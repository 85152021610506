import React, { useEffect, useState } from "react";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import ApplyDiscount from "@components/ApplyDiscount";
import Divider from "@components/Divider";
import { H1, H2, H3, H4 } from "@components/Heading";
import { Text, TextLarge } from "@components/Text";
import Card from "@components/Card";
import { ColouredSemiBoldText } from "@components/Text";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { useIntl } from "react-intl";
import getSubscription from "@app/services/subscription/getSubscription";
import { useSelector } from "react-redux";
import { ROUTES, SUBSCRIPTION_STATUS } from "@utils/constant";
import { useHistory } from "react-router-dom";
const SubscriptionResult = () => {
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const [activePackages, setActivePackages] = useState(false);
  useEffect(() => {
    const loadActiveSubscriptionData = async () => {
      if (userInfo.customer) {
        const customer_id = userInfo;
        const servicesResponse = await getSubscription(customer_id);
        const {
          data: { data },
        } = servicesResponse;
        const current_package = data[0] ? data[0] : false;
        setActivePackages(current_package);
      }
    };
    loadActiveSubscriptionData();
  }, [userInfo]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { messages } = useIntl();
  const history = useHistory();
  const redirectOnSubmit = (url) => {
    history.push(url);
  };
  return (
    <>
      <Div width={1}>
        <Section p={[4, 8]}>
          <Container>
            <Div mb={4}>
              <H1 mb={3} pb={2}>
                {messages.subscription_confirmation}
              </H1>
              <TextLarge>
                {messages.subscription_your_payment_is_approved}
              </TextLarge>
            </Div>
            <Divider />
            <Div>
              <H2 mt={4}>{messages.subscription}</H2>
              <Card width={[1, 1, 8 / 12]} mt={4} p={4}>
                <H3 mb={3}>{activePackages.name}</H3>
                <Text>{messages.subscription_status}</Text>
                <Div
                  width={1}
                  display="flex"
                  justifyContent="space-between"
                  mb={4}
                >
                  <H4>
                    {activePackages.status === SUBSCRIPTION_STATUS.ACTIVE
                      ? messages.active
                      : activePackages.status === SUBSCRIPTION_STATUS.STOPPED
                      ? messages.stopped_label
                      : activePackages.status === SUBSCRIPTION_STATUS.TERMINATED
                      ? messages.terminated_label
                      : activePackages.status}
                  </H4>
                </Div>
                <Text>{messages.subscription_include}</Text>
                <Div
                  width={[1, 12, 12 / 12]}
                  display="flex"
                  justifyContent="space-between"
                >
                  <H4>
                    {messages.subscription_analysis}{" "}
                    <ColouredSemiBoldText>
                      {activePackages.credits}
                    </ColouredSemiBoldText>
                  </H4>
                </Div>
                <ApplyDiscount />
                <Div display="flex" mt={[2, 4]} pt={1}>
                  <LinkArrow
                    mr={2}
                    label={messages.subscription_create_order}
                    direction="left"
                    variant="secondary"
                    onClick={redirectOnSubmit.bind(this, ROUTES.SEARCH.URL)}
                  >
                    <Icon ml={1} name="headerarrowright" />
                  </LinkArrow>
                  <LinkArrow
                    label={messages.subscription_to_my_profile}
                    direction="left"
                    variant="secondary"
                    onClick={redirectOnSubmit.bind(
                      this,
                      ROUTES.COMPANY_INFO.URL,
                    )}
                  >
                    <Icon ml={1} name="headerarrowright" />
                  </LinkArrow>
                </Div>
              </Card>
            </Div>
          </Container>
        </Section>
        <Section
          backgroundColor={"rgba(var(--grey-lightest-rgba), 0.4)"}
          px={2}
          py={50}
        >
          <Container>
            <Card width={[1, 1, 8 / 12]} mt={4} mb={5} ml="40px" p={4}>
              <H3 mb={3}>{messages.subscription_need_interview}</H3>
              <Text>{messages.dummy_text}</Text>
              <LinkArrow
                mt={2}
                label={messages.subscription_order_interview}
                direction="left"
                variant="secondary"
              >
                <Icon ml={1} name="headerarrowright" />
              </LinkArrow>
            </Card>
            <Div
              display="flex"
              justifyContent="left"
              flexDirection="column"
              alignItems="left"
              textAlign="left"
              ml={"40px"}
            >
              <Div width={[1, 1, 8 / 12]}>
                <H2 mb={3} display={"block"}>
                  {messages.subscription_do_you_really_want_to_place_order}
                </H2>
                <Text>{messages.dummy_text}</Text>
              </Div>
              <Div mt={3} pt={1}>
                <PrimaryButtonOutlined
                  onClick={redirectOnSubmit.bind(this, ROUTES.SEARCH.URL)}
                >
                  {messages.subscription_make_new_order}
                </PrimaryButtonOutlined>
                <PrimaryButton
                  ml={4}
                  onClick={redirectOnSubmit.bind(this, ROUTES.COMPANY_INFO.URL)}
                >
                  {messages.subscription_to_my_profile}
                </PrimaryButton>
              </Div>
            </Div>
          </Container>
        </Section>
      </Div>
    </>
  );
};

export default SubscriptionResult;
