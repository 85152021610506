import React from "react";
import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Span from "@components/Span";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";

const SaveAndSendDialog = ({
  handleCloseDialog = () => {},
  handleSave = () => {},
}) => {
  const { messages } = useIntl();

  const dialogHeader = () => (
    <Div textAlign="center" width={1}>
      <Span upper semibold fontSize="var(--fs-h4) !important">
        {messages.title_save_send}
      </Span>
    </Div>
  );

  return (
    <Dialog
      header={dialogHeader}
      visible="displayBasic"
      draggable={false}
      onHide={handleCloseDialog}
      width={["90%", "90%", "90%", 445]}
    >
      <>
        <Div
          alignItems="center"
          lineHeight="130%"
          display="flex"
          flexDirection="column"
          mt={30}
        >
          <Span medium light>
            {messages.description_save_send}
          </Span>
        </Div>
        <Div
          mt={27}
          display="flex"
          justifyContent="center"
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
        >
          <PrimaryButtonOutlined
            rounded
            semibold
            label={messages.label_cancel}
            onClick={handleCloseDialog}
            mr={[0, 0, 0, 30]}
            width={[1, 1, 1, "45%"]}
          />
          <PrimaryButton
            rounded
            semibold
            label={messages.button_confirm}
            onClick={handleSave}
            mb={[4, 4, 4, 0]}
            width={[1, 1, 1, "45%"]}
          />
        </Div>
      </>
    </Dialog>
  );
};

SaveAndSendDialog.propTypes = {
  handleCloseDialog: PropTypes.func,
  handleSave: PropTypes.func,
};

export default SaveAndSendDialog;
