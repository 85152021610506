import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import FullSizeDialog from "@components/FullSizeDialog";
import Div from "@components/Div";
import { unFlattenObject, formatDateAndTime, translateObjectKeys } from "@utils/utils";
import { PrimaryButtonOutlined } from "@components/Button";
import { TextMediumWeight } from "@components/Text";
import { H4 } from "@components/Heading";
import { allExpanded, JsonView } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { TYPE_UPDATED } from "@utils/constant";

const Details = ({ handleClose, data }) => {
  const { messages } = useIntl();
  const activityDetails = unFlattenObject(data);
  const {
    new_values,
    old_values,
    created_at,
    type = "",
    event = "",
  } = activityDetails;

  const newValues = translateObjectKeys(new_values);
  const oldValues = translateObjectKeys(old_values);
  return (
    <FullSizeDialog
      title={`${messages.label_details}: ${messages[type || event]}, ${formatDateAndTime(created_at)}`}
      onClose={handleClose}
    >
      <Div bg={"var(--bg-dark-grey)"} p={"24px 32px"} borderRadius={"10px"}>
        <H4
          mb={3}
          color={"var(--blue-dark) !important"}
        >{`${messages.title_data}:`}</H4>
        <Div
          display="flex"
          flexDirection={["column", "column", "column", "row"]}
        >
          {(type === TYPE_UPDATED || event === TYPE_UPDATED) && (
            <Div display="flex" width={[1, 1, 1, 1 / 2]} mb={[3, 3, 3, 0]}>
              <TextMediumWeight
                whiteSpace={"nowrap"}
                fontSize={"var(--fs-text-m) !important"}
              >
                {`${messages.old_values}: `}
              </TextMediumWeight>
              <TextMediumWeight fontSize={"var(--fs-text-m) !important"}>
                <JsonView data={oldValues} shouldExpandNode={allExpanded} />
              </TextMediumWeight>
            </Div>
          )}
          <Div display="flex" width={[1, 1, 1, 1 / 2]}>
            <TextMediumWeight
              whiteSpace={"nowrap"}
              fontSize={"var(--fs-text-m) !important"}
            >
              {`${messages.new_values}: `}
            </TextMediumWeight>
            <TextMediumWeight fontSize={"var(--fs-text-m) !important"}>
              <JsonView data={newValues} shouldExpandNode={allExpanded} />
            </TextMediumWeight>
          </Div>
        </Div>
      </Div>
      <PrimaryButtonOutlined
        rounded
        semibold
        label={messages.label_back}
        onClick={handleClose}
        mt={4}
        width={[1, "auto"]}
      />
    </FullSizeDialog>
  );
};
Details.propTypes = {
  handleClose: PropTypes.func,
  data: PropTypes.object,
};
export default Details;
