import React from "react";
import MultiSelect from "@components/MultiSelect";
import Div from "@components/Div";
import { H4 } from "@components/Heading";

const InputText = (prop) => {
  const { type, onChange, value, id, options, name, width } = prop;

  return (
    <>
      <Div
        display={["block", "block", "block", "flex"]}
        key={id}
        py={[30, 17]}
        px={[3, 3, 3, 4]}
        borderTop={"1px solid var(--turquoise-light)"}
      >
        <Div flexBasis={"25%"} my={[11, 11, 11, 14]} mt={[30, 30, 30, 14]}>
          <H4>{name}:</H4>
        </Div>
        <Div
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems={"center"}
          width={1}
          mb={14}
          mt={1}
        >
          <Div display="flex" width={1}>
            {
              <MultiSelect
                {...prop}
                type={type}
                onChange={onChange(type, id)}
                width={width}
                borderRadius={10}
                value={value}
                id={id}
                key={id}
                pl={"7px !important"}
                options={options}
                optionLabel={"name"}
                filter
              />
            }
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default InputText;
