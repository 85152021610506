import React from "react";
import { PropTypes } from "prop-types";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Div from "@components/Div";
import UserHeaderMain from "./UserHeaderMain";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";

const UserContainer = (props) => {
  const { wide, hideGdprInfo=false } = props;

  
  return (
    <ScrollToTop>
      <Div
        backgroundColor={"var(--white)"}
        borderBottom={"2px solid var(--grey-lightest)"}
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
      >
        <UserHeaderMain config={props.config} />
      </Div>
      <Div
        mt={["16px", "16px", "16px", "16px"]}
        mx={["16px", "16px", "16px", "32px"]}
        maxWidth={["100%", "100%", "100%", wide ? "1320px" : "1110px"]}
        display="flex" flexDirection="column" minHeight="88vh" justifyContent="space-between"
      >
        <Container m="0 !important" >{props.children}</Container>
        {!hideGdprInfo && <GDPRInformationMessage />}
      </Div>
    </ScrollToTop>
  );
};
UserContainer.propTypes = {
  config: PropTypes.object,
  hideGdprInfo: PropTypes.bool,
  children: PropTypes.node,
  wide: PropTypes.bool,
};

export default UserContainer;
