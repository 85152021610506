import React, { useEffect, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Tabs from "@components/Tabs";
import AdminContainer from "@layout/AdminContainer";
import { ROUTES } from "@utils/constant";
import PermissionList from "../permissions/list";
import RolesList from "../roles/list";

const List = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");

  const TAB_CONTENT = useMemo(
    () => [
      {
        title: <FormattedMessage id="menu_roles" />,
        content: <RolesList />,
        url: ROUTES.ADMIN_ROLES_LIST.URL,
        headerTitle: messages.roles_label,
      },
      {
        title: <FormattedMessage id="report_permissions" />,
        content: <PermissionList />,
        url: ROUTES.ADMIN_PERMISSION_LIST.URL,
        headerTitle: messages.permissions_label,
      },
    ],
    [messages.roles_label, messages.permissions_label],
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(
      (item) => item.url === pathname,
    );
    setActiveIndex(activeTabIndex);
    setHeaderTitle(TAB_CONTENT[activeTabIndex].headerTitle);
  }, [history, TAB_CONTENT]);
  const handleTabChange = (event) => {
    const { index } = event;
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };
  const headerConfig = {
    title: headerTitle,
  };
  return (
    <AdminContainer config={headerConfig} wide>
      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default List;
