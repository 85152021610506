import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PrimaryButton, ButtonRaisedIcon } from "@components/Button";
import Icon from "@components/Icon";
import SearchBar from "./Utils/SearchBar";
import CheckBoxes from "./Utils/Checkbox";
import Calendar from "./Utils/Calendar";
import Multiselect from "./Utils/Multiselect";

const ButtonRaisedIconCustom = styled(ButtonRaisedIcon)`
  background-color: inherit !important;
  box-shadow: none !important;
  padding: 8px 16px 8px 0;
  margin: 0;
  color: var(--grey-dark) !important;
  i {
    font-weight: var(--semibold-weight);
    color: var(--turquoise);
  }
`;

const TransparentButtonCustom = styled(ButtonRaisedIcon)`
  background-color: none;
  text-transform: uppercase;
  font-weight: var(--medium-weight) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: none;
  padding: 8px 16px;
  .p-button-label {
    flex: none;
  }
  i {
    color: var(--blue-dark);
    font-weight: var(--medium-weight);
    font-size: var(--fs-icon-l);
    &:hover {
      color: var(--blue-dark);
    }
  }
`;

const TableActionButton = styled(ButtonRaisedIcon)`
  border-left: 1px solid var(--white) !important;
  background-color: inherit !important;
  padding: 8px !important;
  box-shadow: none !important;
  margin: 0px !important;
  i {
    color: var(--turquoise);
    cursor: pointer;
    transform: ${({ id }) => (id === "filter" ? "" : "rotate(90deg)")};
  }
  &:hover {
    i {
      color: var(--blue-dark);
    }
  }
`;

const ActionTemplate = (type, value) => {
  const { isHidden = false } = value;
  const { id, label, icon, minWidth, mx, content } = value;
  const [inputvalues = {}] = value?.options || [];
  const {
    name = "",
    value: inputValue = "",
    onClick = () => {},
    options = [],
    width = 1,
    placeholder = "",
  } = inputvalues;

  if (!isHidden) {
    return (
      <>
        {(() => {
          switch (type) {
            case "checkbox":
              return (
                <CheckBoxes type={type} value={value} options={value.options} />
              );
            case "calendar":
              return <Calendar value={value} />;
            case "button":
              return (
                <PrimaryButton
                  {...value}
                  semibold
                  id={id}
                  mx={mx || [0, 0, 3]}
                  label={label}
                  icon={icon ? <Icon name={icon} mr={label ? 2 : 0} /> : ""}
                  px={3}
                  py="8px"
                  minWidth={minWidth || "40px"}
                  minHeight="40px"
                  display="inline-flex"
                  textAlign="center"
                  whiteSpace
                />
              );

            case "button-transparent":
              return (
                <TransparentButtonCustom
                  {...value}
                  key={id}
                  id={id}
                  mx={3}
                  label={label}
                  semibold
                  px="20px"
                  py="6px"
                  minWidth={150}
                  alignSelf="center"
                  icon={<Icon name={icon} mr={1} cursor="pointer" />}
                />
              );

            case "button-raised":
              return (
                <ButtonRaisedIconCustom
                  {...value}
                  key={id}
                  id={id}
                  mx={2}
                  label={label}
                  /* padding top condition is done for "SCV" button style */
                  icon={<Icon name={icon} pr={`${label ? 1 : 0}`} />}
                />
              );
            case "table-action":
              return (
                <TableActionButton
                  {...value}
                  key={id}
                  id={id}
                  label={label}
                  icon={<Icon name={icon} pt={`${label ? "10px" : ""}`} />}
                  tooltip={content}
                  tooltipOptions={{ position: "bottom" }}
                />
              );
            case "search":
              return <SearchBar type={value} />;
            case "multiselect":
              return (
                <Multiselect
                  type={type}
                  placeholder={placeholder}
                  name={name}
                  onChange={onClick}
                  inputValue={inputValue}
                  id={id}
                  options={options}
                  width={width}
                  value={value}
                />
              );
          }
        })()}
      </>
    );
  }
  return <></>;
};

ActionTemplate.propTypes = {
  type: PropTypes.string,
};

export default ActionTemplate;
