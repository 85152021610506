import Axios from "@app/api/axios";
import { GET_SUBSCRIPTION } from "@utils/constant";

const getSubscription = (customer_id = "", params = "") => {
  const payload = customer_id ? `filter[customer_id]=${customer_id}&orders=${true}${params}` : `${params}`;
    return Axios.get(
    `${GET_SUBSCRIPTION}?${payload}`
  );
};
export default getSubscription;
