import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import fetchAllProducts from "@app/services/services/fetchProductById";
import getDataSources from "@app/services/products/getDataSources";
import getProductById from "@app/services/services/getProductById";
import updateProductById from "@app/services/services/updateProductById";

import Breadcrumb from "@components/Breadcrumb";
import ErrorDialog from "@components/ErrorDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import Tabs from "@components/Tabs";

import useAuthorization from "@hooks/useAuthorization";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import DetailsTab from "./tabs/DetailsTab";
import FormsTab from "./tabs/FormsTab";
import RulesTab from "./rules/rulesTab";

const UpdateService = () => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const history = useHistory();
  const { id: productId = "" } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const hasReadFormConfigPermission = hasAnyPermissions([
    AdminPermissions.AdminReadFormConfig,
    AdminPermissions.AdminReadReportConfig,
  ]);
  const hasReadRulesPermission = hasAnyPermissions([
    AdminPermissions.AdminReadRules,
  ]);

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: data?.name || "",
    },
  ];

  const formatAndSetData = useCallback(
    (
      responseData = {},
      dataSources = dataSourceTypes,
      servicesInBundle = serviceData,
    ) => {
      const formattedResponse = {
        ...responseData,
        default_selected: !!responseData.default_selected,
        default_available: !!responseData.default_available,
        purchase_by_credits: !!responseData.purchase_by_credits,
        state: responseData.state || "",
        service_type: responseData.service_type || "",
        analysis_type: responseData.analysis_type || "",
        data_source: responseData.data_source
          ? dataSources?.filter((o) =>
              responseData.data_source?.includes(o.code),
            )
          : [],
        child: responseData.child
          ? servicesInBundle?.filter((o) =>
              responseData.child?.includes(o?.code),
            )
          : [],
      };

      setData(formattedResponse);
    },
    [dataSourceTypes, serviceData],
  );

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);

      try {
        const [
          { data: dataSources = [] },
          { data: { data: serviceData = [] } = {} },
          response,
        ] = await Promise.all([
          getDataSources(),
          fetchAllProducts(),
          getProductById(productId),
        ]);

        const formattedDataSources = dataSources.map((dataSource) => ({
          name: messages[`datasource_${dataSource}`],
          code: dataSource,
        }));
        const formattedServiceData = serviceData
          .filter((service) => service?.type === "service")
          .map((service) => ({ name: service?.name, code: service?.id }));

        const {
          data: { data: respData = [] },
        } = response;
        const selectedService =
          respData.find(({ id }) => id === Number(productId)) || {};

        setDataSourceTypes(formattedDataSources);
        setServiceData(formattedServiceData);

        formatAndSetData(
          selectedService,
          formattedDataSources,
          formattedServiceData,
        );
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event) => {
    const pathname = tabContent[event.index]?.url;
    history.replace({
      pathname: pathname,
    });
  };

  const handleUpdateService = useCallback(
    async (payload) => {
      try {
        setIsLoading(true);

        const formattedPayload = Object.keys(payload).reduce((acc, key) => {
          if (data[key] !== payload[key]) {
            acc[key] = payload[key];
          }

          return acc;
        }, {});
        formattedPayload.id = productId;

        if (payload?.data_source) {
          formattedPayload.data_source = payload?.data_source?.map(
            (o) => o.code,
          );
        }

        if (payload?.child) {
          formattedPayload.child = payload?.child?.map((o) => o.code);
        }

        const { data: response = {} } =
          await updateProductById(formattedPayload);

        formatAndSetData(response);
      } catch (e) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [data, formatAndSetData, messages.exception_error_message, productId],
  );

  const handleUpdateServiceWithFormData = useCallback(
    (payload) => {
      const formattedPayload = {
        ...payload,
        id: productId,
      };

      handleUpdateService(formattedPayload);
    },
    [handleUpdateService, productId],
  );

  const handleHideErrorDialog = () => {
    setErrorMessage("");
  };

  const tabsConfigData = useMemo(
    () => ({
      customer_form: data.customer_form,
      candidate_form: data.candidate_form,
      report_config: data.report_config,
    }),
    [data],
  );

  const tabContent = useMemo(
    () => [
      {
        title: messages.title_service_info,
        content: (
          <DetailsTab
            data={data}
            dataSourceTypes={dataSourceTypes}
            onUpdateService={handleUpdateService}
          />
        ),
        url: `/admin/produkter/tjanster/${productId}/detaljer`,
      },
      {
        title: messages.form_configuration,
        content: (
          <FormsTab
            formsData={tabsConfigData}
            onUpdateService={handleUpdateServiceWithFormData}
          />
        ),
        url: `/admin/produkter/tjanster/${productId}/formularkonfiguration`,
        isHidden: !hasReadFormConfigPermission || !data?.is_case,
      },
      {
        title: messages.title_rules,
        content: <RulesTab />,
        url: `/admin/produkter/tjanster/${productId}/regler`,
        isHidden: !hasReadRulesPermission,
      },
    ],
    [
      data,
      dataSourceTypes,
      handleUpdateService,
      handleUpdateServiceWithFormData,
      hasReadFormConfigPermission,
      hasReadRulesPermission,
      messages,
      productId,
      tabsConfigData,
    ],
  );

  useEffect(() => {
    const path = history.location.pathname;
    const selectedTabIndex = Math.max(
      tabContent.findIndex((item) => item.url === path),
      0,
    );
    setActiveTabIndex(selectedTabIndex);
  }, [history.location.pathname, tabContent]);

  const containerConfig = {
    title: data?.name,
  };

  return (
    <AdminContainer config={containerConfig}>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleHideErrorDialog}
          onConfirm={handleHideErrorDialog}
        />
      )}

      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <Breadcrumb items={breadCrumbItems} p={0} />
          <Tabs
            activeIndex={activeTabIndex}
            onTabChange={handleTabChange}
            content={tabContent}
          />
        </>
      )}
    </AdminContainer>
  );
};

export default UpdateService;
