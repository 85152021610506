import PropTypes from "prop-types";


import { useStepper } from "@contexts/StepperContext";
import { useEffect } from "react";

const Stepper = ({ steps }) => {
  const { activeStepIndex } = useStepper();
  const currentItem = steps[activeStepIndex];

  useEffect(() => {
    window.history.pushState(null, '', currentItem.url);
  }, [currentItem]);

  return (
      currentItem?.component
  );
};

Stepper.propTypes = {
  onClose: PropTypes.func,
  steps: PropTypes.array,
};

export default Stepper;
