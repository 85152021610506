import React, { useRef } from "react";
import Proptypes from "prop-types";
import { Menu } from "primereact/menu";
import { PrimaryButton } from "@components/Button";
import styled from "styled-components";
import Icon from "@components/Icon";

const StyledMenu = styled(Menu)`
  width: auto !important;

  span {
    &:before {
      color: var(--turquoise) !important;
    }
  }

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const getBorderRadius = ({ curved, rounded }) => {
  let borderRadius;

  if (curved) {
    borderRadius = "10px";
  } else if (rounded) {
    borderRadius = "50px";
  } else {
    borderRadius = "auto";
  }

  return borderRadius;
};

const Button = styled(PrimaryButton)`
  height: auto;
  padding: 0;
  border-radius: ${({ curved, rounded }) =>
    getBorderRadius({ curved, rounded })};
  color: ${({ has_transparent_bg }) =>
    has_transparent_bg ? "var(--blue-dark)" : "var(--white)"};
  background: ${({ has_transparent_bg }) =>
    has_transparent_bg ? "transparent !important" : "var(--blue-dark)"};
  &:hover {
    background: ${({ has_transparent_bg }) =>
      has_transparent_bg ? "transparent !important" : "var(--blue-dark)"};
    border-color: ${({ has_transparent_bg }) =>
      has_transparent_bg ? "transparent !important" : "var(--blue-dark)"};
    color: ${({ has_transparent_bg }) =>
      has_transparent_bg ? "var(--blue-dark) !important" : "var(--white)"};
  }
`;

const MenuButton = ({ type }) => {
  const menuRef = useRef(null);

  const {
    items = [],
    label = "",
    icon = "",
    has_transparent_bg = false,
    iconSize,
  } = type;
  const iconName = icon?.startsWith("icon-") ? icon?.substring(5) : icon;

  const handleToggleMenu = (e) => menuRef.current.toggle(e);

  return (
    <>
      <StyledMenu model={items} popup ref={menuRef} />
      <Button
        label={label}
        has_transparent_bg={has_transparent_bg}
        onClick={handleToggleMenu}
      >
        {!!icon && <Icon px={1} name={iconName} fontSize={iconSize} />}
      </Button>
    </>
  );
};

MenuButton.propTypes = {
  type: Proptypes.object,
};

export default MenuButton;
