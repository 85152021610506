import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import addCredentials from "@app/services/integrations/addCredentials";

import { ButtonLinkIcon } from "@components/Button";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import PromptDialog from "@components/PromptDialog";
import { TextMediumWeight } from "@components/Text";

import { CredentialsDialog } from "../components";
import { QUESTION_DIALOG_TYPE, QUESTION_DIALOG_CONTENT } from "../types";

const CreateCredentialsHeader = ({ onLoading, onError }) => {
  const { messages } = useIntl();

  const [questionDialogContentType, setQuestionDialogContentType] = useState(
    QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.NONE]
  );
  const [newCredentials, setNewCredentials] = useState(null);

  const handleCreateAPIKey = async () => {
    handleCloseQuestionDialog();

    try {
      onLoading(true);

      const { data: credentialsData = {} } = await addCredentials();

      setNewCredentials(credentialsData);
    } catch (error) {
      onError(messages.exception_error_message);
    } finally {
      onLoading(false);
    }
  };

  const handleOpenCreateQuestionDialog = () => {
    setQuestionDialogContentType({
      ...QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.CREATE],
      handleConfirm: handleCreateAPIKey,
    });
  };

  const handleCloseQuestionDialog = () => {
    setQuestionDialogContentType(
      QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.NONE]
    );
  };

  const handleCloseCredentialsDialog = () => {
    setNewCredentials(null);
  };

  return (
    <Div>
      {questionDialogContentType && (
        <PromptDialog
          title={messages[questionDialogContentType?.titleKey]}
          message={messages[questionDialogContentType?.messageKey]}
          confirmLabel={messages[questionDialogContentType?.confirmLabel]}
          onConfirm={questionDialogContentType?.handleConfirm}
          onCancel={handleCloseQuestionDialog}
        />
      )}

      {newCredentials && (
        <CredentialsDialog
          credentialsData={newCredentials}
          onClose={handleCloseCredentialsDialog}
        />
      )}

      <Div
        my={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <H2 color="var(--blue-dark) !important">
          {messages.header_manage_api_keys}
        </H2>

        <ButtonLinkIcon
          label={
            <TextMediumWeight>
              {messages.label_generate_new_api_key}
            </TextMediumWeight>
          }
          iconPos="left"
          icon={<Icon mr={1} name="plus" color="var(--turquoise)" />}
          onClick={handleOpenCreateQuestionDialog}
        />
      </Div>
    </Div>
  );
};

CreateCredentialsHeader.propTypes = {
  onError: PropTypes.func,
  onLoading: PropTypes.func,
};

export default CreateCredentialsHeader;
