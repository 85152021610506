import React from "react";
import { FormattedMessage } from "react-intl";

const CompanyType  = () => [
  {
    label: <FormattedMessage id="label_company_type_public" />,
    value: "public",
  },
  {
    label: <FormattedMessage id="label_company_type_private" />,
    value: "private",
  },
];


export default CompanyType;
