import React from "react";
import CompleteRegistration from "../auth/shared/CompleteRegistration";

const AdminCompleteRegistration = () => {
  const onClick = () => {
    //
  };

  return <CompleteRegistration onClick={onClick} />;
};

export default AdminCompleteRegistration;
