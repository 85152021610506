import React from "react";
import PropTypes from "prop-types";
import Config from "@src/config";

const currencyMapping = {
  "sv-SE": "SEK", // Swedish
  "en-US": "USD", // US English
  "no-NO": "NOK", // Norwegian
};

const languageMapping = {
  sv: "sv-SE", // Swedish
  en: "en-US", // US English
  no: "no-NO", // Norwegian
};

const Currency = ({ value, decimals = 0, currencyDisplay = "symbol" }) => {
  const language = languageMapping[Config.language] || "sv-SE";
  const currency = currencyMapping[language] || "SEK";

  const formatCurrency = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    currencyDisplay,
    minimumFractionDigits: decimals,
  }).format(value);

  return <>{formatCurrency}</>;
};

export default Currency;

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  decimals: PropTypes.number,
  currencyDisplay: PropTypes.string,
};
