import { useState } from "react";
import { useSelector } from "react-redux";

import couponCodeValidationService from "@app/services/orders/couponCodeValidationService";

import { useOrder } from "@contexts/OrderContext";

import {
  PAYMENT_INVOICE,
  PAYMENT_METHOD_CREDITS_AND_INVOICE,
} from "@utils/constant";
import { calculateVat, calculatePercentage } from "@utils/utils";

export const useCouponCodeValidation = () => {
  const {
    orderUser: { id: user_id, customer: { id: customer_id } = {} },
  } = useOrder();
  const isConsent = useSelector(state => state.authReducer.consent);

  const [couponCodeDetails, setCouponCodeDetails] = useState(null);
  const [couponCodeValidationError, setCouponCodeValidationError] =
    useState(null);

  const validateCouponCode = async (
    orderSummaryProps,
    couponCode,
    orderTotalPriceWithoutVat,
    paymentMethod,
    billingAccountId
  ) => {
    try {
      setCouponCodeValidationError(null);

      const orderItems = orderSummaryProps.flatMap(person =>
        person.services.map(service => ({
          service_id: service.id,
          personal_number: person.ssn,
          person_name: person.name,
          phone: person.phone,
          email: person.email,
        }))
      );

      const body = {
        user_id,
        customer_id,
        consent: isConsent,
        promo_code: couponCode,
        payment_method: paymentMethod,
        order_items: orderItems,
      };

      if (
        paymentMethod === PAYMENT_INVOICE ||
        paymentMethod === PAYMENT_METHOD_CREDITS_AND_INVOICE
      ) {
        body.billing_account_id = billingAccountId;
      }

      const response = await couponCodeValidationService(body);

      if (response.status !== 200) {
        setCouponCodeValidationError(
          response.data.message_term ?? "code_not_valid"
        );

        return;
      }

      const {
        data: { order_draft: orderDraft },
      } = response;

      const orderTotalVat = calculateVat(orderDraft.order_total_with_discount);
      const rawSummaryPropsServices = Object.values(orderDraft.orderItems);

      let rawSummaryPropsServiceIndex = 0;
      const orderTotalDetails = {
        promoCode: couponCode,
        orderTotalVatPrice: orderTotalVat,
        orderTotalPriceWithoutVat: orderDraft.order_total_with_discount,
        orderTotalPriceWithVat:
          orderDraft.order_total_with_discount + orderTotalVat,
        orderTotalDiscount: orderDraft.order_discount,
        orderTotalDiscountPercentage: calculatePercentage(
          orderDraft.order_discount,
          orderTotalPriceWithoutVat
        ),
        orderSummaryProps: orderSummaryProps.map(item => ({
          ...item,
          services: item.services.map(service => {
            const updatedService =
              rawSummaryPropsServices[rawSummaryPropsServiceIndex];

            rawSummaryPropsServiceIndex++;

            return {
              ...service,
              priceWithDiscount: updatedService?.total_with_discount,
              discount: updatedService?.discount,
            };
          }),
        })),
      };

      setCouponCodeDetails(orderTotalDetails);
    } catch (error) {
      setCouponCodeValidationError("code_not_valid");
    }
  };

  return {
    couponCodeDetails,
    couponCodeValidationError,
    validateCouponCode,
  };
};
