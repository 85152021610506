export const ReportStatus = {
  "in-progress": "report_status_in_progress",
  completed: "report_status_completed",
  pending: "pending",
  expired: "report_status_expired",
  failed: "report_status_failed",
  new: "report_status_new_consent",
  "Pending Consent": "report_status_pending_consent",
  "Pending consent to share": "report_status_pending_consent_share",
  "Expired no consent": "report_status_expired_no_consent",
};

export const reportStatusArray = [
  "in-progress",
  "completed",
  "pending",
  "expired",
  "failed",
  "new",
  "Pending Consent",
  "Pending consent to share",
  "Expired no consent",
];

export const ReportStatusToolTip = {
  "in-progress": "report_status_in_progress_tooltip_text",
  completed: "report_status_completed_tooltip_text",
  pending: "pending",
  expired: "report_status_expired_tooltip_text",
  failed: "report_status_failed_tooltip_text",
  new: "report_status_new",
  "Pending Consent": "report_status_pending_consent_tooltip_text",
  "Pending consent to share":
    "report_status_pending_consent_share_tooltip_text",
  "Expired no consent": "report_status_expired_no_consent_tooltip_text",
};
