import React from "react";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import ScrollToTop from "@utils/ScrollToTop";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { H2 } from "@components/Heading";

const ReportList = ({ analysData = {}, isRoleAnalysis, isActaPublica }) => {
  const { messages } = useIntl();
  const { report_items: reportItems = [] } = analysData;

  let crimesList = reportItems
    .filter((o) => Number(o.points) > 0)
    .map((o) => ({
      ...o,
      case_nr: o?.cases?.case_nr,
      court: o?.cases?.court,
      date: o?.cases?.date,
    }));

  const ColumnsForRoleAnalysis = [
    {
      db_field: "keyname",
      title: "title_keyname",
      width: "350px",
      type: "text",
      sortable: true,
    },
    {
      db_field: "case_nr",
      title: "header_case_nr",
      type: "text",
    },
    { db_field: "court", title: "header_court", type: "text", sortable: true },
    { db_field: "date", title: "label_date", type: "date", sortable: true },
    { db_field: "points", title: "title_points", type: "text", sortable: true },
  ];

  const ColumnsForGeneralAnalysis = [
    {
      db_field: "keyname",
      title: "title_keyname",
      width: "350px",
      type: "text",
      sortable: true,
    },
    { db_field: "id", title: "title_id", type: "text" },
    { db_field: "value", title: "title_value", type: "currency", sortable: true },
    { db_field: "points", title: "title_points", type: "text", sortable: true },
    { db_field: "count", title: "title_count", type: "text", sortable: true },
    { db_field: "status", title: "title_status", type: "enum", sortable: true },
  ];

  const ColumnsForActaPublicaAnalysis = [
    {
      db_field: "keyname",
      title: "title_keyname",
      type: "text",
      sortable: true,
      width: "350px",
    },
    { db_field: "id", title: "title_id", type: "text" },
    { db_field: "points", title: "title_points", type: "text", sortable: true },
    { db_field: "status", title: "title_status", type: "enum", sortable: true },
  ];

  let column = null;
  if (isActaPublica) {
    column = ColumnsForActaPublicaAnalysis;
  } else if (isRoleAnalysis) {
    column = ColumnsForRoleAnalysis;
  } else {
    column = ColumnsForGeneralAnalysis;
  }
  let config = {
    title: isRoleAnalysis
      ? messages.title_crime_items
      : messages.title_report_items,
    headerActions: [],
    columns: column,
    backend_querying: false,
    enable_pagination: false,
  };
  return (
    <ScrollToTop>
      <Container>
        <H2>
          {isRoleAnalysis
            ? messages.title_crime_items
            : messages.title_report_items}
        </H2>
        {!!isRoleAnalysis && !!crimesList?.length && (
          <DataTable config={{ ...config, static_data: crimesList }} />
        )}
        {!isRoleAnalysis && (
          <DataTable config={{ ...config, static_data: reportItems }} />
        )}
      </Container>
    </ScrollToTop>
  );
};

ReportList.propTypes = {
  analysData: Proptypes.array,
  isRoleAnalysis: Proptypes.bool,
  isActaPublica: Proptypes.bool,
};

export default ReportList;
