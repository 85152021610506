import React from "react";
import Div from "@components/Div";
import { TextLargeSemiBoldWeight } from "@components/Text";
import { LinkArrow } from "@components/Link";
import Span from "@components/Span";
import Icon from "@components/Icon";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { H1 } from "@components/Heading";
const Error404 = () => {
  const location = useHistory();
  const { messages } = useIntl();
  return (
    <Div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <Div>
        <TextLargeSemiBoldWeight mb={30}>
          {messages.title_error}
        </TextLargeSemiBoldWeight>
      </Div>
      <Div mt={45}>
        <H1 fontSize={`100px !important`}>404</H1>
      </Div>
      <Div mt={24}>
        <H1 color={"var(--gray-dark) !important"}>
          {messages.title_page_not_found}
        </H1>
      </Div>
      <Div mt={20}>
        <LinkArrow
          px={20}
          display={"flex"}
          justifyContent={["center", "center", "center", "start"]}
          alignItems="center"
          handleClick={() => location.push("/")}
        >
          <Span info medium>
            {messages.back_to_homepage_term}
          </Span>
          <Icon name="headerarrowright" ml={2} />
        </LinkArrow>
      </Div>
    </Div>
  );
};
export default Error404;
