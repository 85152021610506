import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "@hooks/useToast";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import { H4, H5 } from "@components/Heading";
import {
  PrimaryButtonIconOutlined,
} from "@components/Button";
import Icon from "@components/Icon";
import Editor from "@components/Editor";
import ProgressSpinner from "@components/ProgressSpinner";

import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import Card from "@components/Card";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import Comment from "@components/Comment";

const CommentsTab = ({ id = "" }) => {
  const { messages } = useIntl();

  const { showSuccessToast, showErrorToast } = useToast();
  const userInfo = useSelector(state => state.authReducer.userInfo);

  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data: { data } = {} } = await customerDetailsService(id);
      const { comments = [] } = data;
      setComments(comments);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOnChangeComment = event => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleAddComment = async () => {
    const payload = {
      comment: newComment,
      user_id: userInfo?.id,
      id,
    };
    try {
      const { data: _customer = {}  } = await editCustomerInfoService(payload).then(res => res.data);
      setComments(_customer?.comments || comments);

      showSuccessToast(messages.label_comment_added);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
    setNewComment("");
  };

  const handleDeleteComment = async () => {
    fetchData();
  };

  return (
    <Div mt={3}>
      {isFetching && <ProgressSpinner />}

      {!isFetching && (
        <Card p={3} width={[1, 1, 8 / 10]}>
          <Div
            pb={10}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid var(--grey-lightest)"
          >
            <H4>{messages.label_comments}</H4>
            <H5>{messages.label_internal_storge}</H5>
          </Div>
          {comments?.map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              onDeleteComment={handleDeleteComment}
            />
          ))}
          <Div mt={comments?.length ? 4 : 3}>
            <TextMediumWeight>{messages.label_add_comment}</TextMediumWeight>
            <Editor
              width={1}
              my={2}
              value={newComment}
              onTextChange={handleOnChangeComment}
            />
          </Div>
          <Div width={1} display="flex" justifyContent="flex-end">
            <Div mt={60}>
            <PrimaryButtonIconOutlined
                  rounded
                  height={40}
                  px={22}
                  icon={
                    <Icon
                      name="add-comment"
                      mr={1}
                      fontSize={"var(--fs-icon-m)"}
                      fontWeight={"var(--medium-weight)"}
                    />
                  }
                  label={messages.label_add_comment}
                  onClick={handleAddComment}
                  alignItems={"end"}
                />
            </Div>
          </Div>
        </Card>
      )}
    </Div>
  );
};
CommentsTab.propTypes = {
  id: PropTypes.number,
};
export default CommentsTab;
