import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { default as Div } from "@components/Div";
import Icon from "@components/Icon";
import Link from "@components/Link";
import Sidebar from "@components/Sidebar";
import { H3 } from "@components/Heading";
import { LOGIN, BOOK_DEMO } from "@utils/constant";
import { Text, TextMediumWeight } from "@components/Text";
import Skeleton from "@components/Skeleton";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import {
  ADMIN_HEADER_NAV_MAIN,
  HEADER_NAV,
  ADMIN_NAV,
  ADMIN_MY_PROFILE,
} from "../utils/constant";
import { useSelector } from "react-redux";
import { Menu as ModelMenubar } from "primereact/menu";
import { Menubar as PRStyledMenubar } from "primereact/menubar";
import { compose, space, flexbox, layout } from "styled-system";
import styled from "styled-components";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";
import useHistory from "@utils/useHistory";
import logoutService from "@app/services/auth/logoutService";
import { PrimaryButtonOutlined, ButtonLinkIcon } from "@components/Button";
import SideMenuContent from "./SideMenuContent";
import useAuthorization from "@hooks/useAuthorization";
import { useUser } from "@contexts/AuthProvider";

const mobileBreakPoint = Config.mobileBreakPoint;
const MenuBar = styled(PRStyledMenubar)`
  position: sticky;
  max-height: 80px;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  border: none;
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
  }
  ${compose(space, flexbox, layout)};
  .p-menubar-root-list {
    width: -webkit-fill-available;
    .p-menuitem {
      .p-menuitem-link {
        padding: 0px 15px;
      }
    }
  }
  .p-menubar-button {
    display: none;
  }
  .p-menubar-start {
    white-space: nowrap;
  }
  .p-menubar-end {
    height: 60px;
    cursor: pointer;
    display: inline-flex;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      height: 100%;
      &:hover {
        border-bottom: 4px solid var(--blue-medium); /* Background color on hover */
      }
    }
  }
  &.p-menubar.p-component {
    padding: 0px;
    background: var(--white);
    .p-menubar-root-list {
      .p-menuitem {
        &:last-child {
          .p-menuitem-link {
            &:hover {
              background: var(--transparent);
            }
            .p-menuitem-text {
              &:hover {
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
        .p-menuitem-link {
          background: transparent;
          transition: none !important;
          &:hover {
            background: var(--white);
          }
          .p-menuitem-text {
            padding-bottom: 30px;
            border-bottom: 5px solid transparent;
            color: var(--grey-dark) !important;
            font-size: var(--fs-text-small);
            line-height: 22px;
            font-weight: var(--medium-weight) !important;
            white-space: nowrap;
            &:hover {
              border-bottom: 5px solid var(--blue-medium);
              color: var(--blue-medium) !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    min-height: 83px;
    .p-menubar-button,
    .p-menubar-end {
      display: none !important;
    }
    .p-menubar-start {
      width: 100%;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
    }
    &.p-menubar.p-component {
      padding: 16px 23px 22px;
      width: 100%;
      .p-menubar-root-list {
        display: none !important;
      }
    }
  }
`;
const Menu = styled(ModelMenubar)`
  ${compose(space, flexbox, layout)};
  top: 60px !important;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  width: 230px;
  text: center;
  position: fixed;
  .p-menuitem-link {
    &:focus {
      box-shadow: none;
    }
    width: 230px;
    text-align: left;
  }
  .p-menuitem-text {
    color: var(--grey-dark) !important;
    font-size: var(--fs-text-small);
    line-height: 22px;
    font-weight: var(--medium-weight) !important;
    width: 100%;
  }
`;

const BookDemoButton = styled(PrimaryButtonOutlined)`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 118px;
  border-color: var(--yellow);
  &.p-button-rounded {
    color: var(--blue-dark);
    border: 2px solid var(--yellow) !important;
    .p-button-label {
      color: var(--yellow);
      font-size: var(--fs-text-m);
      line-height: 22px;
      font-weight: var(--semibold-weight);
    }
    &:not(a):not(.p-disabled):hover {
      background-color: var(--yellow) !important;
      border: 2px solid var(--yellow);
      .p-button-label {
        color: var(--grey-dark) !important;
      }
    }
  }
`;

const StyledButtonLinkIcon = styled(ButtonLinkIcon)`
  .p-button-label {
    font-size: var(--fs-link-m) !important;
    font-weight: var(--semibold-weight) !important;
  }
`;

const WebSideBar = () => {
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const { hasAnyPermissions, isAuthenticated } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { clearUser } = useUser();

  let menuItems = ADMIN_NAV.filter((obj) => {
    const { permissions = [] } = obj;
    return hasAnyPermissions(permissions);
  });

  menuItems = menuItems.map((item) => {
    if (item?.isDropDown) {
      const dropDownOptions = item?.dropDownOptions.filter((obj) => {
        const { permissions = [] } = obj;
        return hasAnyPermissions(permissions);
      });
      return { ...item, dropDownOptions };
    }
    return { ...item };
  });

  const sideMenuNavItems = [...menuItems];
  const navProps = {
    navItems: HEADER_NAV,
  };
  const sidemenuNavProps = {
    navItems: sideMenuNavItems,
  };
  const redirectToLogin = () => {
    clearUser();
    history.push(LOGIN);
  };

  const redirectToMyProfilePage = () => {
    history.push(ADMIN_MY_PROFILE);
  };

  const handleRedirectToWPpage = (url) => {
    window.location = url;
  };

  const getBookDemoButton = () => {
    return (
      <Div
        alignItems={"center"}
        display="flex"
        mb={[3, 3, 3, 0]}
        justifyContent="center"
      >
        <BookDemoButton
          px="20px"
          label={messages.book_demo}
          onClick={handleRedirectToWPpage.bind(this, BOOK_DEMO)}
        />
      </Div>
    );
  };

  return (
    <>
      <Link
        label={messages.header_menu}
        onClick={() => setVisibleFullScreen(true)}
        darkblue
        variant="primary"
        flexDirection={"row-reverse !important"}
        display={["flex", "flex", "flex", "none"]}
      >
        <Icon name="hamburger" pr={2} />
      </Link>
      <Sidebar
        visible={visibleFullScreen}
        fullScreen
        icons={<H3 pl={1}>{messages.header_menu}</H3>}
        onHide={() => setVisibleFullScreen(false)}
      >
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {isAuthenticated ? (
            <Div>
              <SideMenuContent {...sidemenuNavProps} mobileView={true}/>
            </Div>
          ) : (
            <Div>
              <SideMenuContent {...navProps} />
              {getBookDemoButton()}
            </Div>
          )}

          <Div>
            <Div py={3} borderTop="1px solid var(--turquoise-light)">
              {isAuthenticated ? (
                <>
                  <Div
                    width={1}
                    p={"12px 20px"}
                    onClick={redirectToMyProfilePage}
                  >
                    <TextMediumWeight
                      wordBreak="break-all"
                      fontSize={"var(--fs-text-small) !important"}
                      letterSpacing={"0.3px !important"}
                    >
                      {messages.personal_setting}
                    </TextMediumWeight>
                  </Div>
                  <Div width={1} p={"12px 20px"} onClick={redirectToLogin}>
                    <TextMediumWeight
                      wordBreak="break-all"
                      fontSize={"var(--fs-text-small) !important"}
                      letterSpacing={"0.3px !important"}
                    >
                      {messages.label_log_out}
                    </TextMediumWeight>
                  </Div>
                </>
              ) : (
                <Div width={1} p={"12px 20px"} onClick={redirectToLogin}>
                  <TextMediumWeight
                    wordBreak="break-all"
                    fontSize={"var(--fs-text-small) !important"}
                    letterSpacing={"0.3px !important"}
                  >
                    {messages.login}
                  </TextMediumWeight>
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      </Sidebar>
    </>
  );
};

const AdminHeaderMain = (props) => {
  const { config, isLoading = false } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const history = useHistory();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { messages } = useIntl();
  const { name } = userInfo;
  const endRef = useRef(null);
  const {clearUser} = useUser();

  const passedData = Array.isArray(config) ? config[0] : config;
  const handleLogout = async () => {
    await logoutService(localStorage.getItem("token"));
    clearUser();
    history.push(LOGIN);
  };

  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  let items = ADMIN_HEADER_NAV_MAIN.map((item) => {
    const {
      redirectTo = "",
      isWpPage = "",
      value = "",
      preventRedirect = false,
      isDropDown = false,
    } = item;

    if (isWpPage) {
      item.url = redirectTo;
    } else if (!isDropDown) {
      item.command = () => {
        if (!preventRedirect) {
          history.push(value);
        }
      };
    }
    return item;
  });
  items.push({
    label: (
      <Div>
        <Icon color="var(--turquoise)" mr={"8px"} ml={"-8px"} name={"logout"} />
        {messages.logout_term}
      </Div>
    ),
    icon: "logout",
    command: () => {
      handleLogout();
    },
  });

  const start = () => (
    <>
      <Logo
        logo={LogoFull}
        width="auto"
        maxHeight="60px"
        onClick={handleRedirect}
        display={["flex", "flex", "flex", "none"]}
      />
      {isLoading ? (
        <Skeleton height={20} width={200} />
      ) : (
        <Text
          color="var(--blue-dark) !important"
          fontWeight="var(--semibold-weight) !important"
          fontSize={"var(--fs-h2) !important"}
          wordBreak="none !important"
          display={["none", "none", "none", "flex"]}
        >
          {passedData.title}
        </Text>
      )}
      <WebSideBar />
    </>
  );

  const item = passedData.headerActions?.map((action) => ({
    label: action.label,
    template: (
      <div>
        {!action?.isHidden &&
          (() => {
            if (action.type === "button") {
              return (
                <StyledButtonLinkIcon
                  {...action}
                  semibold
                  id={action.id}
                  label={action.label}
                  icon={
                    action.icon ? (
                      <Icon
                        name={action.icon}
                        mr={action.label ? 2 : 0}
                        color="var(--turquoise) !important"
                        fontSize="var(--fs-icon-m)"
                        fontWeight="var(--semibold-weight)"
                        mt={"2px"}
                      />
                    ) : (
                      ""
                    )
                  }
                  color="var(--grey-dark) !important"
                  fontSize="var(--fs-text-m) !important"
                  minWidth={action.minWidth || "40px"}
                  minHeight="30px"
                  display="inline-flex"
                  textAlign="center"
                  whiteSpace
                />
              );
            } else {
              return null;
            }
          })()}
      </div>
    ),
  }));

  const handleToggle = (event) => {
    setMenuVisible((menuVisible) => !menuVisible);
    endRef.current.toggle(event);
  };

  const end = (
    <>
      <Div
        display={["none", "none", "flex"]}
        backgroundColor={"var(--blue-lightest)"}
        px="20px"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={`4px solid ${
          menuVisible ? "var(--blue-medium)" : "transparent"
        }`}
        borderTop="4px solid var(--blue-lightest)"
        whiteSpace
        height={40}
        onClick={handleToggle}
        aria-controls="menu_left"
        aria-haspopup
      >
        <Div display={["none", "none", "flex"]} alignItems="center">
          <Icon pr={13} color="var(--blue-medium) !important" name="avatar" />
          <Link fontSize="16px" label={name} />
        </Div>
        <Icon
          pl={13}
          fontSize={"6px"}
          color="var(--blue-medium) !important"
          name="arrowbig-down"
        />
      </Div>
      <Menu
        model={items}
        popup
        ref={endRef}
        id="popup_menu"
        onHide={() => setMenuVisible(false)}
      />
    </>
  );

  return (
    <Div mx={["16px", "16px", "16px", "32px"]} maxWidth="100%">
      <MenuBar model={item} start={start} end={end} width={[1, 1, 1]} />
    </Div>
  );
};

AdminHeaderMain.propTypes = {
  config: PropTypes.object,
  isLoading: PropTypes.bool,
  navItems: PropTypes.array,
  sideMenuNavItems: PropTypes.array,
  children: PropTypes.node,
};

export default AdminHeaderMain;
