import { Chip as PRChip } from "primereact/chip";

import styled from "styled-components";
import { compose, space, color, typography } from "styled-system";

const StyledChip = styled(PRChip)`
  ${compose(space, color, typography)};

  border: 2px solid var(--grey-lightest);
  border-radius: 10px;
  background-color: var(--turquoise-light);
  width: fit-content;

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  .p-chip-icon {
    color: var(--turquoise);
    transform: rotate(90deg);
  }

  .p-chip-text {
    color: var(--grey-dark);
    font-size: var(--fs-text-small);
  }
`;

export default StyledChip;
