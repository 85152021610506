import { createSlice } from "@reduxjs/toolkit";
import { READ, WRITE, GRADES } from "@utils/constant";

const formatPayload = (payload) => {
  const { role = [], permissions: permissionsFromDB = [] , permissionsCopy : _permissionsCopy } = payload;
  const { name: userRole = "" } = (role && role[0]) || {};
  const permissionsCopy = _permissionsCopy ? _permissionsCopy : permissionsFromDB.map((o) => o?.name);
  let rights = {};
  if (Array.isArray(permissionsCopy)) {
    permissionsCopy &&
    permissionsCopy.map((obj) => {
        const { name = "" } = obj;
        if (name?.includes(WRITE)) {
          const permissionName = name?.split(`${WRITE}-`)[1];
          rights[permissionName] = WRITE;
        }
        if (name?.includes(READ)) {
          const permissionName = name?.split(`${READ}-`)[1];
          if (rights[permissionName] !== WRITE) {
            rights[permissionName] = READ;
          }
        }
        if (name?.includes(GRADES)) {
          const extractedAssignment = name?.split(`${GRADES}-`)[1];
          rights[extractedAssignment] = GRADES;
        }
        return obj;
      });
  } else {
    rights = permissionsCopy;
  }
  payload.permissionsCopy = permissionsCopy;
  payload.role = userRole;
  payload.permissions = rights;
  return payload;
};

const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    isFetching: false,
    userInfo: {},
    isLogout: false,
    consent: false,
  },
  reducers: {
    fetchAuth: (state, action) => {
      state.isFetching = action.payload;
    },
    storeUserInfo: (state, action) => {
      const { payload } = action;
      const { isLogout = false } = payload;
      state.isFetching = false;
      state.userInfo = isLogout ? {} : formatPayload(payload);
      state.isLogout = false;
    },
    isConsent: (state, action) => {
      state.consent = action.payload;
    },
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
