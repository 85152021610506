import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import Div from "@components/Div";
import {
  TextMediumWeight,
  TextSemiBoldWeight,
  TextSmall,
} from "@components/Text";
import InputText from "@components/InputText";
import Dropdown from "@components/Dropdown";
import MultiSelect from "@components/MultiSelect";
import {
  INPUT,
  BUNDLE,
  ANALYSIS,
  ASSIGNMENT,
  WRITE,
  SUBSCRIPTION_STATUS,
  STATUS_IN_ACTIVE,
} from "@utils/constant";
import Switch from "@components/Switches";
import {
  PrimaryButtonIcon,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import Tooltip from "@components/ToolTip";
import Editor from "@components/Editor";
import { SmallEllipseIcon } from "@components/Icon";
import Schema from "./Schema";
import fetchAllProducts from "@app/services/services/fetchProductById";

const initialValues = {
  service_type: "",
  analysis_type: "",
  data_source: [],
  child: [],
  name: "",
  price: "",
  delivery_time: "",
  listing_order: null,
  sku: "",
  description: "",
  default_selected: false,
  default_available: false,
  purchase_by_credits: false,
  price_credits: 0,
  state: "active",
  type: "service",
};

const Form = ({
  data = initialValues,
  onSubmit = () => {},
  isEditService = false,
  dataSourceTypes = [],
}) => {
  const { TEXT, NUMBER } = INPUT.TYPE;
  const { messages } = useIntl();
  const { permissions = {} } = useSelector(
    (state) => state.authReducer.userInfo,
  );
  const [servicesInBundle, setServicesInBundle] = useState([]);
  const serviceTypes = [
    {
      label: messages.analysis,
      value: ANALYSIS,
    },
    {
      label: messages.assignment,
      value: ASSIGNMENT,
    },
    {
      label: messages.servicetype_bundle,
      value: BUNDLE,
    },
  ];

  const analysisTypes = [
    {
      label: messages.label_general_analysis,
      value: "general-analysis",
    },
    {
      label: messages.label_role_analysis,
      value: "role-specific-analysis",
    },
    {
      label: messages.label_general_analysis_actapublic,
      value: "general-analysis-actapublica",
    },
  ];

  const statusValues = [
    {
      label: messages.active,
      value: SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: messages.state_in_active,
      value: STATUS_IN_ACTIVE,
    },
  ];

  useEffect(() => {
    const loadServices = async () => {
      const servicesResponse = await fetchAllProducts();
      const {
        data: { data = [] },
      } = servicesResponse;
      const serviceData = data.filter((o) => o?.type === "service");
      setServicesInBundle(
        serviceData
          .filter((o) => o?.service_type !== "bundle")
          .map((obj) => ({ name: obj?.name, code: obj?.id })),
      );
    };
    loadServices();
  }, []);

  const renderInfoIcon = (target, content) => {
    return (
      <SmallEllipseIcon ml={2} className={target} name="question">
        <Tooltip
          content={content}
          display={["none", "none", "block", "block"]}
          target={`.${target}`}
        ></Tooltip>
        <Tooltip
          display={["block", "block", "none", "none"]}
          content={content}
          target={`.${target}`}
          position="bottom"
        ></Tooltip>
      </SmallEllipseIcon>
    );
  };

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const handleChangeIndescription = (onChange, e) => {
    onChange({ target: { name: "description", value: e.htmlValue } });
  };

  return (
    <Div width={1}>
      <Formik
        enableReinitialize
        validationSchema={Schema}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {(prop) => {
          const {
            values,
            handleReset,
            handleChange,
            dirty,
            isValid,
            handleSubmit,
          } = prop;
          return (
            <FormikForm>
              {isEditService && (
                <Div width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 2]} mt={3}>
                  <Div width={1} mb={2}>
                    <TextMediumWeight>{messages.label_status}</TextMediumWeight>
                  </Div>
                  <Dropdown
                    placeholder={messages.placeholder_choose}
                    value={values?.state}
                    name={"state"}
                    options={statusValues}
                    onChange={handleChange}
                    width={[1, 1, 255, 255]}
                    mb={0}
                  />
                </Div>
              )}
              {!isEditService && (
                <Div display="flex">
                  <Div width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 2]} mt={3}>
                    <Div width={1} mb={2}>
                      <TextMediumWeight>
                        {messages.label_product_type_form}
                      </TextMediumWeight>
                    </Div>
                    <Dropdown
                      placeholder={messages.placeholder_choose}
                      value={values?.service_type}
                      name={"service_type"}
                      options={serviceTypes}
                      onChange={handleChange}
                      width={1}
                      mb={0}
                    />
                  </Div>
                  {values.service_type === "analysis" && (
                    <Div width={[1, 1, 1, 1 / 2]} ml={[0, 0, 0, 2]} mt={3}>
                      <Div width={1} mb={2}>
                        <TextMediumWeight>
                          {messages.label_analysis_type}
                        </TextMediumWeight>
                      </Div>
                      <Dropdown
                        placeholder={messages.placeholder_choose}
                        value={values?.analysis_type}
                        name={"analysis_type"}
                        options={analysisTypes}
                        onChange={handleChange}
                        width={1}
                      />
                    </Div>
                  )}
                </Div>
              )}
              {values.service_type === "analysis" && (
                <>
                  <Div width={1} mb={2} mt={[3, 3, 3, 30]}>
                    <TextMediumWeight>
                      {messages.label_data_source}
                    </TextMediumWeight>
                  </Div>
                  <MultiSelect
                    display="chip"
                    value={values?.data_source}
                    onChange={handleChange}
                    options={dataSourceTypes}
                    optionLabel="name"
                    placeholder={messages.placeholder_choose}
                    name={"data_source"}
                    width={1}
                    inputId="multiselect"
                  />
                </>
              )}
              {values.service_type === "bundle" && (
                <>
                  <Div width={1} mb={2} mt={[3, 3, 3, 30]}>
                    <TextMediumWeight>
                      {messages.label_services_in_bundle}
                    </TextMediumWeight>
                  </Div>
                  <MultiSelect
                    display="chip"
                    value={values?.child}
                    onChange={handleChange}
                    options={servicesInBundle}
                    optionLabel="name"
                    placeholder={messages.placeholder_choose}
                    name={"child"}
                    width={1}
                    inputId="multiselect"
                  />
                </>
              )}
              <InputText
                isLowercaseLabel
                curved
                placeholder={messages.label_service_name}
                name={"name"}
                formikProps={prop}
                type={TEXT}
                label={messages.label_service_name}
                labelAlignment="left"
                width={1}
                value={values?.name}
              />
              <Div display={["block", "flex"]}>
                <Div mr={[0, 3]}>
                  <Div
                    display="flex"
                    mb={2}
                    mt={3}
                    pt={1}
                    justifyContent="space-between"
                  >
                    <TextMediumWeight>
                      {messages.watchlist_label_price}
                    </TextMediumWeight>
                    <TextSmall color={" var(--grey-dark) !important"}>
                      {messages.price_currency_label}
                    </TextSmall>
                  </Div>
                  <InputText
                    isLowercaseLabel
                    curved
                    placeholder={messages.watchlist_label_price}
                    name={"price"}
                    formikProps={prop}
                    type={NUMBER}
                    labelAlignment="left"
                    width={1}
                    value={values?.price}
                  />
                </Div>
                <Div ml={[0, 2]} mr={[0, 3]}>
                  <InputText
                    isLowercaseLabel
                    curved
                    placeholder={messages.delivery_time_label}
                    name={"delivery_time"}
                    formikProps={prop}
                    type={TEXT}
                    label={messages.delivery_time_label}
                    labelAlignment="left"
                    width={1}
                    value={values.delivery_time}
                  />
                </Div>
                <Div ml={[0, 2]} mr={[0, 2]}>
                  <InputText
                    isLowercaseLabel
                    curved
                    name={"listing_order"}
                    formikProps={prop}
                    type={NUMBER}
                    label={messages.label_listing_order}
                    labelAlignment="left"
                    width={1}
                    value={values.listing_order}
                  />
                </Div>
                <Div ml={[0, 3]}>
                  <InputText
                    isLowercaseLabel
                    curved
                    name={"sku"}
                    formikProps={prop}
                    type={TEXT}
                    label={messages.label_sku}
                    labelAlignment="left"
                    width={1}
                    value={values.sku}
                  />
                </Div>
              </Div>
              <Div>
                <Div width={1} mb={2} mt={3}>
                  <TextMediumWeight>
                    {messages.label_description}
                  </TextMediumWeight>
                </Div>
                <Editor
                  value={values?.description}
                  onTextChange={handleChangeIndescription.bind(
                    this,
                    handleChange,
                  )}
                  height={"250px"}
                />
              </Div>
              <Div display={["block", "flex"]}>
                <Div
                  display="flex"
                  alignItems="center"
                  mt={[3, 3, 30]}
                  width={[1, 1, 1 / 2, 1 / 2]}
                >
                  <Switch
                    name={"default_selected"}
                    onChange={handleChange}
                    checked={values.default_selected}
                    mr={3}
                  />
                  <TextSemiBoldWeight fontWeight="var(--light-weight) !important">
                    {`${messages.label_default_selected}: ${
                      values.default_selected
                        ? messages.yes_label
                        : messages.no_label
                    }`}
                  </TextSemiBoldWeight>
                  {renderInfoIcon(
                    "description_selected",
                    messages.description_default_selected,
                  )}
                </Div>
                <Div
                  display="flex"
                  alignItems="center"
                  mt={[3, 3, 30]}
                  width={[1, 1, 1 / 2, 1 / 2]}
                >
                  <Switch
                    name={"purchase_by_credits"}
                    checked={values.purchase_by_credits}
                    onChange={handleChange}
                    mr={3}
                  />
                  <TextSemiBoldWeight fontWeight="var(--light-weight) !important">
                    {`${messages.label_credits_purchase_available}: ${
                      values.purchase_by_credits
                        ? messages.yes_label
                        : messages.no_label
                    }`}
                  </TextSemiBoldWeight>
                  {renderInfoIcon(
                    "purchase_by_credits",
                    messages.description_purchase_by_credits,
                  )}
                </Div>
              </Div>
              <Div display={["block", "flex"]}>
                <Div
                  display="flex"
                  alignItems="center"
                  width={[1, 1, 1 / 2, 1 / 2]}
                  mt={3}
                >
                  <Switch
                    name={"default_available"}
                    onChange={handleChange}
                    checked={values.default_available}
                    mr={3}
                  />
                  <TextSemiBoldWeight fontWeight="var(--light-weight) !important">
                    {`${messages.label_default_availability}: ${
                      values.default_available
                        ? messages.yes_label
                        : messages.no_label
                    }`}
                  </TextSemiBoldWeight>
                  {renderInfoIcon(
                    "description_availability",
                    messages.description_default_availability,
                  )}
                </Div>
                {values?.purchase_by_credits && (
                  <Div width={[1, 1, 255, 255]} mt={3}>
                    <InputText
                      isLowercaseLabel
                      curved
                      placeholder={messages.label_price_in_credits}
                      name={"price_credits"}
                      formikProps={prop}
                      type={NUMBER}
                      label={messages.label_price_in_credits}
                      labelAlignment="left"
                      width={1}
                      value={values?.price_credits}
                    />
                  </Div>
                )}
              </Div>
              <Div mt={40}>
                <PrimaryButtonIcon
                  rounded
                  semibold
                  mr={[0, 0, 0, 30]}
                  mb={[24, 24, 24, 0]}
                  width={[1, 1, 1, "auto"]}
                  height={40}
                  minWidth={186}
                  onClick={handleSubmit}
                  type="submit"
                  disabled={
                    permissions?.products === WRITE ? !(isValid && dirty) : true
                  }
                  label={messages.label_save}
                />
                {permissions?.products === WRITE && dirty && (
                  <PrimaryButtonIconOutlined
                    rounded
                    semibold
                    py={2}
                    px={4}
                    height={40}
                    type="button"
                    width={[1, 1, 1, "auto"]}
                    minWidth={206}
                    fontWeight={"var(--semibold-weight)"}
                    fontSize={"var(--fs-h5)"}
                    onClick={handleReset}
                    label={messages.label_cancel}
                  />
                )}
              </Div>
            </FormikForm>
          );
        }}
      </Formik>
    </Div>
  );
};

Form.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  isEditService: PropTypes.bool,
  dataSourceTypes: PropTypes.array,
};
export default Form;
