import React from "react";
import Div from "@components/Div";
import Section from "@components/Section";
import Container from "@components/Container";
import { H1, H4 } from "@components/Heading";
import FAQSection from "./FAQSection"; // Assuming you have a separate component named FAQSection
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import faq from "./faq.json";

const ConsentFooter = ({ title, description }) => {
  const { messages } = useIntl();

  return (
    <>
      <Section my={[2, 4]} p={"16px !important"} bg={"white"}>
        <Container pb={2} bg={"white !important"}>
          <Div width={1} alignItems="center">
            <Div mb={4} mt={3} width={1}>
              <H1>{title}</H1>
            </Div>
            <Div width={1}>
              <H4>{description}</H4>
            </Div>
          </Div>
        </Container>
      </Section>
      <FAQSection
        title={messages.FAQ_title}
        subtitle="LOREM IPSUM DOLOR SIT AMET, CONSETETUR"
        faqArr={faq}
      />
    </>
  );
};
ConsentFooter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default ConsentFooter;
