import React from "react";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { TextColoured, TextMediumWeight } from "@components/Text";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDate } from "@utils/utils";
const ActivityLog = (prop) => {
  let { activityLogs: orderData } = prop;
  const { messages } = useIntl();
  const activityLogChanges = {
    SO_STOPPED: messages.so_stopped,
    SO_REOPEN: messages.so_reopen,
    SO_DOWNGRADE: messages.so_downgrade,
    SO_UPGRADE: messages.so_upgrade,
    SO_NEW: messages.so_new,
    SO_RENEWAL: messages.renewal_of_term,
  };
  return (
    <Div mt={4}>
      <H2 mb={3}>{messages.subscription_activity_log}</H2>
      {orderData && orderData.length > 0 ? (
        orderData.map((order) => {
          const { order_items, created_at, user } = order.orders;
          let order_item = order_items[0].order_items;
          const name = order_item.name ?? "";
          const price = order_item.price
            ? Math.round(order_item.price * 100) / 100
            : "";
          const service_type = order_item.service_type ?? "";
          return (
            <Div
              py={2}
              key={order}
              display="flex"
              flexWrap="wrap"
              flexDirection={["column", "row"]}
            >
              <TextColoured color="var(--grey)">
                {formatDate(created_at)}
              </TextColoured>{" "}
              <TextMediumWeight pl={[0, "20px"]} pt={[2, 0]}>
                <FormattedMessage
                  id="subscription_activity_log_record"
                  values={{
                    service_type:
                      activityLogChanges[
                        user.type === "system" ? "SO_RENEWAL" : service_type
                      ],
                    service_name: name,
                    price: price.toLocaleString("sv-SE"),
                    user_type:
                      user?.type === "customer"
                        ? messages.subscription_customer
                        : user?.type,
                    user_name: user?.name,
                  }}
                />
              </TextMediumWeight>
            </Div>
          );
        })
      ) : (
        <Div py={2}>{messages.no_payment_received_yet}</Div>
      )}
    </Div>
  );
};
export default ActivityLog;
