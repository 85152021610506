import Axios from "@app/api/axios";

const updateAssignment = (payload) => {
  const { id = "", comment = "", user = {}, status = "" } = payload;
  const { value: userId = "" } = user || {};
  const data = {
    user_id: userId,
    status: status,
  };
  if (comment) {
    data.comment = comment;
  }

  return Axios.put(`assignments/${id}`, data);
};
export default updateAssignment;
