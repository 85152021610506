import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";
import RadioButton from "@components/RadioButton";
import { TextMediumWeight } from "@components/Text";

import InputWrapper from "./InputWrapper";

const InputRadioGroup = (props) => {
  const { options, value, onChange } = props;

  return (
    <InputWrapper {...props}>
      <Div mt={3} display="flex" alignItems="center" gridGap={5}>
        {options.map((option) => (
          <Div key={option.inputId} display="flex" alignItems="center">
            <RadioButton
              {...option}
              mr={2}
              onChange={onChange}
              checked={value === option.value}
            />
            <TextMediumWeight>{option.label}</TextMediumWeight>
          </Div>
        ))}
      </Div>
    </InputWrapper>
  );
};

InputRadioGroup.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  formikProps: PropTypes.object,
};

export default InputRadioGroup;
