import Axios from "@app/api/axios";
import { CUSTOMERS, COMPANY } from "@utils/constant";

const createCustomerService = (payload) => {
  const getPayload = () => {
    if (payload.type === COMPANY) {
      return payload;
    } else {
      const {
        email,
        firstname,
        lastname,
        phone,
        type,
        analysis_type: analysisType,
      } = payload;
      return {
        email,
        firstname,
        lastname,
        phone,
        type,
        analysis_type: analysisType,
      };
    }
  };
  const data = getPayload();

  if (payload && payload.customer_id) {
    data.customer_id = payload.customer_id;
  }
  return Axios.post(`${CUSTOMERS}`, data).then((response) => response);
};

export default createCustomerService;
