import Axios from "@app/api/axios";
import {
  formatDateAndTime,
  isCustomerUser,
  getInitials,
  formatDateAndTimeForCSV,
  formatDate,
} from "@utils/utils";
import {
  CUSTOMERS_API,
  ORDERS_API,
  ASSIGNMENTS_API,
  USERS_API,
  COMPANY,
  GET_SUBSCRIPTION,
  ADMIN_ANALYSIS_API,
  INDIVIDUAL,
  WATCH_LISTS,
  ROLE_ANALYSIS,
  GENERAL_ANALYSIS,
} from "@utils/constant";

const listDataService = (
  screenName = "",
  payload = {},
  customerId = "",
  userInfo = {},
) => {
  const { customer: { type: customerType = INDIVIDUAL } = {} } = userInfo;

  const getUserNames = (orderItems) => {
    const personNames = orderItems.map((i) => {
      return isCustomerUser() ? getInitials(i.person_name) : i.person_name;
    });
    const uniqueUsers = [...new Set(personNames)];
    const usersLength = uniqueUsers.length;
    if (!usersLength) return "-";
    if (uniqueUsers[0] !== "") {
      if (usersLength === 1) return uniqueUsers[0];
      else return `${uniqueUsers[0]} + ${usersLength - 1}`;
    } else return "-";
  };
  const createCSVFormatForOrders = (
    customer,
    order,
    companyName,
    createdAt,
    username,
    paymentType,
    orderItems,
    orderId,
    totalCredits,
  ) => {
    const {
      registration_nr = "",
      billing_email = "",
      phone = "",
      available_credits: availableCredits = 0,
      // total_credits: totalCredits = 0,
    } = customer || {};
    const { type: order_type } = order;
    const {
      name: productName,
      service_type,
      price,
      vat_amount,
    } = orderItems[0] || {};
    return {
      Id: orderId,
      Date: createdAt,
      "Customer Name": companyName,
      "Organization Number": registration_nr,
      User: username,
      "Billing Address": billing_email,
      "Phone Number": phone,
      Type: order_type,
      Package: productName,
      "Service Type": service_type,
      Credits: totalCredits ? `${availableCredits} / ${totalCredits}` : "-",
      Price: price,
      Tax: vat_amount,
      "Payment Method": paymentType,
      "Total Credits": totalCredits,
    };
  };

  const getGradeText = (grade = null) => {
    if (grade === "1") {
      return "Relevanta brott ej identifierade";
    } else if (grade === "-1") {
      return "Relevanta brott identifierade";
    } else {
      return "Resultat ej klart";
    }
  };

  const getOrdersResponse = (order) => {
    const {
      customer = {},
      user = {},
      order_items: orderItems = [],
      payments = [],
      order_id: orderId = "",
      total_credits: totalCredits = 0,
    } = order;

    const { name: subUser = "" } = user || {};
    const { payable_type: paymentType = "" } = (payments && payments[0]) || {};
    const { type = "", company_name: company = "", name = "" } = customer || {};
    const customerName = name || "";
    const companyName = type === COMPANY ? company || "" : name || "";
    const ordersTotal = orderItems.length;
    const serviceName =
      ordersTotal > 1
        ? `${orderItems[0]?.name} + ${ordersTotal - 1}`
        : orderItems[0]?.name;
    const createdAt = order.created_at
      ? formatDateAndTime(order.created_at)
      : "";
    const adminUsername = order.user ? order.user.name || "" : "";
    const username = getUserNames(orderItems);
    const csvFormat = createCSVFormatForOrders(
      customer,
      order,
      companyName,
      createdAt,
      username,
      paymentType,
      orderItems,
      orderId,
      totalCredits,
    );
    return {
      ...order,
      customer: companyName,
      service: serviceName,
      date: createdAt,
      username: customerId ? username : adminUsername,
      subUser: subUser,
      customerName: customerName,
      csvFormat: csvFormat,
    };
  };

  const getWatchListsResponse = (watchListsData = []) => {
    return watchListsData.map((watchlist) => {
      const {
        created_at: createdAt = "",
        updated_at: updatedAt = "",
        watch_list_objects: objects = [],
      } = watchlist || {};

      const date = createdAt ? formatDate(createdAt) : "";
      const lastResult = updatedAt ? formatDate(updatedAt) : "";
      return {
        ...watchlist,
        created_at: date || "",
        last_results: lastResult || "",
        objects: objects?.length,
      };
    });
  };

  const getResponseBasedOnScreen = (responseData) => {
    switch (screenName) {
      case CUSTOMERS_API: {
        const customersData = responseData.map((customer) => {
          const status = customer.status ? "Active" : "Deleted";
          const companyName = customer.company
            ? customer.company
            : customer.name
            ? customer.name
            : "";
          return { ...customer, status: status, company: companyName };
        });
        return customersData;
      }
      case GET_SUBSCRIPTION: {
        return responseData;
      }
      case ORDERS_API: {
        return responseData.map((order) => {
          return getOrdersResponse(order);
        });
      }
      case ADMIN_ANALYSIS_API: {
        const reportData = responseData.map((reports) => {
          const {
            created_at: createdAt = "",
            grades = null,
            customer = {},
            order = {},
            name: person = "",
            product = {},
            analysis_type: analysisType = GENERAL_ANALYSIS,
          } = reports || {};
          const { name: productName = "", grade_mapping: gradeMapping = {} } =
            product || {};
          const { grade = [] } = gradeMapping || {};
          const {
            color: gradeColor = "white",
            bgColor: gradeBgColor = "grey",
          } = grades ? grade?.find((o) => o?.grades === grades) || {} : {};
          const { type = "", company_name = "", name = "" } = customer || {};
          const { user = {} } = order || {};
          const { name: username = "" } = user || {};
          const nameBasedofUserType = type === COMPANY ? company_name : name;
          const customerName =
            customerType === COMPANY ? username : nameBasedofUserType;
          const date = createdAt ? formatDateAndTimeForCSV(createdAt) : "";
          const csvFormat = {
            Id: reports?.id,
            Date: date,
            "Person Name": isCustomerUser()
              ? getInitials(person || "")
              : person,
            Result:
              analysisType === ROLE_ANALYSIS ? getGradeText(grades) : grades,
            User: customerName,
          };
          return {
            ...reports,
            date: date || "",
            grades,
            username: customerName,
            client: isCustomerUser() ? getInitials(person) : person,
            product: productName,
            csvFormat: csvFormat,
            gradeBgColor,
            gradeColor,
          };
        });
        return reportData;
      }
      case ASSIGNMENTS_API: {
        const assignmentsData = responseData.map((assignment) => {
          const {
            created_at: createdAt = "",
            product = {},
            order = {},
            user = {},
          } = assignment || {};
          const serviceName = product ? product.name : "";
          const { name = "" } = user || {};
          const { number: orderNumber = "" } = order || {};
          const date = createdAt ? formatDateAndTime(createdAt) : "";
          return {
            ...assignment,
            date: date || "",
            product: serviceName || "",
            order: orderNumber || "",
            assignment: name || "",
          };
        });
        return assignmentsData;
      }
      case USERS_API: {
        const usersData = responseData.map((user) => {
          const { name, role = [] } = user;
          const roleName = role.length ? role[0].name : "";
          return { ...user, user: name, role: roleName };
        });
        return usersData;
      }
      case WATCH_LISTS: {
        return getWatchListsResponse(responseData);
      }
    }
  };

  return Axios.get(screenName + payload)
    .then((response) => {
      const { status } = response;
      const { data, meta } = response.data;
      return { meta, status, data: getResponseBasedOnScreen(data) };
    })
    .catch((e) => {
      return e.response;
    });
};

export default listDataService;
