import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { default as Div } from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import {
  PrimaryButtonIconOutlined,
} from "@components/Button";
import JsonFormCard from "@components/JsonForm/JsonFormCard";
import * as Yup from "yup";
import { authActions } from "@app/reducers/authReducer";
import putUserService from "@app/services/users/putUserService";
import JsonFormDialog from "@components/JsonForm/JsonFormDialog";
import { useToast } from "@hooks/useToast";
import AdminContainer from "@layout/AdminContainer";
import UserContainer from "@layout/UserContainer";
import Password from "@src/schema/Password";
import ConfirmationPopup from "@components/ConfirmationPopup";
import { deleteUserProfile } from "@app/services/users/deleteUserProfileService";

import { useUser } from "@contexts/AuthProvider";


export const MyProfile = () => {
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();
  const { firstname, lastname, email, phone, id } = userInfo;
  const { isAdmin, isCustomer } = useUser();

  const [isPasswordDialogVisible, setIsPasswordDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);


  const handleDeleteProfile = () => {
    setIsDeleteDialogVisible(true);
  };
  const handleCancelDeleteProfile = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleChangePassword = () => {
    setIsPasswordDialogVisible(true);
  };

  const handleCancelChangePassword = () => {
    setIsPasswordDialogVisible(false);
  };

  const contact = [
    {
      label: <FormattedMessage id="label_firstname" />,
      fieldType: "text",
      name: "firstname",
      value: firstname,
      placeholder: <FormattedMessage id="label_firstname" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_last_name" />,
      fieldType: "text",
      name: "lastname",
      value: lastname,
      placeholder: <FormattedMessage id="label_last_name" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_last_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_email" />,
      fieldType: "text",
      name: "email",
      value: email,
      placeholder: <FormattedMessage id="label_email" />,
      validation: Yup.string().required(
        <FormattedMessage id="label_required" />
      ),
    },
    {
      label: <FormattedMessage id="label_phone" />,
      fieldType: "text",
      name: "phone",
      value: phone,
      placeholder: <FormattedMessage id="label_phone" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_phone_number" />
      ),
    },
  ];

  const password = [
    {
      label: <FormattedMessage id="label_current_password" />,
      fieldType: "password",
      name: "old_password",
      placeholder: <FormattedMessage id="label_current_password" />,
      validation: Password.fields.current_password,
    },
    {
      label: <FormattedMessage id="label_new_password" />,
      fieldType: "password",
      name: "password",
      placeholder: <FormattedMessage id="label_new_password" />,
      validation: Password.fields.password,
    },
    {
      label: <FormattedMessage id="label_repeat_new_password" />,
      fieldType: "password",
      name: "confirm_password",
      placeholder: <FormattedMessage id="label_repeat_new_password" />,
      validation: Password.fields.confirm_password,
    },
  ];

  const handleFormSubmit = async values => {
    try {
      const { data: user } = await putUserService(id, values);
      dispatch(authActions.storeUserInfo({ ...user }));

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handlePasswordSubmit = async values => {
    try {
      await putUserService(id, values);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteUserProfile({
        id,
      });
      localStorage.clear();
      showSuccessToast(messages.text_deleted_successful);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const initialValues = {
    changePassword: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
  };

  let headerConfig = {
    title: messages.personal_setting,
  };

  const renderContent = () => (
      <Div pt={"16px"}>
        <H2 mb={3} display={["flex", "flex", "flex", "none"]}>
          {messages.personal_setting}
        </H2>

        <Div display="flex" flexDirection="column" width={1} >
          <JsonFormCard
            title={messages.title_user_info}
            config={contact}
            onSubmit={handleFormSubmit}
            isEditPermission="true"
          />
        </Div>

        <Div display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap" mt={2} width={1}>
            <PrimaryButtonIconOutlined
              width={[1, 1, "250px", "250px"]}
              margin={["16px"]}
              label={messages.label_change_password}
              onClick={handleChangePassword}
              icon={<Icon mr={2} name="menu-edit" />}
            />
            {isPasswordDialogVisible && (
              <JsonFormDialog
                title={messages.label_change_password}
                config={password}
                onHide={handleCancelChangePassword}
                onSubmit={handlePasswordSubmit}
                initialValues={initialValues.changePassword}
              />
            )}

            <PrimaryButtonIconOutlined
              color="secondaryRed"
              width={[1, 1, "250px", "250px"]}
              margin={["16px"]}
              label={messages.label_delete_account}
              onClick={handleDeleteProfile}
              icon={<Icon mr={2} name="rubber" />}
            />
            {isDeleteDialogVisible && (
              <ConfirmationPopup
                title={messages.label_delete_account}
                description={messages.user_delete_message}
                onCancelClick={handleCancelDeleteProfile}
                onAcceptClick={handleDeleteSubmit}
              />
            )}
        </Div>

      </Div>
  );
  return (
    <>
      {isCustomer && <UserContainer config={headerConfig}>{renderContent()}</UserContainer>}
      {isAdmin && <AdminContainer config={headerConfig}>{renderContent()}</AdminContainer>}
    </>
  );
};

export default MyProfile;
