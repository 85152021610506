import React from "react";
import { FormattedMessage } from "react-intl";

import * as Yup from "yup";

const ValidationSchema = Yup.object({
  customer: Yup.lazy((value) =>
    typeof value === "string"
      ? Yup.string().required(
          <FormattedMessage id="validation_empty_user_id" />,
        )
      : Yup.object().required(
          <FormattedMessage id="validation_empty_user_id" />,
        )
  ),
  user: Yup.object().required(
    <FormattedMessage id="validation_empty_user_id" />,
  ),
});

export default ValidationSchema;
