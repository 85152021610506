import React, { useState, useEffect, memo, useRef } from "react";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import queryString from "query-string";

import getReportAnalys from "@app/services/reports/getReportDetails";

import CaseReport from "@components/Report/CaseReport/CaseReport";
import AssignmentReport from "@components/Report/LegacyReport/AssignmentReport";
import LegacyReport from "@components/Report/LegacyReport/LegacyReport";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { trackPageView } from "@src/analyticsFunctions";

import { ROUTES, STATUS_EXPIRED } from "@utils/constant";

const getDetails = async reportId => {
  const response = await getReportAnalys(reportId);
  return response;
};

const Report = ({ currentReport }) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const { search = "" } = useLocation();
  const { isCustomer } = useAuthorization();
  const { showErrorToast } = useToast();
  const { id: reportId = "" } = queryString.parse(search);
  const apiCalled = useRef(false); // Tracks if the API has already been called

  const {
    documents = [],
    analysis_type: analysisType = "",
    is_case: isCase,
  } = report;

  const isAssignment = analysisType === "manual";
  const assignmentReport = documents[0];
  const assignmentDetails = {
    url: assignmentReport?.url,
    productName: report?.product?.name,
  };

  useEffect(() => {
    if (isExpired) {
      history.push("/error404");
    }
  }, [history, isExpired]);

  useEffect(() => {
    trackPageView();

    const getReport = async () => {
      setIsLoading(true);
      try {
        const response = await getDetails(reportId);
        const { data: { data: _report = {} } = {} } = response;
        const { report_status: reportStatusLabel = "" } = _report;
        if (reportStatusLabel === STATUS_EXPIRED) {
          setIsLoading(false);
          setIsExpired(true);
          return;
        }
        setReport(_report);
      } catch (e) {
        showErrorToast(messages.error);
      } finally {
        setIsLoading(false);
        apiCalled.current = false;
      }
    };
    if (currentReport) {
      setReport(currentReport);
    } else if (!apiCalled.current) {
      getReport();
      apiCalled.current = true;
    }
  }, [currentReport, reportId, isCustomer, showErrorToast, messages.error]);

  const reloadDetails = () => {
    getDetails();
  };

  const handleCloseResultsDialog = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(ROUTES.ALLANALYSIS_LIST.URL);
    }
  };

  return (
    <FullSizeDialog
      title={messages.label_report}
      onClose={handleCloseResultsDialog}
    >
      {isLoading && (
        <Div minHeight="100vh" width={1}>
          <ProgressSpinner />
        </Div>
      )}

      {!isLoading && (
        <>
          {isCase && <CaseReport report={report} />}
          {isAssignment && <AssignmentReport assignment={assignmentDetails} />}
          {!isAssignment && !isCase && (
            <LegacyReport
              report={report}
              reloadDetails={reloadDetails}
              isLoading={isLoading}
            />
          )}
        </>
      )}

      <Div mt={4}>
        <PrivacyPolicyFooter />
      </Div>
    </FullSizeDialog>
  );
};

Report.propTypes = {
  currentReport: PropTypes.object,
};

export default memo(Report);
