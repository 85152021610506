import React, { useState, useEffect  } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import CardWrapper from "@pages/admin/users/components/CardWrapper";
import { Formik, Form } from "formik";
import createCustomerService from "@app/services/auth/createCustomerService";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import JsonForm from "@components/JsonForm/JsonForm";
import * as Yup from "yup";
import ProgressSpinner from "@components/ProgressSpinner";
import { ValidateOrganisationNumber } from "@utils/utils";
import userService from "@app/services/users/userService";
import ConsentDropDownOptions from "@constants/consentDropDownOptions";
import YesnoOptions from "@constants/yesnoOptions";
import CompanyType from "@constants/companyTypeOptions";
import CustomerStatus from "@constants/customerStatus";
import { useToast } from "@hooks/useToast";
import fileUploadService from "@app/services/files/fileUploadService";
import DeliveryDropDown from "@constants/customerDocumentDeliveryOption";
import DeliveryFrequencyOptions from "@constants/deliveryFrequencyOptions";
import DeliveryMethodOptions from "@constants/deliveryMethodOptions";
import ContentOptions from "@constants/contentOptions";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const CustomerAccountDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedManager, setSelectedManager] = useState([]);
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    async function fetchUsers() {
      setIsLoading(true);
      try {
        const response = await userService("filter[type]=admin&filter[status]=active&filter[is_active]=1&per_page=100");
        const { data: { data: users = [] } = {} } = response;
        const userDropDownOptions = users.map(({ name = "", id = "" }) => ({
          label: `${name}`,
          value: id,
        }));
        setSelectedManager(userDropDownOptions);
      } catch (error) {
        showErrorToast(messages.text_no_data_found);
      }
      setIsLoading(false);
    }
    fetchUsers();
  }, [showErrorToast, messages]);

  const consentOptions = ConsentDropDownOptions();
  const yesnoOptions = YesnoOptions();
  const customerStatus = CustomerStatus();
  const companyTypeOptions = CompanyType();



  const company = [
    {
      label: <FormattedMessage id="label_company_name" />,
      fieldType: "text",
      name: "company_name",
      validation: Yup.string()
        .typeError(<FormattedMessage id="validation_empty_company_name" />)
        .required(<FormattedMessage id="validation_empty_company_name" />),
    },
    {
      label: <FormattedMessage id="label_organisation_number" />,
      fieldType: "text",
      name: "registration_nr",
      maxLength:11,
      validation: Yup.string()
        .required(<FormattedMessage id="validation_valid_company_number" />)
        .test({
          name: "valid-company-number",
          message: <FormattedMessage id="validation_valid_company_number" />,
          test: ValidateOrganisationNumber,
        }),

    },
    {
      label: <FormattedMessage id="label_company_address" />,
      fieldType: "text",
      name: "address",
    },
    {
      label: <FormattedMessage id="label_postal_code" />,
      fieldType: "text",
      name: "zip_code",
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_zip_code" />
      ),
    },
    {
      label: <FormattedMessage id="label_city" />,
      fieldType: "text",
      name: "city",
      validation: Yup.string().required(
        <FormattedMessage id="validation_enter_city" />
      ),
    },
    {
      label: <FormattedMessage id="account_manager" />,
      fieldType: "dropdown",
      name: "account_manager_id",
      options: selectedManager,
      validation: Yup.string().required(
        messages.validation_customer_account_manager_required
      ),
    },
  ];

  const contact = [
    {
      label: <FormattedMessage id="label_firstname" />,
      fieldType: "text",
      name: "firstname",
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_last_name" />,
      fieldType: "text",
      name: "lastname",
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_last_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_email" />,
      fieldType: "text",
      name: "email",
      validation: Yup.string()
        .required(<FormattedMessage id="validation_empty_email_input" />)
        .email(<FormattedMessage id="validation_valid_email_input" />),
    },
    {
      label: <FormattedMessage id="label_phone" />,
      fieldType: "text",
      name: "phone",
      validation: Yup.string()
        .typeError(messages.validation_valid_phone_number)
        .required(<FormattedMessage id="validation_empty_phone_number" />),
    },
    {
      label: <FormattedMessage id="label_billing_email" />,
      fieldType: "text",
      name: "billing_email",
      placeholder: <FormattedMessage id="label_billing_email" />,
      validation: Yup.string()
        .required(<FormattedMessage id="validation_empty_email_input" />)
        .email(<FormattedMessage id="validation_valid_email_input" />),
    },
  ];

  const settings = [
    {
      label: <FormattedMessage id="consent_title" />,
      fieldType: "dropdown",
      name: "consent",
      options: consentOptions,
    },
    {
      label: <FormattedMessage id="consent_label_status" />,
      fieldType: "dropdown",
      name: "is_active",
      options: customerStatus,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_status" />
      ),
    },
    {
      label: <FormattedMessage id="order_email_notification" />,
      fieldType: "dropdown",
      name: "order_confirmation_email",
      options: yesnoOptions,
      validation: Yup.string().required(
        <FormattedMessage id="validation_order_confirmation_email_required" />
      ),
    },
    {
      label: <FormattedMessage id="api_integration" />,
      fieldType: "dropdown",
      name: "integration_api_enabled",
      options: yesnoOptions,
      validation: Yup.string().required(
        <FormattedMessage id="validation_apiintegration_required" />
      ),
    },
    {
      label: <FormattedMessage id="test_account" />,
      fieldType: "dropdown",
      name: "test_account",
      options: yesnoOptions,
    },
    {
      label: <FormattedMessage id="label_company_type" />,
      fieldType: "dropdown",
      name: "company_type",
      options: companyTypeOptions,
      validation: Yup.string().required(
        <FormattedMessage id="validation_valid_company_type" />
      ),
    },
  ];
  const pub = [
    {
      label: <FormattedMessage id="pub_agreement" />,
      fieldType: "dropdown",
      name: "pub_agreement",
      options: yesnoOptions,
      validation: Yup.string().required(
        <FormattedMessage id="validation_pub_agreement_required" />
      ),
    },
    {
      name: "pub_date",
      label: <FormattedMessage id="pub_date" />,
      fieldType: "calendar",
      show: values => values.pub_agreement === 1,

      validation: Yup.string().when("pub_agreement", {
        is: pubAgreement => pubAgreement === 1,
        then: () =>
          Yup.string().required(
            <FormattedMessage id="validation_pub_agreement_required" />
          ),
        otherwise: () => Yup.string(),
      }),
    },
    {
      name: "file_name",
      label: <FormattedMessage id="customer_pub_document" />,
      fieldType: "fileupload",
    },

  ];

  const executionConfig = [
    {
      label: <FormattedMessage id="admin_customer_details_delivery_time" />,
      fieldType: "text",
      name: "delivery_time",
      placeholder: <FormattedMessage id="admin_customer_details_delivery_time" />
    },
    {
      label: <FormattedMessage id="admin_customer_details_delivery_place" />,
      fieldType: "text",
      name: "delivery_place",
      placeholder: <FormattedMessage id="admin_customer_details_delivery_place" />
    },
    {
      label: <FormattedMessage id="admin_customer_details_delivery_description" />,
      fieldType: "textarea",
      name: "delivery_description",
      placeholder: <FormattedMessage id="admin_customer_details_delivery_description" />,
    },
    {
      label: <FormattedMessage id="admin_customer_details_interview_template" />,
      fieldType: "dropdown",
      name: "interview_template",
      options: DeliveryDropDown,
      placeholder: <FormattedMessage id="admin_customer_details_interview_template" />,
      maxSelectedLabels: 3,
      className: "w-full md:w-20rem"
    },
  ];

  const deliveryConfig = [
    {
      label: <FormattedMessage id="admin_customer_details_delivery_frequency" />,
      fieldType: "dropdown",
      name: "delivery_frequency",
      options: DeliveryFrequencyOptions,
      placeholder: <FormattedMessage id="admin_customer_details_delivery_frequency" />
    },
    {
      label: <FormattedMessage id="admin_customer_details_delivery_method" />,
      fieldType: "dropdown",
      name: "delivery_method",
      options: DeliveryMethodOptions,
      placeholder: <FormattedMessage id="admin_customer_details_delivery_method" />
    },
    {
      label: <FormattedMessage id="admin_customer_details_other" />,
      fieldType: "textarea",
      name: "delivery_frequency_other",
      placeholder: <FormattedMessage id="admin_customer_details_other" />,
      show: values => values.delivery_frequency === "other",
      gridColumn: "1 / -1",
    },
    {
      label: <FormattedMessage id="admin_customer_details_content" />, 
      fieldType: "multiselect",
      name: "content",
      options: ContentOptions,
      optionLabel: "name",
      placeholder: <FormattedMessage id="admin_customer_details_content" />,
    },
  ];

  const documentConfig = [
    {
      label: <FormattedMessage id="customer_pub_document" />,
      fieldType: "multifiles",
      name: "delivery_documents",
      placeholder: <FormattedMessage id="delivery_time_label" />,
    }
  ];

  const initialValues = {
    company_name: "",
    registration_nr: "",
    address: "",
    zip_code: "",
    city: "",
    account_manager_id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    consent: "",
    is_active: "active",
    order_confirmation_email: "",
    integration_api_enabled: "",
    test_account: "",
    pub_agreement: "",
    pub_date: "",
    billing_email: "",
    company_type:"",
    pub_agreement_file_id:"",
    document:"",
    delivery_description:"",
    delivery_place:"",
    delivery_time:"",
    interview_template:"",
    delivery_frequency:"",
    delivery_method:"",
    content:[],
    delivery_frequency_other:"",
    delivery_documents:[]
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const COMPANY = "company";
      const type = COMPANY;

      let formData = new FormData();
      if (values.file_name) {
        const file = values.file_name;
        formData.append("media", file);
        formData.append("type", file.type);
        formData.append("category", messages.customer_pub_document);
      }

      let fileUploadResponse = {};
      if (values.file_name) {
        fileUploadResponse = await fileUploadService(formData);
      }

      const { data: uploadedFileData = {} } = fileUploadResponse;

      const {
        company_name,
        registration_nr,
        address,
        zip_code,
        city,
        account_manager_id,
        firstname,
        lastname,
        email,
        phone,
        consent,
        is_active,
        order_confirmation_email,
        integration_api_enabled,
        test_account,
        pub_agreement,
        pub_date,
        billing_email,
        company_type,
        delivery_description,
        delivery_place,
        delivery_time,
        interview_template,
        delivery_frequency,
        delivery_method,
        content,
        delivery_frequency_other,
        delivery_documents
      } = values;
      // Prepare payload for customer creation
      let payload = {
        type: type,
        company_name,
        registration_nr,
        address,
        zip_code,
        city,
        account_manager_id,
        firstname,
        lastname,
        email,
        phone,
        consent,
        is_active,
        order_confirmation_email,
        integration_api_enabled,
        test_account, // Ensure boolean value
        pub_agreement,
        pub_date,
        billing_email,
        company_type,
        pub_agreement_file_id: uploadedFileData.id || null,
        delivery_description,
        delivery_place,
        delivery_time,
        interview_template,
        delivery_frequency,
        delivery_method,
        content: content || [],
        delivery_frequency_other,
        delivery_documents
      };

      if (values?.delivery_documents?.length > 0) {
          const deliveryDocumentsResponses = await Promise.all(
            values.delivery_documents.map(async ({ file, file_id }) => {
              if (file_id) {
                return { data: { id: file_id } };
              }

              const deliveryFormData = new FormData();
              deliveryFormData.append("media", file);
              deliveryFormData.append("type", file.type);
              deliveryFormData.append("category", messages.customer_pub_document);

              return await fileUploadService(deliveryFormData);
            })
          );

          payload.delivery_documents = deliveryDocumentsResponses.map(({ data }, index) => ({
            file_id: data.id || null,
            document_description: values.delivery_documents[index]?.document_description || "",
          }));
      }

      await createCustomerService(payload);

      showSuccessToast(messages.text_update_successful);
      handleClose();
    } catch (e) {
      const errors = e.response?.data?.errors;

      if (errors) {
          const errorField = Object.keys(errors)[0];
          const errorMessage = errors[errorField]?.[0];
          const translatedMessage = messages[`${errorMessage}`];
          showErrorToast(translatedMessage || e.response.data.message);
      } else {
          showErrorToast("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.title_create_customer}
        onClose={handleClose}
      >
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ isSubmitting, dirty, isValid }) => (
            <StyledCreateBilling>
              <Form>
                <CardWrapper title={messages.label_details}>
                  <JsonForm config={company} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.title_contact_person}>
                  <JsonForm config={contact} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.Settings}>
                  <JsonForm config={settings} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.pub}>
                  <JsonForm config={pub} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.admin_customer_details_execution}>
                  <JsonForm config={executionConfig} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.admin_customer_details_delivery}>
                  <JsonForm config={deliveryConfig} formLayout={formLayout} />
                </CardWrapper>

                <CardWrapper title={messages.admin_customer_document_card_documents}>
                  <JsonForm config={documentConfig} />
                </CardWrapper>

                <Div
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  alignItems="center"
                  justifyContent="center"
                  gridGap={4}
                  mt={4}
                >
                  <PrimaryButtonOutlined
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_cancel}
                    onClick={handleClose}
                  />
                  <PrimaryButton
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_create}
                    type="submit"
                    disabled={!dirty || isSubmitting || !isValid}
                  />
                </Div>
              </Form>
            </StyledCreateBilling>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};

CustomerAccountDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default CustomerAccountDialog;
