import React from 'react';
import PropTypes from 'prop-types';
import Div from '@components/Div';
import Editor from '@components/Editor';
import { useFormikContext } from 'formik';

const EditorComponent = ({ name, placeholder }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleTextChange = (value) => {
    setFieldValue(name, value.htmlValue);
  };
  return (
    <Div flex="1 1 40%">
      <Editor
        mt={2}
        name={name}
        value={values?.[name]}
        onTextChange={handleTextChange}
        placeholder={placeholder}
      />
    </Div>
  );
};

EditorComponent.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default EditorComponent;
