import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { servicesReducer } from "./servicesReducer";
import { personInformationReducer } from "./personInformationReducer";
import { klarnaReducer } from "./klarnaReducer";

export const rootReducer = combineReducers({
  authReducer,
  servicesReducer,
  personInformationReducer,
  klarnaReducer,
});
