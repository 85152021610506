import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const { messages } = useIntl();
  const showToast = useCallback((severity, detail, summary, life = 4000) => {
    toast?.current?.show({ severity, summary, detail, life });
  }, []);

  const showErrorToast = useCallback(
    (detail, summary = messages.label_error, life = 4000) => {
      showToast("error", detail, summary, life);
    },
    [showToast, messages],
  );
  const showSuccessToast = useCallback(
    (detail, summary = messages.label_success, life = 4000) => {
      showToast("success", detail, summary, life);
    },
    [showToast, messages],
  );
  const value = useMemo(
    () => ({ showToast, showErrorToast, showSuccessToast }),
    [showToast, showErrorToast, showSuccessToast],
  );

  return (
    <ToastContext.Provider value={value}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
