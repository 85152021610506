import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";

import removeCredentials from "@app/services/integrations/removeCredentials";

import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import PromptDialog from "@components/PromptDialog";
import { Text } from "@components/Text";

import { BACKGROUND_SE, INTEGRATIONS_API_URL, ROUTES } from "@utils/constant";

import { CreateCredentialsHeader } from "./components";
import { QUESTION_DIALOG_TYPE, QUESTION_DIALOG_CONTENT } from "./types";

const IntegrationsAPIList = () => {
  const { messages } = useIntl();

  const [questionDialogContentType, setQuestionDialogContentType] = useState(
    QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.NONE]
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectedCredentialsId = useRef(null);

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  const handleDeleteCredentials = async () => {
    try {
      setIsLoading(true);

      const { current: id } = selectedCredentialsId ?? {};

      await removeCredentials(id);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      handleCloseQuestionDialog();
    }
  };

  const handleCloseQuestionDialog = () => {
    setQuestionDialogContentType(
      QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.NONE]
    );
  };

  const handleOpenDeleteQuestionDialog = values => {
    const { id } = values;

    selectedCredentialsId.current = id;

    setQuestionDialogContentType({
      ...QUESTION_DIALOG_CONTENT[QUESTION_DIALOG_TYPE.DELETE],
      handleConfirm: handleDeleteCredentials,
    });
  };

  const renderEmptyTemplate = () => (
    <Div display="flex" flexDirection="column" gridGap={3}>
      <H4>{messages.message_no_api_keys}</H4>
      <Text>{messages.message_start_by_creating_one}</Text>
      <Text>{messages.message_need_to_understand_api}</Text>
      <LinkArrow
        href={`${BACKGROUND_SE}${ROUTES.CONTACT_US_ID.URL}`}
        label={messages.contact_us}
        width={1}
        direction="left"
        variant="secondary"
      >
        <Icon ml={1} name="headerarrowright" />
      </LinkArrow>
    </Div>
  );

  const formatStatus = value =>
    value ? messages.active : messages.label_disabled;

  const formatSecret = () => "********";

  const config = {
    header: {
      title: "header_manage_api_keys",
    },
    emptyTemplate: renderEmptyTemplate,
    backend_querying: true,
    enable_query_builder: true,
    api: {
      resource: `${INTEGRATIONS_API_URL}/credentials`,
      method: "GET",
    },
    columns: [
      {
        db_field: "api_access_client_id",
        title: "header_client_id",
        type: "text",
        width: "20%",
      },
      {
        db_field: "secret",
        title: "header_secret",
        type: "text",
        formatter: formatSecret,
        width: "20%",
      },
      {
        db_field: "created_at",
        title: "header_created_on",
        type: "dateTime",
        width: "25%",
      },
      {
        db_field: "active",
        title: "label_status",
        type: "enum",
        formatter: formatStatus,
        width: "20%",
      },
      {
        type: "cancel",
        command: handleOpenDeleteQuestionDialog,
        width: "5%",
      },
    ],
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorModal}
          onConfirm={handleCloseErrorModal}
        />
      )}

      {questionDialogContentType && (
        <PromptDialog
          title={messages[questionDialogContentType?.titleKey]}
          message={messages[questionDialogContentType?.messageKey]}
          confirmLabel={messages[questionDialogContentType?.confirmLabel]}
          onConfirm={questionDialogContentType?.handleConfirm}
          onCancel={handleCloseQuestionDialog}
        />
      )}

      <CreateCredentialsHeader
        onError={setErrorMessage}
        onLoading={setIsLoading}
      />

      <DataTable config={config} resetData={isLoading} />
    </>
  );
};

export default IntegrationsAPIList;
