import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Link from "@components/Link";
import {
  TextLargeSemiBoldWeight as StyledTextLargeSemiBoldWeight,
  TextLarge as StyledTextLarge,
} from "@components/Text";

import Config from "@src/config";

import {
  ADDRESS,
  CO_RESIDENTS,
  INPUT,
  MARITIAL_STATUS,
  ROUTES,
} from "@utils/constant";
import { addHyphenToNumber, getFormattedPinCode } from "@utils/utils";

function calculateAge(ssn) {
  // Extract year, month, and day from the SSN
  const year = parseInt(ssn.slice(0, 4), 10);
  const month = parseInt(ssn.slice(4, 6), 10);
  const day = parseInt(ssn.slice(6, 8), 10);

  // Create a Date object for the birthdate
  const birthDate = new Date(year, month - 1, day);

  // Get the current date
  const today = new Date();

  // Calculate the age
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust if the birthday hasn't occurred yet this year
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

const prepareCoResidents = (coResidents = [], messages = {}) => {
  const listOfCoResidents = coResidents.map(resident => {
    const residentAge = resident?.ssn ? calculateAge(resident.ssn) : null;
    if (residentAge < 18) {
      const { gender = null, born = "" } = resident;
      return `${gender ? messages[resident.gender] : ""} ${
        messages.label_born
      } ${born}`;
    }

    if (resident?.ssn) {
      const { full_name = "", ssn = "" } = resident;
      const handleClick = () =>
        window.open(
          `${Config.APP_URL}${
            ROUTES.WIZARD_SELECT_SERVICES.URL
          }${addHyphenToNumber(ssn.toString(), 8)}`
        );

      return { full_name, ssn, handleClick };
    }
  });

  return listOfCoResidents;
};

const PersonDetails = ({ report = {}, isPrintable = false, config = {} }) => {
  const { sectionTitle = "", content = [] } = config;
  const { messages } = useIntl();

  const TextLarge = styled(StyledTextLarge)`
    font-size: ${isPrintable && "var(--fs-text-s)"};
    line-height: ${isPrintable && "150%"};
  `;

  const TextLargeSemiBoldWeight = styled(StyledTextLargeSemiBoldWeight)`
    font-size: ${isPrintable && "var(--fs-text-s)"};
    line-height: ${isPrintable && "150%"};
  `;
  const { personal_number = "", co_residents } = report;

  const displayCoResidents = useMemo(() => {
    const _coResidents = prepareCoResidents(co_residents || [], messages);

    return _coResidents.map((resident, index) => (
      <>
        {resident?.ssn ? (
          <>
            {resident.full_name}&nbsp;
            <Link
              rel="noopener noreferrer"
              display="inline"
              handleClick={resident.handleClick}
              fontSize={isPrintable && "var(--fs-text-s)"}
              fontWeight={isPrintable && "var(--regular-weight)"}
            >
              ({addHyphenToNumber(resident?.ssn?.toString(), 8)})
            </Link>
          </>
        ) : (
          <>{resident}</>
        )}
        {index < _coResidents.length - 1 && ", "}
      </>
    ));
  }, [messages, co_residents, isPrintable]);

  const constructContent = arr =>
    arr.map((value, index) => {
      const { title = "", key = "" } = value;

      return (
        <Div key={`${key}_${index}`} display={["block", "flex"]}>
          <Div
            pb={isPrintable ? 1 : [2, 2, 2, 3]}
            wordBreak={["break-word", ""]}
          >
            <TextLargeSemiBoldWeight>{`${messages[title]}:`}</TextLargeSemiBoldWeight>

            {key === ADDRESS && (
              <TextLarge
                ml={[
                  0,
                  isPrintable ? 1 : 2,
                  isPrintable ? 1 : 2,
                  isPrintable ? 1 : 2,
                ]}
                display="inline-block"
              >
                {report.street_address
                  ? `${report.street_address}, ${getFormattedPinCode(
                      report.zip_code
                    )} ${report.city}`
                  : ""}
              </TextLarge>
            )}

            {key === CO_RESIDENTS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {displayCoResidents.length > 0 ? displayCoResidents : messages.label_no_roommates}
              </TextLarge>
            )}

            {key === MARITIAL_STATUS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {messages[report[key]?.toLowerCase() || ""]}
              </TextLarge>
            )}

            {key !== MARITIAL_STATUS && key !== CO_RESIDENTS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {key === INPUT.NAME.PERSONAL_NUMBER
                  ? addHyphenToNumber(personal_number.toString(), 8) || ""
                  : report[key] || ""}
              </TextLarge>
            )}
          </Div>
        </Div>
      );
    });

  return (
    <Div mb={isPrintable ? 3 : "20px"}>
      <H2
        color="var(--blue-dark) !important"
        mb={isPrintable ? 2 : 3}
        lineHeight={isPrintable && "var(--fs-text) !important"}
        fontSize={isPrintable && "var(--fs-h4) !important"}
      >
        {messages[sectionTitle]}
      </H2>
      <Div>{constructContent(content)}</Div>
    </Div>
  );
};

PersonDetails.propTypes = {
  report: PropTypes.object,
  isPrintable: PropTypes.bool,
  config: PropTypes.object,
};

export default PersonDetails;
