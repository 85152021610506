import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Tabs from "@components/Tabs";
import AdminContainer from "@layout/AdminContainer";
import { ROUTES } from "@utils/constant";
import AdminEmailTemplateList from "@pages/admin/email/template/list";
import AdminEmailAccountsList from "@pages/admin/email/accounts/list";

const TAB_CONTENT = [
  {
    title: <FormattedMessage id="email_accounts_lable" />,
    content: <AdminEmailAccountsList />,
    url: ROUTES.ADMIN_EMAIL_ACCOUNTS_LIST.URL,
  },
  {
    title: <FormattedMessage id="email_template_lable" />,
    content: <AdminEmailTemplateList />,
    url: ROUTES.ADMIN_EMAIL_TEMPLATE_LIST.URL,
  },
];
const List = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(
      (item) => item.url === pathname,
    );
    setActiveIndex(activeTabIndex);
  }, [history]);
  const handleTabChange = (event) => {
    const { index } = event;
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };
  const headerConfig = {
    title: messages.title_e_mails,
  };
  return (
    <AdminContainer config={headerConfig} wide>
      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default List;
