import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import Icon from "@components/Icon";
import Link from "@components/Link";

import { useReportHooks } from "../../hooks";
import Accordion from "./CaseDeviationsAccordion";

const INITIAL_ACTIVE_INDEXES = {
  left: [],
  right: [],
};

const SHOW_ALL_LINK_CONTENT = {
  true: {
    text: "label_hide_deviation",
    icon: (
      <Icon
        name="arrowbig-up"
        fontSize="7px"
        ml={2}
        color="var(--blue-dark) !important"
      />
    ),
  },
  false: {
    text: "label_show_deviation",
    icon: (
      <Icon
        name="arrowbig-down"
        fontSize="7px"
        ml={2}
        color="var(--blue-dark) !important"
      />
    ),
  },
};

const arrayOfN = n => [...Array(n)].map((_, index) => index);

const DeviationsPanel = ({ className = "", deviations = [] }) => {
  const { messages } = useIntl();
  const { isPDF } = useReportHooks();

  const expandedIndexes = useMemo(
    () => ({
      left: arrayOfN(Math.ceil(deviations?.length / 2)),
      right: arrayOfN(Math.ceil(deviations?.length / 2)),
    }),
    [deviations?.length]
  );
  const [activeIndexes, setActiveIndexes] = useState(
    isPDF ? expandedIndexes : INITIAL_ACTIVE_INDEXES
  );

  const isTwoColumnLayout = className?.includes("column-2");
  const wrapperWidth = !isTwoColumnLayout ? "84%" : 1;
  const isDeviationsOpen =
    !!activeIndexes.left.length || !!activeIndexes.right.length;
  const showAllLinkContent = SHOW_ALL_LINK_CONTENT[isDeviationsOpen];

  useEffect(() => {
    const isExpandedByDefault = className?.includes("toggleable-expanded");

    if (!isExpandedByDefault) {
      return;
    }

    expandedIndexes.left = arrayOfN(deviations?.length);
    setActiveIndexes(expandedIndexes);
  }, [className, expandedIndexes, deviations]);

  const toggleDeviations = () => {
    const expandedIndexes = {
      left: arrayOfN(Math.ceil(deviations?.length / 2)),
      right: arrayOfN(Math.ceil(deviations?.length / 2)),
    };

    setActiveIndexes(
      isDeviationsOpen ? INITIAL_ACTIVE_INDEXES : expandedIndexes
    );
  };

  const handleTabChange = (targetColumn, event) => {
    const { index } = event;
    const currentActiveIndexes = {
      ...activeIndexes,
      [targetColumn]: index,
    };

    setActiveIndexes(currentActiveIndexes);
  };

  const renderOneColumnLayout = () => {
    return (
      <Div>
        <Accordion
          content={deviations}
          activeIndex={activeIndexes.left}
          onTabChange={handleTabChange.bind(this, "left")}
        />
      </Div>
    );
  };

  const renderTwoColumnsLayout = () => {
    const centerIndex = Math.ceil(deviations?.length / 2);
    const leftColumn = deviations?.slice(0, centerIndex);
    const rightColumn = deviations?.slice(centerIndex);

    return (
      <Div
        display="flex"
        flexDirection={["column", "column", "column", "row"]}
        gridColumnGap="56px"
        gridRowGap="16px"
      >
        <Div width={[1, 1, 1, 1 / 2]}>
          <Accordion
            content={leftColumn}
            activeIndex={activeIndexes.left}
            onTabChange={handleTabChange.bind(this, "left")}
          />
        </Div>

        <Div width={[1, 1, 1, 1 / 2]}>
          <Accordion
            content={rightColumn}
            activeIndex={activeIndexes.right}
            onTabChange={handleTabChange.bind(this, "right")}
          />
        </Div>
      </Div>
    );
  };

  return (
    <Div
      width={wrapperWidth}
      marginTop={[
        0,
        0,
        isTwoColumnLayout && !isPDF ? "-48px" : "0",
        isTwoColumnLayout && !isPDF ? "-48px" : "0",
      ]}
    >
      {isTwoColumnLayout && !isPDF && (
        <Div
          mb={3}
          mr={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          justifyContent="flex-end"
        >
          <Link
            display="flex"
            alignItems="flex-end"
            handleClick={toggleDeviations}
          >
            <Icon name="bullets" mr={2} />
            {messages[showAllLinkContent?.text]}
            {showAllLinkContent?.icon}
          </Link>
        </Div>
      )}

      {isTwoColumnLayout ? renderTwoColumnsLayout() : renderOneColumnLayout()}
    </Div>
  );
};

DeviationsPanel.propTypes = {
  className: PropTypes.string,
  deviations: PropTypes.array,
};

export default DeviationsPanel;
