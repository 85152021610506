import React from "react";
import { useIntl } from "react-intl";
import ConsentFooter from "./ConsentFooter";
export const ReportNotReady = () => {
  const { messages } = useIntl();
  return (
    <>
      <ConsentFooter
        title={messages.consent_status_in_progress_title}
        description={messages.consent_status_in_progress_description}
      />
    </>
  );
};

export default ReportNotReady;
