import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import CrimesForm from "./CrimesForm";

const EditCrimesDialog = ({ crimeDetails, onHide, onEdit }) => {
  const { messages } = useIntl();

  return (
    <CrimesForm
      title={messages.label_edit_crime}
      data={crimeDetails}
      onHide={onHide}
      onSubmit={onEdit}
    />
  );
};

EditCrimesDialog.propTypes = {
  crimeDetails: PropTypes.object,
  onHide: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EditCrimesDialog;
