import React from "react";
import { FormattedMessage } from "react-intl";

const DeliveryDropDown=[
    {
      label: <FormattedMessage id="admin_customer_details_customer_template" />,
      value:"customer_template"
    },
    {
      label: <FormattedMessage id="admin_customer_details_internal_template" />,
      value:"internal_template"
    }
  ]

export default DeliveryDropDown;