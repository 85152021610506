import React from "react";
import PropTypes from "prop-types";

import Dropdown from "@components/Dropdown";
import Icon from "@components/Icon";

import InputWrapper from "./InputWrapper";

const InputDropdown = (props) => {
  const { name, value, onChange, options, optionLabel, placeholder, disabled } =
    props;

  return (
    <InputWrapper {...props}>
      <Dropdown
        mt={2}
        width={1}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        dropdownIcon={<Icon name="chevrondown" />}
        filter={options.length > 5}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

InputDropdown.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  selectAllLabel: PropTypes.string,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  options: PropTypes.array,
  formikProps: PropTypes.object,
  disabled: PropTypes.bool,
  itemTemplate: PropTypes.node,
};

export default InputDropdown;
