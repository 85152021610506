import React from "react";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import { Text } from "@components/Text";
import ProgressSpinner from "@components/ProgressSpinner";

const PDFLoader = () => {
  const { messages } = useIntl();

  return (
    <Div
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Div>
        <Text fontSize="var(--fs-text)" fontWeight="var(--medium-weight)">
          {messages.label_generating_pdf}
        </Text>
        <ProgressSpinner />
      </Div>
    </Div>
  );
};

export default PDFLoader;
