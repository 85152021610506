import "./sass/index.scss";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "@app/store";
import { IntlProvider } from "react-intl";
import ErrorBoundary from "@components/ErrorBoundary";
import AppRoute from "./AppRoute";
import messages_en from "@translation/en.json";
import messages_sv from "@translation/sv.json";
import Config from "@src/config";
import { AuthProvider } from "@contexts/AuthProvider";
import { ToastProvider } from "@hooks/useToast";

import configureIntegrations from "./integrations/configure";

const messages = {
  en: messages_en,
  sv: messages_sv,
};

const applanguage = Config.language || "sv";

let currentPath = window.location.pathname;
currentPath = currentPath?.split("/")[1];

function App() {
  useEffect(() => {
    configureIntegrations();
  }, []);

  return (
    <div className={`App ${currentPath}`}>
      <ErrorBoundary>
        <Provider store={store}>
          <AuthProvider>
            <IntlProvider
              defaultLocale="sv"
              locale={applanguage}
              messages={messages[applanguage]}
            >
              <ToastProvider>
                <AppRoute />
              </ToastProvider>
            </IntlProvider>
          </AuthProvider>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
