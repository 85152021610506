import { useLocation } from "react-router-dom";

const useReportHooks = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isPDF = !!params.get("is_pdf");

  return { isPDF };
};

export default useReportHooks;
