import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Div from "@components/Div";
import { H2, H3 } from "@components/Heading";
import Proptypes from "prop-types";
import {
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  CANDIDATE,
} from "../../../utils/constant";
import { formatDateAndTime } from "@utils/utils";
import { Text } from "@components/Text";

const Timelog = ({ logs = [], ssn = "" }) => {
  const { messages } = useIntl();
  const renderLog = (log) => {
    const { date, status, given_by, consent_given_by_username } = log;
    const adminStatusMessage = {
      [STATUS_IN_PROGRESS]: "consent_timelog_generate_report_admin",
      [STATUS_COMPLETED]: "consent_timelog_share_report_admin",
    };
    const candidateStatusMessage = {
      [STATUS_IN_PROGRESS]: "consent_timelog_generate_report_candidate",
      [STATUS_COMPLETED]: "consent_timelog_share_report_candidate",
    };
    return (
      <Div
        display="flex"
        flexDirection={["column", "row"]}
        alignItems={["start", "center"]}
        mt={3}
      >
        <H3 fontSize="var(--fs-text-m) !important" textTransform="lowercase" mr={3} pb={1}>
          {formatDateAndTime(date)}
        </H3>

        <Text pb={1} display="flex" flexWrap="wrap">
          {given_by === CANDIDATE ? (
            <FormattedMessage
              id={candidateStatusMessage[status]}
              defaultMessage=""
              values={{ ssn: ssn }}
            />
          ) : (
            <FormattedMessage
              id={adminStatusMessage[status]}
              defaultMessage=""
              values={{ name: consent_given_by_username, ssn: ssn }}
            />
          )}
        </Text>
      </Div>
    );
  };

  return logs?.length ? (
    <Div mt={4}>
      <H2 mb={2}>{messages.label_timelog}
      {logs?.map((log) => renderLog(log))}</H2>
    </Div>
  ) : null;
};
Timelog.propTypes = {
  logs: Proptypes.array,
  ssn: Proptypes.string,
};
export default Timelog;
