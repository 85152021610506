import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import Div from "@components/Div";
import Icon from "@components/Icon";
import MultiSelect from "@components/MultiSelect";
import { TextMediumWeight } from "@components/Text";

import InputWrapper from "./InputWrapper";

const StyledWrapper = styled(InputWrapper)`
  .p-multiselect-token-icon.icon-close {
    margin-top: 2px;
    font-size: var(--fs-grade-text);
  }
`;

const InputMultiselect = (props) => {
  const {
    value,
    onChange,
    options,
    placeholder,
    name,
    optionLabel,
    selectAllLabel,
    disabled,
  } = props;

  const renderFooter = () => {
    const itemsCount = value?.length || 0;
    const message = `Chosen: ${itemsCount} items`;

    return (
      <Div mx={3} pt={2} pb={3}>
        <TextMediumWeight>{message}</TextMediumWeight>
      </Div>
    );
  };

  return (
    <StyledWrapper {...props}>
      <MultiSelect
        mt={2}
        width={1}
        display="chip"
        removeIcon="icon-close"
        dropdownIcon={<Icon name="chevrondown" color="var(--turquoise)" />}
        panelFooterTemplate={renderFooter}
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        name={name}
        selectAllLabel={selectAllLabel}
        disabled={disabled}
      />
    </StyledWrapper>
  );
};

InputMultiselect.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  selectAllLabel: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  formikProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default InputMultiselect;
