import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { Text, TextSemiBoldWeight } from "@components/Text";
import { PrimaryButton } from "@components/Button";

const CouponCodeModal = (props) => {
  const { messages } = useIntl();

  const ErrorMessages = useMemo(() => {
    return {
      code_not_valid: {
        title: messages.coupon_code_not_valid_title,
        message: messages.coupon_code_not_valid_message,
      },
      code_cannot_be_applied: {
        title: messages.code_cannot_be_applied_title,
        message: messages.code_cannot_be_applied_message,
      },
      code_was_used_maximum_times: {
        title: messages.code_was_used_maximum_times_title,
        message: messages.code_was_used_maximum_times_message,
      },
      order_not_found: {
        title: messages.order_not_found_title,
        message: messages.order_not_found_message,
      },
    };
  }, [messages]);

  const errorMessageDetails = ErrorMessages[props.errorKey];

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={props.hideModal}
      m={[3, 3, 3, 3]}
    >
      <Div display="flex" flexDirection="column" alignItems="center">
        <TextSemiBoldWeight>
          {errorMessageDetails.title.toUpperCase()}
        </TextSemiBoldWeight>
        <Text my={20} textAlign="center">
          {errorMessageDetails.message}
        </Text>
        <PrimaryButton
          width="200px"
          justifyContent="center"
          onClick={props.hideModal}
        >
          {messages.label_ok}
        </PrimaryButton>
      </Div>
    </Dialog>
  );
};

CouponCodeModal.propTypes = {
  hideModal: PropTypes.func,
  errorKey: PropTypes.string,
};

export default CouponCodeModal;
