import React from "react";
import { FormattedMessage } from "react-intl";
import {
  NO_CONSENT,
  CONSENT_OPTIONAL,
  CONSENT_MANDATORY,
} from "@utils/constant";

const ConsentDropDownOptions = () => [
  {
    label: (
      <FormattedMessage
        id="consent_mandatory"
        defaultMessage="Consent Mandatory"
      />
    ),
    value: CONSENT_MANDATORY,
    message: (
      <FormattedMessage
        id="consent_mandatory_message"
        defaultMessage="Mandatory consent message."
      />
    ),
  },
  {
    label: (
      <FormattedMessage
        id="consent_optional"
        defaultMessage="Consent Optional"
      />
    ),
    value: CONSENT_OPTIONAL,
    message: (
      <FormattedMessage
        id="consent_optional_message"
        defaultMessage="Optional consent message."
      />
    ),
  },
  {
    label: (
      <FormattedMessage
        id="consent_not_needed"
        defaultMessage="Consent Not Needed"
      />
    ),
    value: NO_CONSENT,
    message: (
      <FormattedMessage
        id="consent_not_needed_message"
        defaultMessage="No consent needed message."
      />
    ),
  },
];

export default ConsentDropDownOptions;
