import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ContextMenu as PRContextMenu } from "primereact/contextmenu";

const StyledContextMenu = styled(PRContextMenu)`
  background-color: var(--white);
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);

  .p-menuitem {
    justify-content: center;

    i {
      color: var(--turquoise);
      margin-right: 8px;
      font-size: var(--fs-icon-m);
    }

    .p-menuitem-text {
      font-weight: var(--medium-weight);
      font-size: var(--fs-text-m);
      color: var(--grey-dark);
      line-height: var(--lh-link);
    }
  }
`;

const ContextMenu = ({ items, menuRef, ...props }) => {
  return <StyledContextMenu {...props} ref={menuRef} model={items} />;
};

ContextMenu.propTypes = {
  items: PropTypes.array,
  menuRef: PropTypes.object,
};

export default ContextMenu;
