import React from "react";
import PropTypes from "prop-types";

import { Tooltip as PrimeTooltip } from "primereact/tooltip";
import styled, { css } from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  color,
} from "styled-system";

const tooltipStyles = css`
  background-color: var(--white);
  color: var(--grey-dark);
  font-size: var(--fs-h5);
  line-height: var(--lh-text-m);
  font-weight: var(--medium-weight);
  max-width: 651px;
  border-radius: 10px;
  strong {
    font-weight: var(--semibold-weight);
  }
  em {
    font-style: italic;
  }
  li {
    margin-left: 24px;
  }
  ol {
    list-style: disc;
  }
  ul {
    list-style: disc;
  }
`;

const ToolTip = styled(PrimeTooltip)`
  ${compose(layout, space, flexbox, position, typography, color)};
  .p-tooltip {
    ${tooltipStyles}
  }

  .p-tooltip-text {
    ${tooltipStyles}
    padding: ${({ padding = "20px" }) => padding};
    border-radius: ${({ borderRadius = "10px" }) => borderRadius};
  }
`;

const Tooltip = ({ isHtml = false, content, autoHide = false, ...props }) => {
  return (
    <ToolTip autoHide={autoHide} {...props}>
      {isHtml ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
    </ToolTip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node,
  autoHide: PropTypes.bool,
  isHtml: PropTypes.bool,
};

export default Tooltip;
