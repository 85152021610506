import { useEffect, useState, useCallback } from "react";
import {
  useHistory as useReactDomHistory,
  useLocation,
} from "react-router-dom";

import Routes from "../shared/Routes";
import { ADMIN, PAGE_NOT_FOUND as CONST_PAGE_NOT_FOUND } from "@utils/constant";
import RoutesList from "@shared/RoutesList";

const {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PAGE_NOT_FOUND,
  ORDERS_LIST,
  ALLANALYSIS_LIST,
  ADMIN_LOGIN,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_RESET_PASSWORD,
  ADMIN_ORDERS_LIST,
  ORDERS_DETAILS,
  ADMIN_ORDER_DETAILS,
  USER_PROFILE,
  COMPANY_INFO,
  ADMIN_CUSTOMERS,
  ADMIN_USERS_LIST,
  SUB_USERS_LIST,
  ADMIN_ANALYSIS_LIST,
  ORDER_RESULTS,
  WATCH_LIST_OBJECT_DETAIL,
  ASSIGNMENT_DETAILS,
} = Routes;

const COMMON_ROUTES = {
  LOGIN: {
    user: LOGIN.URL,
    admin: ADMIN_LOGIN.URL,
  },
  FORGOT_PASSWORD: {
    user: FORGOT_PASSWORD.URL,
    admin: ADMIN_FORGOT_PASSWORD.URL,
  },
  RESET_PASSWORD: {
    user: RESET_PASSWORD.URL,
    admin: ADMIN_RESET_PASSWORD.URL,
  },
  PAGE_NOT_FOUND: {
    user: PAGE_NOT_FOUND.URL,
    admin: PAGE_NOT_FOUND.URL,
  },
  ORDER_LIST: {
    user: ORDERS_LIST.URL,
    admin: ADMIN_ORDERS_LIST.URL,
  },
  ANALYSIS_LIST: {
    user: ALLANALYSIS_LIST.URL,
  },
  USERS_LIST: {
    user: SUB_USERS_LIST.URL,
    admin: ADMIN_USERS_LIST.URL,
  },
  ADMIN_ANALYSIS_LIST: {
    admin: ADMIN_ANALYSIS_LIST.URL,
  },
  ORDERS_DETAILS: {
    user: ORDERS_DETAILS.URL,
    admin: ADMIN_ORDER_DETAILS.URL,
  },
  DASHBOARD: {
    user: USER_PROFILE.URL,
    admin: ADMIN_CUSTOMERS.URL,
  },
  DASHBOARD_COMPANY: {
    user: COMPANY_INFO.URL,
  },
  ORDER_RESULTS: {
    user: ORDER_RESULTS.URL,
    admin: ORDER_RESULTS.URL,
  },
  WATCH_LIST_OBJECT_DETAIL: {
    user: WATCH_LIST_OBJECT_DETAIL.URL,
    admin: WATCH_LIST_OBJECT_DETAIL.URL,
  },
  ASSIGNMENT_DETAILS: {
    user: ASSIGNMENT_DETAILS.URL,
    admin: ASSIGNMENT_DETAILS.URL,
  },
};

const useHistory = () => {
  const history = useReactDomHistory();
  const domLocation = useLocation();
  const [location, setLocation] = useState({});

  const push = useCallback(
    (routeName, search = "") => {
      if (Object.keys(COMMON_ROUTES).includes(routeName)) {
        const isAdmin = domLocation.pathname.includes(ADMIN);
        const { user, admin } = COMMON_ROUTES[routeName];
        return history.push(
          isAdmin === true ? `${admin}${search}` : `${user}${search}`,
        );
      }
      const route = RoutesList.find((route) => {
        const { name = CONST_PAGE_NOT_FOUND } = route;
        if (name === routeName) {
          return route.url;
        }
      });
      const routeURL = route
        ? `${route.url}${search}`
        : Routes.PAGE_NOT_FOUND.URL;
      return history.push(routeURL);
    },
    [history, domLocation],
  );

  useEffect(() => {
    setLocation({ push });
  }, [push]);

  return location;
};

export default useHistory;
