import React from "react";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import { calculateDateExpiryDays } from "@utils/common";
import { TextLargeSemiBoldWeight } from "@components/Text";
import PropTypes from "prop-types";
import { EXPIRY_DAYS } from "@utils/constant";
import Icon from "@components/Icon";

const ShareHeader = (props) => {
  const { messages } = useIntl();
  const { orderDetails } = props;

  const expiryDays = calculateDateExpiryDays(
    orderDetails?.created_at,
    EXPIRY_DAYS,
  );
  return (
    <Div
      display={"flex"}
      alignItems={["center"]}
      justifyContent="space-between"
      px={[0, 0, 0, 0]}
    >
      <Div
        display="flex"
        alignItems={["center"]}
        width={["75%", "75%", "75%", "61%"]}
        minHeight={["100%", 1, 1, 1]}
      >
        <Div pr={2}>
          <Icon
            name="sign"
            fontSize={"24px !important"}
            rounded={true}
            width={36}
            height={36}
          />
        </Div>
        <Div minHeight={"100%"}>
          <TextLargeSemiBoldWeight pl={[0, 0, 2, 2]}>
            {messages.consent_requestExpires}
          </TextLargeSemiBoldWeight>
        </Div>
      </Div>
      <Div>
        <TextLargeSemiBoldWeight>
          {expiryDays < 1
            ? messages.consent_label_expired
            : `${expiryDays} ${messages.label_days}`}
        </TextLargeSemiBoldWeight>
      </Div>
    </Div>
  );
};

ShareHeader.propTypes = {
  orderDetails: PropTypes.object,
};
export default ShareHeader;
