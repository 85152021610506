import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { CSVLink as PRCSVLink } from "react-csv";
import styled from "styled-components";
import ParseData from "papaparse";
import DataTable from "@components/DataTableV2/DataTable";
import PropTypes from "prop-types";
import Dialog from "@components/Dialog";
import DeleteWatchlistObjectModal from "@pages/user/watchlist/components/DeleteWatchlistObjectModal";
import { IMPORT_CSV_COLUMN_NAME } from "@utils/constant";
import AddSingleObjModal from "@pages/user/watchlist/components/AddSingleObjModal";
import LoadObjectsModal from "@pages/user/watchlist/components/LoadObjectsModal";
import updateWatchlist from "@app/services/watchlist/updateWatchlist";
import AnalyseWatchListModal from "@pages/user/watchlist/components/AnalyseWatchListModal";

import ErrorModal from "@components/Report/LegacyReport/ErrorModal";
import MoveWatchListObjectModal from "@pages/user/watchlist/components/MoveWatchListObjectModal";
import DoneModal from "@components/Report/LegacyReport/DoneModal";
import {
  loadWatchListObjects,
  checkIfObject,
  handleException,
} from "@utils/common";
import UploadButton from "@components/UploadButton";

const CSVLink = styled(PRCSVLink)`
  display: none;
`;

const WatchlistDetailTab = ({
  watchList,
  id,
  handleRedirectToUpgradePlan,
  customerAvailableCredits,
  customerId,
  handleWatchListUpdate,
  activeSubscription,
  isWatchlistWritable,
  customerWatchLists,
}) => {
  const { messages } = useIntl();
  const downloadCsv = useRef(null);

  const [selectedWatchlistObjects, setSelectedWatchlistObjects] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMode, setDeleteMode] = useState("");
  const [selectedWatchlistObject, setSelectedWatchlistObject] = useState([]);
  const [showAddObjectModal, setShowAddObjectModal] = useState(false);
  const [showObjectsLoadedModal, setShowObjectsLoadedModal] = useState(false);
  const [showCSVDownloadedMessage, setShowCSVDownloadedMessage] =
    useState(false);
  const [collectedWatchListObjects, setCollectedWatchListObjects] = useState(
    [],
  );
  const [loadedWatchListObjects, setLoadedWatchListObjects] = useState([]);
  const [showAnalyseWatchListPopup, setShowAnalyseWatchListPopup] =
    useState(false);
  const [analyseMode, setAnalyseMode] = useState("");

  const [analyseWatchListObjects, setAnalyseWatchListObjects] = useState(
    watchList?.watch_list_objects,
  );
  const [reloadList, setReloadList] = useState(false);
  const [invalidType, setInvalidType] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [existingSSNs, setExistingSSNs] = useState(0);
  const [invalidSSNs, setInvalidSSNs] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const csvDownloadData = [[IMPORT_CSV_COLUMN_NAME]];
  const allowedTypes = ["text/csv", ".csv"];

  const searchableColumns = ["id", "name"];

  const handleSelectionChange = (e) => {
    if (selectedWatchlistObjects.length) {
      setSelectedWatchlistObject([]);
    } else {
      setSelectedWatchlistObjects(e?.value);
    }
  };

  const handleDeleteWatchListObject = () => {
    setDeleteMode("single");
    setShowDeleteModal(true);
  };

  const handleDeleteWatchListObjects = () => {
    setDeleteMode("multiple");
    setShowDeleteModal(true);
  };

  const handleDownloadSampleTemplate = () => {
    setShowCSVDownloadedMessage(true);
    downloadCsv?.current?.link?.click();
  };

  const handleCloseCSVDownloadedPopup = () => {
    setShowCSVDownloadedMessage(false);
  };

  const handleClosePopup = () => {
    setShowDeleteModal(false);
    setShowMoveModal(false);
    setShowAddObjectModal(false);
    setShowAnalyseWatchListPopup(false);
    setShowObjectsLoadedModal(false);
    setInvalidType(false);
    setInvalidSSNs(0);
    setExistingSSNs(0);
    setShowErrorModal(false);
  };

  const handleAnalyseAllWatchList = () => {
    setAnalyseWatchListObjects(watchList?.watch_list_objects);
    handleAnalyseWatchList("all");
  };

  const handleAnalyseSelectedWatchList = () => {
    setAnalyseWatchListObjects(selectedWatchlistObjects);
    handleAnalyseWatchList("selected");
  };

  const handleAnalyseWatchList = (mode) => {
    setShowAnalyseWatchListPopup(true);
    setAnalyseMode(mode);
  };

  const handleMoveObject = () => {
    setShowMoveModal(true);
  };

  const handleShowAddObjectModal = () => {
    setShowAddObjectModal(true);
  };

  const handleReloadList = (updatedWatchList) => {
    setReloadList(!reloadList);
    clearSelectedProducts();
    if (updatedWatchList) {
      handleWatchListUpdate(updatedWatchList);
    }
  };

  const handleAddWatchListObject = async (object = {}) => {
    try {
      const { name, ssn } = object;
      const newWatchListObject = {
        name,
        ssn_number: ssn,
      };
      const updatedWatchListObjects = [
        ...watchList.watch_list_objects,
        newWatchListObject,
      ];
      const updatedWatchList = {
        ...watchList,
        watch_list_objects: updatedWatchListObjects,
        notification_emails: watchList?.notification_emails
          ? watchList?.notification_emails
          : null,
      };
      const { data: { data: newWatchList = {} } = {} } = await updateWatchlist(
        watchList.id,
        updatedWatchList,
      );
      handleReloadList(newWatchList);
      handleClosePopup();
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };

  const handleAddloadedWatchListObjects = async () => {
    try {
      if (loadedWatchListObjects.length > 0) {
        const updatedWatchListObjects = loadedWatchListObjects.map((obj) => ({
          name: obj.name,
          ssn_number: obj.ssn,
        }));
        const updatedWatchList = {
          ...watchList,
          watch_list_objects: [
            ...watchList.watch_list_objects,
            ...updatedWatchListObjects,
          ],
          notification_emails: watchList?.notification_emails
            ? watchList?.notification_emails
            : null,
        };
        const { data: { data: newWatchList = {} } = {} } =
          await updateWatchlist(watchList.id, updatedWatchList);
        handleReloadList(newWatchList);
      }
      handleClosePopup();
    } catch (error) {
      // Handle the error
      return error.response;
    }
  };

  const parseSSNs = async (results) => {
    const rowsArray = results?.data?.map((d) => Object.keys(d));
    const [rows = []] = rowsArray;
    if (rows.includes(IMPORT_CSV_COLUMN_NAME)) {
      const collectAllSSNs = results.data.map((obj) => obj?.Personnummer);
      setCollectedWatchListObjects(collectAllSSNs);
      let objectsLoaded = [];
      let invalidSsnCsv = 0;
      let existingSsnCsv = 0;
      const noOfSSNs = collectAllSSNs.length;
      const noOfCalls = Math.ceil(noOfSSNs / 50);
      for (let i = 0; i < noOfCalls; i++) {
        const values = collectAllSSNs.slice(i * 50, (i + 1) * 50);
        const responseData = await loadWatchListObjects(values, customerId);
        const {
          existing_ssn_list = [],
          invalid_ssn_list = [],
          valid_ssn_list = [],
        } = responseData;
        const existingSsnList = checkIfObject(existing_ssn_list);
        const invalidSsnList = checkIfObject(invalid_ssn_list);
        const validSsnsList = checkIfObject(valid_ssn_list);
        invalidSsnCsv = invalidSsnCsv + invalidSsnList?.length;
        existingSsnCsv += existingSsnList.length;
        const validSsnList =
          validSsnsList?.length > 0
            ? validSsnsList.filter((validSsn) => {
                return !existingSsnList.find((existingSsn) =>
                  existingSsn.ssn_number.includes(validSsn.ssn),
                );
              })
            : validSsnsList;
        objectsLoaded = [...objectsLoaded, ...validSsnList];
      }
      setExistingSSNs(existingSSNs + existingSsnCsv);
      setInvalidSSNs(invalidSSNs + invalidSsnCsv);
      setLoadedWatchListObjects(
        objectsLoaded.map((o) => ({ ...o, id: o?.ssn })),
      );
      setShowObjectsLoadedModal(true);
    } else {
      setInvalidType(true);
    }
  };

  const changeHandler = async (event) => {
    const file = event?.target?.files[0];
    if (file && allowedTypes.includes(file?.type)) {
      await ParseData.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          await parseSSNs(results);
        },
      });
    } else {
      setInvalidType(true);
    }
  };

  const handleInvalidTypeError = () => {
    setInvalidType(false);
    handleClosePopup();
  };

  const clearSelectedProducts = () => {
    setSelectedWatchlistObjects([]);
  };

  const getDynamicLabel = (label) => {
    return selectedWatchlistObjects?.length > 0
      ? `${label} (${selectedWatchlistObjects?.length})`
      : label;
  };

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent,
    );

  const MenuButton = {
    id: "menu-button",
    type: "menu-button",
    variant: "header",
    label: isMobile ? "" : messages.watchlist_label_add,
    icon: "icon-plus",
    items: isMobile
      ? [
          {
            label: getDynamicLabel(messages.watchlist_label_move),
            icon: "icon-backarrow",
          },
          {
            label: getDynamicLabel(messages.watchlist_label_delete),
            icon: "icon-rubber",
            command: handleDeleteWatchListObjects,
          },
          {
            label: messages.add_object,
            icon: "icon-account-circle",
            command: handleShowAddObjectModal,
          },
          {
            template: (
              <UploadButton
                changeHandler={changeHandler}
                allowedTypes={allowedTypes}
              />
            ),
            icon: "icon-upload-file",
            command: changeHandler,
          },
          {
            label: messages.download_template,
            icon: "icon-download-file",
            command: handleDownloadSampleTemplate,
          },
        ]
      : [
          {
            label: messages.add_object,
            icon: "icon-account-circle",
            command: handleShowAddObjectModal,
          },
          {
            template: (
              <UploadButton
                changeHandler={changeHandler}
                allowedTypes={allowedTypes}
              />
            ),
            icon: "icon-upload-file",
            command: changeHandler,
          },
          {
            label: messages.download_template,
            icon: "icon-download-file",
            command: handleDownloadSampleTemplate,
          },
        ],
    isHidden: isWatchlistWritable,
  };

  const dataTableConfig = {
    enable_csv_download: true,
    csv_filename: "watchlist_objects.csv",
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_watchlist_no_objects_found",
    enable_search: true,
    filterType: "dialog",
    pagination: true,
    selectionMode: "checkbox",
    enable_query_builder: true,
    api: {
      resource: `/watch-lists/${id}/watch-list-objects`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", "orders,subscription"]],
    },
    header: {
      actions: [
        {
          id: "move",
          label: getDynamicLabel(messages.watchlist_label_move),
          type: "link",
          icon: "backarrow",
          iconPos: "left",
          variant: "header",
          disabled: !selectedWatchlistObjects?.length,
          onClick: handleMoveObject,
          isHidden: isWatchlistWritable || customerWatchLists.length <= 1,
        },
        {
          id: "delete",
          label: getDynamicLabel(messages.watchlist_label_delete),
          type: "link",
          icon: "rubber",
          iconPos: "left",
          variant: "header",
          disabled: !selectedWatchlistObjects?.length,
          onClick: handleDeleteWatchListObjects,
          isHidden: isWatchlistWritable,
        },

        MenuButton,
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    columns: [
      {
        field: "id",
        selectionMode: "multiple",
        headerStyle: { width: "3rem" },
        onClick: handleSelectionChange,
      },
      {
        field: "name",
        header: messages.watchlist_label_name,
        primary: true,
        sortable: false,
      },
      {
        field: "ssn_number",
        header: messages.watchlist_label_personal_number,
        sortable: false,
        width: "25%",
      },
      {
        field: "grades",
        header: messages.watchlist_label_grade,
        sortable: false,
        width: "13%",
      },
      {
        field: "last_report_generated_date",
        header: messages.watchlist_label_last_results,
        sortable: false,
      },
    ],
    rowActions: [
      {
        id: "delete",
        label: messages.watchlist_label_delete,
        type: "link",
        icon: "rubber",
        iconPos: "left",
        variant: "secondary",
        onClick: handleDeleteWatchListObject,
        isHidden: isWatchlistWritable,
      },
    ],
    filters: [
      {
        title: "label_status",
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
            checked: false,
          },
          {
            label: messages.deleted,
            value: 0,
            checked: false,
          },
        ],
      },
    ],

    footer: [
      {
        id: "analyse_all",
        title: messages.watchlist_label_analys_now_all,
        type: "link",
        icon: "headerarrowright",
        iconPos: "right",
        disabled: !(
          selectedWatchlistObjects?.length === 0 ||
          selectedWatchlistObjects?.length ===
            watchList.watch_list_objects?.length
        ),
        onClick: handleAnalyseAllWatchList,
        isHidden: isWatchlistWritable || !activeSubscription,
      },
      {
        id: "analyse_chosen",
        label: getDynamicLabel(messages.watchlist_label_analys_chosen),
        type: "link",
        icon: "headerarrowright",
        iconPos: "right",
        disabled: !selectedWatchlistObjects?.length,
        onClick: handleAnalyseSelectedWatchList,
        isHidden: isWatchlistWritable || !activeSubscription,
      },
    ],
  };

  return (
    <>
      <DataTable config={dataTableConfig} />

      {(showAddObjectModal ||
        showDeleteModal ||
        showObjectsLoadedModal ||
        showAnalyseWatchListPopup ||
        showCSVDownloadedMessage ||
        invalidType ||
        showErrorModal ||
        showMoveModal) && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={
            showAnalyseWatchListPopup
              ? ["90%", "90%", "90%", 540]
              : ["90%", "90%", "90%", 445]
          }
        >
          {showDeleteModal && (
            <DeleteWatchlistObjectModal
              onCloseModal={handleClosePopup}
              selectedWatchlistObjects={
                deleteMode === "single"
                  ? [selectedWatchlistObject]
                  : selectedWatchlistObjects
              }
              watchlist={watchList}
              handleReloadList={handleReloadList}
            />
          )}
          {showAddObjectModal && (
            <AddSingleObjModal
              onClose={handleClosePopup}
              onSubmit={handleAddWatchListObject}
              customerId={customerId}
            />
          )}
          {showObjectsLoadedModal && (
            <LoadObjectsModal
              collectedSSNs={collectedWatchListObjects?.length}
              loadedSSNs={loadedWatchListObjects?.length}
              onClose={handleClosePopup}
              onSubmit={handleAddloadedWatchListObjects}
              existingSSNs={existingSSNs}
              invalidSSNs={invalidSSNs}
            />
          )}
          {showAnalyseWatchListPopup && (
            <AnalyseWatchListModal
              onCloseModal={handleClosePopup}
              analyseMode={analyseMode}
              watchlistName={watchList.name}
              watchListId={watchList.id}
              customerAvailableCredits={customerAvailableCredits}
              selectedWatchlistObjects={analyseWatchListObjects}
              handleRedirectToUpgradePlan={handleRedirectToUpgradePlan}
              clearSelectedProducts={clearSelectedProducts}
            />
          )}
          {invalidType && (
            <ErrorModal
              content={messages.invalid_type_error}
              handleConfirmPopup={handleInvalidTypeError}
              hideContactUsButton
            />
          )}
          {showMoveModal && (
            <MoveWatchListObjectModal
              onCloseModal={handleClosePopup}
              selectedWatchListObjects={selectedWatchlistObjects}
              watchList={watchList}
              customerWatchLists={customerWatchLists}
              handleReloadList={handleReloadList}
              selectedWatchlistObjects={selectedWatchlistObjects}
            />
          )}
          {showCSVDownloadedMessage && (
            <DoneModal
              title={messages.watchlist_csv_downloaded_title}
              content={messages.watchlist_csv_downloaded_message}
              handleConfirmPopup={handleCloseCSVDownloadedPopup}
            />
          )}
          {showErrorModal && (
            <ErrorModal
              handleConfirmPopup={handleClosePopup}
              content={errorMessage}
            />
          )}
        </Dialog>
      )}
      <CSVLink
        data={csvDownloadData}
        filename={`Mall bevakningslista.csv`}
        ref={downloadCsv}
        separator={";"}
      >
        click
      </CSVLink>
    </>
  );
};

WatchlistDetailTab.propTypes = {
  watchList: PropTypes.object,
  handleRedirectToUpgradePlan: PropTypes.func,
  customerAvailableCredits: PropTypes.number,
  id: PropTypes.number,
  customerId: PropTypes.number,
  handleWatchListUpdate: PropTypes.func,
  activeSubscription: PropTypes.bool,
  isWatchlistWritable: PropTypes.bool,
  customerWatchLists: PropTypes.array,
};

export default WatchlistDetailTab;
