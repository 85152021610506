import React from "react";
import { H2 } from "@components/Heading";
import Div from "@components/Div";
function Header(prop) {
  const name = prop.title;
  return (
    <Div
      py={22}
      px={3}
      textAlign="center"
      mb={3}
      backgroundColor={"var(--turquoise-light)"}
    >
      <H2>{name}</H2>
    </Div>
  );
}
export default Header;
