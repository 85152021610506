import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";

import UserContainer from "@layout/UserContainer";

import { ROUTES } from "@utils/constant";

import BillingDetails from "./tabs/BillingDetails";

const BillingAccountInfo = () => {
  const { messages } = useIntl();
  const { billing_account_id = "" } = useParams();
  const { customer: { id: customerId = "" } = {} } = useSelector(
    state => state.authReducer.userInfo
  );

  const [billingAccountInfo, setBillingAccountInfo] = useState(null);

  const breadCrumbItems = [
    { label: messages.my_profile },
    {
      label: messages.organisation,
      url: ROUTES.COMPANY_INFO.URL,
    },
    {
      label: messages.title_billing,
      url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
    },
    {
      label: billingAccountInfo?.name,
    },
  ];

  const containerConfig = {
    title: billingAccountInfo?.name,
  };

  return (
    <UserContainer config={containerConfig}>
      <Breadcrumb items={breadCrumbItems} px={0} pt={0} />

      <BillingDetails
        billingAccountId={billing_account_id}
        customerId={customerId}
        onBillingInfoLoaded={setBillingAccountInfo}
      />
    </UserContainer>
  );
};

export default BillingAccountInfo;
