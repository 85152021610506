import React from "react";
import SubscriptionOrderSummary from "./SubscriptionOrderSummary";
import { useSelector } from "react-redux";

const initSelect = (personInfo, servicesInfo, paymentMethod) => {
  return personInfo.map((item) => ({
    ...item,
    servicesInfo,
    paymentMethod: paymentMethod,
  }));
};

const SubscriptionCheckout = () => {
  const paymentMethod = useSelector(
    (state) => state.personInformationReducer.paymentMethod,
  );
  const servicesInfo = useSelector(
    (state) => state.servicesReducer.servicesInfo,
  );
  const personInfo = useSelector(
    (state) => state.personInformationReducer.personInfo,
  );
  return (
    <SubscriptionOrderSummary
      orderSummaryProps={initSelect(personInfo, servicesInfo, paymentMethod)}
    />
  );
};

export default SubscriptionCheckout;
