import Axios from "@app/api/axios";

import { PRODUCTS } from "@utils/constant";

const updateProductById = (payload) => {
  const { id } = payload;

  return Axios.patch(`${PRODUCTS}/${id}`, payload);
};

export default updateProductById;
