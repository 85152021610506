import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { H2 } from "@components/Heading";
import JSONViewer from "@components/JSONViewer";

const RawDataViewer = ({ sourceData }) => {
  const { messages } = useIntl();

  return (
    <Div mt="32px">
      <H2 mb="16px">{messages.title_raw_data}</H2>
      <JSONViewer data={sourceData} />
    </Div>
  );
};

RawDataViewer.propTypes = {
  sourceData: PropTypes.object,
};

export default RawDataViewer;
