import React from "react";
import { PropTypes } from "prop-types";

import Div from "@components/Div";
import Skeleton from "@components/Skeleton";

const PlaceholderForContent = ({ isLoading, children, ...loaderProps }) => {
  return <Div>{isLoading ? <Skeleton {...loaderProps} /> : children}</Div>;
};

PlaceholderForContent.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  loaderProps: PropTypes.object,
};

export default PlaceholderForContent;
