import React from "react";
import { FormattedMessage } from "react-intl";

const CustomerStatus = () => [
  {
    label: <FormattedMessage id="active" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="label_inactive" />,
    value: 0,
  },
];

export default CustomerStatus;
