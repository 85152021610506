import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Div from "@components/Div";
import UploadBordered from "@components/UploadBordered";
import { Error } from "@components/Heading";

const FileUploadField = ({ name }) => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const uploadRef = useRef(null);

  const onFileAttached = (event) => {
    if (event.files.length > 0) {
      setFieldValue(name, event.files[0]);
    }
  };

  return (
    <Div flex="1 1 40%" mt="2">
      <UploadBordered
        uploadRef={uploadRef}
        errorMessage={errors[name]}
        category={name}
        onFileAttached={onFileAttached}
      />
      {errors[name] && touched[name] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors[name]}</Error>
        </Div>
      )}
    </Div>
  );
};

FileUploadField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FileUploadField;
