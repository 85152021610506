import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import { H2 } from "@components/Heading";
import { PrimaryButtonIcon } from "@components/Button";
import Icon from "@components/Icon";
import Editor from "@components/Editor";
import { useToast } from "@hooks/useToast";

import Comment from "@components/Comment";
import updateAssignment from "@app/services/assignments/updateAssignment";

const CommentsTab = ({ comments: _comments, assignment }) => {
  const { messages } = useIntl();
  const [comments, setComments] = useState(_comments);
  const { showSuccessToast, showErrorToast } = useToast();

  const [newComment, setNewComment] = useState("");
  const handleOnChangeComment = event => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleAddComment = async () => {
    try {
      const { data: _assignment } = await updateAssignment({
        id: assignment.id,
        status: assignment.status,
        comment: newComment,
      }).then(res => res.data);
      showSuccessToast(messages.label_comment_added);
      setComments(_assignment.comments);
    } catch (e) {
      showErrorToast(messages.error_try_later);
    }
    setNewComment("");
  };

  const handleDeleteComment = async id => {
    const newComments = comments.filter(comment => comment.id !== id);
    setComments(newComments);
  };

  return (
    <Div width={[1, 1, 8 / 10]}>
      <H2 color="var(--blue-dark) !important">{messages.label_comments}</H2>
      {comments?.map(comment => (
        <Comment
          key={comment.id}
          comment={comment}
          onDeleteComment={handleDeleteComment}
        />
      ))}
      <Div mt={comments?.length ? 4 : 3}>
        <TextMediumWeight>{messages.label_add_comment}</TextMediumWeight>
        <Editor
          my={2}
          value={newComment}
          onTextChange={handleOnChangeComment}
        />
      </Div>
      <Div display="flex" justifyContent="flex-end" mt={60}>
        <PrimaryButtonIcon
          rounded
          height={40}
          px={22}
          icon={<Icon name="add-comment" mr={1} />}
          label={messages.label_add_comment}
          onClick={handleAddComment}
        />
      </Div>
    </Div>
  );
};

CommentsTab.propTypes = {
  comments: PropTypes.array,
  assignment: PropTypes.object,
  onAddComments: PropTypes.func,
  onUpdateComments: PropTypes.func,
};

export default CommentsTab;
