import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import ScrollToTop from "@utils/ScrollToTop";
import { ADMIN_ANALYSIS_API, ROUTES } from "@utils/constant";

import { StatusComponent } from "@components/Report/StatusComponent";
import { ReportStatus, reportStatusArray } from "@src/enum/reportStatus";
import GradeComponent from "@components/Report/GradeComponent";

const searchableColumns = ["id", "order_id"];

const FILTER_KEY_FROM = "filter[created_at][gte]";
const FILTER_KEY_TO = "filter[created_at][lte]";

const Analysis = ({ customerId }) => {
  const { messages } = useIntl();

  const dataTableConfig = {
    header: {
      title: "menu_reports",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.grades,
        id: "filter[grades]",
        type: "multiselect",
        options: [
          {
            name: "filter[grades]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[grades]",
            options: [
              {
                name: "+3",
                code: "+3",
              },
              {
                name: "+2",
                code: "+2",
              },
              {
                name: "+1",
                code: "+1",
              },
              {
                name: "0",
                code: "0",
              },
              {
                name: "-1",
                code: "-1",
              },
              {
                name: "-2",
                code: "-2",
              },
              {
                name: "-3",
                code: "-3",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            name: "filter[status]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[status]",
            options: reportStatusArray.map((singleStatus, index) => {
              return {
                name: messages[ReportStatus[singleStatus]],
                code: singleStatus,
                key: index,
              };
            }),
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ADMIN_ANALYSIS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "created_at",
            "status",
            "order_id",
            "grades",
            "person_initials",
            "order_item_id",
            "product_id",
            "analysis_type",
            "grade_data",
            "is_case",
          ],
        ],
        ["filter[customer_id]", customerId],
        ["include", ["orderItem", "product"]],
      ],
    },
    columns: [
      {
        title: "title_person",
        db_field: "person_initials",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "grade",
        db_field: "grades",
        type: "link",
        width: "100px",
        formatter: (value, report) => {
          return <GradeComponent value={value} report={report} />;
        },
      },
      {
        title: "label_order",
        db_field: "order_id",
        type: "id",
        width: "100px",
        className: "text-blue",
        href: value => {
          const { order_id } = value;
          return `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${order_id}`;
        },
        formatter: value => `#${value}`,
      },
      {
        title: "label_product",
        db_field: "product.name",
        type: "text",
        className: "text-black",
        sortable: true,
      },
      {
        title: "label_status",
        db_field: "status",
        type: "text",
        formatter: (value, db_fields) => {
          return <StatusComponent value={value} db_fields={db_fields} />;
        },
        className: "text-grey-dark",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            title: messages.label_details,
            type: "link",
            href: value => `${ROUTES.ADMIN_ANALYSIS_LIST.URL}/${value.id}`,
          },
        ],
      },
    ],
  };

  return (
    <ScrollToTop>
      <Container m={"0px !important"}>
        <DataTable config={dataTableConfig} />
      </Container>
    </ScrollToTop>
  );
};
Analysis.propTypes = {
  customerId: PropTypes.string,
};
export default Analysis;
