import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Span from "@components/Span";
import Icon from "@components/Icon";
import Editor from "@components/Editor";
import { useToast } from "@hooks/useToast";
import { useIntl } from "react-intl";

import { formatDateAndTime } from "@utils/utils";
import editComment from "@app/services/assignments/editComment";
import deleteComment from "@app/services/assignments/deleteComment";

const StyledCommentBody = styled.div`
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  ul {
    margin-top: 10px;
    li {
      margin-left: 20px;
      margin-top: 5px;

      &::before {
        content: "- ";
      }
    }
  }
`;

const Comment = props => {
  const { onSavedComment = () => {}, onDeleteComment } = props;
  const { id, comment, created_at, user } = props.comment;

  const [isEditComment, setIsEditComment] = useState(false);
  const [newComment, setNewComment] = useState(comment);
  const { showSuccessToast, showErrorToast } = useToast();
  const { messages } = useIntl();

  const handleLoad = quill => {
    quill.clipboard.dangerouslyPasteHTML(newComment);
  };

  const handleOnChangeComment = event => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleEditComment = () => {
    setIsEditComment(true);
  };

  const handleSaveComment = async () => {
    try {
      await editComment({ id, comment: newComment });
      showSuccessToast(messages.label_comment_updated);
      onSavedComment();
      setIsEditComment(false);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
  };

  const handleCancelEditComment = () => {
    setIsEditComment(false);
    setNewComment(comment);
  }

  const handleDeleteComment = async () => {
    try {
      await deleteComment(id);
      showSuccessToast(messages.label_comment_deleted);
      onDeleteComment(id);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
  };

  return (
    <Div width={1} key={created_at} my={24}>
      <Div display={["block", "flex"]} justifyContent="space-between" mb={2}>
        <Div display="flex" alignItems="center">
          <Icon
            name="account-circle"
            mr={1}
            color="var(--turquoise)"
            fontSize="var(--fs-h3)"
          />
          <H4 fontWeight="var(--semibold-weight) !important" mr={4}>
            {user?.name}
          </H4>
          <Span
            color="var(--light-gray) !important"
            fontWeight="var(--medium-weight) !important"
            lineHeight="21px"
          >
            {user?.type}
          </Span>
        </Div>
        <Div display="flex" alignItems="center" gridGap={2}>
          <Span color="var(--grey) !important" light>
            {formatDateAndTime(created_at)}
          </Span>
          {isEditComment ? (
            <>
              <Icon
                name="tick-mark"
                fontSize="var(--fs-icon)"
                color="var(--turquoise)"
                style={{ cursor: "pointer" }}
                onClick={handleSaveComment}
              />
              <Icon
                name="close"
                fontSize="var(--fs-icon)"
                color="var(--turquoise)"
                style={{ cursor: "pointer" }}
                onClick={handleCancelEditComment}
              />
            </>
          ) : (
            <>
              <Icon
                name="pen"
                fontSize="var(--fs-icon)"
                color="var(--turquoise)"
                style={{ cursor: "pointer" }}
                onClick={handleEditComment}
              />
              <Icon
                name="trash"
                fontSize="var(--fs-icon-m)"
                color="var(--turquoise)"
                style={{ cursor: "pointer" }}
                onClick={handleDeleteComment}
              />
            </>
          )}
        </Div>
      </Div>
      <Div>
        {isEditComment ? (
          <>
            <Editor
              width={1}
              my={2}
              value={newComment}
              onTextChange={handleOnChangeComment}
              onLoad={handleLoad}
            />
          </>
        ) : (
          <StyledCommentBody dangerouslySetInnerHTML={{ __html: newComment }} />
        )}
      </Div>
    </Div>
  );
};
Comment.propTypes = {
  key: PropTypes.string,
  comment: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onSavedComment: PropTypes.func,
  onDeleteComment: PropTypes.func.isRequired,
};

export default Comment;
