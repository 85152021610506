import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { RULES } from "@utils/constant";
import fetchProductById from "@app/services/services/fetchProductById";
import { useParams } from "react-router-dom";
const RulesTab = () => {
  const { messages } = useIntl();
  const { id = "" } = useParams();
  const [services, setServices] = useState([]);
  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";
  const searchableColumns = ["id", "name", "product_name"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [servicesResponse] = await Promise.all([fetchProductById()]);
        const { data: { data: products = [] } = {} } = servicesResponse;
        const formattedServices = products.map((o) => ({
          name: `${o?.name} #${o?.id}`,
          code: o?.id,
        }));
        setServices(formattedServices);
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
  }, []);
  const dataTableConfig = {
    header: {
      title: "menu_reports",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "rule_reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.date_created,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${RULES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["fields", ["id", "name", "type", "step", "created_at"]],
        ["filter[products.id]", id],
        ["include", ["products"]],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "rule_id_lable",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "date_created",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "label_rule_name",
        db_field: "name",
        type: "text",
      },
      {
        title: "type_label",
        db_field: "type",
        type: "text",
      },
      {
        title: "produc_id_lable",
        db_field: "products",
        type: "text",
        formatter: (value) => {
          const productIds = (value.length > 0 ? value : ["-"])
            .map((item) => item.id)
            .join(", ");
          return productIds;
        },
        tooltip: (value) => {
          const productIds = (value.length > 0 ? value : ["-"])
            .map((item) => `${item.name} - #${item.pivot.product_id}`)
            .join(", ");
          return productIds;
        },
      },
      {
        title: "step_lable",
        db_field: "step",
        type: "text",
        sortable: true,
        formatter: (value) => value || "-",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.view_edit_rule_lable,
            type: "link",
            icon: "icon-pen",
            href: () => {
              return "";
            },
          },
          {
            label: messages.label_delete_email,
            type: "link",
            icon: "icon-rubber",
            href: () => {
              return "";
            },
          },
        ],
      },
    ],
  };
  
  return (
<>
      {services.length > 0 && <DataTable config={dataTableConfig} />}
      </>
  );
};

export default RulesTab;
