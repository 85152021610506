import React from "react";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import Div from "@components/Div";
import { LinkArrow } from "@components/Link";
import { TextLarge } from "@components/Text";
import { useIntl } from "react-intl";
import { BACKGROUND_SE } from "@utils/constant";
import Routes from "../shared/Routes.js";
import Proptypes from "prop-types";

const ContactUs = (props) => {
  const { messages } = useIntl();
  return (
    <Div
      display="flex"
      flexWrap={["wrap", "wrap", "nowrap"]}
      alignItems="start"
      mt={["30px", "30px", "48px"]}
      width={1}
    >
      <Icon
        name="stack"
        rounded
        mr={[0, 0, "-19px"]}
        mt={(0, "24px")}
        mb={["-19px", "-19px", 0]}
        ml={[3, 3, 0]}
        position="relative"
        fontSize="var(--fs-icon-l)"
        padding="10px"
      ></Icon>
      <Div
        p={[3, 3, 4]}
        pl={[3, 3, 5]}
        pt={[4, 4, 4]}
        backgroundColor={
          props.backgroundColor ? props.backgroundColor : "var(--blue-lightest)"
        }
        width={1}
      >
        <H3 mb="15px">{messages.need_not_standard_package}</H3>
        <TextLarge>{messages.standard_package_info}</TextLarge>
        <LinkArrow
          href={BACKGROUND_SE + Routes.CONTACT_US_ID.URL}
          label={messages.contact_us}
          mt="20px"
          direction="left"
          variant="secondary"
        >
          <Icon ml={1} name="headerarrowright" />
        </LinkArrow>
      </Div>
    </Div>
  );
};
ContactUs.propTypes = {
  backgroundColor: Proptypes.string,
};

export default ContactUs;
