import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import Icon from "@components/Icon";
import Link from "@components/Link";
import { TextMediumWeight } from "@components/Text";
import { default as Div } from "@components/Div";

const StyledIcon = styled(Icon)`
  margin: 0 1px;
  font-size: ${(props) => props.iconSize};
  transform: ${(props) => props.flip && "scaleX(-1)"};
`;

const StyledText = styled(TextMediumWeight)`
  white-space: nowrap;
`;

const UtilsLink = ({ type }) => {
  const IconPosition = {
    Left: "left",
  };
  const {
    id,
    label,
    icon,
    onClick = () => {},
    iconSize = "",
    iconPos = IconPosition.Left,
    rowData,
  } = type;

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick({ ...e, data: rowData });
  };
  return (
    <StyledText mr={3}>
      <Link
        {...type}
        key={id}
        label={label}
        onClick={handleOnClick}
        iconPosition={iconPos}
      >
        <Div mx={2}>
          <StyledIcon
            name={icon}
            iconSize={iconSize}
            id={id}
            flip={id === "move"}
          />
        </Div>
      </Link>
    </StyledText>
  );
};

UtilsLink.propTypes = {
  type: Proptypes.object,
};

export default UtilsLink;
