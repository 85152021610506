import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Proptypes from "prop-types";
import styled from "styled-components";
import { DataTable as PRDataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { space, layout, flexbox, position } from "styled-system";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { HeaderFilterTemplate, BodyTemplate } from "./HeaderTemplate";
import Div from "@components/Div";
import Link from "@components/Link";
import Icon from "@components/Icon";
import Span from "@components/Span";
import Checkbox from "@components/Checkbox";
import {
  STATUS_VALUES,
  ASSIGNMENTS_API,
  LOGIN,
  ORDERS_DETAILS,
  ORDERS_API,
  GET_SUBSCRIPTION,
  SORT_KEY_UPDATED_AT,
  AVAILABLE_CREDITS,
  RENEWAL_DATE,
  SUBSCRIPTION_STATUS,
  GRADES,
  ORDERS_ID,
  STATUS_EXPIRED,
  ROLE_ANALYSIS,
  ADMIN_ANALYSIS_API,
  MANUAL_CHECKOUT,
  READ,
  CUSTOMER,
  LAST_REPORT_GENERATED_DATE,
  WATCH_LIST_OBJECTS_API,
  WATCH_LIST_HISTORY,
  PRICE,
  OBJECT_ORDER_DETAILS,
  ROUTES,
  SEE_REPORT,
  PENDING_CONSENT,
  PENDING_CONSENT_TO_SHARE,
  STATUS_IN_PROGRESS,
  CREDITS_PAYMENT,
  INVOICE_PAYMENT,
  CREDITS_AND_INVOICE_PAYMENT,
  GRADE_BG_COLOR,
  GRADE_COLOR,
  USERS,
} from "@utils/constant";
import useHistory from "@utils/useHistory";
import { TextMediumWeight, TextUpperCase } from "@components/Text";
import { H5, H4 } from "@components/Heading";
import listDataService from "@app/services/common/listDataService";
import { parseFilterParams, getSortKey } from "@utils/utils";
import { authActions } from "@app/reducers/authReducer";
import { FooterTemplate } from "@components/Table/FooterTemplate.jsx";
import { STATUS_FAILED, STATUS_COMPLETED } from "../../utils/constant";

const StyledDataTable = styled(PRDataTable)(space, layout, flexbox, position);

const StyledDiv = styled(Div)`
  i {
    transform: rotate(180deg);
  }
`;

const DataTable = (props) => {
  const { messages } = useIntl();
  const location = useHistory();
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { userInfo = {} } = useSelector((state) => state.authReducer);
  const { permissions = {}, type = "" } = userInfo;

  const {
    title,
    subTitle,
    headerActions,
    filterType,
    filters: filterOptions = [],
    columns,
    pagination,
    selectionMode,
    rowActions = [],
    onCellClick = () => {},
    footerActions,
  } = props.config[0];
  const {
    screenName = "",
    customerId = "",
    userType = "",
    reloadList = false,
    handleRedirectToDetails = () => {},
    tableData = false,
    onSelectionChange = () => {},
    selectedProducts = [],
    showActionButton = false,
    searchQuery = "",
    loadData = listDataService,
    watchListId = 0,
    clearSelectedProducts = () => {},
    noDataMessage = messages.label_no_anlayze_ordered,
  } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState(filterOptions);
  const [lazyParams, setLazyParams] = useState({
    rows: 10,
    page: 1,
    sortOrder: 1,
    first: 0,
    filters: {},
  });
  const [emptyMessage, setEmptyMessage] = useState(noDataMessage);

  const hasNoReportAccess =
    permissions?.["report-analysis"] !== READ &&
    type === CUSTOMER &&
    screenName === ADMIN_ANALYSIS_API.toLowerCase();

  const getPrice = (totalCredits, totalPrice, paymentType) => {
    if (paymentType === CREDITS_PAYMENT) {
      return `${totalCredits} ${messages.watchlist_title_credits}`;
    } else if (paymentType === INVOICE_PAYMENT) {
      return `${totalPrice} ${messages.watchlist_label_currency}`;
    } else if (paymentType === CREDITS_AND_INVOICE_PAYMENT) {
      return `${totalCredits} ${messages.watchlist_title_credits}<br />${totalPrice} ${messages.watchlist_label_currency}`;
    }
    return "";
  };

  const renderPriceDetails = (rowData) => {
    const {
      total_credits: totalCredits,
      total_price: totalPrice,
      payment_type: paymentType,
    } = rowData;
    const priceDetails = getPrice(totalCredits, totalPrice, paymentType);
    return (
      <TextMediumWeight>
        {priceDetails?.split("<br />").map((price) => (
          <>
            {price}
            <br />
          </>
        ))}
      </TextMediumWeight>
    );
  };
  const reportColumnList = (e, rowData) => {
    switch (true) {
      case e.field === GRADES: {
        if (rowData?.report_status !== STATUS_COMPLETED) {
          return <>-</>;
        }
        return (
          <Span
            bg={`var(--${rowData[GRADE_BG_COLOR]})`}
            width={40}
            height={30}
            display={"inline-block"}
            borderRadius={50}
          >
            <TextMediumWeight
              color={`var(--${rowData[GRADE_COLOR]})`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {rowData.analysis_type !== ROLE_ANALYSIS && rowData.grades}
            </TextMediumWeight>
          </Span>
        );
      }
      case e.field === ORDERS_ID &&
        !(
          screenName === WATCH_LIST_HISTORY ||
          screenName === OBJECT_ORDER_DETAILS
        ): {
        return (
          <Link
            handleClick={onCellClick.bind(this, rowData[e.field])}
            color="var(--blue-medium)!important"
          >
            #{rowData[e.field]}
          </Link>
        );
      }
      case e.field === LAST_REPORT_GENERATED_DATE: {
        return (
          <TextMediumWeight>
            <Link
              key={e.field}
              label={rowData[e.field]}
              handleClick={handleRedirectToWatchListOrderDetails.bind(
                e,
                rowData,
              )}
            >
              <Icon name={"headerarrowright"} mx={[0, 0, 1, 1]} />
            </Link>
          </TextMediumWeight>
        );
      }
      case e.field === PRICE && screenName === WATCH_LIST_HISTORY:
        return renderPriceDetails(rowData);
      case e.field === SEE_REPORT && screenName === OBJECT_ORDER_DETAILS: {
        return (
          <>
            {rowData[e.field] !== STATUS_EXPIRED ? (
              <TextMediumWeight>
                <Link
                  key={e.field}
                  label={rowData[e.field]}
                  handleClick={handleRedirectToReport.bind(e, rowData)}
                >
                  <Icon name={"headerarrowright"} mx={1} />
                </Link>
              </TextMediumWeight>
            ) : (
              <TextMediumWeight title={rowData[e.field]}>
                {rowData[e.field]}
              </TextMediumWeight>
            )}
          </>
        );
      }
      default: {
        return (
          <TextMediumWeight title={e.field === "product" && rowData[e.field]}>
            {messages[rowData[e.field]] || rowData[e.field]}
          </TextMediumWeight>
        );
      }
    }
  };
  const dataTableRef = useRef(null);

  const formatCsvData = (formattedCsvData) => {
    const formattedData = formattedCsvData.map((obj) => {
      const item = {};
      if (screenName === ORDERS_API || screenName === ADMIN_ANALYSIS_API) {
        const { csvFormat = {} } = obj;
        obj = csvFormat;
      }
      Object.entries(obj).map((x) => {
        const key = x[0]?.toString();
        const value = x[1]?.toString();
        item[key] = value;
        return x;
      });
      return item;
    });
    return formattedData;
  };

  const handleDownloadCSV = async () => {
    let param = "?export=1";
    try {
      const csvData = await loadData(screenName, param);
      const { data = [] } = csvData || {};
      const csv = Papa.unparse(formatCsvData(data));
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${screenName}.csv`);
    } catch (e) {
      throw new Error(e);
    }
  };

  const fetchPrimaryHeader = () =>
    columns
      .filter((value) => value.primary === true)
      .map((value) => value.field);

  const fetchPrimaryAction = () =>
    rowActions
      .filter((element) => element.isPrimary === true)
      .map((o) =>
        o.label === messages.label_more
          ? { ...o, onClick: handleRowClick }
          : { ...o },
      );

  const fetchSecondaryAction = () =>
    rowActions.filter((element) => !element.isPrimary);

  const handleFilters = (selectedFilters, options, event) => {
    const filterData = {};
    selectedFilters.map((obj) => {
      if (obj.id === "date") {
        if (screenName === ADMIN_ANALYSIS_API) {
          filterData["whereBetween[created_at]"] = [
            obj.values[0] ? obj.values[0] : "",
            obj.values[1] ? obj.values[1] : "",
          ];
        } else {
          filterData["start_date"] = [obj.values[0] ? obj.values[0] : ""];
          filterData["end_date"] = [obj.values[1] ? obj.values[1] : ""];
        }
      } else {
        filterData[obj.id] = [];
        filterData[obj.id].push(obj.values);
      }
      return obj;
    });
    let _lazyParams = {
      ...lazyParams,
      ...event,
      filters: filterData,
    };
    setLazyParams(_lazyParams);
    setFilters(options);
    setAppliedFilters(selectedFilters);
  };

  const headerTemplateProps = {
    title,
    appliedFilters,
    subTitle,
    headerActions,
    filterType,
    filters,
    onApplyFilter: handleFilters,
    onCancelFilter: handleFilters,
  };

  const handleRedirectToWatchListOrderDetails = (rowData, e) => {
    e.preventDefault();
    const id = rowData?.report?.order_id;
    if (id) {
      location.push(ORDERS_DETAILS, `?id=${id}`);
    }
  };

  const handleRedirectToReport = (rowData, e) => {
    e.preventDefault();
    const id = rowData?.id;
    if (id) {
      location.push(ROUTES.ORDER_RESULTS.URL, `?id=${id}`);
    }
  };

  const handleCheckbox = (data) => (e) => {
    const { id = "" } = data;
    if (e?.checked) {
      onSelectionChange({ value: [...selectedProducts, ...[data]] });
    } else {
      onSelectionChange({
        value: selectedProducts.filter((o) => o?.id !== id),
      });
    }
  };

  const renderPrimaryHeader = (getPrimaryHeader, rowData) => {
    if (
      STATUS_VALUES.includes(
        rowData[getPrimaryHeader[0]]?.toString().toLowerCase(),
      )
    ) {
      return messages[rowData[getPrimaryHeader[0]]?.toString().toLowerCase()];
    } else if (getPrimaryHeader[0] === "grades") {
      return (
        <Span
          bg={rowData[GRADE_BG_COLOR]}
          width={40}
          height={30}
          display={"inline-block"}
          borderRadius={50}
        >
          <TextMediumWeight
            color={rowData[GRADE_COLOR]}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {rowData.analysis_type !== ROLE_ANALYSIS && rowData.grades}
          </TextMediumWeight>
        </Span>
      );
    }
    return rowData[getPrimaryHeader?.[0]];
  };

  const resBodyTemplate = (rowData) => {
    const getPrimaryHeader = fetchPrimaryHeader();
    const headerActions = fetchPrimaryAction();
    const { report_status: reportStatusLabel } = rowData;
    const isReportExpired = reportStatusLabel === STATUS_EXPIRED;
    const isCompleted = reportStatusLabel === STATUS_COMPLETED;
    const isPendingConsent = reportStatusLabel === PENDING_CONSENT;
    const isPendingConsentToShare =
      reportStatusLabel === PENDING_CONSENT_TO_SHARE;
    const isFailed = reportStatusLabel === STATUS_FAILED;
    const isInProgress = reportStatusLabel === STATUS_IN_PROGRESS;

    let getheaderActions = [];
    const isRoleSpecificAnalysis = rowData.analysis_type === ROLE_ANALYSIS;

    if (!hasNoReportAccess) {
      if (isReportExpired && !isRoleSpecificAnalysis) {
        getheaderActions = [
          {
            id: "expired",
            label: messages.expired,
            type: "expired-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isPendingConsent && !isRoleSpecificAnalysis) {
        getheaderActions = [
          {
            id: "pending-consent",
            label: messages.consent_label_pending_consent,
            type: "pending-consent-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isPendingConsentToShare && !isRoleSpecificAnalysis) {
        getheaderActions = [
          {
            id: "pending-consent-share",
            label: messages.consent_label_pending_consent_share,
            type: "pending-consent-share-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isFailed && !isRoleSpecificAnalysis) {
        getheaderActions = [
          {
            id: "failed",
            label: messages.failed,
            type: "failed-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isInProgress && !isRoleSpecificAnalysis) {
        getheaderActions = [
          {
            id: "in-progress",
            label: messages.status_in_progress,
            type: "in-progress-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isRoleSpecificAnalysis || !isCompleted) {
        getheaderActions = [];
      } else {
        getheaderActions = headerActions;
      }
    }

    const props = {
      headerActions: getheaderActions,
      rowData: rowData,
    };
    const findProduct = !!selectedProducts.find(
      (obj) => obj?.id === rowData?.id,
    );
    return (
      <>
        <Div>
          {selectionMode === "checkbox" && (
            <Checkbox
              mr={2}
              onChange={handleCheckbox(rowData)}
              checked={findProduct}
            />
          )}

          <TextUpperCase>
            {renderPrimaryHeader(getPrimaryHeader, rowData)}
          </TextUpperCase>
        </Div>
        <BodyTemplate {...props} />
      </>
    );
  };
  const changeColorForCredits = (e, rowData) => {
    return e.field === AVAILABLE_CREDITS ? (
      <Div>
        <TextMediumWeight color="var(--grey-dark)">
          {messages[rowData[e.field]] || rowData[e.field]}
        </TextMediumWeight>

        <TextMediumWeight color="var(--grey)">
          /{messages[rowData["total_credits"]] || rowData["total_credits"]}
        </TextMediumWeight>
      </Div>
    ) : (
      reportColumnList(e, rowData)
    );
  };
  const changeColorForStopped = (e, rowData) => {
    return (e.field == SUBSCRIPTION_STATUS.STATUS || e.field == RENEWAL_DATE) &&
      rowData[SUBSCRIPTION_STATUS.STATUS] == SUBSCRIPTION_STATUS.STOPPED ? (
      <TextMediumWeight color="red">
        {messages[rowData[e.field]] || rowData[e.field]}
      </TextMediumWeight>
    ) : (
      changeColorForCredits(e, rowData)
    );
  };

  const getGradeValue = (e, rowData) => {
    let value;
    if (
      (rowData?.report_status === STATUS_COMPLETED ||
        rowData?.status === STATUS_COMPLETED) &&
      e?.field === "grades" &&
      rowData?.analysis_type !== MANUAL_CHECKOUT
    ) {
      value = reportColumnList(e, rowData);
    } else if (rowData[e.field] === 0) {
      value = 0;
    } else {
      value = "-";
    }
    return value;
  };

  const bodyTemplate = (rowData, e) => {
    if (e.field === "action") {
      const headerActions = fetchSecondaryAction();
      const props = { headerActions };
      return (
        <>
          {rowActions.length > 1 && (
            <>
              <TextUpperCase className="p-column-title">
                {e.header}
              </TextUpperCase>
              <Div display={"flex"} justifyContent="flex-end">
                <BodyTemplate {...props} />
              </Div>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <TextUpperCase className="p-column-title">
            {e.column.props.header}
          </TextUpperCase>
          {!!rowData[e.field] &&
            (STATUS_VALUES.includes(
              rowData[e.field].toString().toLowerCase(),
            ) ? (
              <TextMediumWeight
                className={`${rowData[e.field].toString().toLowerCase()}`}
              >
                {
                  messages[
                    rowData[e.field]
                      .toString()
                      .toLowerCase()
                      .replaceAll("-", "_")
                  ]
                }
              </TextMediumWeight>
            ) : (
              changeColorForStopped(e, rowData)
            ))}
          {!rowData[e.field] && (
            <>
              {screenName === ASSIGNMENTS_API && (
                <>
                  <Icon name="sign" color="var(--red)" mr={2} />{" "}
                  <TextMediumWeight className="not_assigned">
                    {messages["not_assigned"]}
                  </TextMediumWeight>
                </>
              )}
              {getGradeValue(e, rowData)}
            </>
          )}
        </>
      );
    }
  };

  const header = () => (
    <HeaderFilterTemplate
      {...headerTemplateProps}
      onDownload={handleDownloadCSV}
    />
  );

  const actionBodyTemplate = (rowData) => {
    const { report_status: reportStatusLabel } = rowData;
    const isReportExpired = reportStatusLabel === STATUS_EXPIRED;
    const isCompleted = reportStatusLabel === STATUS_COMPLETED;
    const isPendingConsent = reportStatusLabel === PENDING_CONSENT;
    const isPendingConsentToShare =
      reportStatusLabel === PENDING_CONSENT_TO_SHARE;
    let getActions = [];
    const isRoleSpecificAnalysis = rowData.analysis_type === ROLE_ANALYSIS;
    if (!hasNoReportAccess) {
      if (screenName === OBJECT_ORDER_DETAILS) {
        getActions = rowActions;
      } else if (isReportExpired && !isRoleSpecificAnalysis) {
        getActions = [
          {
            id: "expired",
            label: messages.expired,
            type: "expired-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isPendingConsent && !isRoleSpecificAnalysis) {
        getActions = [
          {
            id: "pending-consent",
            label: messages.consent_label_pending_consent,
            type: "pending-consent-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (isPendingConsentToShare && !isRoleSpecificAnalysis) {
        getActions = [
          {
            id: "pending-consent-share",
            label: messages.consent_label_pending_consent_share,
            type: "pending-consent-share-text",
            icon: "question",
            variant: "column",
            isPrimary: true,
            checkReportStatus: true,
          },
        ];
      } else if (
        isRoleSpecificAnalysis ||
        (!!rowData.analysis_type && !isCompleted)
      ) {
        getActions = [];
      } else {
        getActions = rowActions.map((o) =>
          o.label === messages.label_more
            ? { ...o, onClick: handleRowClick }
            : { ...o },
        );
      }
    }

    const actionBodyTemplateProps = {
      headerActions: getActions,
      rowData,
    };

    return <BodyTemplate {...actionBodyTemplateProps} />;
  };

  const paginatorTemplate = {
    layout: "PrevPageLink CurrentPageReport NextPageLink",

    // eslint-disable-next-line react/display-name
    PrevPageLink: (options) => {
      return (
        <Link
          onClick={options.onClick}
          disabled={options.disabled}
          className={options.className}
        >
          <StyledDiv display="flex" flexDirection="reverse" opacity={1} px={3}>
            <Icon name={"headerarrowright"} color={"var(--turquoise)"} mr={2} />
            <H4 color="var(--blue-dark)">{messages.label_previous}</H4>
          </StyledDiv>
        </Link>
      );
    },
    // eslint-disable-next-line react/display-name
    CurrentPageReport: (options) => {
      return (
        <H5>
          {options.first} - {options.last} {messages.text_of}{" "}
          {options.totalRecords}
        </H5>
      );
    },
    // eslint-disable-next-line react/display-name
    NextPageLink: (options) => {
      return (
        <Link
          onClick={options.onClick}
          disabled={options.disabled}
          className={options.className}
        >
          <Div display="flex" flexDirection="reverse" opacity={1} px={3}>
            <H4 color="var(--blue-dark)">{messages.label_next}</H4>
            <Icon ml={2} name={"headerarrowright"} color={"var(--turquoise)"} />
          </Div>
        </Link>
      );
    },
  };

  const onSort = (event) => {
    let _lazyParams = {
      ...lazyParams,
      ...event,
      sortOrder: lazyParams.sortOrder === -1 ? 1 : -1,
      filters: lazyParams?.filters,
    };
    setLazyParams(_lazyParams);
  };

  const onPage = (event) => {
    const { page } = event;
    let _lazyParams = {
      ...lazyParams,
      ...event,
      page: page + 1,
      filters: lazyParams?.filters,
    };
    setLazyParams(_lazyParams);
    if (selectionMode === "checkbox") {
      clearSelectedProducts();
    }
  };
  const handleRowClick = (event) =>
    hasNoReportAccess ||
    (event?.data?.analysis_type === ROLE_ANALYSIS && type === CUSTOMER) ||
    (!!event?.data?.analysis_type &&
      event?.data?.report_status !== STATUS_COMPLETED)
      ? () => {}
      : handleRedirectToDetails(event);

  useEffect(() => {
    const loadLazyData = async () => {
      try {
        setLoading(true);

        const {
          rows = 10,
          page = 1,
          sortOrder = 1,
          sortField = screenName === GET_SUBSCRIPTION
            ? SORT_KEY_UPDATED_AT
            : "created_at",
          filters = {},
        } = lazyParams;

        const sortKey = getSortKey(sortField, screenName);
        const sort = sortOrder === 1 ? "desc" : "asc";
        let payload = `?pagesize=${rows}&pagenumber=${page}&sort=${sort}&sortby=${sortKey}`;
        if (userType) {
          payload = `${payload}&type=${userType}`;
        }
        if (customerId) {
          if (screenName === "reports") {
            payload = `${payload}&where[customer_id]=${customerId}`;
          } else {
            payload = `${payload}&customer_id=${customerId}`;
          }
        }
        if (filters && Object.keys(filters).length) {
          payload = `${payload}&${parseFilterParams(["filter", filters])}`;
        }
        if (searchQuery && screenName === WATCH_LIST_OBJECTS_API) {
          const searchFilters = ["name", "ssn_number"];
          searchFilters.forEach((filter) => {
            payload = `${payload}&${filter}=${searchQuery}`;
          });
        }
        const listDataResponse = await loadData(
          screenName,
          payload,
          customerId,
          userInfo,
          watchListId,
        );
        if (listDataResponse.status !== 200) {
          throw listDataResponse;
        }
        const {
          data,
          meta: { total = "" },
        } = listDataResponse;
        setTotalRecords(total);
        setData(data);
        setLoading(false);
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          setLoading(false);
          setEmptyMessage(messages.text_no_data_found);
          localStorage.clear();
          dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
          location.push(LOGIN);
          return;
        }

        setLoading(false);
        setEmptyMessage(messages.text_no_data_found);
      }
    };
    if (screenName && !tableData) {
      loadLazyData();
    } else {
      setTotalRecords(tableData.length);
      setData(tableData);
      setLoading(false);
    }
  }, [
    customerId,
    dispatch,
    lazyParams,
    location,
    messages.text_no_data_found,
    reloadList,
    screenName,
    userType,
    tableData,
    userInfo,
    loadData,
    watchListId,
    searchQuery,
  ]);

  useEffect(() => {
    setFilters(filterOptions);
  }, [filterOptions]);

  return (
    <Div className="datatable-doc-demo">
      <Div className="card" mt={screenName === WATCH_LIST_OBJECTS_API ? 2 : 0}>
        <StyledDataTable
          ref={dataTableRef}
          value={data}
          header={header()}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          paginator={pagination}
          paginatorTemplate={paginatorTemplate}
          selectionMode={selectionMode}
          rows={10}
          emptyMessage={emptyMessage}
          loading={loading}
          lazy={!!screenName}
          onSort={!!screenName && onSort}
          onPage={onPage}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          totalRecords={totalRecords}
          first={lazyParams.first}
          onRowClick={handleRowClick}
          onSelectionChange={onSelectionChange}
          selection={selectedProducts}
          globalFilter={searchQuery}
          footer={<FooterTemplate footerActions={footerActions} />}
        >
          <Column body={resBodyTemplate} />
          {selectionMode === "checkbox" && (
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "52px" }}
              key={"id"}
            />
          )}
          {columns.map((value, i) => {
            const { sortable = true } = value;
            return (
              <Column
                key={i}
                field={value.field}
                header={value.header}
                sortable={sortable}
                body={bodyTemplate}
                column={{ header: value.header }}
                style={{ width: value.width }}
              />
            );
          })}
          <Column
            body={actionBodyTemplate}
            style={{
              width:
                (!screenName && !showActionButton) || hasNoReportAccess
                  ? "0px"
                  : screenName === USERS || screenName === ADMIN_ANALYSIS_API
                  ? "260px"
                  : "160px",
            }}
          />
        </StyledDataTable>
      </Div>
    </Div>
  );
};

DataTable.propTypes = {
  config: Proptypes.array,
  screenName: Proptypes.string,
  handleRedirectToDetails: Proptypes.func,
  userType: Proptypes.string,
  customerId: Proptypes.number,
  reloadList: Proptypes.bool,
  tableData: Proptypes.array,
  onSelectionChange: Proptypes.func,
  selectedProducts: Proptypes.array,
  showActionButton: Proptypes.bool,
  searchQuery: Proptypes.string,
  watchListId: Proptypes.number,
  loadData: Proptypes.func,
  clearSelectedProducts: Proptypes.func,
  noDataMessage: Proptypes.string,
};

export default DataTable;
