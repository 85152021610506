import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FilterMatchMode } from "primereact/api";
import { space, layout, flexbox, position } from "styled-system";
import { DataTable as PRDataTable } from "primereact/datatable";
import { FooterTemplate } from "./FooterTemplate";
import { Column } from "primereact/column";
import Div from "../Div";
import { HeaderFilterTemplate } from "./HeaderTemplate";
import { bodyTemplate } from "./BodyTemplate";
import { paginatorTemplate } from "./PaginatorTemplate";
import Dropdown from "@components/Dropdown";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { Skeleton } from "primereact/skeleton";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import Dialog from "@components/Dialog";
import { Text } from "@components/Text";
import { PrimaryButton } from "@components/Button";
import { H3 } from "@components/Heading";

const StyledDataTable = styled(PRDataTable)(space, layout, flexbox, position);

const DataTable = ({ config }) => {
  const { messages } = useIntl();
  const [data, setData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(
    config?.defaultappliedFilters || [],
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const { search: urlParam = "" } = useLocation();
  const params = new URLSearchParams(urlParam);
  const [sort, sortOrder] = params.get("orderBy")
    ? params.get("orderBy").split(",")
    : [];
  const { intialSort = "created_at" } = config;
  const [lazyParams, setLazyParams] = useState({
    rows: Number(params.get("per_page")) || 20,
    page: Number(params.get("page")) || 1,
    sortOrder: sortOrder || 1,
    sortField: sort || intialSort,
    first: Number(params.get("first")) || 0,
    filters: params.get("filters") || {},
    search: params.get("search") || "",
  });
  const [selectedCell, setSelectedCell] = useState(null);
  const [searchQuery, setSearchQuery] = useState(params.get("search") || "");

  const skeletonLoader = [...Array(lazyParams?.rows)].map((o) => ({ id: o }));

  const {
    title,
    subTitle,
    headerActions,
    columns,
    pagination,
    footerActions,
    loadData,
    onCellSelect,
    searchAction = false,
    dataTableActions = [],
    filterType,
    filters: filterOptions = [],
    screenName = "",
    lazyload = true,
    globalFilterFields,
    allowEdit = false,
    emptyMessage = messages.no_results_found,
    reloadScreen = false,
    updateData = () => {},
    dropDownOptons = {},
    isFilterValuesLoaded = false,
    setIsFilterValuesLoaded = () => {},
    defaultappliedFilters = [],
  } = config;
  const [loading, setLoading] = useState(false);

  const [customSearchFilters, setCustomSearchFilters] = useState(null);
  const [intialLoad, setIntialLoad] = useState(true);
  const [customTableFilters, setCustomTableFilters] = useState(null);
  const [isCsvDownloaded, setIsCsvDownloaded] = useState(false);

  const customFilters = { ...customSearchFilters, ...customTableFilters };

  const [filters, setFilters] = useState(filterOptions);

  useEffect(() => {
    if (isFilterValuesLoaded) {
      setFilters(filterOptions);
    }
  }, [isFilterValuesLoaded, filterOptions]);

  useEffect(() => {
    setLoading(reloadScreen);
  }, [reloadScreen]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setLoading(true);
        const { data = [], meta: { total = "" } = {} } = await loadData(
          lazyParams,
          false,
        );
        setData(data);
        setTotalRecords(lazyload ? total : data?.length);
        setIntialLoad(false);
      } catch (error) {
        setData([]);
        setTotalRecords(0);
        // catch the error
      } finally {
        setLoading(false);
      }
    };
    if (lazyload || intialLoad) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyParams]);

  const onSort = (event) => {
    let _lazyParams = {
      ...lazyParams,
      ...event,
      sortOrder: lazyParams.sortOrder === -1 ? 1 : -1,
      filters: lazyParams?.filters,
    };
    setLazyParams(_lazyParams);
  };

  const handleFilters = (selectedFilters, options, event) => {
    const filterData = {};
    setIsFilterValuesLoaded(false);

    selectedFilters.map((obj) => {
      if (obj.id === "date") {
        filterData["start_date"] = [obj.values[0] ? obj.values[0] : ""];
        filterData["end_date"] = [obj.values[1] ? obj.values[1] : ""];
      } else if (obj.type === "multiselect") {
        filterData[obj.id] = [];
        const [filtervalue = []] = obj.values || [];
        filterData[obj.id].push(
          filtervalue?.map((o) => o?.code || "").join(","),
        );
      } else {
        filterData[obj.id] = [];
        filterData[obj.id].push(obj.values);
      }
      return obj;
    });
    let _lazyParams = {
      ...lazyParams,
      ...event,
      filters: filterData,
    };
    setLazyParams(_lazyParams);
    setFilters(options);
    setAppliedFilters(selectedFilters);
    if (!lazyload) {
      const findFilters = {};
      selectedFilters.map((o) => {
        const { id, values } = o;
        findFilters[id] = { value: values, matchMode: FilterMatchMode.EQUALS };
        return o;
      });
      setCustomTableFilters(findFilters);
    }
  };

  useEffect(() => {
    if (defaultappliedFilters?.length) {
      const findFilters = {};
      defaultappliedFilters.map((o) => {
        const { id, values } = o;
        findFilters[id] = { value: values, matchMode: FilterMatchMode.EQUALS };
        return o;
      });
      setCustomTableFilters(findFilters);
    }
  }, [defaultappliedFilters]);

  const handleCustomSearch = (lazyParams) => {
    const { search = "" } = lazyParams;
    setCustomSearchFilters({
      global: { value: search, matchMode: FilterMatchMode.CONTAINS },
    });
  };

  const handleSearch = (event) => {
    const {
      target: { value = "" },
    } = event;
    setSearchQuery(value);
    setLazyParams({
      ...lazyParams,
      search: value,
      filters: lazyParams?.filters,
    });
    if (!lazyload) {
      handleCustomSearch({
        ...lazyParams,
        search: value,
        filters: lazyParams?.filters,
      });
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setLazyParams({
      ...lazyParams,
      search: "",
      filters: lazyParams?.filters,
    });
    if (!lazyload) {
      handleCustomSearch({
        ...lazyParams,
        search: "",
        filters: lazyParams?.filters,
      });
    }
  };

  const handleSearchChange = (e) => {
    const {
      target: { value = "" },
    } = e;
    setSearchQuery(value);
    if (lazyParams?.search && value === "") {
      setLazyParams({
        ...lazyParams,
        search: "",
        filters: lazyParams?.filters,
      });
      if (!lazyload) {
        handleCustomSearch({
          ...lazyParams,
          search: "",
          filters: lazyParams?.filters,
        });
      }
    }
  };

  const formatCsvData = (formattedCsvData = []) => {
    const formattedData = formattedCsvData?.map((obj) => {
      const item = {};
      const { csvFormat = {} } = obj;
      Object.entries(csvFormat).map((x) => {
        const key = x[0]?.toString();
        const value = x[1]?.toString();
        item[key] = value;
        return x;
      });
      return item;
    });
    return formattedData;
  };

  const handleDownloadCSV = async () => {
    setLoading(true);
    try {
      const csvData = await loadData(
        { ...lazyParams, rows: totalRecords },
        true,
      );
      const { data = [] } = csvData || {};
      const csv = Papa.unparse(formatCsvData(data));
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${screenName}.csv`);
      setIsCsvDownloaded(!isCsvDownloaded);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoading(false);
    }
  };

  const headerTemplateProps = {
    title,
    subTitle,
    headerActions,
    searchAction: searchAction
      ? {
          ...searchAction,
          onChange: handleSearchChange,
          onClear: handleClearSearch,
          onSearch: handleSearch,
          value: searchQuery,
        }
      : searchAction,
    dataTableActions,
    appliedFilters,
    filterType,
    filters,
    onApplyFilter: handleFilters,
    onCancelFilter: handleFilters,
    onDownload: handleDownloadCSV,
  };

  const header = () => <HeaderFilterTemplate {...headerTemplateProps} />;

  const onPage = (event) => {
    const { page } = event;
    const { first, rows } = event;
    let _lazyParams = {
      ...lazyParams,
      ...event,
      page: page + 1,
      first: first,
      rows: rows,
      filters: lazyParams?.filters,
    };
    setLazyParams(_lazyParams);
  };

  const onRowsChange = (event) => {
    let _lazyParams = {
      ...lazyParams,
      ...event,
      rows: event?.value,
      page: 1,
      first: 0,
      filters: lazyParams?.filters,
    };
    setLazyParams(_lazyParams);
  };

  const rowsPerPageDropdown = (options) => {
    return (
      <Dropdown
        onChange={onRowsChange}
        value={options?.value}
        name="rows"
        optionLabel={"label"}
        options={options?.options}
        width={"auto"}
      />
    );
  };

  const paginator = {
    ...paginatorTemplate,
    RowsPerPageDropdown: rowsPerPageDropdown,
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options?.value}
        name={options?.field}
        width={"-webkit-fill-available"}
        formikProps={{
          handleChange: (e) => options.editorCallback(e.target.value),
        }}
      />
    );
  };

  const dropDownEditor = (options) => {
    return (
      <Dropdown
        placeholder={messages.placeholder_choose}
        value={options?.value}
        name={options?.field}
        options={dropDownOptons[options?.field]}
        onChange={(e) => options.editorCallback(e.target.value)}
        width={"-webkit-fill-available"}
        mb={0}
      />
    );
  };

  const onRowEditComplete = async (e) => {
    let { newData } = e;
    setLoading(true);
    try {
      await updateData(newData);
      const { data = [], meta: { total = "" } = {} } = await loadData(
        lazyParams,
        false,
      );
      setTotalRecords(lazyload ? total : data?.length);
      setData(data);
      setIntialLoad(false);
    } catch (e) {
      setTotalRecords(0);
      setData([]);
      //catch error
    } finally {
      setLoading(false);
    }
  };

  const getRespectiveEditor = (value) => {
    if (value?.dropdown) {
      return dropDownEditor;
    } else {
      return textEditor;
    }
  };

  const handleCloseCsvPopup = () => {
    setIsCsvDownloaded(!isCsvDownloaded);
  };

  return (
    <>
      {isCsvDownloaded && (
        <Dialog
          header={<H3 textAlign="center">{messages.download_csv}</H3>}
          visible="displayBasic"
          width={[1, 1, 500, 500]}
          draggable={false}
          onHide={handleCloseCsvPopup}
          m={[3, 3, "auto", "auto"]}
        >
          <Div display="flex" alignItems="center" flexDirection="column">
            <Div my={3} pb={3} textAlign="center">
              <Text>{messages.message_download_csv}</Text>
            </Div>
            <PrimaryButton
              rounded
              semibold
              label={messages.label_ok}
              onClick={handleCloseCsvPopup}
              mb={[4, 4, 4, 0]}
              width={[1, 1, 1, "45%"]}
            />
          </Div>
        </Dialog>
      )}
      <Div className="datatable-demo">
        <StyledDataTable
          value={loading ? skeletonLoader : data}
          header={header()}
          className="p-datatable-custom"
          dataKey="id"
          rowHover
          paginator={pagination}
          emptyMessage={emptyMessage}
          paginatorTemplate={paginator}
          selectionMode={true}
          rows={lazyParams?.rows}
          rowsPerPageOptions={[20, 50, 100]}
          lazy={lazyload}
          onPage={onPage}
          onRowsChange={onRowsChange}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          totalRecords={totalRecords}
          first={lazyParams.first}
          footer={<FooterTemplate footerActions={footerActions} />}
          onCellSelect={onCellSelect}
          cellSelection
          selection={selectedCell}
          onSelectionChange={(e) => setSelectedCell(e.value)}
          onSort={onSort}
          sortIcon={<Icon name="sort" />}
          filters={customFilters}
          globalFilterFields={globalFilterFields}
          onRowEditComplete={onRowEditComplete}
          editMode="row"
        >
          {columns.map((value) => {
            const { sortable = true } = value;
            return (
              <Column
                key={value.field}
                field={value.field}
                header={value.header}
                sortable={sortable}
                editor={value.allowEdit ? getRespectiveEditor(value) : false}
                body={loading ? <Skeleton /> : bodyTemplate[value.body]}
                column={{ header: value.header }}
                style={{ width: value.width }}
                className={value.className}
              />
            );
          })}
          {allowEdit && (
            <Column
              rowEditor={true}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            />
          )}
        </StyledDataTable>
      </Div>
    </>
  );
};

DataTable.propTypes = {
  config: PropTypes.object,
};

export default DataTable;
