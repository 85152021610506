import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { default as Div } from "@components/Div";
import { H2, H4, H3 } from "@components/Heading";
import ActionTemplate from "./ActionTemplate";
import FilterTemplate from "./FilterTemplate";
import Logo from "../Logo";
import CloseIcon from "@assets/close-icon.svg";
import { Text, TextMediumWeight } from "@components/Text";
import { onFilterSelectedData } from "@utils/utils";

export const HeaderFilterTemplate = (props) => {
  const {
    title,
    subTitle,
    headerActions,
    filterType,
    filters,
    onApplyFilter,
    onCancelFilter,
    appliedFilters,
  } = props;
  let [options, setOptions] = useState(filters);

  const handleOnChangeOfFilterValue = (value, type, id, optionId) => (e) => {
    const filterSelectedData = options.map((optValues) => {
      if (optValues.options)
        optValues.options = optValues.options.map((obj) => {
          if (type === "calendar" && obj.id === id) {
            return { ...obj, value: "" };
          } else if (type === "input" && obj.id === id) {
            return { ...obj, value: "" };
          } else if (type === "multiselect" && obj.id === id && optionId) {
            return { ...obj, value: "" };
          } else if (obj.value === value) {
            return { ...obj, checked: !obj.checked };
          }
          return obj;
        });
      return optValues;
    });
    setOptions(filterSelectedData);
    const selectedData = onFilterSelectedData(options);
    onApplyFilter(selectedData, filterSelectedData, e);
  };

  const filterTemplateProps = {
    filterType,
    filters,
    onApplyFilter,
    onCancelFilter,
    options,
    setOptions,
    handleOnChangeOfFilterValue,
    onFilterSelectedData,
  };

  const { messages } = useIntl();
  const [displayBasic, setDisplayBasic] = useState(false);
  const [position, setPosition] = useState("center"); // eslint-disable-line

  const handleOnClick = (e, name, pos = "center") => {
    setDisplayBasic(true);
    setPosition(pos);
  };

  useEffect(() => {
    setOptions(filters);
  }, [filters]);

  const handleOnHide = () => {
    setDisplayBasic(false);
  };

  const filterSelectedValues = (values, data) => {
    const { title = "", options = [], type = "" } = data;
    return values.map((value, i) => {
      let {
        label = "",
        id = "",
        options: multiselectOptions = [],
      } = options.find((opt) => opt.value === value) || {};
      let datelabel = "",
        filterId = "",
        optionId = "";
      if (type === "calendar") {
        label = value;
        datelabel = i ? messages.filter_date_to : messages.filter_date_from;
        filterId = i ? "end_date" : "start_date";
      }
      if (type === "input") {
        label = value;
        filterId = id;
      }
      if (type === "multiselect") {
        const values = value.map((obj) => {
          const filteredValue = multiselectOptions.find((o) => o.value === obj);
          return filteredValue?.name;
        });
        label = values.join(", ");
        optionId = value;
        filterId = id;
      }
      return (
        <Div
          display="flex"
          alignItems="center"
          bg="var(--dark-gray)"
          p={2}
          key={i}
          mr={3}
          mb={3}
          onClick={handleOnChangeOfFilterValue(value, type, filterId, optionId)}
        >
          {getFilterCapsule(datelabel, title, label)}
        </Div>
      );
    });
  };

  const renderFiterCapules = () => {
    return appliedFilters.map((obj) => {
      const { id, values } = obj;
      const data = filters.find((filter) => filter.id === id);
      return filterSelectedValues(values, data);
    });
  };

  const getFilterCapsule = (datelabel, title, label) => (
    <>
      <Logo width={9} height={9} mr={10} logo={CloseIcon} />
      <Text
        color="var(--light-gray) !important"
        fontWeight="var(--light-weight) !important"
      >
        {datelabel || title}:
      </Text>
      <TextMediumWeight ml={1}>{label}</TextMediumWeight>
    </>
  );

  const headerComponent = (children) => (
    <>
      <Div
        className="table-header"
        flexDirection={["column", "column", "row", "row"]}
      >
        <Div flexDirection={"column"} width={[1, 1, "auto", "auto"]}>
          <H2 my={3}>{title}</H2>
          {subTitle && <H4 my={3}>{subTitle}</H4>}
        </Div>
        <Div
          alignItems={"center"}
          width={[1, 1, "auto", "auto"]}
          mb={[3, 3, 0, 0]}
        >
          {children}
        </Div>
        <FilterTemplate
          {...filterTemplateProps}
          showDialog={displayBasic}
          onHide={handleOnHide}
        />
      </Div>
      {!!appliedFilters.length && (
        <Div display="flex" alignItems="center" flexWrap="wrap">
          <H3 mr={20} mb={3}>
            {messages.filter_active}:
          </H3>
          {renderFiterCapules()}
        </Div>
      )}
    </>
  );
  const headerEl = headerActions.map((value) => {
    if (value.id === "filter") {
      return ActionTemplate(value.type, { ...value, onClick: handleOnClick });
    } else if (value.id === "search") {
      return ActionTemplate(value.type, { ...value });
    } else if (value.id === "menu-button") {
      return ActionTemplate(value.type, { ...value });
    } else if (value.id === "download") {
      return ActionTemplate(value.type, {
        ...value,
        onClick: props.onDownload,
      });
    }
    return ActionTemplate(value.type, value);
  });
  return headerComponent(headerEl);
};

export const BodyTemplate = (headerTemplateProps) => {
  const { headerActions, rowData } = headerTemplateProps;
  const elComponent = (children) => (
    <Div display="inline-flex" justifyContent="space-between">
      {children}
    </Div>
  );
  const el = headerActions.map((value) => {
    return ActionTemplate(value.type, value, rowData);
  });
  return elComponent(el);
};
