import React from "react";
import SideMenuContent from "./SideMenuContent";
import { ADMIN_NAV } from "@utils/constant";
import Div from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/adminLOGO.svg";
import Config from "@src/config";
import useAuthorization from "@hooks/useAuthorization";

const handleRedirect = () => {
  window.location = `${Config.WP_URL}/`;
};

const LogoComponent = () => (
  <Div display="flex" justifyContent="center" my={4}>
    <Logo
      display="block"
      logo={LogoFull}
      height={"auto"}
      onClick={handleRedirect}
    />
  </Div>
);

const AdminSideMenu = () => {
  const { hasAnyPermissions } = useAuthorization();
  let menuItems = ADMIN_NAV.filter((obj) => {
    const { permissions = [] } = obj;
    return hasAnyPermissions(permissions);
  });

  menuItems = menuItems.map((item) => {
    if (item?.isDropDown) {
      const dropDownOptions = item?.dropDownOptions.filter((obj) => {
        const { permissions = [] } = obj;
        return hasAnyPermissions(permissions);
      });
      return { ...item, dropDownOptions };
    }
    return { ...item };
  });

  const props = {
    Component: LogoComponent,
    navItems: [...menuItems],
  };
  return <SideMenuContent {...props} />;
};

export default AdminSideMenu;
