import React from "react";
import { H3, ErrorLight } from "@components/Heading";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import { Text, TextSemiBoldWeight } from "@components/Text";
import PropTypes from "prop-types";

const SearchResultErrorCardHeader = ({ ssn = "" }) => {
  const { messages } = useIntl();

  return (
    <Div
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      justifyContent="space-between"
      alignItems={["", "", "center", "center"]}
    >
      <H3 display="flex" alignItems="center">
        <ErrorLight>{messages.service_unavailable_title}</ErrorLight>
      </H3>
      <Div
        display="flex"
        flexDirection={["row", "row", "column", "column"]}
        justifyContent="space-between"
        alignItems={["center", "center", "start", "start"]}
      >
        <Text>{messages.search_label_number}</Text>
        <TextSemiBoldWeight mt={1}>{`${ssn}`}</TextSemiBoldWeight>
      </Div>
    </Div>
  );
};

SearchResultErrorCardHeader.propTypes = {
  ssn: PropTypes.string,
};

export default SearchResultErrorCardHeader;
