import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { PrimaryButton } from "@components/Button";
import FullSizeDialog from "@components/FullSizeDialog";
import Div from "@components/Div";
import { SurveyBuilder } from "@components/Survey";

import QuestionDialog from "./QuestionDialog";

const SurveyBuilderDialog = ({
  title,
  data,
  surveyCreatorActiveTab,
  onHide,
  onUpdate,
}) => {
  const { messages } = useIntl();

  const [isQuestionDialogVisible, setIsQuestionDialogVisible] = useState(false);
  const [shouldAskUserForSave, setShouldAskUserForSave] = useState(false);

  const formDataRef = useRef(null);

  const handleOpenQuestionDialog = () => {
    if (!shouldAskUserForSave) {
      onHide();

      return;
    }

    setIsQuestionDialogVisible(true);
  };

  const handleCloseQuestionDialog = () => {
    setIsQuestionDialogVisible(false);
    onHide();
  };

  const handleUpdateFormData = useCallback(formDataJson => {
    formDataRef.current = JSON.stringify(formDataJson);
  }, []);

  const handleSaveChanges = () => {
    if (!formDataRef.current) {
      return;
    }

    onUpdate(formDataRef.current);
    onHide();
  };

  const handleModify = useCallback(() => {
    setShouldAskUserForSave(true);
  }, []);

  const handleSaveManually = useCallback(() => {
    onUpdate(formDataRef.current);

    setShouldAskUserForSave(false);
  }, [onUpdate]);

  return (
    <FullSizeDialog fullWidth title={title} onClose={handleOpenQuestionDialog}>
      {isQuestionDialogVisible && (
        <QuestionDialog
          onConfirm={handleSaveChanges}
          onCancel={handleCloseQuestionDialog}
        />
      )}

      <Div
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flex={1}
        width="100%"
        height="100%"
        backgroundColor="var(--white)"
        className="report-config"
      >
        <SurveyBuilder
          data={data}
          activeTab={surveyCreatorActiveTab}
          onUpdate={handleUpdateFormData}
          onModify={handleModify}
        />

        {shouldAskUserForSave && (
          <Div
            width="100%"
            py={3}
            px={4}
            backgroundColor="var(--white)"
            borderTop="2px solid var(--grey-lightest) !important"
          >
            <PrimaryButton
              width="10rem"
              label={messages.label_save}
              onClick={handleSaveManually}
            />
          </Div>
        )}
      </Div>
    </FullSizeDialog>
  );
};

SurveyBuilderDialog.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
  surveyCreatorActiveTab: PropTypes.string,
  onHide: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default SurveyBuilderDialog;
