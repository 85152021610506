import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { SVLANG } from "@utils/constant";

/**
 * Subscription fields validation
 */
const SpecialSubscriptionValidation = Yup.object().shape({
  package: Yup.number().required(
    <FormattedMessage id="error_package_id" defaultMessage="Select Package" />,
  ),
  availableanalysis: Yup.string().when("specialInputs", {
    is: true,
    then: () =>
      Yup.string()
        .required(
          <FormattedMessage
            id="error_availableanalysis"
            defaultMessage="Please Enter Available Analysis"
          />,
        )
        .when("totalanalysis", (totalanalysis, schema) => {
          return schema.test({
            test: (availableanalysis) => {
              return availableanalysis <= totalanalysis;
            },
            message: SVLANG.validation_availableanalysis_msg,
          });
        }),
  }),

  totalanalysis: Yup.string().when("specialInputs", {
    is: true,
    then: () =>
      Yup.string()
        .required(
          <FormattedMessage
            id="error_totalanalysis"
            defaultMessage="Please Enter Total Analysis"
          />,
        )
        .when("availableanalysis", (availableanalysis, schema) => {
          return schema.test({
            test: (totalanalysis) => {
              return totalanalysis >= availableanalysis;
            },
            message: SVLANG.validation_totalanalysis_msg,
          });
        }),
  }),

  price: Yup.string().when("specialInputs", {
    is: true,
    then: () =>
      Yup.string().required(
        <FormattedMessage id="error_price" defaultMessage="Enter price" />,
      ),
  }),
});

export default SpecialSubscriptionValidation;
